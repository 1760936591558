<template>
	<div class="modal-sidebar">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Settings', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="top" v-if="activeSlide != 'edit_profile'">
			<h1>{{ $t('settings.my-profile') }}</h1>
			<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
		</div>
		<div class="profile-summary" @click="changeActiveSlide('edit_profile')" v-if="activeSlide != 'edit_profile'">
			<img :src="$auth.user().avatar" v-if="$auth.user().avatar">
			<div class="user-circle-default" v-else>
				<icon-user-settings/>
			</div>
			<p>
				{{ $auth.user().first_name+" "+$auth.user().last_name }}
				<br>
				<span>{{ $auth.user().email }}</span>
			</p>
		</div>
		<div class="main-modal">
			<transition name="slide" mode="out-in">
				<div class="main-screen-settings" v-if="activeSlide == 'main-settings-page'">
					<div class="top top-minus-margin">
						<h1>{{ $t('settings.settings') }}</h1>
						<img src="/build/icons/settings-icn.svg">
					</div>
					<ul class="list-with-arrow">
						<li class="custom" v-if="$auth.user().rights.view_subscription && $auth.user().rights.instance != 'new'">
							<p>
								{{ $t('settings.subscription') }}
								<span>{{ $t('settings.subscription-change') }}</span>
							</p>
							<button :class="[$auth.user().package.can_upgrade_account ? 'button-tbf-blue' : 'btn-default-tbf']" v-if="$auth.user().rights.view_upgrade" @click="changeActiveSlide('subscription_general')">{{ $t('settings.change') }}</button>
						</li>
						<li  @click="changeActiveSlide('list_tags')" v-if="$auth.user().rights.view_tag">
							<p>
								{{ $t('settings.tags') }}
								<span>{{ $t('settings.tags-change') }}</span>
							</p>
						</li>
						<li @click="changeActiveSlide('list_invoices')" v-if="$auth.user().rights.view_subscription">
							<p>
								{{ $t('settings.invoices') }}
								<span>{{ $t('settings.invoices-list') }}</span>
							</p>
						</li>

						<!-- <li @click="changeActiveSlide('list_referrals')" v-if="$auth.user().rights.view_subscription">
							<p>
								{{ $t('settings.referrals') }}
								<span>{{ $t('settings.referrals-list') }}</span>
							</p>
						</li> -->

						<li @click="changeActiveSlide('list_download_links')">
							<p>
								{{ $t('settings.downloads') }}
								<span>{{ $t('settings.downloads-list') }}</span>
							</p>
						</li>
					</ul>
				</div>
				<!-- <list-roles  v-if="activeSlide == 'list_roles'" @change_slide="changeActiveSlide"></list-roles> -->
				<!-- <create-role :model="modelRole" v-if="activeSlide == 'create_role'" @change_slide="changeActiveSlide"></create-role> -->
				<list-tags  v-if="activeSlide == 'list_tags'" @change_slide="changeActiveSlideTag"></list-tags>
				<list-invoices  v-if="activeSlide == 'list_invoices'" @change_slide="changeActiveSlide"></list-invoices>
				<list-referrals  v-if="activeSlide == 'list_referrals'" @change_slide="changeActiveSlide"></list-referrals>
				<profile  v-if="activeSlide == 'edit_profile'" @change_slide="changeActiveSlide" @close_modal="closeModal"></profile>
				<create-tag :model="modelTag" v-if="activeSlide == 'create_tag'" @change_slide="changeActiveSlideTag"></create-tag>
				<!-- Billing -->
				<subscription-general  v-if="activeSlide == 'subscription_general'" @change_slide="changeActiveSlide"></subscription-general>
				<upgrade-subscription  v-if="activeSlide == 'upgrade_subscription'" @change_slide="changeActiveSlide"></upgrade-subscription>
				<card-details v-if="activeSlide == 'card_details'" @change_slide="changeActiveSlide"></card-details>
				<invoice-details  v-if="activeSlide == 'invoice_details'" @change_slide="changeActiveSlide"></invoice-details>
				<add-emails-invoices v-if="activeSlide == 'add_emails_invoices'" @change_slide="changeActiveSlide"/>
				<download-links v-if="activeSlide == 'list_download_links'" @change_slide="changeActiveSlide"/>
			</transition>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	// import CreateRole from "../SubModals/CreateRole";
	// import ListRoles from "../SubModals/ListRoles";
	import CreateTag from "../SubModals/CreateTag";
	import ListTags from "../SubModals/ListTags";
	import ListReferrals from "../SubModals/ListReferrals";
	import ListInvoices from "../SubModals/ListInvoices";
	import Profile from "../Modals/Profile";
	import SubscriptionGeneral from "../SubModals/SubscriptionGeneral";
	import UpgradeSubscription from "../SubModals/UpgradeSubscription";
	import CardDetails from "../SubModals/CardDetails";
	
	import InvoiceDetails from "../SubModals/InvoiceDetails";
	import DownloadLinks from "../SubModals/DownloadLinks";

	import AddEmailsInvoices from "../SubModals/AddEmailsInvoices";
	import IconUserSettings from '../Icons/UserSettings'

	export default {
		data() {
			return {
				user: {
					first_name: this.$auth.user().first_name,
					last_name: this.$auth.user().last_name,
					email: this.$auth.user().email,
					avatar: this.$auth.user().avatar,
					password: '',
					password_confirmation: '',
				},
				activeSlide: 'main-settings-page',
				modelRole: {},
				modelTag: {},
				arrayTranslations: [
					'settings.my-profile',
					'settings.settings',
					'settings.subscription',
					'settings.subscription-change',
					'settings.change',
					'settings.tags',
					'settings.tags-change',
					'settings.invoices',
					'settings.invoices-list',
					'settings.referrals',
					'settings.referrals-list',
					'settings.downloads',
					'settings.downloads-list',
					'my_profile.title',
					'my_profile.avatar',
					'my_profile.add-new-image',
					'my_profile.last_name',
					'my_profile.first_name',
					'my_profile.email',
					'validator.email_format_validation',
					'my_profile.password',
					'validator.password_min_length',
					'validator.same_password',
					'my_profile.pwd_confirmation',
					'my_profile.save',
					'list-invoices.receiver-mail',
					'list-invoices.receiver-mail-label',
					'list-invoices.receiver-mail-placeholder',
					'list-invoices.btn-set',
					'card-details.title',
					'card-details.number',
					'card-details.valability',
					'card-details.cvc',
					'card-details.modify',
					'create-tags.title_edit',
					'create-tags.title_new',
					'create-tags.name',
					'create-tags.name-placeholder',
					'create-tags.color',
					'create-tags.users',
					'create-tags.search-users',
					'create-tags.select',
					'create-tags.select-all',
					'create-tags.selected',
					'create-tags.deselect',
					'create-tags.deselect-all',
					'create-tags.selected-users',
					'create-tags.no-results',
					'create-tags.edit_button',
					'create-tags.save_button',
					'list-apps.title',
					'settings.download-windows',
					'settings.download-mac',
					'settings.download-linux',
					'invoice-details.title',
					'invoice-details.account-type',
					'invoice-details.no-results',
					'invoice-details.country',
					'invoice-details.choose-country',
					'invoice-details.name',
					'invoice-details.palceholder-name',
					'invoice-details.cnp',
					'invoice-details.palceholder-cnp',
					'invoice-details.company',
					'invoice-details.palceholder-company_name',
					'invoice-details.cui',
					'invoice-details.palceholder-cui',
					'invoice-details.save',
					'invoice-details.individual',
					'invoice-details.company_title',
					'list-invoices.title',
					'key-result-tasks.save',
					'subscription.invoice-data',
					'list-invoices.invoice-subtitle',
					'list-invoices.invoice',
					'list-invoices.no-invoices',
					'list-invoices.desc',
					'list-referrals.title',
					'list-referrals.label-code',
					'list-referrals.copy',
					'list-referrals.list-title',
					'list-tags.title',
					'list-tags.people',
					'list-tags.edit',
					'list-tags.delete',
					'list-tags.no-tags',
					'list-tags.desc',
					'list-tags.add',
					'subscription.title',
					'subscription.monthly',
					'subscription.employees',
					'subscription.objectives',
					'subscription.change',
					'subscription.change-to-low',
					'subscription.grace_period_desc',
					'subscription.card-details',
					'subscription.error_payment',
					'subscription.upgrade.title',
					'subscription.upgrade.up-to',
					'subscription.upgrade.employees',
					'subscription.upgrade.employees-b',
					'subscription.upgrade.unlimited',
					'subscription.upgrade.objectives',
					'subscription.upgrade.objectives-b',
					'subscription.upgrade.key-results',
					'subscription.upgrade.key-results-b',
					'validator.error_upgrade',
					'subscription.upgrade.modify-plan'
				]
			};
		},
		props:{

		},
		components: {
			IconPlus,
			// CreateRole,
			// ListRoles,
			CreateTag,
			ListTags,
			ListInvoices,
			ListReferrals,
			Profile,
			SubscriptionGeneral,
			UpgradeSubscription,
			CardDetails,
			InvoiceDetails,
			AddEmailsInvoices,
			IconUserSettings,
			DownloadLinks
		},
		async mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			changeActiveSlide(value, modelRole = {}){
 					setTimeout(() => {
  						$('#dropdownEditTag' + this.hoverTag).dropdown('toggle');
  					}, 0)
				this.modelRole = modelRole
				this.activeSlide = value
			},
			changeActiveSlideTag(value, modelTag = {}){
				this.modelTag = modelTag
				this.activeSlide = value
			},
		}
	};
</script>