<template>
	<div class="modal_info modal_cant_create_more_objective">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Cant Create More Objective', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/warning-info.png"></div>
			<div class="title">
				{{ $t('subscription.objective-max-reached-title') }}
			</div>
		</div>
		<div class="description-modal">
			{{ $t('subscription.objective-max-reached-description', {objectivesLimit: $auth.user().package.objective_limit, usersLimit: $auth.user().package.user_limit}) }}
		</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelDelete">{{ $t('subscription.objective-max-reached-change-plan-cancel-btn') }}</button>
			<button class="btn-blue-tbf button-action" @click="changeModal">{{ $t('subscription.objective-max-reached-change-plan-btn') }}</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				arrayTranslations: [
					'subscription.objective-max-reached-title',
					'subscription.objective-max-reached-description',
					'subscription.objective-max-reached-change-plan-cancel-btn',
					'subscription.objective-max-reached-change-plan-btn'
				]
			};
		},
		mounted() {
			
		},
		methods: {
			cancelDelete(){
				this.$emit("close_modal");
			},
			changeModal(){
				window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;
				
				this.$root.$emit('change_modal', 'change_subscription');
			},
		}
	};
</script>