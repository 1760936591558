<template>
	<div class="modal-tbf">
		<div class="container-modal form-modal" v-if="loaded">
			<div class="header-modal">
				<div class="title">{{ $t('update-key-results.title') }} <span class="text-capitalize">{{ selectedKeyResult.next_date_for_update | moment('DD MMMM, YYYY') }}</span></div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="header-form">
						<img src="/build/icons/key-results-icon.svg">
						<div class="data-title">
							<div class="title">{{ selectedKeyResult.name }}</div>
							<div class="description">{{ $t('progress_type_text.' + selectedKeyResult.progress_type, { to: parseFLoat(selectedKeyResult.target).toString(), unit: selectedKeyResult.unity, from: parseFLoat(selectedKeyResult.start_value).toString()} ) }}</div>
						</div>
					</div>

					<div class="box-update-key-result">
						<div class="new-data">
							<div class="input-group-tbf update-kr" v-bind:class="{has_error: $v.update_key_result.value.$error, complete: update_key_result.value != '' && !$v.update_key_result.value.$error, 'view-tooltip': activeTooltip == 'kr_update_value' }">
								<div class="label-input">
									<label>{{ $t('update-key-results.new-value') }}</label>
									<div class="previous-value">{{ $t('update-key-results.actual-value') }}: <span class="value">{{ selectedKeyResult.last_key_result_log ? (parseFLoat(selectedKeyResult.last_key_result_log.value).toString() + ' ' + selectedKeyResult.unity ) : (parseFLoat(selectedKeyResult.start_value).toString() + ' ' + selectedKeyResult.unity) }}</span></div>
									<div class="info-input" @click="activeTooltip == 'kr_update_value' ? activeTooltip = '' : activeTooltip = 'kr_update_value'">
										<icon-circle-check class="icon-check" v-if="update_key_result.value != '' && !$v.update_key_result.value.$error" />
										<icon-info class="icon-info" v-else/>
									</div>
								</div>
								<div class="input-box">
									<input type="text" :placeholder="$t('update-key-results.new-value') + ' ('+ selectedKeyResult.unity +')'" class="input-update-kr" v-model="update_key_result.value" ref="first_input">
									<div class="calculate-new-percent">
										<img src="/build/icons/new-percent.svg">
										<span class="value">{{ diffPercent(update_key_result.value) }}</span>
									</div>
								</div>

								<div class="tooltip-input">
									<div class="header">
										<div class="title">{{ $t('update-key-results.actual-value-tooltip-title') }}</div>
										<div class="close-tooltip" @click="activeTooltip = ''">
											<icon-plus class="icon-close"/>
										</div>
									</div>
									<div class="description">{{ $t('update-key-results.actual-value-tooltip-description') }}</div>
								</div>
							</div>

							<div class="input-group-tbf" v-bind:class="{has_error: $v.update_key_result.description.$error, complete: update_key_result.description != '' && !$v.update_key_result.description.$error, 'view-tooltip': activeTooltip == 'kr_update_description' }">
								<div class="label-input">
									<label>{{ $t('update-key-results.description') }}</label>
									<div class="info-input" @click="activeTooltip == 'kr_update_description' ? activeTooltip = '' : activeTooltip = 'kr_update_description'">
										<icon-circle-check class="icon-check" v-if="update_key_result.description != '' && !$v.update_key_result.description.$error" />
										<icon-info class="icon-info" v-else/>
									</div>
								</div>
								<div class="input-box">
									<textarea class="input-textarea" :placeholder="$t('key-result.form.description-placeholder')" v-model="update_key_result.description" v-autosize rows="3"></textarea>
								</div>

								<div class="tooltip-input">
									<div class="header">
										<div class="title">{{ $t('update-key-results.description-tooltip-title') }}</div>
										<div class="close-tooltip" @click="activeTooltip = ''">
											<icon-plus class="icon-close"/>
										</div>
									</div>
									<div class="description">{{ $t('update-key-results.description-tooltip-description') }}</div>
								</div>
							</div>
							<div class="description-update">
								{{ $t('update-key-results.key-result') }} {{ selectedKeyResult.name }} {{ $t('update-key-results.are-deadline-in') }} {{ selectedKeyResult.end_date | moment("DD MMM") }}, {{ $t('update-key-results.current-value-is') }} {{ selectedKeyResult.last_key_result_log ? (parseInt(selectedKeyResult.last_key_result_log.value).toString() + ' ' + selectedKeyResult.unity) : (parseInt(selectedKeyResult.start_value).toString() + ' ' + selectedKeyResult.unity) }} {{ $t('update-key-results.target-to-score') }} {{ parseInt(selectedKeyResult.target).toString() + ' ' + selectedKeyResult.unity }}
							</div>
						</div>
						<div class="confidence-level" v-bind:class="{has_error: $v.update_key_result.degree_of_confidence.$error, complete: update_key_result.degree_of_confidence != '' && !$v.update_key_result.degree_of_confidence.$error}">
							<div class="label">
								<label>{{ $t('update-key-results.trust-grade') }}</label>
								<div class="info-input">
									<icon-circle-check class="icon-check"/>
								</div>
							</div>
							<div class="icon-degree">
								<img :src="'/build/icons/mood-' + update_key_result.degree_of_confidence + '.svg'">
							</div>
							<div class="title-mood">{{ $t('mood_type.' + update_key_result.degree_of_confidence) }}</div>
							<div class="description-confidence">{{ $t('update-key-results.trust-desc') }}</div>

							<div class="slider-degree">
								<vue-slider
								v-model="update_key_result.degree_of_confidence"
								:data="optionsDegreeConfidence"
								:marks="false"
								:hide-label="true"
								tooltip="none"
								:contained="true"
								:process-style="{ backgroundColor: '#f0f1f3' }"
								>
									<template v-slot:dot="{ value, focus }">
										<img src="/build/icons/slider-icn.svg">
										<!-- <img src="/build/icons/arrows-slider.svg"> -->
									</template>
									<template v-slot:process="{ start, end, style, index }">
										<div class="vue-slider-process custom-class" :style="[style]">
											<!-- Can add custom elements here -->
										</div>
									</template>
								</vue-slider>
							</div>
						</div>
					</div>

					<div class="submit-form">
						<button class="button-tbf-blue button-submit" @click="saveModifications">
							<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
							{{ $t('update-key-results.save') }}
						</button>
					</div>
				</div>
			</div>

			<div class="submodal-container" v-if="subModalShow">
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose"></confirm-close>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"

	import { required, decimal } from 'vuelidate/lib/validators'
	import ConfirmClose from "../SubModals/ConfirmClose"

	export default {
		data() {
			return {
				loaded: false,
				activeTooltip: '',
				update_key_result: {
					value: '',
					description: '',
					degree_of_confidence: 'not_very_sure'
				},
				showSelectKeyResults: false,
				selectedKeyResult: '',
				optionsKeyResults: [],
                focusedInput: 'default',
                optionsDegreeConfidence: ["uncertain","not_very_sure","sure"],
                tooltip: {
                	value: false
                },
                subModalShow: false,
                show_subm_modal: {
                	calendar_show: false,
                	confirm_close: false
                },
			};
		},
		props:{
			model: Object,
			requiredData: Object,
			userData: Object,
			fromPage: String
		},
		components: {
			IconPlus,
			IconInfo,
			IconCircleCheck,
			ConfirmClose
		},
		validations: {
			update_key_result: {
				value: {required, decimal},
				description: {required},
				degree_of_confidence: {required}
			},
			selectedKeyResult: {required}
		},
		async mounted() {

			if(!(Object.keys(this.requiredData).length === 0 && this.requiredData.constructor === Object)){
				this.selectedKeyResult = this.requiredData
			}

			if(!(Object.keys(this.userData).length === 0 && this.userData.constructor === Object)){
				this.showSelectKeyResults = true
				await this.getUserKeyResults()
			}else{
				this.loaded = true
			}


			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	calendar_show: false,
	                	confirm_close: true
					}
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });
		},
		methods: {
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	calendar_show: false,
	                	confirm_close: true
					}
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$root.$emit('refreshObjectivePage')
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			async getUserKeyResults(){
				await axios.get('/people/' + this.userData.id + '/key-results').then(({data}) => {
					this.optionsKeyResults = data.data
					this.loaded = true
				})
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					axios.post('/key-result-logs/store',{
						key_result_id: this.selectedKeyResult.id,
						description: this.update_key_result.description,
						value: Number(this.update_key_result.value),
						status: this.update_key_result.degree_of_confidence,
						related_to_date: this.selectedKeyResult.next_date_for_update
					}).then(({data}) => {
						$(e.target).attr('disabled', false)
						this.refreshPage()
						this.$emit("toggle_modal");
					})

					$(e.target).attr('disabled', false)
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			showSubModal(type){
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeSubModal(type){
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			checkInputs(){
				var notEmpty = false

				if(this.update_key_result.value != '' || this.update_key_result.description != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			diffPercent(value){
				if(value){
					var percent = 0;
					var diffPercentVal = 0;
					switch (this.selectedKeyResult.progress_type) {
						case 'percentage':
							diffPercentVal = value - this.selectedKeyResult.percent;
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						case 'grow':
							percent = 100 - ((this.selectedKeyResult.target - value) / ((this.selectedKeyResult.target - this.selectedKeyResult.start_value) / 100));
							diffPercentVal = percent - this.selectedKeyResult.percent;
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						case 'decrease':
							percent = 100 - ((value - this.selectedKeyResult.target) / ((this.selectedKeyResult.start_value - this.selectedKeyResult.target) / 100));
							diffPercentVal = percent - this.selectedKeyResult.percent;
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						case 'above':
							percent = value > this.selectedKeyResult.target ? 100 : 0;
							diffPercentVal = percent - this.selectedKeyResult.percent;
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						case 'under':
							percent = value < this.selectedKeyResult.target ? 100 : 0;
							diffPercentVal = percent - this.selectedKeyResult.percent;
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
					}
				}else{
					return '0%'
				}
			},
			refreshPage(){
				switch (this.fromPage) {
					case 'daily_plan':
						this.$root.$emit('refreshPlanOfDay')
						break;
					case 'objectives':
						this.$root.$emit('refreshObjectivesList')
						break;
					case 'show_objective':
						this.$root.$emit('refreshObjectivePage')
						break;
					case 'users':
						this.$root.$emit('refreshUsersList')
						break;
					case 'show_user':
						this.$root.$emit('refreshUserPage')
						break;
					case 'master_goals':
						this.$root.$emit('refreshMasterGoalsList')
						break;
					default:
						this.$root.$emit('refreshObjectivePage')
						break;
				}
			}
		}
	};
</script>