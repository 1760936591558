<template>
	<div class="modal-tbf active modal-register">
		<transition name="slide" mode="out-in">
			<register-step-1 v-if="step_active == 1 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-12 v-if="step_active == 12 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-2 v-if="step_active == 2 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-21 v-if="step_active == 21 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-3 v-if="step_active == 3 && $route.name == 'payment'" :user="user" @next_step="next_step"/>
		</transition>
	</div>
</template>

<script>
import RegisterStep1 from './RegisterStep1'
import RegisterStep12 from './RegisterStep12'
import RegisterStep2 from './RegisterStep2'
import RegisterStep21 from './RegisterStep21'
import RegisterStep3 from './RegisterStep3'

export default {
	data(){
		return {
			loaded: false,
			step_active: this.$route.name == 'payment' ? 3 : 1,
			packages: [],
			packageActive: {},
			user: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				password: '',
				language: '',
				has_company: 1,
				company_name: '',
				company_cui: '',
				complet_name: '',
				cnp: '',
				terms_and_conditions: '',
				country: ''
			}
		}
	},
	components: {
		RegisterStep1,
		RegisterStep12,
		RegisterStep2,
		RegisterStep21,
		RegisterStep3,
	},
	watch: {
		$route (to, from){
			if(this.$route.name == 'payment'){
				this.step_active = 3
			}
		}
	},
	methods: {
		viewGame(){
			$('.bird-login').removeClass('active');
			setTimeout(() => {
				this.view_game = true;
				setTimeout(() => {
					game_start();
				},0)
			},300)
		},
		next_step(value){
			this.user = value.user ? value.user : this.user

			if(value.action == 'finish_redirect'){
				this.$router.push('/')
			}else{
				this.step_active = value.next_step ? value.next_step : this.step_active
			}
		}
	}
}
</script>