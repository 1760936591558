<template>
	<div class="opacity-page" v-if="loaded">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Master Goals', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="item-page" v-if="notAllowed">
			<not-allowed></not-allowed>
		</div>
		<div v-else-if="masterGoals.length && !notAllowed">
			<div class="list-page-tbf master-goals">
				<section class="header-list-page-section">
					<div class="space-left">
						<img src="/build/icons/search-disabled.svg" />
					</div>
					<div class="content-section">
						<div class="header-table">
							<div class="left-part">
								<input type="text" name="search" :placeholder="$t('master_goal.search_by_name')" id="inputSearch" v-model="search_text" autocomplete="off" class="search-box">
							</div>
							<div class="right-part">
								<div class="action-header filter-date filter-involved dropdown-tbf">
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
										{{ $t(`filter_master_goals.${activeInvolved == 'all' ? 'all' : (activeInvolved == 'true' ? 'involved' : 'not_involved')}`) }} <icon-arrow class="icon-arrow"/>
									</button>
									
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div class="dropdown-item" @click="changeActiveStatusInvolved('all')"  v-bind:class="{active: activeInvolved == 'all'}">
											{{ $t('filter_master_goals.all') }}
										</div>
										<div class="dropdown-item" @click="changeActiveStatusInvolved('true')"  v-bind:class="{active: activeInvolved == 'true'}">
											{{ $t('filter_master_goals.involved') }}
										</div>
										<div class="dropdown-item" @click="changeActiveStatusInvolved('false')"  v-bind:class="{active: activeInvolved == 'false'}">
											{{ $t('filter_master_goals.not_involved') }}
										</div>
									</div>
								</div>
								<div class="action-header filter-date dropdown-tbf">
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
										{{ $t('filter_master_goals.' + activeStatus) }} <icon-arrow class="icon-arrow"/>
									</button>
									
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div v-for="objStatus in acceptedStatuses" 
											class="dropdown-item" 
											@click="changeActiveStatus(objStatus)" 
											v-bind:class="{active: activeStatus == objStatus}">
												{{ $t('filter_master_goals.'+objStatus) }}
										</div>
									</div>
								</div>
								<div class="action-header add-button" v-if="$auth.user().rights.can_create">
									<button class="button-tbf-blue" @click="showModal('master_goal')"><icon-plus class="white" />{{ $resize && $mq.above(600) ? $t('master_goal.title_new') : '' }}</button>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>

				<section class="mastergoal-list-section" v-if="filteredMasterGoals.length">
					<div class="space-left"></div>
					<div class="content-section">
						<div class="master-goal-lists">
							<div class="master-goal-card" v-for="master_goal in filteredMasterGoals" @click="showMasterGoal(master_goal.slug)" v-bind:class="{'viewEdit': hoverMasterGoal == master_goal.id}">
								<div class="header-card-mg">
									<div class="text-medium" :title="master_goal.name" v-html="highlight((master_goal.name ? (master_goal.name.charAt(0).toUpperCase() + master_goal.name.slice(1)) : $t('master_goal.private')), search_text)"></div>
									<div class="dots-edit" v-click-outside="hideDropdown" v-if="master_goal.rights.edit || master_goal.rights.delete">
										<div class="dropdown edit-item-dropdown">
											<div class="overlay-button" @click.stop="showDropdown(master_goal.id)"></div>
											<div class="edit-item-button" :id="'dropdownEditMasterGoal'+master_goal.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<img src="/build/icons/edit-mg.svg">
											</div>
											<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenuMasterGoal'+ master_goal.id " :aria-labelledby="'dropdownEditMasterGoal'+master_goal.id">
												<div class="dropdown-item" @click.stop="showModal('master_goal', master_goal.slug)" v-if="master_goal.rights.edit">{{ $t('master_goal.btn.edit') }}</div>
												<div class="dropdown-item" @click.stop="openModalV2('delete', master_goal, 'master_goal', 'master_goals')" v-if="master_goal.rights.delete">{{ $t('master_goal.btn.delete') }}</div>
												<div class="dropdown-divider"></div>
												<div class="dropdown-item" @click.stop="showModal('share_master_goal', master_goal.slug)">{{ $t('master_goal.btn.share') }}</div>
											</div>
										</div>
									</div>
								</div>
								<p class="description" :title="master_goal.description ? master_goal.description : $t('master_goal.private')">
									{{ master_goal.description ? master_goal.description : $t('master_goal.private') }}
								</p>
								<hr>
								<div class="values">
									<div class="percentage">{{ parseInt(master_goal.percentage_finished).toString() }}%</div>
									<div class="remainingtime">{{ master_goal.remaining_days }} {{ $t('master_goal.days') }}</div>
								</div>

								<div class="progress-bar-tbf">
									<div class="complete-progress-tbf" v-bind:class="{pink: (master_goal.remaining_days < 1 && master_goal.percentage_finished != 100), green: master_goal.percentage_finished == 100}" :style="'width: calc( 2px + '+(master_goal.percentage_finished < 1 ? '2px' : master_goal.percentage_finished + '%') +');'"></div>
								</div>
								<div class="counters">
									<div class="counter-box"><span class="number">{{ master_goal.linked_master_goals }}</span><br><span class="text">{{ $t('master_goal.counters.visions') }}</span></div>
									<div class="counter-box"><span class="number">{{ master_goal.linked_objectives }}</span><br><span class="text">{{ $t('master_goal.counters.objectives') }}</span></div>
									<div class="counter-box"><span class="number">{{ master_goal.people_involved }}</span><br><span class="text">{{ $t('master_goal.counters.people') }}</span></div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
				<section class="list-items-section" v-else>
					<div class="group_items">
						<div class="space-left"></div>
						<div class="empty-row full">
							<div class="icon">
								<img src="/build/icons/no-results-found.svg">
							</div>
							<div class="title">
								{{ $t('master_goal.no-vision-found') }}
							</div>
							<div class="description narrow" v-html="$t('master_goal.no-vision-description')">
							</div>
							<div class="add-button" v-if="$auth.user().rights.can_create">
								<button class="button-tbf-blue" @click="showModal('master_goal')"><icon-plus class="white" />{{ $t('master_goal.title_new') }}</button>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</section>
			</div>
		</div>
		<div v-else>
			<section class="list-items-section">
				<div class="group_items">
					<div class="space-left"></div>
					<div class="empty-row full">
						<div class="icon">
							<icon-vision-empty />
						</div>
						<div class="title">
							{{ $t('master_goal.you-have-no-vision') }}
						</div>
						<div class="description narrow" v-html="$t('master_goal.no-vision-description')"></div>
						<div class="add-button" v-if="$auth.user().rights.can_create">
							<button class="button-tbf-blue" @click="showModal('master_goal')"><icon-plus class="white" />{{ $t('master_goal.title_new') }}</button>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</section>
		</div>
	</div>
	<div v-else>
		<div class="list-page-tbf master-goals">
			<section class="header-list-page-section">
				<div class="space-left">
					<img src="/build/icons/search-disabled.svg" />
				</div>
				<div class="content-section">
					<div class="header-table">
						<div class="left-part">
							<div class="placeholder-loader" style="width: 150px; height: 24px"></div>
						</div>
						<div class="right-part">
							<div class="placeholder-loader" style="width: 130px; height: 37px"></div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>
			<section class="mastergoal-list-section">
				<div class="space-left"></div>
				<div class="content-section">
					<div class="master-goal-lists">
						<div class="master-goal-card" v-for="n in 5">
							<div class="header-card-mg">
								<div class="text-medium placeholder-loader" style="width: 150px; height: 27px"></div>
							</div>
							<p class="description">
								<span class="placeholder-loader" style="width: 130px; height: 12px; display: block;"></span>
							</p>
							<hr>
							<div class="values">
								<div class="percentage">
									<div class="placeholder-loader" style="width: 15px; height: 21px"></div>
								</div>
								<div class="remainingtime"><div class="placeholder-loader" style="width: 30px; height: 21px; display: inline-block;"></div></div>
							</div>

							<div class="progress-bar-tbf placeholder-loader"></div>
							<div class="counters">
								<div class="counter-box"><span class="number placeholder-loader" style="width: 16px; height: 25px; display: inline-block;"></span><br><span class="text placeholder-loader" style="width: 50px; height: 16px; display: inline-block;"></span></div>
								<div class="counter-box"><span class="number placeholder-loader" style="width: 16px; height: 25px; display: inline-block;"></span><br><span class="text placeholder-loader" style="width: 50px; height: 16px; display: inline-block;"></span></div>
								<div class="counter-box"><span class="number placeholder-loader" style="width: 16px; height: 25px; display: inline-block;"></span><br><span class="text placeholder-loader" style="width: 50px; height: 16px; display: inline-block;"></span></div>
							</div>
						</div>
						<div class="master-goal-card empty" v-if="(3 - (5 % 3)) == 1 || (3 - (5 % 3)) == 2"></div>
						<div class="master-goal-card empty" v-if="(3 - (5 % 3)) == 2"></div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>
		</div>
	</div>
</template>

<script type="text/javascript">
	import EmptyPage from '../General/EmptyPage'
	import NotAllowed  from '../General/NotAllowed'
	import NotPaid  from '../General/NotPaid'
	import IconVisionEmpty from '../Icons/VisionEmpty'
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconFilter from '../Icons/Filter'

	export default {
		data() {
			return {
				loaded: false,
				search_text: '',
				masterGoals: [],
				notAllowed: false,
				hoverMasterGoal: '',
				activeStatus: 'active',
				activeInvolved: 'true',
				acceptedStatuses: ['all', 'active', 'past', 'future'],
				arrayTranslations: [
					'master_goal.search_by_name',
					'filter_master_goals.active',
					'filter_master_goals.all',
					'filter_master_goals.archived',
					'master_goal.title_new',
					'master_goal.private',
					'master_goal.btn.edit',
					'master_goal.btn.archiving',
					'master_goal.btn.unzip',
					'master_goal.btn.delete',
					'master_goal.btn.share',
					'master_goal.days',
					'master_goal.counters.visions',
					'master_goal.counters.objectives',
					'master_goal.counters.people',
					'master_goal.no-vision-found',
					'master_goal.no-vision-description',
					'master_goal.you-have-no-vision',
					'navbar.master_goals',
				]
			};
		},
		components: {
			EmptyPage,
			NotAllowed,
			IconVisionEmpty,
			IconPlus,
			IconArrow,
			IconFilter,
			NotPaid
		},
		computed: {
			filteredMasterGoals(){
				return getByInvolved(getByStatus(getByKeyword(this.masterGoals, this.search_text), this.activeStatus), this.activeInvolved)
			}
		},
		watch: {
			search_text: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['name'] = this.search_text;
				if(this.$route.query.name != this.search_text){
					this.$router.push({query : data });
				}
			}
		},
		beforeDestroy () {
			this.$root.$off('refreshMasterGoalsList')
		},
		async mounted() {
			// Filters
			if(this.$cookies.get('filter_master_goals') && Object.keys(this.$route.query).length == 0){
				this.$router.push({query : this.$cookies.get('filter_master_goals') });
			}

			if(this.acceptedStatuses.includes(this.$route.query.status)){
				this.activeStatus = this.$route.query.status;
			}
			
			if(['all', 'true', 'false'].includes(this.$route.query.involved)){
				this.activeInvolved = this.$route.query.involved;
			}
			
			if(this.$route.query.name && this.$route.query.name != ""){
				this.search_text = this.$route.query.name;
			}
			// End filters
			await this.getMasterGoals()
			
			this.$root.$on("refreshMasterGoalsList", () => {
				this.getMasterGoals()
			});
		},
		methods: {
			async getStatusesMasterGoals(){
				axios.get('master-goal/status-list').then(({data}) => {
					data.data.map(el => {
						this.acceptedStatuses.push(el)
					})
				})
			},
			async getMasterGoals(){
				await axios.get('/' + this.$auth.user().instance_id + '/master-goals')
				.then(({data}) => {
					this.masterGoals = data.data
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()

						setTimeout(() => {
							if(this.$auth.check()){
								location.reload()
							}
						}, 300)
					}
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						var title = this.$t('navbar.master_goals');
						this.$root.$emit("navbar_title", title);
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'master_goals');
			},
			showMasterGoal(masterGoalSlug){
				this.$router.push({ name: 'show-master-goal', params: { slug: masterGoalSlug }})
				// this.$root.$emit('open_modal', 'show_objective', objectiveSlug);
			},
			showDropdown(itemId){
				if(itemId == this.hoverMasterGoal){
					$('#dropdownEditMasterGoal' + this.hoverMasterGoal).dropdown('toggle');
					setTimeout(() => {
						this.hoverMasterGoal = ''
					}, 0)
				}else{
					this.hoverMasterGoal = itemId
					setTimeout(() => {
						$('#dropdownEditMasterGoal' + this.hoverMasterGoal).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdown(){
				if(this.hoverMasterGoal != ''){
					$('#dropdownEditMasterGoal' + this.hoverMasterGoal).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverMasterGoal = ''
					}, 0)
				}
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdown()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
			highlight(text, query){
				String.prototype.replaceBetween = function(start, end, what) {
					return this.substring(0, start) + what + this.substring(end);
				};

				if(query != ''){
					var regex = new RegExp( query ,'ig');
					var new_text = text
					var match = ''

					var match_obj = []

					while ((match = regex.exec(text)) != null) {
						match_obj.push({
							text_replace: text.substring(match.index, match.index+query.length),
							start_position: match.index,
							end_position: match.index+query.length
						})
					}
					match_obj.reverse()

					if(match_obj.length){
						match_obj.forEach(element => {
							new_text = new_text.replaceBetween(element.start_position, element.end_position, '<mark class="highlight">' + element.text_replace + '</mark>')
						})
					}

					return new_text
				}else{
					return text
				}
			},
		
			changeActiveStatus(status){
				this.activeStatus = status;
				var data = Object.assign({}, this.$route.query);
				data['status'] = status;
				if(this.$route.query.status != status){
					this.$router.push({query : data });
				}
				this.$cookies.set('filter_master_goals', data);
			},
			changeActiveStatusInvolved(status){
				this.activeInvolved = status;
				var data = Object.assign({}, this.$route.query);
				data['involved'] = status;
				if(this.$route.query.involved != status){
					this.$router.push({query : data });
				}
				this.$cookies.set('filter_master_goals', data);
			}
		}
	};

	function getByKeyword(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}

	function getByInvolved(list, status) {
		if (status == 'all') return list

		return list.filter(item => String(item.involved) == status)
	}

	function getByStatus(list, status) {
		if (status == 'all') return list
		
		return list.filter(item => item.status == status)
	}
</script>