<template>
	<div class="container-modal form-modal">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register 2', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
        
        <div class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="header-modal">
			<div class="title">{{ $t('register.title_step_new') }}</div>
			<!-- <div class="actions" @click="closeModal">
				<div class="btn-default-tbf close-btn"><close-icon/></div>
			</div> -->
		</div>
		<div class="container-content-modal" v-if='loaded'>
			<div class="form-section">
				<div class="input-group-tbf w-tbf-100">
					<div class="subscription-box custom" v-for="subPackage,index in packagesList" v-bind:class="{active: subPackage.id == packageActive.id, inactive: packageActive.id && subPackage.id != packageActive.id, first: index == 0, 'error-selected': errorSelectPlan}" @click="selectPlan(subPackage)">
						<div class="top-section">
							<p class="first">
								{{ subPackage.name }}
								<span>{{ subPackage.short_description }}</span>
							</p>
							<div class="second">
								<div class="price">
									{{ user.newPrices.find((el) => el.package_id == subPackage.id) ? user.newPrices.find((el) => el.package_id == subPackage.id).price + ' ' + subPackage.euro_prefix : subPackage.price_in_euro + ' ' + subPackage.euro_prefix }}
								</div>
								<span class="old-price" v-if="user.newPrices.find((el) => el.package_id == subPackage.id)">{{ subPackage.price_in_euro }} EUR / luna</span>
								<span v-else>{{ $t('subscription.monthly') }}</span>
							</div>
						</div>
						<div class="third-section">
							<div class="box">
								<div class="icon"><user-settings-icon /></div>
								<span v-if="subPackage.user_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.user_limit }} <br>{{ $t('subscription.upgrade.employees') }}</span>
								<span v-else>{{ $t('subscription.upgrade.employees-b') }} <br>{{ $t('subscription.upgrade.unlimited') }}</span>
							</div>
							<div class="box">
								<div class="icon icon-obj"><icon-objectives-list /></div>
								<span v-if="subPackage.objective_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.objective_limit }} <br>{{ $t('subscription.upgrade.objectives') }}</span>
								<span v-else>{{ $t('subscription.upgrade.objectives-b') }} <br>{{ $t('subscription.upgrade.unlimiteds') }}</span>
							</div>
							<div class="box">
								<div class="icon"><settings-goal-icon /></div>
								<span v-if="subPackage.results_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.results_limit }} <br>{{ $t('subscription.upgrade.key-results') }}</span>
								<span v-else>{{ $t('subscription.upgrade.key-results-b') }} <br> {{ $t('subscription.upgrade.unlimiteds') }}</span>
							</div>
						</div>
					</div>
				</div>	

				<div class="input-group-tbf w-tbf-100">
					<p class="get-quote" v-html="$t('register.step-3.get_quote')"></p>
				</div>

			</div>
			<div class="submit-form">
				<button class="button-tbf-blue button-submit" @click="openConfirmation">
					<div class="loader"></div>
					<div class="text">{{$t('register.button-action-2')}}</div>
				</button>
			</div>
		</div>

		<div class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>

			<div class="submodal-tbf active">
				<div class="container-content-modal">
					<div class="header-modal">
						<div class="title">{{ $t('register.step-2.confirm_subscription_title') }}</div>
						<div class="actions">
							<div class="btn-default-tbf close-btn" @click="closeModalCoupon"><icon-plus class="icon-close" /></div>
						</div>
					</div>
					<div class="summary">
						<div class="summary-box">
							{{ $t('register.step-2.confirm_subscription_summary', { package: packageActive.name, price: user.newPrices.find((el) => el.package_id == packageActive.id) ? user.newPrices.find((el) => el.package_id == packageActive.id).price + ' ' + packageActive.euro_prefix : packageActive.price_in_euro + ' ' + packageActive.euro_prefix}) }}
						</div>

						<div class="submit-form">
							<button class="button-tbf-blue button-submit btn-confirm-modal" @click="goNextStep">
								<div class="loader"></div>
								<div class="text">{{ $t('register.step-2.confirm') }}</div>
							</button>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../Animations/tbfdigital_logo.json';
	import CloseIcon from '../Icons/Close'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import InformationIcon from '../Icons/Information'
	import IconIndividual from '../Icons/Individual'
	import IconOffice from '../Icons/Office'
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import UserSettingsIcon from '../Icons/UserSettings';
	import SettingsGoalIcon from '../Icons/SettingsGoal';
	import IconObjectivesList from '../Icons/ObjectivesList'
	import PasswordIcon from '../Icons/Password';
	import { required, minLength, requiredIf } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				activeTooltip: '',
				packageActive: {},
				packagesList: {},
				cardErrors: {},
				subModalShow: false,
				loaded: false,
				errorSelectPlan: false,
				arrayTranslations: [
					'register.title_step_new',
					'subscription.monthly',
					'subscription.upgrade.employees',
					'subscription.upgrade.employees-b',
					'subscription.upgrade.unlimited',
					'subscription.upgrade.up-to',
					'subscription.upgrade.objectives',
					'subscription.upgrade.objectives-b',
					'subscription.upgrade.unlimiteds',
					'subscription.upgrade.key-results',
					'subscription.upgrade.key-results-b',
					'subscription.upgrade.unlimiteds',
					'register.step-3.get_quote',
					'register.button-action-2',
					'register.step-2.confirm_subscription_title',
					'register.step-2.confirm_subscription_summary',
					'register.step-2.confirm'
				]
			}
		},
		props:{
			user: Object
		},
		components: {
			CloseIcon,
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			InformationIcon,
			IconIndividual,
			IconOffice,
			IconBuilding,
			IconCnp,
			IconCui,
			UserSettingsIcon,
			SettingsGoalIcon,
			IconObjectivesList,
			PasswordIcon,
			Lottie
		},
		async mounted() {
			await this.getPackages()
		},
		methods: {
			async getPackages(){
				await axios.get('packages').then(({data}) => {
					this.packagesList = data.data.reverse();
					if(this.$route.query.token){
						this.packageActive = this.packagesList.find(el => el.token == this.$route.query.token)
						this.user.packageActive = this.packageActive
					}else{
						this.packageActive = {};
					}

					this.loaded = true;
					this.logoAnim();
				});
			},
			openConfirmation(){
				this.errorSelectPlan = false

				if(this.packageActive.id){
					this.subModalShow = true;
				}else{
					this.errorSelectPlan = true
				}
			},
			goNextStep(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.btn-confirm-modal')
				var btnSubmitLoader = $('.btn-confirm-modal .loader')
				var btnSubmitText = $('.btn-confirm-modal .text')
				
				btnSubmitText.html(this.$t('btn-submit.success'))
				btnSubmit.addClass('completed')
				btnSubmitLoader.removeClass('onProgress finish')
				btnSubmit.removeClass('loading')
				setTimeout(()=>{
					btnSubmit.removeClass('completed')
					btnSubmit.attr('disabled', false)
					this.$emit("next_step", {user: this.user, next_step: 21});
				}, 1000)
			},

			closeModal(){
				this.$router.push("/login");
			},
			closeModalCoupon() {
				this.subModalShow = false;
			},
			selectPlan(packageItem){
				this.errorSelectPlan = false
				this.packageActive = packageItem;
				this.user.packageActive = packageItem;
			},
			displayModal(){
				this.subModalShow = true;
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	}
</script>