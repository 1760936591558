<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('list-invoices.title') }}<a href="https://help.tbf.ro/ro/articles/4558459-cum-descarci-facturile"><icon-info class="icon-info"/></a></h1>
		</div>
		<section class="list-items-section" v-if="loaded">

			<div class="form-section inovices-form">
				<div class="input-group-tbf" v-bind:class="{complete: receiverEmails != '', has_error: inputEmailsError}">
					<div class="label-input">
						<label>{{ $t('list-invoices.receiver-mail') }}</label>
					</div>
					<div class="input-box">
						<div class="icon-left"><icon-email /></div>
						<div class="icon-right" v-if="editEmails == true" @click="canEditEmails"><icon-pencil /></div>
						<div class="icon-right" v-else>
							<div class="actions-emails">
								<button class="btn-default-tbf btn-save" @click="saveEmails">
									{{$t('key-result-tasks.save')}}
								</button>
								<button class="btn-delete" @click="cancelEditEmails"><icon-delete /></button>
							</div>
						</div>
						<input type="text" :placeholder="$t('list-invoices.receiver-mail-placeholder')" class="input-text" v-bind:class="{active: !editEmails}" v-model="receiverEmails" :readonly="editEmails" ref="emails_input">
					</div>
				</div>

				<div class="input-group-tbf">
					<div class="label-input">
						<label>{{ $t('subscription.invoice-data') }}</label>
					</div>
					<div class="input-box">
						<div class="icon-left"><icon-billing-details /></div>
						<div class="icon-right" @click="viewSlide('invoice_details')"><icon-pencil /></div>
						<div class="text" v-if="$auth.user().instance.has_company">{{ $auth.user().instance.company_name }}, {{ $auth.user().instance.cui }}, {{ $auth.user().instance.country_name }}</div>
					<div class="text" v-else>{{ $auth.user().instance.name }}, {{ $auth.user().instance.cnp }}, {{ $auth.user().instance.country_name }}</div>
					</div>
				</div>
			</div>

			<div class="title-list">
				<div class="label second-label">{{ $t('list-invoices.invoice-subtitle') }}</div>
			</div>

			<div v-if="invoices.length">
				<div class="group_items invoice_items" v-for="invoice in invoices">
					<div class="space-left"></div>
					<div class="content-row">
						<a class="row-tbf action-row a-tag" :href="invoice.download_link" download>
							<div class="column-tbf row-title invoice-name-width">
								<div class="icon-left"><icon-invoice /></div>
								<div class="name">{{ invoice.created | moment('DD MMM YYYY') }} - {{ $t('list-invoices.invoice') }} {{ invoice.invoice_number }}</div>
							</div>

							<div class="column-tbf row-details invoice-download-width">
								<icon-download class="download-invoice" />
							</div>
						</a>
					</div>
					<div class="space-right"></div>
				</div>
			</div>

			<div class="no-notifications custom" v-else>
				<p>{{ $t('list-invoices.no-invoices') }}</p>
				<span>{{ $t('list-invoices.desc') }}</span>
			</div>
		</section>
		<section class="empty-list list-items-section" v-else>
			<div class="invoice-mail-settings">
				<div class="label placeholder-loader" style="height: 16px; width: 150px;"></div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>
			<div class="invoice-mail-settings">
				<div class="label placeholder-loader" style="height: 16px; width: 150px;"></div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>

			<div class="label placeholder-loader" style="height: 16px; width: 150px; margin-bottom: 15px;"></div>
			<div class="group_items invoice_items" v-for="n in 5">
				<div class="space-left"></div>
				<div class="content-row">
					<div class="row-tbf">
						<div class="column-tbf">
							<div class="label placeholder-loader" style="height: 16px; width: 150px;"></div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</section>
	</div>
</template>

<script type="text/javascript">
	import ConfirmDelete from "../SubModals/ConfirmDelete"
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'
	import IconPencil from '../Icons/PencilAvatar'
	import IconEmail from '../Icons/Email'
	import IconBillingDetails from "../Icons/BillingDetails"
	import IconDownload from "../Icons/Download"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconInfo from "../Icons/Info"
	import IconDelete from "../Icons/Delete"
	import IconInvoice from "../Icons/Invoice"

	export default {
		data() {
			return {
				loaded: false,
				invoices: [],
				invoiceEmails: '',
				editEmails: true,
				inputEmailsError: false,
				subModalShow: false,
                show_subm_modal: {
                	confirm_delete: false
                },
                deleteItemId: '',
                hoverTag: '',
                receiverEmails: ''
			};
		},
		components: {
			ConfirmDelete,
			IconPlus,
			IconArrow,
			IconEditDots,
			IconPencil,
			IconEmail,
			IconBillingDetails,
			IconDownload,
			IconCircleCheck,
			IconInfo,
			IconDelete,
			IconInvoice
		},
		async mounted() {
			await this.getEmails()
			await this.getInvoices()

            this.$root.$on("reload_modal_key_result", () => {
				this.getInvoices()
            });
		},
		methods: {
			async getInvoices(){
				await axios.get('/list-invoices')
				.then(({data}) => {
					this.invoices = data
				})
				.then(() => {
					this.loaded = true
				})
			},
			async getEmails(){
				await axios.get('/instance-notifications')
				.then(({data}) => {
					this.invoiceEmails = data.data.toString()
					this.receiverEmails = data.data.toString()
				})
			},
			viewSlide(slide, modelRole){
				this.$emit('change_slide', slide, modelRole)
			},
			canEditEmails(){
				this.editEmails = false
				setTimeout(() => {
					this.$refs.emails_input.focus()
				})
			},
			cancelEditEmails(){
				this.editEmails = true
				this.receiverEmails = this.invoiceEmails
			},
			saveEmails(e){
				$(e.target).attr('disabled', 'disabled')

				axios.patch('/instance-notifications', {
					email: this.receiverEmails,
				}).then(({data}) => {
					$(e.target).attr('disabled', false)
					this.editEmails = true
					this.getEmails()
				}).catch(error => {
					this.inputEmailsError = true
					$(e.target).attr('disabled', false)
				});
			}
		}
	};
</script>