<template>
	<div v-if="loaded">
		<div v-if="notAllowed">
			<not-allowed />
		</div>
		<div v-if="notFound">
			<not-found />
		</div>
		<div v-else>
			<div class="tree-page">
				<div class="tree-actions">
					<div class="left-part">
						<button class="btn-default-tbf tree-action-btn sdb-details" @click="openSidebar"><img src="/build/icons/master-goals.svg"></button>
						<div class="dropdown settings-org">
							<button class="btn-default-tbf tree-action-btn" id="dropdownSettingsOrg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="/build/icons/filters.svg"></button>

							<div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownSettingsOrgg">

								<div class="dropdown-item" @click.stop="centerTree">{{$t('master_goal.centertree')}}</div>
								<div class="dropdown-item" @click.stop="extendTree">{{$t('master_goal.extendtree')}}</div>
								<div class="dropdown-item" id="btnExport" @click.stop="exportImg">
									<div class="loader"></div>
									<div class="text">{{$t('master_goal.exporttree')}}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="center-part">
						<div class="text" v-if="$resize && $mq.above(600)">{{ master_goal.name }}</div>
					</div>

					<div class="right-part">
						<div class="btns-zoom mr-0">
							<div class="zoom-minus" @click="parseFloat(zoomPage).toFixed(1) > 0.50 ? zoomPage += -0.1 : zoomPage" v-bind:class="{disabled: zoomPage == 0.50}"><img src="/build/icons/minus.svg"></div>
							<div class="current-zoom" @click="zoomPage = 1">{{ parseFloat(zoomPage * 100).toFixed(0) }}%</div>
							<div class="zoom-plus" @click="parseFloat(zoomPage).toFixed(1) == 1.50 ? zoomPage : zoomPage += 0.1" v-bind:class="{disabled: zoomPage == 1.50}"><img src="/build/icons/plus.svg"></div>
						</div>
					</div>
				</div>

				<div class="scrollable-chart" v-dragscroll>
					<div class="center-box" id="divToImage">
						<TreeChartPublic :json="master_goal" :maxNumber="master_goal.goal_count" :zoomPage="zoomPage" :class="{landscape: landscape.length}" :key="treeChartKey" @click-node="clickNode" :style="'zoom: ' + zoomPage + ';' " />
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>


<script type="text/javascript">
	import NotAllowed from '../General/NotAllowed'
	import NotFound from '../Pages/NotFound'
	import TreeChartPublic from '../Public/TreeChartPublic.vue'
	import IconPlus from '../Icons/Plus.vue'
	import * as htmlToImage from 'html-to-image'
	import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
	import download from 'downloadjs'

	export default {
		data() {
			return {
				loaded: true,
				limitedDescription: true,
				master_goal: {},
      			master_goal_details: {},
				notAllowed: false,
				notFound: false,
				viewMasterGoalAllDescription: false,
      			landscape: [],
      			treeChartKey: 1,
      			zoomPage: 1,
      			extendItems: true,
      			exportImgOnProgress: false
			};
		},
		components: {
			NotAllowed,
			NotFound,
			TreeChartPublic,
			IconPlus
		},
		watch:{
			$route (to, from){
				var jsonExtend = []
				localStorage.setItem('numberCount', 0)
				localStorage.setItem('numberCountExtend', 0)
				localStorage.setItem('jsonExtend', jsonExtend)

				if(this.$route.params.uuid){
					this.getMasterGoal()
				}else{
					this.$router.push({ name: 'page-404'})
				}
            }
        },
		async mounted() {
			var jsonExtend = []
			localStorage.setItem('numberCount', 0)
			localStorage.setItem('numberCountExtend', 0)
			localStorage.setItem('jsonExtend', jsonExtend)

			if(this.$route.params.uuid){
				await this.getMasterGoal()
			}else{
				this.$router.push({ name: 'page-404'})
			}
		},
		methods: {
			async getMasterGoal(){
				await axios.get('/master-goals/public/'+ this.$route.params.uuid)
				.then(({data}) => {
					this.master_goal = data.data
					this.master_goal_details = { main: data.data, visions: data.all_visions, objectives: data.all_objectives, key_results: data.all_key_results }

					localStorage.setItem('numberCount', 0)
					localStorage.setItem('maxNumber', this.master_goal.goal_count)
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'
					this.notFound = status == 'not found'
				}).then(() => {
					setTimeout(()=>{
						this.loaded = true
					}, 300)
				})
			},
			displayAllText() {
				this.limitedDescription = false;
			},
			clickNode: function(node){
				// console.log(node)
			},
			openSidebar(){
				this.$root.$emit('open_modal', 'sidebar_org', false, this.master_goal_details)
			},
			updateTree(){
				this.treeChartKey += 1
			},
			extendTree(){
				localStorage.setItem('numberCountExtend', 0)

				setTimeout(() => {
					this.extendItems = !this.extendItems
					this.$root.$emit('toggleExtendAll', this.extendItems)
				}, 0)
			},
			centerTree(){
				var calcWidth = ($('.scrollable-chart .center-box .parentLevel .node').position().left * this.zoomPage) - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel .node').width() / 2) * this.zoomPage) + 60

				$('.scrollable-chart').animate({
					scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
					scrollLeft: calcWidth
				}, 300);

			},
			exportImg(e){
				this.exportImgOnProgress = true
				var btnExport = $('#btnExport')
				var btnExportLoader = $('#btnExport .loader')
				var btnExportText = $('#btnExport .text')

				btnExport.addClass('loading')
				btnExportLoader.addClass('onProgress')
				btnExportText.html('...')

				btnExport.attr('disabled', 'disabled')

				htmlToImage.toPng(document.getElementById('divToImage'))
				.then((dataUrl) => {
					download(dataUrl, this.master_goal.name + '.png');
				})
				.then(() => {
					btnExportLoader.addClass('finish')
					setTimeout(()=>{
						btnExportText.html(this.$t('btn-submit.success'))
						btnExport.addClass('completed')
						btnExportLoader.removeClass('onProgress finish')
						btnExport.removeClass('loading')
						setTimeout(()=>{
							btnExport.removeClass('completed')
							btnExportText.html(this.$t('master_goal.exporttree'))
							setTimeout(() => {
								this.exportImgOnProgress = false
							}, 1000)
							btnExport.attr('disabled', false)
						}, 1000)
					}, 300)
				})
			}
		}
	};
</script>
