<template>
	<div class="submodal-tbf" id="submodal_reason_evaluation">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Reason Evaluation', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<!-- <div class="header-submodal">
			<div class="image"><img src="/build/icons/warning.svg"></div>
			<div class="title">{{$t('daily_promise.evaluate_failed_title')}}</div>
		</div> -->
		<div class="body-modal">
			<div class="input-group-tbf" v-bind:class="{has_error: $v.reason.$error, complete: reason != '' && !$v.reason.$error}">
				<div class="label-input">
					<label>{{$t('daily_promise.evaluate_failed_title')}}</label>

				</div>
				<div class="input-box">
					<textarea class="input-textarea" :placeholder="$t('daily_promise.description_failed_evaluation')" v-model="reason" v-autosize rows="3"></textarea>
				</div>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="sendResponse">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('daily_promise.send_failed_evaluation')}}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				reason: '',
				arrayTranslations: [
					'daily_promise.evaluate_failed_title',
					'daily_promise.description_failed_evaluation',
					'daily_promise.send_failed_evaluation'
				]
			};
		},
		validations: {
			reason: {required}
		},
		methods: {
			sendResponse(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					this.$emit("send_response", this.reason);
					$(e.target).attr('disabled', false)
				}else{
					$(e.target).attr('disabled', false)
				}

			}
		}
	};
</script>