<template>
    <section class="page-attention">
        <div class="row-space-tbf">
            <div class="space-left"></div>
            <not-paid v-if="['new', 'unpaid', 'not_instance'].includes($auth.user().rights.instance)"/>
            <not-allowed v-else/>
            <div class="space-right"></div>
        </div>
    </section>
</template>

<script>
    import NotAllowed from '../General/NotAllowed'
    import NotPaid from '../General/NotPaid'

    export default {
        data(){
            return{
            }
        },
        mounted(){
            var title = 'Not Access';
            this.$root.$emit("navbar_title", title);
        },
        components: {
            NotAllowed,
            NotPaid
        },
        methods: {
            handleAnimationLogo(anim){
                this.anim_logo = anim;
                this.anim_logo.setSpeed(1);
            }
        }
    }
</script>