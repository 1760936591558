<template>
	<div>
		<!-- key result list -->
		<section class="list-items-section tab-container active" v-if="data.list.length">
			<div class="count-list">
				<div class="space-left"></div>
				<div class="content-row">
					<div class="total-items">{{ $t('general.total_items') }} <span class="number">{{ data.totalItems }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="group_items" v-for="key_result in data.list">
				<div class="space-left">
					<icon-arrow class="icon-arrow right"/>
				</div>
				<div class="content-row">
					<div class="row-tbf action-row" @click="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'updates')" v-bind:class="{upcoming: key_result.status == 'upcoming'}">
						<!-- asigned user -->
						<div class="column-tbf icon-title kr-icon-width" v-if="$resize && $mq.above(770)">
							<icon-list />
						</div>
						<!-- end assigned user -->

						<!-- RC name box -->
						<div class="column-tbf row-title with-icon kr-name-width">
							<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 200, hide: 0}" :disabled="$resize && $mq.below(1030)">
								<div class="text"><img class="attention-update" src="/build/icons/attention-square.svg" v-if="$resize && $mq.below(769) && (key_result.needs_an_update == 'today' || key_result.needs_an_update == 'past')"> {{ key_result.name.charAt(0).toUpperCase() + key_result.name.slice(1) }}</div>
								<div class="subtitle">{{ $t('show-user.objectives') }}: {{ key_result.objective.name }}</div>
								
								<template slot="popover">
									<div class="description">{{ key_result.description }}</div>
								</template>
							</v-popover>
						</div>
						<!-- end rc name box -->

						<!-- promises box -->
						<div class="column-tbf column-value kr-promises-width" v-if="$resize && $mq.above(1030)" @click.stop="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'promises')">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
								<div class="label">{{$t('show-user.promises')}}</div>
								<div class="value" v-if="key_result.need_evaluate_promise"><img class="circle-info" src="/build/icons/circle-info-orange.svg"> {{ $t('show-user.status-pending') }}</div>
								<div class="value" v-else>{{ key_result.promises_completed }}/{{ key_result.promises_count }} {{ $t('key-result-promises.completed')}}</div>

								<template slot="popover">
									<div class="simple-text" v-if="key_result.status == 'upcoming'">{{ $tc('key-result.tooltip-promises-cant') }}</div>
									<div class="simple-text" v-else>{{ key_result.need_evaluate_promise ? $t('key-result.tooltip-promises-evaluate') : $t('key-result.tooltip-promises') }}</div>
								</template>
							</v-popover>
						</div>
						<!-- end promises box -->

						<!-- updates box -->
						<div class="column-tbf column-value kr-updates-width" v-if="$resize && $mq.above(1030)" @click.stop="showModalKR(parseInt(key_result.id).toString(), key_result.uuid, 'updates')">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
								<div class="label">{{$t('show-user.updates')}}</div>

								<div class="value" v-if="key_result.needs_an_update == 'today' || key_result.needs_an_update == 'past'"><img class="circle-info" src="/build/icons/circle-info-orange.svg"> {{ $t('show-user.status-pending') }}</div>
								<div class="value" v-else-if="key_result.last_key_result_log"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $tc('show-user.days-ago',  diffDaysFrom(key_result.last_key_result_log.created_at)) }}</div>
								<div class="value" v-else>{{ $t('show-user.no-updates') }}</div>

								<template slot="popover">
									<div class="simple-text" v-if="key_result.needs_an_update == 'today'">{{ $t('key-result.tooltip-update-today') }}</div>
									<div class="simple-text" v-else-if="key_result.needs_an_update == 'past'">{{ $t('key-result.tooltip-update-past') }}</div>
									<div class="simple-text" v-else-if="key_result.needs_an_update">{{ $tc('key-result.tooltip-update-future', key_result.needs_an_update) }}</div>
									<div class="simple-text" v-else-if="key_result.status == 'upcoming'">{{ $t('key-result.tooltip-update-cant') }}</div>
									<div class="simple-text" v-else>{{ $tc('key-result.tooltip-updates-view') }}</div>
								</template>
							</v-popover>
						</div>

						<div class="column-tbf column-value kr-deadline-width" v-if="$resize && $mq.above(1030)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
								<div class="label">{{ moment(key_result.start_date) >= moment() ? $t('objectives.start_on') : $t('objectives.deadline')}}</div>

								<div class="value" v-if="key_result.percent >= 100"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $t('status.completed') }}</div>
								<div class="value" v-else-if="diffDays(key_result.end_date) < 0"><img class="circle-info" src="/build/icons/circle-info-red.svg"> {{ $t('show-user.outdated') }}</div>
								<div class="value" v-else>{{ diffWeeksAndDays(key_result.end_date) }}</div>
								

								<template slot="popover">
									<div class="simple-text">{{ key_result.end_date | moment('D MMMM YYYY') }}</div>
								</template>
							</v-popover>
						</div>

						<!-- last values box -->
						<div class="column-tbf column-value kr-last-value-width" v-if="$resize && $mq.above(770)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
								<div class="label">{{ $t('show-user.last-value') }}</div>

								<div class="value" v-if="displayKeyResultCurrentValue(key_result) % 1 != 0">{{ displayKeyResultCurrentValue(key_result) | numeral('0.00a') }}{{ optionsUnits[key_result.unity] ? key_result.unity : ''}} {{ !optionsUnits[key_result.unity] ? key_result.unity : optionsUnits[key_result.unity]}}</div>
								<div class="value" v-else>{{ displayKeyResultCurrentValue(key_result) | numeral('0a') }}{{ optionsUnits[key_result.unity] ? key_result.unity : ''}} {{ !optionsUnits[key_result.unity] ? key_result.unity : "("+optionsUnits[key_result.unity]+")"}}</div>
								
								<template slot="popover">
									<div class="simple-text">{{ $t('progress_type_text.' + key_result.progress_type, { to: key_result.target % 1 != 0 ? $options.filters.numeral(key_result.target ,  '0,0.00') : $options.filters.numeral(key_result.target ,  '0,0'), unit: key_result.unity, from: key_result.start_value % 1 != 0 ? $options.filters.numeral(key_result.start_value ,  '0,0.00') : $options.filters.numeral(key_result.start_value ,  '0,0')}) }}</div>
								</template>
							</v-popover>

						</div>
						<!-- end last values box -->

						<!-- Percentage box -->
						<div class="column-tbf item-value kr-value-width" v-if="$resize && $mq.above(600)">
							<v-popover offset="10" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" :disabled="$resize && $mq.below(1030)">
								<div class="top-data">
									<div class="current-value" v-if="moment(key_result.start_date) >= moment()">
										<div class="value">0</div>
									</div>
									<div class="current-value" v-else>
										<div class="value">{{ parseInt(key_result.percent).toString()+'%' }}</div>
									</div>
									<div class="info"><icon-info class="icon-info"/></div>
								</div>
								<div class="progress-bar-tbf" v-bind:class="{disabled: moment(key_result.start_date) > moment()}">
									<div class="complete-progress-tbf" v-bind:class="{pink: diffDays(key_result.end_date) < 1 && key_result.percent < 100, green: key_result.percent >= 100}" :style="'width: calc(2px + ' + (parseInt(key_result.percent) < 1 ? '2px' : (key_result.percent < 100 ? key_result.percent + '%' : '100%')) + ');'"></div>
								</div>

								<template slot="popover">
									<div class="simple-text">
										{{ $t('progress_type_text.' + key_result.progress_type, { to: key_result.target % 1 != 0 ? $options.filters.numeral(key_result.target ,  '0,0.00') : $options.filters.numeral(key_result.target ,  '0,0'), unit: key_result.unity, from: key_result.start_value % 1 != 0 ? $options.filters.numeral(key_result.start_value ,  '0,0.00') : $options.filters.numeral(key_result.start_value ,  '0,0')}) }}. 
										{{ $t('progress_type_text.current_value') }}: {{ displayKeyResultCurrentValue(key_result) % 1 != 0 ? $options.filters.numeral(displayKeyResultCurrentValue(key_result), '0,0.00') : $options.filters.numeral(displayKeyResultCurrentValue(key_result), '0,0')}} {{ key_result.unity }}
									</div>
								</template>
							</v-popover>
						</div>
						<!-- End percentage box -->
					</div>
				</div>
				<div class="space-right">
					<div class="dots-edit" :id="'edit-key-result-' + key_result.id" v-click-outside="hideDropdown" v-if="key_result.rights.edit || key_result.rights.delete">
						<div class="dropdown edit-item-dropdown">
							<div class="overlay-button" @click.stop="showDropdown(key_result.id)"></div>
							<div class="edit-item-button" :id="'dropdownEdit'+key_result.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<icon-edit-dots />
							</div>
							<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ key_result.id " :aria-labelledby="'dropdownEdit'+key_result.id">
								<div class="dropdown-item" @click.stop="showModal('key_result', parseInt(key_result.id).toString(), key_result.objective)" v-if="key_result.rights.edit">{{$t('show-user.edit')}}</div>
								<div class="dropdown-item" @click.stop="openModalV2('delete', key_result, 'kr', 'show_user')" v-if="key_result.rights.delete">{{$t('show-user.delete')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- END key result list -->

		<!-- no key results message -->
		<section class="list-items-section tab-container active" v-else>
			<div class="group_items">
				<div class="space-left"></div>
				<div class="empty-row mrgn">
					<div class="title single" v-html="$t('show-user.no-key-results', { name: data.userName })">
					</div>
					<div class="description wide" v-html="$t('show-user.desc-no-key-results')">
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</section>
		<!-- END no key results message -->
	</div>
</template>

<script type="text/javascript">
	import IconList from '../../Icons/List'
	import IconInfo from '../../Icons/Info'
	import IconArrow from '../../Icons/Arrow'
	import IconEditDots from '../../Icons/EditDots'

	export default {
		props: {
			data: Object,
		},
		data() {
			return {
				optionsUnits: {
                	"€": 'Euro',
                	"$": 'Dolar',
                	"%": 'Procent',
                }
			};
		},
		components: {
			IconList,
			IconInfo,
			IconArrow,
			IconEditDots
		},
		mounted() {

		},
		methods: {
			showModalKR(model, uuid, type){
				this.hideDropdown()
				this.$root.$emit('open_key_result_modals', model, uuid, type, 'show_user');
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			diffDaysFrom(update_date){
				var a = moment(update_date).startOf('day');
				var b = moment().startOf('day');
				return b.diff(a, 'days');  
			},
			diffWeeksAndDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');

				var diff = moment.duration(b.diff(a));
				
				return diff.asDays() > 7 ? `${this.$tc('weeks', Math.floor(diff.asWeeks()))} ${diff.days()%7 != 0 ? this.$tc('days', diff.days()%7) : ''}` : this.$tc('days', diff.days()%7) ;  
			},
			displayKeyResultCurrentValue(keyResult) {
				var text = "";
				var value = keyResult.last_key_result_log ? keyResult.last_key_result_log.value : keyResult.start_value;		
				return value.includes('.00') ? parseInt(value).toString() : parseFloat(value).toString();
			},
			showDropdown(itemId){
				if(itemId == this.hoverkeyResult){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}else{
					this.hoverkeyResult = itemId
					setTimeout(() => {
						$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdown(){
				if(this.hoverkeyResult != ''){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}
			},
			showModal(type, model = false, requiredData = false, userData = false){
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'show_user');
				this.hideDropdown()
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdown()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
		}
	};
</script>