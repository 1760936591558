<template>
	<table v-if="treeData.name">
		<tr>
      		<td :colspan="treeData.children ? treeData.children.length * 2 : 1" 
			  	:class="{ parentLevel: treeData.children, 
				  		  extend: treeData.children && 
						  treeData.children.length > 0 && 
						  treeData.extend }">
				<div :class="{ node: true }">
				<!-- PARENT NODE -->
					<div class="box-tbf" @click="$emit('click-node', treeData)" v-if="treeData.type == 'master-goal'">
						<div class="upper-contain">
							<div class="vision-title-wrapper">
								<div class="vision-title" v-if="treeData.type == 'master-goal'">
									{{$t('tree-chart.title-master-goal')}}
								</div>
								<div class="vision-action">
									<div class="vision-notification mr-0">
										<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
											<span>{{ treeData.goal_count }}</span>

											<template slot="popover">
												<div class="simple-text">{{ $tc('tree-chart.no_count', treeData.goal_count) }}</div>
											</template>
										</v-popover>
									</div>
								</div>
							</div>
							<div class="vision-sub-title">
								<span>{{treeData.name ? treeData.name : $t('master_goal.private')}}</span>
							</div>
						</div>

						<div class="separator"></div>

						<div class="bottom-contain">
							<div class="amount">{{ parseInt(treeData.percentage_finished).toString() }}%</div>
							<div class="time">{{ $tc('days', treeData.remaining_days)}}</div>
						</div>

						<div class="progress-bar-tbf">
							<div class="complete-progress-tbf" v-bind:class="{blue: !treeData.has_one_overdue, overdue: treeData.has_one_overdue}" :style="'width: calc(2px + ' + (treeData.percentage_finished < 1 ? '2px' : treeData.percentage_finished  + '%') + ');'"></div>
						</div>

					</div>

					<!-- CHILD LIGHT GREY -->
					<div class="box-tbf-goal" v-if="treeData.type == 'goal'">
						<div class="goal-avatar-wrapper">
							<div class="goal-avatar">
								<img :src="`/build${treeData.user.avatar.substring(treeData.user.avatar.lastIndexOf('.ro')+3)}`" class="avatar" v-if="treeData.user.avatar">
								<div class="user-circle" v-else>
									<icon-user-settings/>
								</div>
							</div>
						</div>
						<div class="upper-contain">
							<div class="vision-title-wrapper">
								<div class="vision-title">
									{{$t('tree-chart.title-goal')}}
								</div>
								<div class="vision-action">
									<div class="vision-notification mr-0">
										<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
											<span :title="$tc('count_rezultate', treeData.total_key_results, { n: treeData.total_key_results })">{{treeData.total_key_results}}</span>

											<template slot="popover">
												<div class="simple-text">{{ $tc('tree-chart.no_count', treeData.total_key_results) }}</div>
											</template>
										</v-popover>
									</div>
								</div>
							</div>
							<div class="goal-title">
								{{ treeData.name ? treeData.name : $t('objective.private') }}
							</div>
						</div>

						<div class="separator"></div>

						<div class="bottom-contain">
							<div class="amount">{{ parseInt(treeData.percent).toString() }}%</div>
							<div class="time">{{ diffDays(treeData.end_date) }} {{$t('tree-chart.days')}}</div>
						</div>

						<div class="progress-bar-tbf">
							<div class="complete-progress-tbf" v-bind:class="{red: treeData.status == 'overdue', blue: treeData.status != 'overdue', green: treeData.percent > 99}" :style="'width: calc(2px + ' + (treeData.percent < 1 ? '2px' : treeData.percent + '%') + ');'"></div>
						</div>

						<div class="extend-handle-goal" :class="{open: !isHidden}" @click="isHidden = !isHidden"></div>

						<div class="extend-details-goal" v-if="!isHidden">
							<div class="goal-key-upper">
								<div class="section-title">
									{{$t('tree-chart.title-results')}}
								</div>
								<div class="section-action">
									<div class="arrow-extend-up" @click="isHidden = !isHidden">
										<icon-arrow class="icon-arrow up" />
									</div>
								</div>
							</div>

							<div class="goal-keys-content">
								<div class="goal-key" v-for="(goal_key_result, index) in treeData.key_results" :key="index">
									<div class="goal-content">
										<div class="header-user-kr">
											<div class="left-part">
												<div class="icon">
													<img :src="`/build${goal_key_result.user.avatar.substring(goal_key_result.user.avatar.lastIndexOf('.ro')+3)}`" class="avatar" v-if="goal_key_result.user.avatar">
													<div class="user-circle" v-else>
														<icon-user-settings/>
													</div>
												</div>
												<div class="user-data">
													<div class="name">{{ goal_key_result.user.name }}</div>
													<div class="user-role">{{ $t('users.role-name-' + goal_key_result.user.role) }}</div>
												</div>
											</div>
										</div>
										<div class="header-kr">
											<div class="text">{{ goal_key_result.name ? (goal_key_result.name.charAt(0).toUpperCase() + goal_key_result.name.slice(1)) : $t('objective.kr_private') }}</div>
										</div>
										<div class="actions">
											<div class="row-data">
												<div class="notification" @click="goal_key_result.name ? showModalKR(parseInt(goal_key_result.id).toString(), goal_key_result.uuid, 'updates') : ''" v-bind:class="{disabled: !goal_key_result.name}">
													<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
														<div class="di-flex-center">
															{{$t('tree-chart.updates')}} <span v-bind:class="{warning: goal_key_result.needs_an_update == 'today' || goal_key_result.needs_an_update == 'past'}">{{ goal_key_result.needs_an_update == 'today' || goal_key_result.needs_an_update == 'past' ? '!' : goal_key_result.key_results_logs_count }}</span>
														</div>

														<template slot="popover">
															<div class="simple-text" v-if="goal_key_result.needs_an_update == 'today'">{{ $t('key-result.tooltip-update-today') }}</div>
															<div class="simple-text" v-else-if="goal_key_result.needs_an_update == 'past'">{{ $t('key-result.tooltip-update-past') }}</div>
															<div class="simple-text" v-else-if="goal_key_result.needs_an_update">{{ $tc('key-result.tooltip-update-future', goal_key_result.needs_an_update) }}</div>
															<div class="simple-text" v-else-if="goal_key_result.status == 'upcoming'">{{ $t('key-result.tooltip-update-cant') }}</div>
															<div class="simple-text" v-else>{{ $tc('key-result.tooltip-updates-view') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="notification percentage-not">
													<v-popover offset="10" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
														<div class="progress-bar-tbf">
															<div class="complete-progress-tbf" v-bind:class="{red: diffDays(goal_key_result.end_date) < 0, green: parseInt(goal_key_result.percentage_finished) > 99}" :style="'width: calc(2px + ' + (parseInt(goal_key_result.percentage_finished) < 1 ? '2px' : parseInt(goal_key_result.percentage_finished).toString() + '%') + ');'"></div>
														</div>

														<template slot="popover">
															<div class="simple-text">
															{{ $t('progress_type_text.' + goal_key_result.progress_type, { to: goal_key_result.target % 1 != 0 ? $options.filters.numeral(goal_key_result.target ,  '0,0.00') : $options.filters.numeral(goal_key_result.target ,  '0,0'), unit: goal_key_result.unity, from: goal_key_result.start_value % 1 != 0 ? $options.filters.numeral(goal_key_result.start_value ,  '0,0.00') : $options.filters.numeral(goal_key_result.start_value ,  '0,0')}) }}. 
															{{ $t('progress_type_text.value') }}: {{ goal_key_result.current_value % 1 != 0 ? $options.filters.numeral(goal_key_result.current_value, '0,0.00') : $options.filters.numeral(goal_key_result.current_value, '0,0')}} {{ goal_key_result.unity }}
															</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="goal-key" v-if="treeData.key_results.length == 0">
									<div class="goal-content">
										<div class="header-kr">
											<div class="icon">
												<key-results-icon />
											</div>
											<div class="text">
												{{$t('tree-chart.no-key')}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="extend-handle-tbf" :class="{open: !parentOpen}" @click="toggleExtend(treeData)" v-if="treeData.children && treeData.children.length > 0"></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend" class="children-row-wrapper">
			<td
				v-for="(children, index) in treeData.children"
				:key="index"
				colspan="2"
				:class="{hasGoal: children.type == 'goal'}"
				class="childLevel"
			>
				<TreeChartPublic :json="children" :maxNumber="maxNumber" :zoomPage="zoomPage" @click-node="$emit('click-node', $event)" />
			</td>
		</tr>
	</table>
</template>

<script>
import EditDotsIcon from "../Icons/EditDots";
import PlusIcon from "../Icons/Plus";
import IconArrow from "../Icons/Arrow";
import KeyResultsIcon from "../Icons/KeyResults";
import IconUserSettings from '../Icons/UserSettings'

export default {
	data() {
		return {
			isHidden: true,
			treeData: {},
			showGoals: false,
			showPeople: false,
			parentOpen: false,
			clickedGoal: ''
		};
	},
  	props: {
  		json: Object,
  		maxNumber: Number,
  		zoomPage: Number
  	},
  	name: "TreeChartPublic",

	components: {
		EditDotsIcon,
		PlusIcon,
		IconArrow,
		KeyResultsIcon,
		IconUserSettings
	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

					if(jsonExtend.find(el => el.id == jsonData.id)){
						jsonData.extend = jsonExtend.find(el => el.id == jsonData.id).extend
					}else{
						jsonData.extend = true
						jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })
					}
					localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

					// if (Array.isArray(jsonData.children)) {
					// 	jsonData.children.forEach((c) => {
					// 		if(typeof c === 'object'){
					// 			extendKey(c);
					// 		}
					// 	});
					// }
					return jsonData;
				};
				if(Props) {
					if(typeof Props === 'object'){
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		},
	},
	mounted(){
		this.$root.$on('toggleExtendAll', (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
			this.$forceUpdate()

			var noCountExntend = localStorage.getItem('numberCountExtend')
			localStorage.setItem('numberCount', (noCountExntend ? noCountExntend = parseInt(noCountExntend) + 1 : 1))
		})

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

			jsonData.extend = extendValue
			if(jsonExtend.find(el => el.id == jsonData.id)){
				jsonExtend.find(el => el.id == jsonData.id).extend = jsonData.extend
			}else{
				jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })
			}
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			return jsonData;
		};

		var noCount = localStorage.getItem('numberCount')
		localStorage.setItem('numberCount', (noCount ? noCount = parseInt(noCount) + 1 : 1))

		if(localStorage.getItem('numberCount') == this.maxNumber){
			this.centerOrg()
		}
	},
  	methods: {
		toggleExtend: function(treeData) {
			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];
			treeData.extend = !treeData.extend;
			jsonExtend.find(el => el.id == treeData.id).extend = treeData.extend
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			this.parentOpen = !this.parentOpen;
			this.$forceUpdate();
		},
		showGoalKeys() {
			this.showGoals = !this.showGoals;
		},
		showGoalPeople() {
			this.showPeople = !this.showPeople;
		},
		showDropdown(itemId){
			if(itemId == this.clickedGoal){
				$('#dropdownTreeGoal' + this.clickedGoal).dropdown('toggle');
				setTimeout(() => {
					this.clickedGoal = ''
				}, 0)
			}else{
				this.clickedGoal = itemId
				setTimeout(() => {
					$('#dropdownTreeGoal' + this.clickedGoal).dropdown('toggle');
				}, 0)
			}

		},
		hideDropdown(){
			if(this.clickedGoal != ''){
				$('#dropdownTreeGoal' + this.clickedGoal).dropdown('toggle');
				setTimeout(() => {
					this.clickedGoal = ''
				}, 0)
			}
		},
		diffDays(end_date) {
			var a = moment().startOf('day');
			var b = moment(end_date).startOf('day');
			return b.diff(a, 'days');  
		},
		showModal(type, model = false, requiredData = false, userData = false){
			this.hideDropdown()
			this.$root.$emit('open_modal', type, model, requiredData, userData);
		},
		showModalKR(model, uuid, type){
			this.hideDropdown()
			this.$root.$emit('open_key_result_modals', model, uuid, type);
		},
		openModalV2(type, model = false, typeDelete = false, fromDelete = false){
			this.hideDropdown()
			this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
		},
		centerOrg(){
			var calcWidth = ($('.scrollable-chart .center-box .parentLevel').first().children('.node').position().left * this.zoomPage) - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel').first().children('.node').width() / 2) * this.zoomPage) + 60

			$('.scrollable-chart').animate({
				scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
				scrollLeft: calcWidth
			}, 300);
		}
  	}
};
</script>
