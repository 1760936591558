<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>
				{{ $t('list-referrals.title') }}
				<a href="https://help.tbf.ro/ro/articles/4558375-cum-ca%C8%99tigi-3-luni-gratuite-la-abonamentul-tbf-digital" target="_blank">
					<icon-info class="icon-info"/>
				</a>
			</h1>
		</div>
		<section class="list-items-section" v-if="loaded">
			<div class="form-section inovices-form">
				<div class="input-group-tbf">
					<div class="label-input">
						<label>{{ $t('list-referrals.label-code') }}</label>
					</div>
					<div class="input-box referral-copy">
						<div class="icon-left"><icon-referral-code /></div>
						<div class="icon-right" @click="copyToClipboard">{{ $t('list-referrals.copy') }}</div>
						<input type="text" id="referralCode" class="input-text" :value="referralCode" readonly>
					</div>
				</div>
			</div>

			<div class="title-list">
				<div class="label second-label" v-if="referrals.length">{{ $t('list-referrals.list-title') }}</div>
			</div>

			<div v-if="referrals.length">
				<div class="group_items invoice_items" v-for="referral in referrals">
					<div class="space-left"></div>
					<div class="content-row">
						<div class="row-tbf">
							<div class="column-tbf row-title invoice-name-width">
								<div class="icon-left"><icon-people /></div>
								<div class="email">{{ referral.email }}</div>
								<div class="date">{{ referral.created_at | moment('from', 'now') }}</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
			<!-- <div class="no-notifications custom" v-else>
				<p>{{ $t('list-referrals.no-referrals') }}</p>
				<span>{{ $t('list-referrals.no-referrals-desc') }}</span>
			</div> -->
		</section>
		<section class="empty-list list-items-section" v-else>
			<div class="invoice-mail-settings">
				<div class="label placeholder-loader" style="height: 16px; width: 150px;"></div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>

			<div class="label placeholder-loader" style="height: 16px; width: 150px; margin-bottom: 15px;"></div>
			<div class="group_items invoice_items" v-for="n in 5">
				<div class="space-left"></div>
				<div class="content-row">
					<div class="row-tbf">
						<div class="column-tbf">
							<div class="label placeholder-loader" style="height: 16px; width: 150px;"></div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</section>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'
	import IconCopy from '../Icons/Copy'
	import IconEmail from '../Icons/Email'
	import IconReferralCode from "../Icons/ReferralCode"
	import IconPeople from "../Icons/People"
	import IconInfo from "../Icons/Info"

	export default {
		data() {
			return {
				loaded: false,
				referrals: [],
				referralCode: ''
			};
		},
		components: {
			IconPlus,
			IconArrow,
			IconEditDots,
			IconCopy,
			IconEmail,
			IconReferralCode,
			IconPeople,
			IconInfo
		},
		async mounted() {
			await this.getReferrals()
		},
		methods: {
			async getReferrals(){
				await axios.get('/instances/referral')
				.then(({data}) => {
					this.referrals = data.data.referred_instances
					this.referralCode = data.data.referral_uuid
				})
				.then(() => {
					this.loaded = true
				})
			},
			viewSlide(slide, modelRole){
				this.$emit('change_slide', slide, modelRole)
			},
			copyToClipboard(){
				var copyText = document.getElementById("referralCode");
				copyText.select();
				copyText.setSelectionRange(0, 99999)
				document.execCommand("copy");

				$('.referral-copy .icon-right').addClass('success')
				$('.referral-copy .icon-right').html(this.$t('list-referrals.copied'))
				setTimeout(() => {
					$('.referral-copy .icon-right').removeClass('success')
					$('.referral-copy .icon-right').html(this.$t('list-referrals.copy'))
				}, 3000)
			}
		}
	};
</script>