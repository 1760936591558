<template>
	<div class="full-page">
		<slot></slot>

		<div class="overflow-modal-profile" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
			<modal-key-result id="modal_key_result" v-if="show_modal.key_result" :model="model.key_result" :requiredData="requiredData.key_result" :userData="userData.key_result" @toggle_modal="closeModal('key_result')"/>
			<modal-key-result-modals id="modal_key_result_modals" v-if="show_modal.key_result_modals" :keyResult="model.key_result_modals" :uuid="krUuid" :activeTab="modalKeyTab" @toggle_modal="closeModal('key_result_modals')"/>
			<modal-objective id="modal_objective" v-if="show_modal.objective" :model="model.objective" :requiredData="requiredData.objective" @toggle_modal="closeModal('objective')" canAddMasterGoalsInline="false" />
			<modal-master-goal id="modal_master_goal" v-if="show_modal.master_goal" :model="model.master_goal" :requiredData="requiredData.master_goal" :userData="userData.master_goal" @toggle_modal="closeModal('master_goal')" :fromPage="modalFromPage"/>
			<modal-delete id="modal_delete" v-if="show_modal.delete" :model="model.delete" :type="typeDelete" :from="fromDelete" @close_modal="closeModal('delete')" />
			<modal-cant-create-more-objective id="modal_cant_create_more_objective" v-if="show_modal.cant_create_more_objective" @close_modal="closeModal('cant_create_more_objective')" />
			<modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @toggle_modal="closeModal('change_subscription')"/>
			<modal-payment-error id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
			<modal-congratulations id="modal_congratulations" v-if="show_modal.congratulations" @close_modal="closeModal('congratulations')"/>

			<modal-cant-add-more-updates id="modal_cant_add_more_updates" v-if="show_modal.cant_add_more_updates" @close_modal="closeModal('cant_add_more_updates')" />

			<sidebar-org id="modal_sidebar_org" v-if="show_modal.sidebar_org" :requiredData="requiredData.sidebar_org" @close_modal="closeModal('sidebar_org')"/>
			<modal-user id="modal_user" v-if="show_modal.user" :model="model.user" @toggle_modal="closeModal('user')" :fromPage="modalFromPage" />
			<modal-tag id="modal_tag" v-if="show_modal.tag" :model="model.tag" @toggle_modal="closeModal('tag')" :fromPage="modalFromPage" />
			<modal-evaluate-promise id="modal_evaluate_promise" v-if="show_modal.evaluate_promise" @toggle_modal="closeModal('evaluate_promise')"  :model="evaluate_promise" :fromPage="modalFromPage"/>

			<modal-details-objective id="modal_details_objective" v-if="show_modal.details_objective" :model="model.details_objective" @toggle_modal="closeModal('details_objective')" />
			<modal-translate id="modal_translate" v-if="show_modal.translate" :component="componentTranslation" :translationData="arrayTranslationKeys" @close_modal="closeModal('translate')"/>
        </div>
	</div>
</template>

<script>
	import ModalKeyResult from "../Modals/KeyResult"
	import ModalObjective from "../Modals/Objective"
	import ModalDelete from "../Modals/Delete"
	import ModalMasterGoal from "../Modals/MasterGoal"
	import ModalKeyResultModals from "../Modals/KeyResultModals"
	import ModalCantCreateMoreObjective from "../Modals/CantCreateMoreObjective"
	import ModalChangeSubscription from "../Modals/ChangeSubscription"
	import ModalPaymentError from "../Modals/PaymentError"
	import ModalCongratulations from "../Modals/Congratulations"
	import ModalCantAddMoreUpdates from "../Modals/CantAddMoreUpdates"
	import SidebarOrg from "../Modals/SidebarOrg"
	import ModalUser from "../Modals/User"
	import ModalTag from "../Modals/Tag"
	import ModalEvaluatePromise from "../Modals/EvaluatePromise"
	import ModalDetailsObjective from "../Modals/DetailsObjective"
	import ModalTranslate from "../General/ModalTranslate"

	export default {
		data() {
			return {
				show_overflow: false,
				show_modal: {
					key_result: false,
					key_result_modals: false,
					objective: false,
					user: false,
					tag: false,
					master_goal: false,
					change_subscription: false,
					payment_error: false,
					congratulations: false,
					sidebar_org: false,
					cant_add_more_updates: false,
					evaluate_promise: false,
					details_objective: false,
					translate: false
				},
				model: {
					user : '',
					tag : '',
					key_result: '',
					key_result_modals: '',
					master_goal: '',
					objective: '',
					evaluate_promise: '',
					details_objective: ''
				},
				requiredData: {
					key_result: {},
					objective: {},
					master_goal: {},
					objective: {},
					sidebar_org: {}
				},
				userData: {
					key_result: {},
					objective: {},
					master_goal: {}
				},
				active_modal: '',
				active_modal_list: [],
				typeDelete: false,
				fromDelete: false,
				modalFromPage: false,
				modalKeyTab: '',
				krUuid: '',
				evaluate_promise: {},
				arrayTranslationKeys: [],
				componentTranslation: ''
			}
		},
		components: {
			ModalKeyResult,
			ModalObjective,
			ModalMasterGoal,
			ModalDelete,
			ModalCantCreateMoreObjective,
			ModalKeyResultModals,
			ModalChangeSubscription,
			ModalPaymentError,
			ModalCongratulations,
			ModalCantAddMoreUpdates,
			SidebarOrg,
			ModalUser,
			ModalTag,
			ModalEvaluatePromise,
			ModalDetailsObjective,
			ModalTranslate
		},
		watch:{
			$route (to, from){
				this.closeModal()
			}
		},
		mounted(){
			Intercom('shutdown');
			
			this.$root.$on("open_modal", (type, model = false, requiredData = false, userData = false, fromPage = false) => {
				this.active_modal = type
				this.active_modal_list.push(type);
				this.modalFromPage = fromPage;
				this.model[type] = model ? model : ''
				this.requiredData[type] = requiredData ? requiredData : {}
				this.userData[type] = userData ? userData : {}
                setTimeout(() => {
                    this.show_overflow = true;
					this.show_modal[type] = true
                    setTimeout(() => {
                    	$('.overflow-modal-profile').addClass('show');
            			$("#modal_" + type).addClass("active");
						if((fromPage == 'objective' || fromPage == 'keyresult') && type == 'user'){
            				$("#modal_" + type).addClass("smaller");
			            }
			            if(fromPage == 'objective' && type == 'master_goal'){
            				$("#modal_" + type).addClass("smaller");
			            }
                    }, 0);
                }, 0);
            });

			this.$root.$on("evaluate_promise_modal", (promise = false, fromPage = false) => {
				this.active_modal = 'evaluate_promise';
				this.active_modal_list.push('evaluate_promise');
				this.modalFromPage = fromPage
				this.evaluate_promise = promise ? promise : null
                setTimeout(() => {
                    this.show_overflow = true
					this.show_modal['evaluate_promise'] = true
                    setTimeout(() => {
                    	$('.overflow-modal').addClass('show');
            			$("#modal_evaluate_promise").addClass("active");
                    }, 0);
                }, 0);
            })

			this.$root.$on('open_modal_v2', (type, model = false, typeDelete = false, fromDelete = false) => {
				this.model[type] = model ? model : {}
				this.typeDelete = typeDelete ? typeDelete : false
				this.fromDelete = fromDelete ? fromDelete : false
				this.active_modal = type

            	setTimeout(() => {
            		this.show_overflow = true;
            		this.show_modal[type] = true
            		setTimeout(() => {
            			$('.overflow-modal-profile').addClass('show');
	                   	$("#modal_" + type).addClass("active");
            		}, 0);
            	}, 0);
            })

            this.$root.$on('change_modal', (type) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            this.$root.$on('open_key_result_modals', (model = false, uuid = false, activeTab) => {
				this.active_modal = 'key_result_modals'
				this.model['key_result_modals'] = model ? model : ''
				this.krUuid = uuid ? uuid : ''
				this.modalKeyTab = activeTab
				setTimeout(() => {
                    this.show_overflow = true;
					this.show_modal['key_result_modals'] = true
                    setTimeout(() => {
                    	$('.overflow-modal-profile').addClass('show');
            			$("#modal_key_result_modals").addClass("active");
                    }, 0);
                }, 0);
            })

            this.$root.$on('open_modal_translation', (component, arrayData) => {
				this.show_modal.translate = true
				this.arrayTranslationKeys = arrayData
				this.componentTranslation = component
				setTimeout(() => {
					$("#modal_translate").addClass("active");
				}, 0);
			})

		    document.addEventListener("keydown", (e) => {
		        if (e.keyCode == 27) {
		            this.closeModals();
		        }
		    });

		    if(this.$auth.check() && this.$auth.user().is_entrepreneur && this.$auth.user().status == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
            	this.$root.$emit('open_modal', 'payment_error')
            }

			this.checkLanguage();
		},
		methods:{
			closeModals(){
				var arrayModalsNeedVerification = ['key_result', 'objective', 'master_goal', 'user', 'add_promise', 'evaluate_promise']
				if(arrayModalsNeedVerification.includes(this.active_modal)){
					this.$root.$emit('confirm_close')
				}else{
					this.closeModal()
				}
			},
			closeModal(type = false){
				this.$root.$emit('refreshMasterGoalTree')

				var target_modal = type ? type : this.active_modal
				$("#modal_" + target_modal).removeClass("active")
				this.active_modal_list = this.active_modal_list.filter(item => item !== target_modal)
				if(this.active_modal_list.length != 0) {
					this.active_modal = this.active_modal_list[this.active_modal_list.length - 1]
				}
				if(this.active_modal == 'user'){
					this.$root.$emit('visibilityHiddenUser', false);
				}
				if(this.active_modal == 'objective'){
					this.$root.$emit('visibilityHiddenObjective', false);
				}
				if(this.active_modal == 'key_result_modals'){
					this.$root.$emit('visibilityHiddenKeyResultPromises', false);
				}	
				setTimeout(() => {
					this.show_modal[target_modal] = false
					if(this.active_modal_list.length == 0) {
						$('.overflow-modal-profile').removeClass('show')
						setTimeout(() => {
							this.show_overflow = false;
						}, 200);
					}
				}, 200);
			},
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
		}
	}
</script>