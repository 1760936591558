<template>
	<div class="modal-tbf modal-procedure-check">
		<div class="container-modal opacity-page">
			<div class="header-modal" v-if="loaded">
				<div class="back-to-list" @click="backToList" v-if="currentView == 'form' && !fromProcedure">
					<icon-arrow-back />
				</div>
				<div class="title">
					{{ currentView == 'form' ? $t('procedure_check_modal.progress_procedure', {name: procedureSelected.name}) : $t('procedure_check_modal.procedure-tracking-modal') }}
				</div>
				<div class="actions">
					<button class="btn-tbf green only-icon" @click="loadingCompleteCheck == selectedProcedureCheck ? '' : updateCheckedSteps(selectedProcedureCheck, 'complete', procedureSelected.id, checked_steps, steps.length, false)" v-if="currentView == 'form'">
						<div class="loader-spin white" v-if="loadingCompleteCheck == selectedProcedureCheck"></div>
						<div class="icon" v-else><icon-check /></div>
					</button>
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon"/></button>
				</div>
			</div>
			<loader-header-modal-simple v-else/>

			<div class="container-content-modal">
				<template v-if="currentView == 'list'">
					<template v-if="loadedList">
						<div class="alert-tbf blue start-new-tracking" v-if="optionsProcedures.find(el => !el.$isDisabled)">
							<div class="data">
								<div class="title">{{ $t('procedure_check_modal.start_new_tracking') }}</div>
								<div class="description">{{ $t('procedure_check_modal.desc_start_new_tracking') }}</div>
								<div class="form-section">
									<div class="input-group">
										<div class="input-box">
											<div class="icon-left"><icon-procedures /></div>
											<multiselect 
												class="hide-tags multiselect_checkbox"
												v-model="selectedProcedureDropdown"
												:options="optionsProcedures"
												:allow-empty="true"
												:show-labels="false"
												:multiple="false" 
												:close-on-select="true"
												track-by="id" 
												label="name"
												>
												<template slot="placeholder" slot-scope="props">
													<span class="text">{{$t('procedure_check_modal.choose_procedure')}}</span>
												</template>
												<template slot="noResult">{{ $t('general.no_result') }}</template>
												<template slot="noOptions">{{ $t('general.empty_list') }}</template>
											</multiselect>
										</div>
									</div>
								</div>
							</div>
							<button id="buttonStartNewTracking" class="btn-tbf blue center" @click="trackSelectedProcedure(selectedProcedureDropdown)">
								<div class="text">{{ $t('procedure_check_modal.follow') }}</div>
								<div class="loader"></div>
							</button>
						</div>

						<div class="data-list list-procedures-tracking" v-if="proceduresTracking.length">
							<div class="row-space-tbf header-list">
								<div class="content">
									<div class="column-filter column-name">
										<div class="text">{{ $t('general.name') }}</div>
									</div>
									<div v-if="$resize && $mq.above(700)" class="column-filter column-progress">
										<div class="text">{{ $t('procedure_check_modal.progress') }}</div>
									</div>
									<div class="column-filter column-actions">
										<div class="text">{{ $t('procedure_check_modal.actions') }}</div>
									</div>
								</div>
							</div>

							<template v-for="procedureCheck in proceduresTracking">
								<div class="row-space-tbf row-list-item" :key="`entryItem${procedureCheck.id}`">
									<div class="content">
										<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
										<div class="column-name-title column-name" @click="getProcedureCheckData(procedureCheck.id)">
											<div class="name">{{ procedureCheck.procedure.name }}</div>
										</div>
										<div v-if="$resize && $mq.above(700)" class="column-value-progress column-progress">
											<div class="top-data">
												<div class="current-value">
													<div class="value">{{ procedureCheck.checked_steps.length }}/{{ procedureCheck.total_steps }}</div>
												</div>
												<div class="deadline">{{ procedureCheck.percent }}%</div>
											</div>
											<div class="progress-bar-tbf">
												<div class="complete-progress-tbf" :style="`width: calc(5px + ${procedureCheck.percent}%);`"></div>
											</div>
										</div>
										<div class="column-icons column-actions">
											<button class="btn-tbf only-icon blue" @click="getProcedureCheckData(procedureCheck.id)">
												<div class="icon"><icon-show /></div>
											</button>
											<button class="btn-tbf only-icon green" @click="loadingCompleteCheck == procedureCheck.id ? '' : updateCheckedSteps(procedureCheck.id, 'complete', procedureCheck.procedure.id, procedureCheck.checked_steps, procedureCheck.procedure.steps.length, true)">
												<div class="loader-spin white" v-if="loadingCompleteCheck == procedureCheck.id"></div>
												<div class="icon" v-else><icon-check /></div>
											</button>
										</div>
									</div>
								</div>
							</template>
						</div>
						<div class="list-empty-modal" v-else>
							<div class="title">{{ $t('procedure_check_modal.empty_list')}}</div>
						</div>
					</template>
					<loader-items-list v-else/>
				</template>

				<template v-else>
					<template v-if="loadedForm">
						<div class="form-steps">
							<div class="procedure-step-check" v-for="(step, index) in steps">
								<div class="checkbox clickable" :class="{ active: checked_steps.includes(step.id)}" @click="addOrRemoveStep(step.id)">
									<div class="checkmark"></div>
								</div>

								<div class="step-data-accordion">
									<div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseStepCheck${step.id}`">
										<div class="title">{{ $t('procedure_check_modal.step_count', {n: index + 1}) }}</div>
										<div class="actions">
											<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" :data-target="`#collapseStepCheck${step.id}`" aria-expanded="true">
												<icon-arrow />
											</button>
										</div>
									</div>

									<div class="collapse content-accordion" :id="`collapseStepCheck${step.id}`">
										<div class="html-step" v-html="step.body"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="form-submit">
							<button id="buttonUpdate" class="btn-tbf blue center" @click="updateCheckedSteps(selectedProcedureCheck, 'update', procedureSelected.id, checked_steps, steps.length, false)">
								<div class="loader"></div>
								<div class="text">{{ $t('general.update') }}</div>
							</button>
						</div>
					</template>
					<loader-form-procedure-check v-else/>
				</template>     
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconVersion from "../Icons/Version"
	import IconProcedures from "../Icons/Procedures"
	import IconTrash from "../Icons/Trash"
	import IconArrow from '../Icons/Arrow'
	import IconCheck from '../Icons/Check'
	import IconShow from '../Icons/ShowEye'
	import IconArrowBack from '../Icons/ArrowLong'
	import LoaderItemsList from '../PagesLoaders/ProceduresTracking'
	import LoaderFormProcedureCheck from '../PagesLoaders/ProceduresFromTracking'
	import LoaderHeaderModalSimple from '../PagesLoaders/HeaderModalSimple'

	export default {
		data() {
			return {
				loaded: false,
				loadedList: false,
				loadedForm: false,
				currentView: 'list',
				proceduresTracking: [],
				selectedProcedureCheck: '',
				steps: [],
				checked_steps: [],
				procedure_id: '',
				fromProcedure: false,
				optionsProcedures: [],
				selectedProcedureDropdown: '',
				loadingCompleteCheck: ''
			}
		},
		components: {
			IconClose,
			IconTrash,
			IconVersion,
			IconProcedures,
			IconArrow,
			IconArrowBack,
			LoaderItemsList,
			LoaderFormProcedureCheck,
			LoaderHeaderModalSimple,
			IconCheck,
			IconShow
		},
		async mounted(){
			setTimeout(() => {
				$('.opacity-page').addClass('show')
			}, 100)

			this.loaded = true
			await this.getProceduresTracking()
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			async getProceduresTracking(){
				await axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/${this.$auth.user().slug}/procedure-checks`)
				.then(({data}) => {
					this.proceduresTracking = data.data
					this.getProceduresDropdown()
				})
			},
			getProceduresDropdown(){
				axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: { procedures: true }})
				.then(({data}) => {
					this.optionsProcedures = data.data.procedures.map(item =>  {
						if (this.proceduresTracking.map((el) => el.procedure.id).includes(item.id)) {
							item.$isDisabled = true
						} return item
					})
				})
				.finally(() => {
					this.loadedList = true
				})
			},
			getProcedureCheckData(id){
				this.currentView = 'form';
				this.loaded = false;
				this.loadedForm = false

				axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/procedure-checks/${id}/edit`)
				.then(({data}) => {
					this.selectedProcedureCheck = id
					this.steps = data.data.procedure.steps
					this.procedureSelected = data.data.procedure
					this.checked_steps = data.data.checked_steps
				})
				.finally(() => {
					this.loadedForm = true
					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			addOrRemoveStep(id){
				if (this.checked_steps.includes(id)) {
					this.checked_steps.splice(this.checked_steps.indexOf(id), 1)
				} else {
					this.checked_steps.push(id)
				}

				axios.patch(`${process.env.VUE_APP_PROCEDURES_LINK}/api/procedure-checks/${this.selectedProcedureCheck}`, {
					procedure_id: this.procedureSelected.id,
					status: 'open',
					checked_steps: this.checked_steps,
					total_steps: this.steps.length,
					completed: this.checked_steps.length == this.steps.length ? 1 : 0
				})
			},
			updateCheckedSteps(id, type, procedureId, checkedSteps, totalSteps, fromList = false) {
				if(type == 'complete') { this.loadingCompleteCheck = id }

				var buttonName = `button${ type == 'update' ? 'Update' : 'Completed'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				if(btnSubmit) {
					btnSubmit.disabled = true
					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

				axios.patch(`${process.env.VUE_APP_PROCEDURES_LINK}/api/procedure-checks/${id}`, {
					procedure_id: procedureId,
					status: type == 'update' ? 'open' : 'closed',
					checked_steps: checkedSteps,
					total_steps: totalSteps,
					completed: checkedSteps.length == totalSteps ? 1 : 0
				})
				.then(({data}) => {
					if(fromList) {
						this.proceduresTracking.splice(this.proceduresTracking.findIndex(el => el.id == id), 1)
					}

					if(btnSubmit) {
						btnSubmitLoader.classList.add('finish')
					}
					setTimeout(()=>{
						if(btnSubmit) {
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
						}
						setTimeout(()=>{
							if (this.fromProcedure && type == 'complete'){
								this.$root.$emit('refreshProcedureTracked')
							} else if(!this.fromProcedure){
								this.currentView = 'list';
								this.getProceduresTracking()
							}
						}, 500)
					}, 300)
				}).catch(error =>{
					if(btnSubmit) {
						btnSubmitLoader.classList.add('finish')
					}
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						if(btnSubmit) {
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
						}
						setTimeout(()=>{
							if(btnSubmit) {
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = type == 'update' ? this.$t('general.update') : this.$t('general.completed')
								btnSubmit.disabled = false
							}
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 500)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						this.loadingCompleteCheck = ''
						if(btnSubmit) {
							btnSubmit.disabled = false
						}
						if (this.fromProcedure){
							this.closeModal()
						}
					}, 1000)
				})
			},
			trackSelectedProcedure(item){
				var buttonName = `buttonStartNewTracking`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				if (!this.proceduresTracking.map((el) => el.procedure.id).includes(item.id)){
					axios.post(`${process.env.VUE_APP_PROCEDURES_LINK}/api/procedure-checks/store`, {procedure_id: item.id, status: 'open', checked_steps: [], total_steps: 0, completed: 0})
					.then(({data}) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('procedure_check_modal.follow')
								this.selectedProcedureDropdown = ''
								this.getProceduresTracking()
							}, 500)
						}, 300)
					})
					.catch(error =>{
						btnSubmitLoader.classList.add('finish')
						this.errorsBe = error.response.data.errors
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('procedure_check_modal.follow')
								btnSubmit.disabled = false
								if(error.response.status == 500){
									this.error_message = this.$t('error.500')
								}
							}, 500)
						}, 300)
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1000)
					})
				} else {

				}
			},
			backToList() {
				this.currentView = 'list';
				this.getProceduresTracking()
			}
		}
	}
</script>