<template>
	<div class="container-modal form-modal">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register 3', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

        <div class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="header-modal">
			<div class="title">{{ $t('register.title_step_3') }}</div>
		</div>
		<div class="info-content" id="content">
			<div class="container-content-modal">
				<div class="form-section">
					<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.card.card_number.$error || stripeError != '' || cardErrors.cardNumber, complete: card.card_number != '' && !$v.card.card_number.$error }" id="payment">
						<div class="label-input">
							<label style="min-width: 125px;">{{$t('register.card_number')}}</label>
							<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
							<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-card /></div>
							<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><close-icon /></div>
							<input type="tel" :placeholder="$t('register.card_number_placeholder')" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
						</div>
					</div>

					<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.card.card_expiration.$error || cardErrors.cardExpiry, complete: card.card_expiration != '' && !$v.card.card_expiration.$error }">
						<div class="label-input">
							<label>{{ $t('register.validity_date') }}</label>
							<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-date /></div>
							<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><close-icon /></div>
							<input type="tel" :placeholder="$t('register.validity_date_placeholder')" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
						</div>
					</div>
					
					<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.card.card_cvc.$error || cardErrors.cardCvc, complete: card.card_cvc != '' && !$v.card.card_cvc.$error, 'view-tooltip': activeTooltip == 'card_cvc' }">
						<div class="label-input">
							<label>{{ $t('register.cvc') }}</label>
							<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><password-icon /></div>
							<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><close-icon /></div>
							<input type="tel" :placeholder="$t('register.cvc_placeholder')" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
						</div>
					</div>
				</div>
				<div class="submit-form">
					<button class="button-tbf-blue button-submit" @click="goNextStep">
						<div class="loader"></div>
						<div class="text">{{$t('register.button-action-3')}}</div>
					</button>
				</div>
				<div class="powered-by">
					<a href="https://stripe.com" target="_blank">
						<img src="/build/images/stripe-black.png">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../Animations/tbfdigital_logo.json';
	import CloseIcon from '../Icons/Close'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"
	import InformationIcon from '../Icons/Information'
	import IconCard from "../Icons/Card"
	import IconDate from "../Icons/Date"
	import PasswordIcon from '../Icons/Password';

	import { required } from 'vuelidate/lib/validators'
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				packageActive: {},
				packagesList: {},
				stripeError: '',
				activeTooltip: '',
				cuponCode: '',
				newPrices: [],
				errorCoupon: false,
				arrayTranslations: [
					'register.title_step_3',
					'register.card_number',
					'register.card_number_placeholder',
					'register.validity_date',
					'register.validity_date_placeholder',
					'register.cvc',
					'register.cvc_placeholder',
					'register.button-action-3',
					'validator.invalid_credit_card',
					'validator.invalid_credit_card_date',
					'validator.invalid_credit_card_cvc'
				]
			}
		},
		props:{
			user: Object
		},
		components: {
			CloseIcon,
			IconPlus,
			IconInfo,
			IconCircleCheck,
			InformationIcon,
			IconCard,
			IconDate,
			PasswordIcon,
			Lottie
		},
		validations: {
			card: {
				card_number: {required},
				card_expiration: {required},
				card_cvc: {required}
			}
		},
		async mounted() {
			this.logoAnim();
		},
		methods: {
			goNextStep(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(!cardError){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))
					
					axios.post('/payment', {
						number: this.card.card_number,
						exp_month: this.card.card_expiration.substring(0, 2),
						exp_year: this.card.card_expiration.substring(5, 9),
						cvc: this.card.card_cvc,
					}).then(({data}) => {
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.success'))
							btnSubmit.addClass('completed')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('completed')
								btnSubmit.attr('disabled', false)
								this.$auth.fetch();
								this.$router.push('/register-success');
							}, 1000)
						}, 300)
					}).catch(error => {
						btnSubmitLoader.addClass('finish')
						

						if(error.response.data.subscription && ['requires_source_action', 'requires_action'].includes(error.response.data.subscription.latest_invoice.payment_intent.status) ){
							var sub = error.response.data.subscription;
							var _this = this;
							let stripe = Stripe(process.env.VUE_APP_STRIPEPUBLISHABLE_KEY);
							stripe
							  .confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
							  .then(function(result) {
							  	if(result.error){
							  		btnSubmitText.html(_this.$t('btn-submit.error'))
									btnSubmit.addClass('error')
									btnSubmitLoader.removeClass('onProgress finish')
									btnSubmit.removeClass('loading')
									setTimeout(()=>{
										btnSubmit.removeClass('error')
										_this.stripeError = result.error.message;
										btnSubmitText.html(_this.$t('register.button-action-3'))
										btnSubmit.attr('disabled', false)
									}, 1000)
							  	}else{
							  		axios.post('/set-as-active', {
									}).then(({data}) => {
										btnSubmitText.html(_this.$t('btn-submit.success'))
										btnSubmit.addClass('completed')
										btnSubmitLoader.removeClass('onProgress finish')
										btnSubmit.removeClass('loading')
										setTimeout(()=>{
											btnSubmit.removeClass('completed')
											btnSubmit.attr('disabled', false)
											_this.$auth.fetch();
											_this.$router.push('/register-success');
										}, 1000)
									})
							  	}
							  });
						}else{
							setTimeout(()=>{
								btnSubmitText.html(this.$t('btn-submit.error'))
								btnSubmit.addClass('error')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('error')
									this.stripeError = error.response.data.message;
									btnSubmitText.html(this.$t('register.button-action-3'))
									btnSubmit.attr('disabled', false)
								}, 1000)
							}, 300)
						}
					});
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			checkCode(){
				var btnApply = $('.btn-apply')
				var btnApplyLoader = $('.btn-apply .loader')
				var btnApplyText = $('.btn-apply .text')

				btnApply.addClass('loading')
				btnApplyLoader.addClass('onProgress')
				this.errorCoupon = false
				
				axios.post('validate-coupon', {coupon : this.cuponCode}).then((data) => {
					if(data.data.is_valid){
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('btn-submit.success'))
							btnApply.addClass('completed')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('completed')
								btnApply.attr('disabled', false)
								btnApplyText.html(this.$t('register.step-3.coupon.apply-success'))

								this.newPrices = data.data.values

							}, 1000)
						}, 300)
					}else{
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('register.step-3.coupon.apply-error'))
							btnApply.addClass('error')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('error')
								btnApplyText.html(this.$t('register.step-3.coupon.apply'))
								btnApply.attr('disabled', false)
								this.errorCoupon = true
								this.newPrices = []
							}, 1000)
						}, 300)
					}
				})
			},
			selectLanguage(value, id){
				this.user.language = value.name
			},
			selectPlan(packageItem){
				this.packageActive = packageItem;
				setTimeout(function() {
                    $('#content').animate({
                        scrollTop: $("#payment").offset().top
                    }, 250);
                }, 250);
			},
		}
	}
</script>