<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('subscription.title') }}</h1>
		</div>
		<div class="subscription-box" v-if="loaded">
			<div class="top-section">
				<p class="first">
					{{ selectedPackage.package_name }}
				</p>
				<div class="second">
					<div class="price">
						<span class="old-price" v-if="selectedPackage.old_price_in_euro != selectedPackage.price_in_euro">{{ selectedPackage.old_price_in_euro }}</span>
						{{ newPrices.find((el) => el.package_id == selectedPackage.package_id) ? newPrices.find((el) => el.package_id == selectedPackage.package_id).price + ' EUR' : selectedPackage.price_in_euro + ' EUR' }}
					</div>
					<span>{{ $t('subscription.monthly') }}</span>
				</div>
			</div>
			<div class="second-section" v-bind:class="{has_button: $auth.user().package.can_upgrade_account}">
				<div class="boxes-left">
					<div class="box">
						{{ $auth.user().package.user_limit == 0 ? '&infin;' : $auth.user().package.user_limit}} 
						<span>{{ $t('subscription.employees') }}</span>
					</div>
					<!-- <div class="box">
						{{ $auth.user().package.objective_limit }} 
						<span>obiective</span>
					</div> -->
					<div class="box second">
						{{ $auth.user().package.objective_limit == 0 ? '&infin;' : $auth.user().package.objective_limit}} 
						<span>{{ $t('subscription.objectives') }}</span>
					</div>
					<div class="box button">
						<button :class="[$auth.user().package.can_upgrade_account ? 'btn-blue-tbf' : 'btn-default-tbf']" @click="viewSlide('upgrade_subscription')">{{ $auth.user().package.can_upgrade_account ? $t('subscription.change') : $t('subscription.change-to-low') }}</button>
					</div>
				</div>	
			</div>
		</div>

		<div class="short-description-modal" v-if="$auth.user().status == 'grace_period'">{{ $t('subscription.grace_period_desc') }}</div>

		<div class="form-section" v-if="loaded">
			<div class="input-group-tbf first" v-bind:class="{has_error: $auth.user().status == 'grace_period'}">
				<div class="label-input">
					<label>{{ $t('subscription.card-details') }}</label>
					<div class="error-msg" v-if="$auth.user().status == 'grace_period'">{{ $t('subscription.error_payment') }}</div>
				</div>
				<div class="input-box input-custom-box input-custom-box-bill">
					<credit-card-icon class="icon-left"/>
					<span>**** **** **** {{ cardDetails.last4 }}<i>{{ cardDetails.exp_month }}/{{ cardDetails.exp_year }}</i></span>
					<button class="edit-property" @click="viewSlide('card_details')"><icon-pencil /></button>
				</div>
			</div>
		</div>
		<div class="form-section" v-else>
			<div class="input-group-tbf">
				<div class="label-input">
					<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
				</div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconInfo from "../Icons/Info"
	import CreditCardIcon from "../Icons/CreditCard"
	import IconPlus from "../Icons/Plus"
	import IconPencil from "../Icons/PencilAvatar"

	export default {
		data() {
			return {
				loaded: false,
				cardDetails: {},
				activeTooltip: '',
				selectedPackage: '',
				oldPackage: false,
				newPrices: []
			};
		},
		components: {
			IconInfo,
			CreditCardIcon,
			IconPlus,
			IconPencil
		},
		async mounted() {
			if(this.$auth.user().package && this.$auth.user().package.group_name == 'Old'){
				this.oldPackage = true
			}

			this.getUserNewPrices();
			await this.getCardDetails()
			await this.getPackages()
		},
		methods: {
			async getCardDetails(){
				await axios.get('card-details')
				.then(({data}) => {
					this.cardDetails = data
				})
			},
			async getPackages(){
				let paramsApiCall = {}
				if(this.oldPackage){
					paramsApiCall.package_token = this.$auth.user().package.token
				}

				await axios.get('packages', {params: paramsApiCall})
				.then(({data}) => {
					this.optionsPackages = data.data
				})
				.finally(() => {
					if(this.$auth.user().instance){
						if(this.$auth.user().instance.package_id && this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)){
							let currentPackage = this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)
							this.selectedPackage = currentPackage
						}else{
							this.selectedPackage = {package_id: 0}
						}
					}

					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			getUserNewPrices(){
				axios.post('get-referral-coupon', {instance_id : this.$auth.user().instance_id}).then((data) => {
					if(data.data.is_valid){
						this.newPrices = data.data.values;
					}
				})				
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			}
		}
	};
</script>