<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('list-tags.title') }}</h1>
			<button @click="viewSlide('create_tag')" class="button-tbf-blue add"></button>
		</div>
		<section class="list-items-section list-tags" v-if="loaded">
			<div v-if="Object.keys(tags).length">
				<div class="group_items"  v-for="tag in tags">
					<div class="space-left">
						<icon-arrow :id="'arrow-key-result-' + tag.id" class="icon-arrow right"/>
					</div>
					<div class="content-row">
						<div class="row-tbf action-row" @click="viewSlide('create_tag', tag)">
							<div class="column-tbf row-details tag-color-row">
								<div class="tag-color" v-bind:class="['color-' + tag.color.name]">
									<span></span>
								</div>
							</div>
							<div class="column-tbf row-title tag-name-width">
								<div class="name">{{ tag.name }}</div>
							</div>

							<div class="column-tbf row-details tag-users-width" v-bind:class="{ empty: tag.users.length == 0 }">
								<div class="label">{{ $t('list-tags.people') }}</div><div class="count">{{ tag.users.length }}</div>
							</div>
						</div>
					</div>
					<div class="space-right">
						<div class="dots-edit" :id="'edit-key-result-' + tag.id" v-click-outside="hideDropdown">
							<div class="dropdown edit-item-dropdown">
								<div class="overlay-button" @click.stop="showDropdown(tag.id)"></div>
								<div class="edit-item-button" :id="'dropdownMenuTag'+tag.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<icon-edit-dots />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenuTag'+ tag.id " :aria-labelledby="'dropdownEditTag'+tag.id">
									<div class="dropdown-item" @click.stop="viewSlide('create_tag', tag)">{{ $t('list-tags.edit') }}</div>
									<div class="dropdown-item" @click.stop="deleteItem('confirm_delete', tag.id)">{{ $t('list-tags.delete') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="no-notifications custom" v-else>
				<p>{{ $t('list-tags.no-tags') }}</p>
				<span>{{ $t('list-tags.desc') }}</span>
				<button @click="viewSlide('create_tag')" class="button-tbf-blue add">{{ $t('list-tags.add') }}</button>
			</div>	
		</section>
		<section class="empty-list list-items-section" v-else>
			<div class="group_items" v-for="n in 5">
				<div class="space-left"></div>
				<div class="content-row">
					<div class="row-tbf">
						<div class="column-tbf">
							<div class="label placeholder-loader" style="height: 16px; width: 100px;"></div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</section>

		<div class="submodal-container" v-if="subModalShow">
			<confirm-delete v-if="show_subm_modal.confirm_delete" nameItem="tag" @cancel_delete="closeDelete('confirm_delete')" @delete_item="confirmDelete('confirm_delete')"></confirm-delete>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ConfirmDelete from "../SubModals/ConfirmDelete"
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'

	export default {
		data() {
			return {
				loaded: false,
				tags: {},
				subModalShow: false,
                show_subm_modal: {
                	confirm_delete: false
                },
                deleteItemId: '',
                hoverTag: ''
			};
		},
		components: {
			ConfirmDelete,
			IconPlus,
			IconArrow,
			IconEditDots,
		},
		async mounted() {
			await this.getTags()

            this.$root.$on("reload_modal_key_result", () => {
				this.getTags()
            });
		},
		methods: {
			async getTags(){
				await axios.get('/' + this.$auth.user().master_instance_id + '/tags')
				.then(({data}) => {
					this.tags = data.data
				})
				.then(() => {
					this.loaded = true
				})
			},
			viewSlide(slide, modelRole){
				this.$emit('change_slide', slide, modelRole)
			},
			deleteItem(type, itemId){
				this.hideDropdown()

				this.deleteItemId = itemId
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeDelete(type){
				this.deleteItemId = ''
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			confirmDelete(type){
				axios.delete('/tags/' + this.deleteItemId)
				.then(() => {
					this.deleteItemId = ''
					$("#submodal_" + type).removeClass("active")
					this.getTags()
					setTimeout(() => {
						$('.submodal-container').removeClass('active')
						setTimeout(() => {
							this.subModalShow = false;
							this.show_subm_modal[type] = false
						}, 200);
					}, 200);
				})
			},
			showDropdown(itemId){
				if(itemId == this.hoverTag){
					$('#dropdownMenuTag' + this.hoverTag).dropdown('toggle');
					setTimeout(() => {
						this.hoverTag = ''
					}, 0)
				}else{
					this.hoverTag = itemId
					setTimeout(() => {
						$('#dropdownMenuTag' + this.hoverTag).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdown(){
				if(this.hoverTag != ''){
					$('#dropdownMenuTag' + this.hoverTag).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverTag = ''
					}, 0)
				}
			}
		}
	};
</script>