<template>
	<div class="opacity-page show">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Show User', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="item-page" v-if="notAllowed">
			<not-allowed></not-allowed>
		</div>
		<div class="item-page" v-else-if="notFound">
			<not-found></not-found>
		</div>
		<div class="item-page" v-else>
			<template v-if="loaded">
				<section class="header-user-section">
					<div class="space-left"></div>
					<div class="content-section">
						<div class="primary-boxes-user">
							<div class="row-data-user">
								<div class="box-data-user">
									<div class="col-50">
										<div class="user-image">
											<img :src="user.avatar" v-if="user.avatar">
											<div class="user-circle" v-else>
												<icon-user-settings/>
											</div>
										</div>
										<div class="user-name">
											<h1 class="name">{{ user.first_name + ' ' + user.last_name }}</h1>
											<p class="email" v-if="!user.dummy_account">{{ user.email }}</p>
											<p class="role">{{ $t('users.role-name-' + user.role_name) }}</p>
										</div>
									</div>
									<div class="col-50 working-days-col" v-if="$resize && $mq.above(600)">
										<h1>{{ $t('show-user.working-days.title') }}</h1>
										<span class="subtitle">{{ user.working_days.length }} {{ $t('show-user.working-days.desc') }}</span>
										<div class="select-workdays">
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('monday')}">
												<span class="checkmark">{{ $t('calendar.small_days.monday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('tuesday')}">
												<span class="checkmark">{{ $t('calendar.small_days.tuesday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('wednesday')}">
												<span class="checkmark">{{ $t('calendar.small_days.wednesday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('thursday')}">
												<span class="checkmark">{{ $t('calendar.small_days.thursday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('friday')}">
												<span class="checkmark">{{ $t('calendar.small_days.friday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('saturday')}">
												<span class="checkmark">{{ $t('calendar.small_days.saturday') }}</span>
											</label>
											<label class="checkbox-workdays" v-bind:class="{active: user.working_days.includes('sunday')}">
												<span class="checkmark">{{ $t('calendar.small_days.sunday') }}</span>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="row-data-promises" v-if="user.key_results_count > 0">
								<!-- Check if we need to evaluate yesterday promise, today promise or to set a promise for today -->
								<!-- Card - Promisiunea zilei -->
								<div class="box-data-promises" v-if="user.has_daily_promise == 'promise_time_passed'">
									<div class="text-promise-tutorial">
										<div class="title">{{ $t('daily_promise.promise_time_passed') }}</div>
										<div class="description">{{ $t('daily_promise.promise_time_passed_desc', {hour: user.promise_time}) }}</div>
									</div>
									<div class="button-action with-lottie">
								        <lottie :options="defaultOptions" :height="85" :width="85" v-on:animCreated="handleAnimation"/>
									</div>
								</div>
								<div class="box-data-promises" v-else-if="user.has_daily_promise != 'person_not_working_today'">
									<div class="text-promise-tutorial">
										<div class="title">{{ $t('tutorial-promise.title-box') }} {{ user.has_daily_promise != 'promise_not_set' ? '' : $t('daily_promise.unstable') }}</div>
										<div class="description" v-html="user.has_daily_promise == 'has_promise' ? user.daily_promise.name : $t('tutorial-promise.desc-box')"></div>
										<div class="contribute-to" v-if="user.has_daily_promise == 'has_promise'">{{ $t('set-promise.contribute-to') }}: {{ $tc("daily_promise.count_key_results", user.daily_promise.key_results_involved) }}</div>
									</div>
									<!-- tutorial_promise -->
									<div class="button-action" v-if="$auth.user().slug == user.slug">
										<div class="with-timer" v-if="user.has_daily_promise == 'has_promise' && user.daily_promise.status == 'pending'">
											<button class="button-tbf-blue" @click="user.has_daily_promise == 'has_promise' ? (user.daily_promise.status == 'pending' ? showEvaluate(user.daily_promise) : '') : showModal('add_promise')">
												{{ $t('daily_promise.evaluate') }}
											</button>
											<span>{{ $t('show-user.start_evaluation') }}</span>
										</div>
										<div class="failed" v-else-if="user.has_daily_promise == 'has_promise' && user.daily_promise.status == 'not_completed'"><icon-close/></div>
										<div class="realized" v-else-if="user.has_daily_promise == 'has_promise' && user.daily_promise.status == 'completed'"><icon-check/></div>
										<div class="with-timer" v-else>
											<button class="button-tbf-blue" @click="user.has_daily_promise == 'has_promise' ? (user.daily_promise.status == 'pending' ? showEvaluate(user.daily_promise) : '') : showModal('add_promise')" >
												{{ $t('daily_plan.add-promise') }}
											</button>
											<span>{{ $t('daily_plan.time-remain') }}: {{timeRemain}}</span>
										</div>
									</div>
									<div class="button-action only-svg" v-else>
										<img src="/build/icons/promises.svg">
									</div>
								</div>
								<div class="box-data-promises" v-else>
									<div class="text-promise-tutorial">
										<div class="title">{{ $t('tutorial-promise.title-box') }} {{ user.has_daily_promise ? '' : $t('daily_promise.unstable') }}</div>
										<div class="description" v-if="$auth.user().slug == user.slug">{{ $t('daily_promise.free_day') }}</div>
										<div class="description" v-else>{{ $t('daily_promise.free_day') }}</div>
									</div>
									<div class="button-action with-lottie">
								        <lottie :options="defaultOptions" :height="85" :width="85" v-on:animCreated="handleAnimation"/>
									</div>
								</div>
								<!-- END Card - promisiunea zilei -->
							</div>
							<div  class="row-data-promises" v-else>
								<div class="box-data-promises">
									<div class="text-promise-tutorial">
										<div class="title">{{ $t('tutorial-promise.title-box') }}</div>
										<div class="description">{{ $t('daily_promise.not_key_results') }}</div>
									</div>
									<div class="button-action with-lottie">
								        <lottie :options="defaultOptions" :height="85" :width="85" v-on:animCreated="handleAnimation"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>
			<template v-else>
				<section class="header-user-section">
					<div class="space-left"></div>
					<div class="content-section">
						<div class="primary-boxes-user">
							<div class="row-data-user">
								<div class="box-data-user placeholder-loader" style="height: 169px;">
								</div>
							</div>
							<div class="row-data-promises">
								<div class="box-data-promises placeholder-loader" style="height: 169px;">
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>

			<template v-if="loadedTimeline">
				<section class="subheader-page-section timeline">
					<div class="space-left"><icon-list class="icon-list" /></div>
					<div class="content-section">
						<div class="header-table">
							<div class="title">{{$t('show-user.timeline.title')}}</div>
							<div class="right-part">
								<div class="action-header filter-date">
									<button class="btn-default-tbf" @click="default_timeline_offset == timeline_offset ? '' : changeOffset(false, 'default')" v-bind:class="{disabled: default_timeline_offset == timeline_offset}" :disabled="default_timeline_offset == timeline_offset">{{$t('show-user.timeline.filter')}} <icon-arrow class="icon-arrow right"/></button>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>

				<section class="timeline-bar-section">
					<div class="space-left"></div>
					<div class="content-section">
						<div class="timeline-bar">
							<div class="prev-week" @click="moment(data_timeline[6].created_at) < moment(user.created_at) ? '' : changeOffset(+1)" v-bind:class="{disabled: moment(data_timeline[6].created_at) < moment(user.created_at) }">
								<icon-arrow class="icon-arrow left" />
							</div>
							<div class="hider-left"></div>
							<div class="current-weeks">
								<div class="day" v-for="(day, index) in data_timeline" :data-week="moment(day.created_at).week()" v-bind:class="{'start-week': moment(day.created_at).format('d') == 1, 'end-week': moment(day.created_at).format('d') == 0}" :style="'width:' +dayWidth+ '%;'">
									<div class="promises-status">
										<div class="title">{{$t('show-user.promise')}}</div>
										<div class="status">{{ $t('timeline_status.' + day.status) }}</div>
									</div>
									<div class="num-week">{{ moment(day.created_at).format('d') == 1 ? 'Sapt. '+ moment(day.created_at).format('W') : '' }}</div>
									<v-popover offset="0" trigger="hover" placement="top" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 0, hide: 0}" :disabled="day.status == 'not_exist'">
										<div class="space-box-day" @click="day.status == 'pending' ? showEvaluate(day) : ''">
											<div class="box-day"

											v-bind:class="{
												completed: day.status == 'completed', 
												not_set: day.status == 'not_completed' || day.status == 'not_set', 
												warning: day.status == 'pending' || (moment(day.created_at).isSame(moment().format('YYYY-MM-DD')) && user.has_daily_promise == 'promise_not_set'),
												blue: day.status == 'pending' && moment(day.created_at).isSame(moment().format('YYYY-MM-DD')),
												disabled: day.status == 'not_exist' }"
											></div>
										</div>
										<template slot="popover">
											<!-- <div class="label" v-if="day.name || (moment(day.created_at) <= moment() && $auth.user().working_days.includes(moment(day.created_at).locale('en').format('dddd').toLowerCase()))">{{ $t('timeline-bar.title') }}</div> -->

											<div class="label" v-if="day && day.key_results_names != null"><icon-list class="icon-list" /> {{day.key_results_names}}</div>
											
											<div class="description" v-if="day.name">{{day.name}}</div>

											<div class="description" v-else-if="day.status == 'not_working'">{{ $t('show-user.timeline.promise-not-need') }}</div>
											<div class="description" v-else-if="day.status == 'free_day'">{{ $t('show-user.timeline.free_day') }}</div>

											<div class="description" v-else-if="day.status == 'no_key_result'">{{ $t('show-user.timeline.promise-without-key-result') }}</div>
											<div class="description" v-else>{{ $t('show-user.timeline.promise-not-set') }}</div>

											<div class="reason" v-if="day.why_not"><span>{{ $t('show-user.timeline.reason') }}:</span> {{ day.why_not }}</div>
										</template>
									</v-popover>
									<div class="date" v-if="$resize && $mq.above(600)">{{ moment(day.created_at).isSame(moment().format('YYYY-MM-DD')) ? $t('general.today') : moment(day.created_at).format('MMM DD') }}</div>
									<div class="date" v-else>{{ moment(day.created_at).format('ddd') }}</div>
								</div>
							</div>
							<div class="hider-right"></div>
							<div class="next-week" @click="timeline_offset <= default_timeline_offset ? '' : changeOffset(-1)" v-bind:class="{disabled: timeline_offset <= default_timeline_offset}">
								<icon-arrow class="icon-arrow right" />
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>
			<template v-else>
				<section class="subheader-page-section timeline">
					<div class="space-left"><icon-list class="icon-list" /></div>
					<div class="content-section">
						<div class="header-table">
							<div class="title placeholder-loader" style="height: 21px; max-width: 170px; width: 100%;"></div>
							<div class="placeholder-loader" style="margin-left: auto; height: 37px; width: 100%; max-width: 140px;"></div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>

				<section class="timeline-bar-section">
					<div class="space-left"></div>
					<div class="content-section">
						<div class="placeholder-loader" style="height: 36px; width: 100%; border-radius: 10px; margin-top: 75px;"></div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>

			<template v-if="loaded">
				<section class="header-list-section">
					<div class="space-left like-subtitle"><icon-list class="list-icon" /></div>
					<div class="content-section">
						<div class="header-table">
							<div class="tabs">
								<div class="tab-item" v-bind:class="activetab == 'keyresults' ? 'active' : ''" @click="activateTab('keyresults')">
									<span class="like-subtitle">{{$t('show-user.key-results')}}</span>
								</div>
								<div class="tab-item" v-bind:class="activetab == 'objectives' ? 'active' : ''" @click="activateTab('objectives')">
									<span class="like-subtitle">{{$t('show-user.objectives')}}</span>
								</div>
							</div>

							<div class="action-header filter-date dropdown-tbf">
								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
									{{ $t('filter_objectives_date.' + activeDate) }}<icon-arrow class="icon-arrow"/>
								</button>

								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>

								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" @click="activeDate = 'asc'" v-bind:class="{active: activeDate == 'asc'}">
										{{ $t('filter_objectives_date.asc')}}
									</div>
									<div class="dropdown-item" @click="activeDate = 'desc'" v-bind:class="{active: activeDate == 'desc'}">
										{{ $t('filter_objectives_date.desc')}}
									</div>
								</div>
							</div>

							<div class="action-header filter-date dropdown-tbf" v-if="activetab == 'keyresults'">
								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
									{{ $t('filter_key-results.' + activeStatusKr) }} <icon-arrow class="icon-arrow"/>
								</button>

								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div v-for="krStatus in krStatusList" 
									class="dropdown-item" 
									@click="changeActiveStatus('kr', krStatus)" 
									v-bind:class="{active: activeStatusKr == krStatus}">
									{{ $t('filter_key-results.'+krStatus) }}
									</div>
								</div>
							</div>
							<div class="action-header filter-date dropdown-tbf" v-else-if="activetab == 'objectives'">
								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
									{{ $t('filter_objectives.' + activeStatusObj) }} <icon-arrow class="icon-arrow"/>
								</button>

								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div v-for="objStatus in objStatusList" 
									class="dropdown-item" 
									@click="changeActiveStatus('objective', objStatus)" 
									v-bind:class="{active: activeStatusObj == objStatus}">
									{{ $t('filter_objectives.'+objStatus) }}
									</div>
								</div>
							</div>

							<div class="action-header filter-quarter solo dropdown-tbf" v-if="$resize && $mq.above(600)">
								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="quarter-no" v-if="activeQuarter">{{ $t('objectives.quarter') }} {{ activeQuarter.quarter }}, {{activeQuarter.year }}</div> 
									<div class="quarter-no" v-else>{{ $t('general.all') }}</div>
									<icon-arrow class="icon-arrow"/>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item bor-bottom" @click="changeActiveQuarter('all')">{{ $t('general.all') }}</div>
									<div class="dropdown-item" v-for="quarterItem in arrayQuarters" @click="changeActiveQuarter(quarterItem)" v-bind:class="{active: activeQuarter == quarterItem, 'bor-top': quarterItem.quarter == 4 }">
										<div class="quarter-no">{{ $t('objectives.quarter_short') }}{{ quarterItem.quarter }} {{ quarterItem.year }}</div>  <div class="quarter-period">{{ quarterItem.start_quarter | moment('MMMM') }} - {{ quarterItem.end_quarter | moment('MMMM') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>
			<template v-else>
				<section class="header-list-section">
					<div class="space-left"><icon-list class="list-icon" /></div>
					<div class="content-section">
						<div class="header-table">
							<div class="tabs">
								<div class="tab-item">
									<div class="placeholder-loader" style="height: 36px; width: 136px; display: inline-block; opacity: 1;"></div>
								</div>
								<div class="tab-item">
									<div class="placeholder-loader" style="height: 36px; width: 95px; display: inline-block; opacity: 1;"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</template>

			<template v-if="loaded">
				<div v-if="activetab == 'keyresults'">
					<infinite-loading :identifier="1" @infinite="getKeyResultsInfinite" ref="infiniteLoadingKeyResults"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
				</div>
				<div v-else-if="activetab == 'objectives'">
					<infinite-loading :identifier="2" @infinite="getObjectivesInfinite" ref="infiniteLoadingObjectives"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
				</div>
			</template>

			<template v-if="loadedList">
				<key-results-list :data="{list: filteredKrs, userSlug: user.slug, userName: user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1), totalItems: total_key_results}" v-if="activetab == 'keyresults'"/>
				<objectives-list :data="{list: filteredObj, userSlug: user.slug, userName: user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1), totalItems: total_objectives}" v-if="activetab == 'objectives'"/>
			</template>

			<template v-if="!loadedList">
				<section class="list-items-section tab-container" style="display: block;">
					<div class="group_items" v-for="n in 3">
						<div class="space-left">
							<icon-arrow class="icon-arrow right"/>
						</div>
						<div class="content-row">
							<div class="row-tbf">
								<div class="column-tbf user-img kr-user-width" v-if="$resize && $mq.above(770)">
									<div class="placeholder-loader" style="height: 38px; width: 100%; max-width: 38px;"></div>
								</div>
								<div class="column-tbf row-title with-icon kr-name-width">
									<div class="text placeholder-loader" style="height: 21px; width: 100%; max-width: 170px;"></div>
								</div>

								<div class="column-tbf status kr-updates-width" v-if="$resize && $mq.above(1030)">
									<div class="link-modal disabled">
										<div class="box-icon">
										</div>
										<span><div class="placeholder-loader" style="height: 16px; width: 100%; max-width: 63px;"></div></span>
									</div>
								</div>

								<div class="column-tbf row-details kr-promises-width" v-if="$resize && $mq.above(1030)">
									<div class="link-modal">
										<div class="label placeholder-loader" style="height: 16px; width: 100%; max-width: 95px;"></div>
									</div>
								</div>

								<div class="column-tbf row-details kr-tasks-width" v-if="$resize && $mq.above(1030)">
									<div class="link-modal">
										<div class="label placeholder-loader" style="height: 16px; width: 100%; max-width: 95px;"></div>
									</div>
								</div>

								<div class="column-tbf item-value kr-value-width">
									<div class="top-data">
										<div class="current-value">
											<div class="value placeholder-loader" style="height: 16px; width: 100%; max-width: 15px;"></div>
										</div>
										<div class="deadline placeholder-loader" style="height: 14px; width: 100%; max-width: 30px;"></div>
									</div>
									<div class="progress-bar-tbf placeholder-loader"></div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div class="dots-edit">
								<div class="dropdown edit-item-dropdown">
									<div class="edit-item-button">
										<icon-edit-dots />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</template>
		</div>
	</div>
</template>


<script type="text/javascript">
	import NotAllowed from '../General/NotAllowed'
	import NotPaid  from '../General/NotPaid'
	import NotFound from '../Pages/NotFound'
	import IconEmail from '../Icons/Email'
	import IconArrow from '../Icons/Arrow'
	import IconList from '../Icons/List'
	import IconInfo from '../Icons/Info'
	import IconPlus from '../Icons/Plus'
	import IconEditDots from '../Icons/EditDots'
	import IconObjectiveSingle from '../Icons/ObjectiveSingle'
	import IconObjectivesList from '../Icons/ObjectivesList'
	import IconCheckPrimary from "../Icons/CheckPrimary"
	import IconCheck from "../Icons/Check"
	import IconClose from "../Icons/Close"
	import IconFilter from '../Icons/Filter'
	import IconUserSettings from '../Icons/UserSettings'
    import Lottie from 'vue-lottie';
    import * as animationData from '../Animations/tbfdigital_sleep.json';
	import InfiniteLoading from 'vue-infinite-loading'
	import KeyResultsList from './User/KeyResults'
	import ObjectivesList from './User/Objectives'

	export default {
		data() {
			return {
                defaultOptions: {
                    animationData: animationData.default
                },
				notAllowed: false,
				notFound: false,
				loaded: false,
				loadedTimeline: false,
				loadedList: false,
				page: 0,
				key_results: [],
				total_key_results: 0,
				total_objectives: 0,
				objectives: [],
				data_timeline: {},
				active_day: false,
				user: {},
				activetab: 'keyresults',
				hoverkeyResult: '',
				hoverObjective: '',
				activeDate: 'asc',
				user_slug: '',
				arrayQuarters: [],
				activeQuarter: { 
					quarter: moment().quarter(), 
					year: moment().format('YYYY'), 
					start_quarter: moment().startOf('quarter').format('YYYY-MM-DD'), 
					end_quarter: moment().endOf('quarter').format('YYYY-MM-DD') 
				},
				timeline_offset: 4,
				default_timeline_offset: 4,
				weekNumbers: 6,
				dayWidth: 0,
				firstLoadedTimeline: false,
                dateRemain: '',
                timeRemain: '00:00:00',
                krStatusList: [],
                activeStatusKr: 'active',
                objStatusList: [],
                activeStatusObj: 'active',
                arrayTranslations: [
	                'calendar.small_days.friday',
	                'calendar.small_days.monday',
	                'calendar.small_days.saturday',
	                'calendar.small_days.sunday',
	                'calendar.small_days.thursday',
	                'calendar.small_days.tuesday',
	                'calendar.small_days.wednesday',
	                'daily_plan.add-promise',
	                'daily_plan.time-remain',
	                'daily_promise.count_key_results',
	                'daily_promise.evaluate',
	                'daily_promise.free_day',
	                'daily_promise.not_key_results',
	                'daily_promise.unstable',
	                'filter_key-results.active',
	                'filter_key-results.all',
	                'filter_key-results.finished',
	                'filter_key-results.overdue',
	                'filter_key-results.upcoming',
	                'filter_objectives.active',
	                'filter_objectives.all',
	                'filter_objectives.finished',
	                'filter_objectives.overdue',
	                'filter_objectives.upcoming',
	                'general.today',
	                'key-result-promises.completed',
	                'key-result.tooltip-promises',
	                'key-result.tooltip-promises-cant',
	                'key-result.tooltip-promises-evaluate',
	                'key-result.tooltip-update-cant',
	                'key-result.tooltip-update-future',
	                'key-result.tooltip-update-past',
	                'key-result.tooltip-update-today',
	                'key-result.tooltip-updates-view',
	                'navbar.dashboard',
	                'navbar.people',
	                'objectives.deadline',
	                'objectives.key-results',
	                'objectives.key-results-progress',
	                'objectives.quarter',
	                'objectives.quarter_short',
	                'objectives.start_on',
	                'progress_type_text.above',
	                'progress_type_text.current_value',
	                'progress_type_text.decrease',
	                'progress_type_text.grow',
	                'progress_type_text.under',
	                'progress_type_text.value',
	                'set-promise.contribute-to',
	                'show-user.days',
	                'show-user.days-ago',
	                'show-user.delete',
	                'show-user.desc-no-key-results',
	                'show-user.desc-no-objective',
	                'show-user.edit',
	                'show-user.key-results',
	                'show-user.last-value',
	                'show-user.no-key-results',
	                'show-user.no-objective',
	                'show-user.no-updates',
	                'show-user.objectives',
	                'show-user.outdated',
	                'show-user.promise',
	                'show-user.promises',
	                'show-user.start_evaluation',
	                'show-user.status-pending',
	                'show-user.timeline.filter',
	                'show-user.timeline.promise-not-set',
	                'show-user.timeline.promise-without-key-result',
	                'show-user.timeline.reason',
	                'show-user.timeline.title',
	                'show-user.updates',
	                'show-user.working-days.desc',
	                'show-user.working-days.title',
	                'status.completed',
	                'timeline_status.completed',
	                'timeline_status.not_completed',
	                'timeline_status.not_set',
	                'timeline_status.pending',
	                'timeline_status.state_title',
	                'tutorial-promise.desc-box',
	                'tutorial-promise.title-box',
	                'users.role-name-admin',
	                'users.role-name-employee',
	                'users.role-name-manager'
                ]
			};
		},
		components: {
			IconUserSettings,
			IconCheckPrimary,
			NotAllowed,
			NotFound,
			IconEmail,
			IconArrow,
			IconList,
			IconPlus,
			IconInfo,
			IconFilter,
			IconEditDots,
			IconObjectiveSingle,
			IconObjectivesList,
			NotPaid,
			IconCheck,
			IconClose,
			Lottie,
			InfiniteLoading,
			KeyResultsList,
			ObjectivesList
		},
		watch:{
            $route (to, from){
            	if(this.$route.name == 'homepage' || this.$route.name == 'dashboard'){
            		var title = 'Dashboard';
            		var backRoute = '' 
            	}else{
            		var title = 'Colegi';
            		var backRoute = 'users' 
            	}
            	this.$root.$emit("navbar_title", title, {route: backRoute})

            	if(this.$route.name == 'homepage' || this.$route.name == 'dashboard'){
            		this.user_slug = this.$auth.user().slug
            		this.getUser()
            		this.getTimeline()
            		this.searchFilterFunct()
            	}
            }
        },
        created() {
        	window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
        	window.removeEventListener('resize', this.handleResize)
        },
        beforeDestroy () {
			this.$root.$off('refreshUserPage')
		},
		async mounted() {
			//Create array of quarters
			var awaitArray = true
			var currentQuarter = moment(this.$auth.user().instance_created_at)

			while(awaitArray){
				this.arrayQuarters.push({ quarter: currentQuarter.quarter(), year: currentQuarter.format('YYYY'), start_quarter: currentQuarter.startOf('quarter').format('YYYY-MM-DD'), end_quarter: currentQuarter.endOf('quarter').format('YYYY-MM-DD') })

				if(currentQuarter.endOf('quarter') < moment().add(1,'year')){
					currentQuarter = currentQuarter.endOf('quarter').add(1, 'day')
				}else{
					awaitArray = false
				}
			}
			this.arrayQuarters.reverse()
			//END Create array of quarters

			this.user_slug = this.$route.params.slug;

			if(this.$route.name == 'homepage' || this.$route.name == 'dashboard'){
				this.user_slug = this.$auth.user().slug
			}

			if(this.$resize && this.$mq.between([1031,1300])){
				this.weekNumbers = 5
				this.timeline_offset = 3
				this.default_timeline_offset = 3
			}

			if(this.$resize && this.$mq.between([771,1030])){
				this.weekNumbers = 4
				this.timeline_offset = 2
				this.default_timeline_offset = 2
			}

			if(this.$resize && this.$mq.below(770)){
				this.weekNumbers = 3
				this.timeline_offset = 1
				this.default_timeline_offset = 1
			}

			if(this.user_slug != ''){
				await this.getKrStatusList()
				await this.getObjStatusList()
				await this.getUser()
				await this.getTimeline()
			}else{
				this.$router.push({ name: 'page-404'})
			}

			this.$root.$on("refreshUserPage", () => {
				this.getUser()
				this.getTimeline()
				this.searchFilterFunct()
            });
		},
	    computed: {
			filteredObj(){
				return SortByDeadline(this.objectives, this.activeDate)
			},
			filteredKrs(){
				return SortByDeadline(this.key_results, this.activeDate)
			}
		},
		methods: {
			async getUser(){
				await axios.get('/people/'+this.user_slug)
				.then(({data}) => {
					this.user = data.data
					this.dateRemain = this.user.promise_time
					this.startCountdown()
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'
					this.notFound = status == 'not found'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()

						setTimeout(() => {
							if(this.$auth.check()){
								location.reload()
							}
						}, 300)
					}

				}).finally(() => {
					if(this.$route.name == 'homepage' || this.$route.name == 'dashboard'){
						var title = this.$t('navbar.dashboard');
						var backRoute = '' 
					}else{
						var title = this.$t('navbar.people');
						var backRoute = 'users' 
					}
					this.$root.$emit("navbar_title", title, {route: backRoute})
					this.loaded = true
				})
			},
			async getTimeline(){
				await axios.get('/people/'+ this.user_slug +'/timeline?offset=' + this.timeline_offset + '&week_numbers=' + this.weekNumbers)
				.then(({data}) => {
					this.data_timeline = data.data.array_of_promises_with_index
					// this.active_day = moment().format('YYYY-MM-DD');
				}).catch(error => {
					var status = error.response.data.status
					this.notFound = status == 'not found'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()
					}
				}).then(() => {
					this.loadedTimeline = true

					if(this.firstLoadedTimeline){
						this.calculateDayWidth()
					}else{
						setTimeout(() => {
							this.calculateDayWidth()
						}, 500)
					}
				})
			},
			async getKrStatusList(){
				await axios.get('/key-results/status/list').then(({data}) => {
					this.krStatusList = data.data
					this.krStatusList.unshift("all");
				})
			},
			async getObjStatusList(){
				await axios.get('/objectives/status/list').then(({data}) => {
					this.objStatusList = data.data
					this.objStatusList.unshift("all");
				})
			},
			getKeyResultsInfinite($state) {
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		var slugUser = this.$route.params.slug ? this.$route.params.slug : (this.user ? this.user.slug : this.$auth.user().slug)

				axios.get(`people/${slugUser}/key-results`, {params: paramsCall})
				.then(({data}) => {
					this.total_key_results = data.total_results

					if(data.data.data.length){
						this.page++
						this.key_results.push(...data.data.data)
						setTimeout(() => { $state.loaded() }, 200)
						if(data.data.data.length < 20){
							$state.complete()
						}
					}else{
						$state.complete()
					}
				})
				.finally(() => {
        			this.loadedList = true
				})
				.catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
			},
			getObjectivesInfinite($state) {
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

				axios.get(`/${this.$auth.user().instance_id}/objectives`, {params: paramsCall})
				.then(({data}) => {
					this.total_objectives = data.total_results
					if(data.data.data.length){
						this.page++
						this.objectives.push(...data.data.data)
						setTimeout(() => { $state.loaded() }, 200)
						if(data.data.data.length < 20){
							$state.complete()
						}
					}else{
						$state.complete()
					}
				})
				.finally(() => {
        			this.loadedList = true
				})
				.catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
			},
			changeOffset(val = false, reset = false){
				this.timeline_offset = reset == 'default' ? this.default_timeline_offset : this.timeline_offset + val
				if(!reset){
					var widthTotalDays = $('.timeline-bar .current-weeks').width()
					var widthMove = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 7

					$('.timeline-bar .current-weeks').animate({
						scrollLeft: (val > 0 ? 0 : (widthMove * 2))
					}, 300)
				}

				setTimeout(() => {
					this.getTimeline()
				}, 300)
			},
			showModal(type, model = false, requiredData = false, userData = false){
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'show_user');
				this.hideDropdown()
				this.hideDropdownObj()
			},
			showEvaluate(promise = false){
				this.$root.$emit('evaluate_promise_modal', promise, 'show_user');
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdown()
				this.hideDropdownObj()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
			showModalKR(model, uuid, type){
				this.hideDropdown()
				this.hideDropdownObj()
				this.$root.$emit('open_key_result_modals', model, uuid, type, 'show_user');
			},
			showObjective(objectiveSlug){
				this.$router.push({ name: 'show-objective', params: { slug: objectiveSlug }, query: { form_user: this.user_slug}})
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			diffDaysFrom(update_date){
				var a = moment(update_date).startOf('day');
				var b = moment().startOf('day');
				return b.diff(a, 'days');  
			},
			activateTab(tab_name){
				this.activetab = tab_name;
				this.searchFilterFunct(true)
			},
			checkDiffUnity(unity){
				var inlineUnits = ['€', '$', 'RON']
				return inlineUnits.includes(unity)
			},
			showDropdown(itemId){
				if(itemId == this.hoverkeyResult){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}else{
					this.hoverkeyResult = itemId
					setTimeout(() => {
						$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdown(){
				if(this.hoverkeyResult != ''){
					$('#dropdownEdit' + this.hoverkeyResult).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverkeyResult = ''
					}, 0)
				}
			},
			showDropdownObj(itemId){
				if(itemId == this.hoverObjective){
					$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}else{
					this.hoverObjective = itemId
					setTimeout(() => {
						$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdownObj(){
				if(this.hoverObjective != ''){
					$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}
			},
			calculateDayWidth(){
				var widthTotalDays = $('.timeline-bar .current-weeks').width()

				this.dayWidth = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 100 / widthTotalDays
				var widthMove = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 7
				$('.timeline-bar .current-weeks .day').css('opacity', 1)

				if(!this.firstLoadedTimeline){
					setTimeout(() => {
						$('.timeline-bar .current-weeks').scrollLeft(widthMove)
					}, 100)
				}else{
					$('.timeline-bar .current-weeks').scrollLeft(widthMove)
				}

				this.firstLoadedTimeline = true
			},
			displayKeyResultCurrentValue(keyResult) {
				var text = "";
				var value = keyResult.last_key_result_log ? keyResult.last_key_result_log.value : keyResult.start_value;		
				return value.includes('.00') ? parseInt(value).toString() : parseFloat(value).toString();
			},
			needsDecimal(keyResult){
				var needsDecimal = false;
				var value = keyResult.last_key_result_log ? keyResult.last_key_result_log.value : keyResult.start_value;
				if(displayType == 'short'){
					needsDecimal = parseFloat(value) % 1 != 0 ? true : false;
				}			
				return needsDecimal;
			},
			handleResize(){
				if(this.$mq.above(1301) && this.weekNumbers != 6){
					this.weekNumbers = 6
					this.timeline_offset = 4
					this.default_timeline_offset = 4
					this.getTimeline()
				}else if(this.$mq.between([1031,1300]) && this.weekNumbers != 5){
					this.weekNumbers = 5
					this.timeline_offset = 3
					this.default_timeline_offset = 3
					this.getTimeline()
				}else if(this.$mq.between([771,1030]) && this.weekNumbers != 4){
					this.weekNumbers = 4
					this.timeline_offset = 2
					this.default_timeline_offset = 2
					this.getTimeline()
				}else if(this.$mq.below(770) && this.weekNumbers != 3){
					this.weekNumbers = 3
					this.timeline_offset = 1
					this.default_timeline_offset = 1
					this.getTimeline()
				}else{
					var widthTotalDays = $('.timeline-bar .current-weeks').width()
					this.dayWidth = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 100 / widthTotalDays
					var widthMove = (widthTotalDays / ((this.weekNumbers - 2) * 7)) * 7
					$('.timeline-bar .current-weeks .day').css('opacity', 1)
					$('.timeline-bar .current-weeks').scrollLeft(widthMove)
				}
			},
			changeActiveQuarter(quarter){
				this.activeQuarter = quarter == 'all' ? '' : quarter;
				this.searchFilterFunct()
			},
			changeActiveStatus(type, status){
				if(type == 'kr') this.activeStatusKr = status;
				else if(type == 'objective') this.activeStatusObj = status;
				this.searchFilterFunct()
			},
            handleAnimation(anim){
                this.anim = anim;
                this.anim.setSpeed(1);
            },
			startCountdown(){
				let progressBar = document.querySelector('.e-c-progress');
				let length = Math.PI * 2 * 100;
				let intervalTimer;
				let timeLeft;
				var startTime = moment();
				var endTime = moment(this.dateRemain+":00", "HH:mm:ss");
				let wholeTime = endTime.diff(startTime, 'seconds');

				var app = this;

				function timer (){
					intervalTimer = setInterval(function(){
							var startTime = moment();
							var endTime = moment(app.dateRemain+":00", "HH:mm:ss");
							timeLeft = endTime.diff(startTime, 'seconds');
							// timeLeft = moment(app.dateRemain).diff(moment(), 'seconds');
							if(timeLeft < 0){
								clearInterval(intervalTimer);
								app.timeRemain = '00:00'
								return ;
							}

						displayTimeLeft(timeLeft);
					}, 1000);
				}

				function displayTimeLeft (timeLeft){
					let seconds = timeLeft % 60;
					let hours = Math.floor(timeLeft / 60);
					let minutes = hours % 60;
					hours = Math.floor(hours / 60);

					let displayString = `${(hours < 10 && hours >= 1) ? '0' : ''}${hours >= 1 ? hours + ':' : ''}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
					app.timeRemain = displayString;
				}

				timer()
			},
			queryObject(paramsCall){
				paramsCall.status = this.activetab == 'keyresults' ? (this.activeStatusKr == 'all' ? '' : this.activeStatusKr) : (this.activeStatusObj == 'all' ? '' : this.activeStatusObj)
				if(this.activeQuarter) {
					paramsCall.start_quarter = this.activeQuarter.start_quarter
					paramsCall.end_quarter = this.activeQuarter.end_quarter
				}
				paramsCall.user_id = this.user.id
			},
			searchFilterFunct(without_refresh = false){
				this.loadedList = false
				this.page = 0

				if(this.activetab == 'keyresults') {
					this.key_results = []
					if(!without_refresh) {
						this.$refs.infiniteLoadingKeyResults.status = 1
						this.$refs.infiniteLoadingKeyResults.$emit('infinite', this.$refs.infiniteLoadingKeyResults.stateChanger)
					}
				} else {
					this.objectives = []
					if(!without_refresh) {
						this.$refs.infiniteLoadingObjectives ? this.$refs.infiniteLoadingObjectives.status = 1 : ''
						this.$refs.infiniteLoadingObjectives ? this.$refs.infiniteLoadingObjectives.$emit('infinite', this.$refs.infiniteLoadingObjectives.stateChanger) : ''
					}
				}
			}
		}
	};

	function getByStatus(list, status) {
		if(status == 'all'){ return list }
			
		return list.filter(item => item.status == status)
	}
	function SortByDeadline(list, type) {
			if (type == 'asc'){
				return list.sort(function(a,b){
					return moment(a.end_date) - moment(b.end_date)
					}
				)
			} else if( type == 'desc') {
				return list.sort(function(a,b){
					return moment(b.end_date) - moment(a.end_date)
					}
				)
			}
	}
</script>