<template>
	<div class="modal_info modal_cant_create_more">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Cant Create More', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/warning-info.png"></div>
			<div class="title">
				{{ $t('subscription.people-max-reached-title') }}
			</div>
		</div>
		<div class="description-modal">
			{{ $t('subscription.people-max-reached-description') }}
		</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelDelete">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('subscription.cancel') }}
			</button>
			<button class="btn-blue-tbf button-action" @click="changeModal">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('subscription.people-max-reached-change-plan-btn') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				nameItem: '',
				arrayTranslations: [
					'subscription.people-max-reached-title',
					'subscription.people-max-reached-description',
					'subscription.cancel',
					'subscription.people-max-reached-change-plan-btn'
				]
			};
		},
		props: {
			type: String,
		},
		mounted() {
			
		},
		methods: {
			cancelDelete(){
				this.$emit("close_modal");
			},
			changeModal(){
				window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;
				
				this.$root.$emit('change_modal', 'change_subscription');
			},
		}
	};
</script>