window.Popper = require('popper.js').default;
window.$ = require('jquery');
window.jQuery = require('jquery');

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import './scss/app.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import './libraries/jquery.transit.min.js'
window.buzz = require('./libraries/buzz.min.js')
import './libraries/flappy.js'

import 'es6-promise/auto'

import Vue from 'vue'

import http   from './http'
import store  from './store'
import router from './router'
import config from './config'
import i18n from './i18n'

import App from './components/App.vue'


Vue.config.productionTip = false

window.Vue = Vue

Vue.mixin({
  data: function() {
    return {get globalTranslate() {return process.env.VUE_APP_VIEW_TRANSLATE == 'true'}}
  }
})

$(".description-dropdown").hover(function(){
  var dropdownMenu = $(this).children(".dropdown-menu");
  if(dropdownMenu.is(":visible")){
    dropdownMenu.parent().toggleClass("open");
  }
});

import {MediaQueries} from 'vue-media-queries';
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

new Vue({
    el: '#app',
    http: http,
    store: store,
    router: router,
    config: config,
    mediaQueries: mediaQueries,
    i18n,
    render: h => h(App)
});
