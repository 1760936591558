<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('subscription_general')" class="back-arrow">
			<h1>
				{{$t('card-details.title')}}
				<a href="https://help.tbf.ro/ro/articles/4558483-cum-schimbi-cardul-de-plata" target="blank">
					<icon-info class="icon-info"/>
				</a>
			</h1>
		</div>
		<div class="form-section" v-if="loaded">
			<div class="input-group-tbf" v-bind:class="{has_error: cardErrors.cardNumber || stripeError != '', complete: card.card_number != '' && (!cardErrors.cardNumber  || stripeError != '') }">
				<div class="label-input">
					<label>{{$t('card-details.number')}}</label>
					<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
					<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
				</div>
				<div class="input-box">
					<div class="icon-left"><credit-card-icon/></div>
					<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><icon-plus class="icon-clear"/></div>
					<input type="tel" v-model="card.card_number" class="input-text" :placeholder="'XXXX XXXX XXXX ' + cardDetails.last4" v-cardformat:formatCardNumber maxlength="19" ref="first_input">
				</div>
			</div>
			<div class="input-group-tbf w-tbf-50" v-bind:class="{has_error: cardErrors.cardExpiry, complete: card.card_expiration != '' && (!cardErrors.cardExpiry  || stripeError != ''), 'view-tooltip': activeTooltip == 'expire_date' }">
				<div class="label-input">
					<label>{{$t('card-details.valability')}}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-date/></div>
					<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><icon-plus class="icon-clear"/></div>
					<input type="tel" v-model="card.card_expiration" class="input-text simple-input" :placeholder="cardDetails.exp_month + ' / ' + cardDetails.exp_year" v-cardformat:formatCardExpiry maxlength="9">
				</div>
			</div>
			<div class="input-group-tbf w-tbf-50" v-bind:class="{has_error: cardErrors.cardCvc, complete: card.card_cvc != '' && (!cardErrors.cardCvc  || stripeError != ''), 'view-tooltip': activeTooltip == 'cvv' }">
				<div class="label-input">
					<label>{{$t('card-details.cvc')}}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-password/></div>
					<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-plus class="icon-clear"/></div>
					<input type="tel" v-model="card.card_cvc" class="input-text simple-input" placeholder="***" v-cardformat:formatCardCVC maxlength="4">
				</div>
			</div>
		</div>
		<div class="form-section" v-else>
			<div class="input-group-tbf">
				<div class="label-input">
					<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
				</div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>
			<div class="input-group-tbf w-tbf-50">
				<div class="label-input">
					<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
				</div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>
			<div class="input-group-tbf w-tbf-50">
				<div class="label-input">
					<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
				</div>
				<div class="input-box placeholder-loader" style="height: 40px;"></div>
			</div>
		</div>
		<div class="submit-form">
			<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
				<div class="loader"></div>
				<div class="text">{{$t('card-details.modify')}}</div>
			</button>
		</div>
	</div>
</template>


<script type="text/javascript">
	import CreditCardIcon from "../Icons/CreditCard"
	import IconPlus from "../Icons/Plus"
	import IconDate from "../Icons/Date"
	import IconPassword from "../Icons/Password"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: false,
				cardDetails: {},
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: '',
				activeTooltip: ''
			};
		},
		components: {
			IconPlus,
			IconInfo,
			IconCircleCheck,
			IconDate,
			IconPassword,
			CreditCardIcon
		},
		async mounted() {
			await this.getCardDetails()
		},
		methods: {
			async getCardDetails(){
				await axios.get('card-details')
				.then(({data}) => {
					this.cardDetails = data
					this.loaded = true
				})
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = "Numarul cardului este invalid";
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = "Data de expirare este invalida";
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = "Codul CVC este invalid";
					cardError = true
				};

				if(!cardError){
					var btnSubmit = $('.button-submit')
					var btnSubmitLoader = $('.button-submit .loader')
					var btnSubmitText = $('.button-submit .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))
					
					axios.post('/change-card', {
						number: this.card.card_number,
						exp_month: this.card.card_expiration.substring(0, 2),
						exp_year: this.card.card_expiration.substring(5, 9),
						cvc: this.card.card_cvc
					}).then(({data}) => {
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.success'))
							btnSubmit.addClass('completed')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('completed')
								btnSubmit.attr('disabled', false)
								this.$emit('change_slide', 'subscription_general')
							}, 1000)
						}, 300)
					}).catch(error => {
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.error'))
							btnSubmit.addClass('error')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('error')
								btnSubmitText.html(this.$t('card-details.modify'))
								this.stripeError = error.response.data.message;
								btnSubmit.attr('disabled', false)
							}, 1000)
						}, 300)

					});
				}else{
					btnSubmit.attr('disabled', false)
				}
			}
		}
	};
</script>