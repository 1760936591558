<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="smart-planner">
						<div class="group-objectives" v-for="(data, index) in draftData">
							<div class="objective">
								<div class="d-flex">
									<div class="row first">
										<div class="column">
											<div class="icon-type">
												<icon-objectives />
											</div>
											<input type="text" class="input-planner" v-model="draftData[index].name" v-on:keyup.enter="goNextKrOrAddNew(index)" :placeholder="$t('smart_planner.placeholder_objective')" :class="{error: $v.draftData.$each[index].name.$error}">
										</div>
										<div class="column fixed w65">
											<div class="user-assign dropdown-tbf" :class="{error: $v.draftData.$each[index].user_id.$error}">
												<button class="btn-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="searchUsers = ''">
													<img :src="draftData[index].responsible.avatar" v-if="draftData[index].responsible">
													<icon-add-user v-else/>
												</button>

												<div class="dropdown-menu" aria-labelledby="dropdownFilter">
													<div class="filter-dropdown">
														<input type="text" v-model="searchUsers" :placeholder="$t('smart_planner.search_user')">
													</div>

													<div class="dropdown-list">
														<div v-for="user in filteredUsers" class="dropdown-item" @click="populateUserId(user, index)" v-bind:class="{active: draftData[index].responsible && draftData[index].responsible.id == user.id}">
															<span class="text">{{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="column fixed w50 column-delete" v-if="$resize && $mq.below(1600)">
											<button class="btn-delete" @click="deleteRow(index)">
												<icon-delete />
											</button>
										</div>
									</div>
									<div class="row second">
										<div class="column fixed interval-width">
											<div class="input-planner simple" >
												<div class="input-box" @click="showCalendarPeriod(index)" :class="{error: $v.draftData.$each[index].start_date.$error}">
													<div class="icon-left"><icon-date /></div>
													<div class="input-link pr-15">
														<div class="placeholder" v-if="!draftData[index].dateRange.start_date || draftData[index].dateRange.start_date == ''">{{ $t('smart_planner.interval_dates') }}</div>
														<div class="text" v-else>
															<span class="data">{{ draftData[index].dateRange.start_date | moment('DD/MM/YY') }}</span> - <span class="data"> {{ draftData[index].dateRange.end_date | moment('DD/MM/YY') }}</span>
															<div class="total-days">{{ $tc('days', calculateTotalDays(draftData[index].dateRange.start_date, draftData[index].dateRange.end_date)) }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="column fixed w200">
											<div class="input-planner simple">
												<div class="input-box">
													<div class="icon-left"><icon-master-goals /></div>
													<multiselect 
														v-model="draftData[index].master_goal"
														class="select-tags-tbf"
														:options="optionsMasterGoals"
														:allow-empty="true"
														:show-labels="false"
														track-by="id" 
														label="name"
														:placeholder="$t('smart_planner.master_goal')"
														@input="populateMasterGoalId($event, index)"
														>
														<template slot="option" slot-scope="props">
															<div :class="'option-mg level-' + props.option.level_depth" :style="'padding-left: calc(10px * ' + props.option.level_depth + ');'">
																<div class="icon-level" v-if="props.option.level_depth != 0"><icon-level-up /></div>
																<div class="'option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
															</div>
														</template>
														<template slot="singleLabel" slot-scope="props">
															<div class="option_ellipsis">{{ props.option.name ? props.option.name : $t('master_goal.private') }}</div>
														</template>
														<template slot="noResult">{{ $t('objective.no-results') }}</template>
														<template slot="noOptions">{{ $t('general.empty-list') }}</template>
													</multiselect>
												</div>
											</div>
										</div>
										<div class="column fixed w160">
											<div class="input-planner simple">
												<div class="input-box" :class="{error: $v.draftData.$each[index].is_private.$error}">
													<div class="icon-left"><icon-privat /></div>
													<multiselect 
													v-model="draftData[index].privacy"
													class="select-tags-tbf"
													:options="optionsPrivacy"
													:allow-empty="false"
													:show-labels="false"
													track-by="id"
													label="name"
													:placeholder="$t('smart_planner.privacy')"
													@input="populatePrivacyId($event, index)"
													>
														<template slot="noResult">{{ $t('objective.no-results') }}</template>
														<template slot="noOptions">{{ $t('general.empty-list') }}</template>
													</multiselect>
												</div>
											</div>
										</div>
										<div class="column fixed w50 column-delete" v-if="$resize && $mq.above(1601)">
											<button class="btn-delete" @click="deleteRow(index)">
												<icon-delete />
											</button>
										</div>
									</div>
								</div>
								<div class="row error" v-if="!$v.draftData.$each[index].name.maxLength">
									<div class="column">{{ $t('validator.max_length_255') }}</div>
								</div>
							</div>
							<div class="group-key-results">
								<div class="key_result" v-for="(kr, ind_krs) in data.key_results">
									<div class="d-flex">
										<div class="row first">
											<div class="column">
												<div class="icon-type">
													<icon-key-results />
												</div>
												<input type="text" class="input-planner" v-model="draftData[index].key_results[ind_krs].name" v-on:keyup.enter="goNextKrOrAddNew(index, ind_krs)" :placeholder="$t('smart_planner.placeholder_kr')" :class="{error: $v.draftData.$each[index].key_results.$each[ind_krs].name.$error}" :ref="`kr_input_${index}_${ind_krs}`">
											</div>
											<div class="column fixed w65">
												<div class="user-assign dropdown-tbf" :class="{error: $v.draftData.$each[index].key_results.$each[ind_krs].user_id.$error}">
													<button class="btn-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														<img :src="draftData[index].key_results[ind_krs].responsible.avatar" v-if="draftData[index].key_results[ind_krs].responsible">
														<icon-add-user v-else/>
													</button>

													<div class="dropdown-menu" aria-labelledby="dropdownFilter">
														<div class="filter-dropdown">
															<input type="text" v-model="searchUsers" :placeholder="$t('smart_planner.search_user')" @click="searchUsers = ''">
														</div>

														<div class="dropdown-list">
															<div v-for="user in filteredUsers" class="dropdown-item" @click="populateUserId(user, index, ind_krs)" v-bind:class="{active: draftData[index].key_results[ind_krs].responsible && draftData[index].key_results[ind_krs].responsible.id == user.id}">
																<span class="text">{{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="column fixed w50 column-delete" v-if="$resize && $mq.below(1600)">
												<button class="btn-delete" @click="deleteRow(index, ind_krs)">
													<icon-delete />
												</button>
											</div>
										</div>
										<div class="row second">
											<div class="column fixed interval-width">
												<div class="input-planner simple">
													<div class="input-box" @click="showCalendarPeriod(index, ind_krs)" :class="{error: $v.draftData.$each[index].key_results.$each[ind_krs].start_date.$error}">
														<div class="icon-left"><icon-date /></div>
														<div class="input-link pr-15">
															<div class="placeholder" v-if="!draftData[index].key_results[ind_krs].dateRange.start_date || draftData[index].key_results[ind_krs].dateRange.start_date == ''">{{ $t('smart_planner.interval_dates') }}</div>
															<div class="text" v-else>
																<span class="data">{{ draftData[index].key_results[ind_krs].dateRange.start_date | moment('DD/MM/YY') }}</span> - <span class="data"> {{ draftData[index].key_results[ind_krs].dateRange.end_date | moment('DD/MM/YY') }}</span>
																<div class="total-days">{{ $tc('days', calculateTotalDays(draftData[index].key_results[ind_krs].dateRange.start_date, draftData[index].key_results[ind_krs].dateRange.end_date)) }}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="column fixed w200">
												<div class="input-planner simple">
													<div class="input-box" @click="showProgress(index, ind_krs)" :class="{error: $v.draftData.$each[index].key_results.$each[ind_krs].progress_type.$error}">
														<div class="icon-left"><icon-progress-kr /></div>
														<div class="input-link pr-15">
															<div class="text" v-if="draftData[index].key_results[ind_krs].progress_type">{{ $t('progress_type_text_simple.' + draftData[index].key_results[ind_krs].progress_type, { to: draftData[index].key_results[ind_krs].target.toString(), unit: draftData[index].key_results[ind_krs].unity, from: draftData[index].key_results[ind_krs].start_value.toString()}  ) }}</div>
															<div class="placeholder" v-else>{{ $t('smart_planner.progress') }}</div>
														</div>
													</div>
												</div>
											</div>
											<div class="column fixed w160">
												<div class="input-planner simple">
													<div class="input-box" :class="{error: $v.draftData.$each[index].key_results.$each[ind_krs].measurement.$error}">
														<div class="icon-left"><icon-frequency /></div>
														<multiselect 
														v-model="draftData[index].key_results[ind_krs].measurement"
														class="select-tags-tbf"
														:options="optionsFrequency"
														:allow-empty="false"
														:show-labels="false"
														:placeholder="$t('smart_planner.frequency')"
														>
															<template slot="option" slot-scope="props">
																<div class="'option_ellipsis">{{$t('frequency-interval', {n: props.option})}}</div>
															</template>
															<template slot="singleLabel" slot-scope="props">
																<div class="'option_ellipsis">{{$t('frequency-interval', {n: props.option})}}</div>
															</template>
															<template slot="noResult">{{ $t('objective.no-results') }}</template>
															<template slot="noOptions">{{ $t('general.empty-list') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
											<div class="column fixed w50 column-delete" v-if="$resize && $mq.above(1601)">
												<button class="btn-delete" @click="deleteRow(index, ind_krs)">
													<icon-delete />
												</button>
											</div>
										</div>
									</div>
									<div class="row error" v-if="!$v.draftData.$each[index].key_results.$each[ind_krs].name.maxLength">
										<div class="column">{{ $t('validator.max_length_255') }}</div>
									</div>
								</div>
							</div>
							<div class="btn-tbf white center btn-add-kr" @click="addNewKeyResult(index)">
								<div class="icon"><icon-plus /></div>
								<div class="text">{{ $t('smart_planner.add_new_kr') }}</div>
							</div>
						</div>
						<div class="btn-tbf grey center btn-add-objective" @click="addNewObjective">
							<div class="icon"><icon-plus /></div>
							<div class="text">{{ $t('smart_planner.add_new_objective') }}</div>
						</div>

						<div class="submit-actions">
							<div class="error-message" v-if="$v.draftData.$error || Object.keys(errorsBe).length">
								<div v-if="$v.draftData.$error">{{ $t('smart_planner.validation_check_formular') }}</div>

								<div v-if="errorsBe.objective_limit">{{ $t('validator.objective_limit') }}</div>
								<div v-if="errorsBe.leader_limit">{{ $t('validator.leader_limit') }}</div>
							</div>
							<div class="buttons">
								<button id="saveAsDraft" class="btn-tbf grey center" @click="saveAsDraft">
									<div class="loader"></div>
									<div class="text">{{ $t('smart_planner.save_as_draft') }}</div>
								</button>
								<button id="makeLive" class="btn-tbf blue center" @click="makeLive">
									<div class="loader"></div>
									<div class="text">{{ $t('smart_planner.make_live') }}</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-smart-planner v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/EditSettings'
	import IconTrash from '../../Icons/Trash'
    import IconPeople from "../../Icons/People"
	import LoaderSmartPlanner from '../../PagesLoaders/SmartPlanner'
	import IconMasterGoals from "../../Icons/MasterGoals"
	import IconPrivat from "../../Icons/Privat"
	import IconDate from "../../Icons/Date"
	import IconAddUser from "../../Icons/AddUser"
	import IconPlus from "../../Icons/Plus"
	import IconLevelUp from "../../Icons/LevelUp"
	import IconObjectives from "../../Icons/ObjectiveSingle"
	import IconKeyResults from "../../Icons/KeyResults"
	import IconProgressKr from "../../Icons/ProgressKr"
	import IconFrequency from "../../Icons/Frequency"
	import IconDelete from "../../Icons/Delete"

	import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
            IconPeople,
            IconMasterGoals,
            IconPrivat,
            IconDate,
            IconAddUser,
            IconPlus,
            IconLevelUp,
            IconObjectives,
            IconKeyResults,
            IconProgressKr,
            IconFrequency,
            IconDelete,
			LoaderSmartPlanner
		},
		data() {
			return {
				loaded: false,
                errorsBe: {},
                searchUsers: '',
                users: [],
                optionsMasterGoals: [],
				optionsFrequency: [7,14,30,90],
                selectedPrivacy: '',
			  	optionsPrivacy: [{id: 1, name: this.$t('general.public'), key: 'public', value: 0}, {id: 2, name: this.$t('general.private'), key: 'private', value: 1}],
			  	draftData: [],
			  	draftMode: true
			}
		},
		computed: {
			filteredUsers(){
				return getByKeywordUsers(this.users, this.searchUsers)
			}
		},
		async mounted(){
			setTimeout(() => {
				var title = this.$t('settings-navbar.smart-planner');
				this.$root.$emit("navbar_title", title);
			}, 0)

			if(this.$auth.user().is_admin){
				await this.getUsers()
				await this.getMasterGoals()
				await this.getDraftData()
			}else{
				this.$router.push({name: 'forbbiden'})        		
			}

			this.$root.$on('getIntervalDataTemplate', (value) => {
				let valueObj = JSON.parse(value)

				if(valueObj.krIndex !== false) {
					this.draftData[valueObj.objIndex].key_results[valueObj.krIndex].dateRange.start_date = valueObj.start_date
					this.draftData[valueObj.objIndex].key_results[valueObj.krIndex].dateRange.end_date = valueObj.end_date

					this.draftData[valueObj.objIndex].key_results[valueObj.krIndex].start_date = this.moment(valueObj.start_date).format('YYYY-MM-DD')
					this.draftData[valueObj.objIndex].key_results[valueObj.krIndex].end_date = this.moment(valueObj.end_date).format('YYYY-MM-DD')
				} else {
					this.draftData[valueObj.objIndex].dateRange.start_date = valueObj.start_date
					this.draftData[valueObj.objIndex].dateRange.end_date = valueObj.end_date
					this.draftData[valueObj.objIndex].start_date = this.moment(valueObj.start_date).format('YYYY-MM-DD')
					this.draftData[valueObj.objIndex].end_date = this.moment(valueObj.end_date).format('YYYY-MM-DD')

					this.draftData[valueObj.objIndex].key_results.map((el) => {
						if(el.start_date == '' || el.start_date == null) {
							el.start_date = this.draftData[valueObj.objIndex].start_date
							el.end_date = this.draftData[valueObj.objIndex].end_date
							el.dateRange.start_date = this.draftData[valueObj.objIndex].dateRange.start_date
							el.dateRange.end_date = this.draftData[valueObj.objIndex].dateRange.end_date
						}
					})
				}
			})

			this.$root.$on('getProgressDataTemplate', (value) => {
				let valueKrObj = JSON.parse(value)
				this.draftData[valueKrObj.objIndex].key_results[valueKrObj.krIndex].progress_type = valueKrObj.progressData.type
				this.draftData[valueKrObj.objIndex].key_results[valueKrObj.krIndex].unity = valueKrObj.progressData.unit
				this.draftData[valueKrObj.objIndex].key_results[valueKrObj.krIndex].start_value = valueKrObj.progressData.from
				this.draftData[valueKrObj.objIndex].key_results[valueKrObj.krIndex].target = valueKrObj.progressData.to
			})
		},
		validations: {
			draftData: {
				$each: {
					name: { required, maxLength: maxLength(255) },
					user_id: { required: requiredIf(function(){ return !this.draftMode }) },
					start_date: { required: requiredIf(function(){ return !this.draftMode }) },
					is_private: { required: requiredIf(function(){ return !this.draftMode }) },
					key_results: {
						$each:{
							name: { required, maxLength: maxLength(255) },
							user_id: { required: requiredIf(function(){ return !this.draftMode }) },
							start_date: { required: requiredIf(function(){ return !this.draftMode }) },
							progress_type: { required: requiredIf(function(){ return !this.draftMode }) },
							measurement: { required: requiredIf(function(){ return !this.draftMode }) }
						}
					}
				}
			}
		},
		methods: {
			async getDraftData() {
				await axios.get('/draft-planners/show')
				.then(({data}) => {
					this.draftData = data.data ? data.data.structure : []
				})
				.finally(() => {
					this.loaded = true
					var title = this.$t('settings-navbar.smart-planner');
					this.$root.$emit("navbar_title", title);
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			async getUsers(){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true } })
				.then(({data}) => {
					this.users = data.data.users
				});
			},
			async getMasterGoals(){
				await axios.get('/master-goals')
				.then(({data}) => {
					this.optionsMasterGoals = data.data ? data.data : []
				})
			},
			addNewObjective(){
				var newObj = { 
					name: '', 
					responsible: '', 
					user_id: '', 
					start_date: '', 
					end_date: '', 
					dateRange: {start_date: '', end_date: ''}, 
					privacy: '',
					is_private: '', 
					master_goal: '', 
					master_goal_id: '',
					key_results: [
					{ name: '', responsible: '', user_id: '', start_date: '', end_date: '', dateRange: {start_date: '', end_date: ''}, progress_type: '', unity: '', start_value: '', target: '', measurement: '' }
					]
				};

				this.draftData.push(newObj)
			},
			addNewKeyResult(indexObj){
				this.draftData[indexObj].key_results.push(
					{ 
						name: '', 
						responsible: '', 
						user_id: '', 
						start_date: this.draftData[indexObj].start_date ? this.draftData[indexObj].start_date : '', 
						end_date: this.draftData[indexObj].end_date ? this.draftData[indexObj].end_date : '', 
						dateRange: {
							start_date: this.draftData[indexObj].start_date ? this.draftData[indexObj].start_date : '', 
							end_date: this.draftData[indexObj].end_date ? this.draftData[indexObj].end_date : ''
						}, 
						progress_type: '', 
						unity: '', 
						start_value: '', 
						target: '', 
						measurement: '' 
					}
				)
			},
			showModal(type, model = false, data = false){
				this.$root.$emit('open_modal', type, model, data);
			},
			calculateTotalDays(start_date, end_date) {
				var startDate = this.moment(start_date)
				var endDate = this.moment(end_date)

				var diff_days = endDate.diff(startDate, 'days')
				return diff_days + 1;
			},
			showCalendarPeriod(objIndex, krIndex = false) {
				if(krIndex !== false) {
					if(this.draftData[objIndex].dateRange.start_date) {
						this.showModal('calendar_period', false, { 
							typeModal: 'key_result', 
							objective: this.draftData[objIndex], 
							intervalDates: JSON.stringify(this.draftData[objIndex].key_results[krIndex].dateRange), 
							objIndex: objIndex, 
							krIndex: krIndex, 
							userSelected: this.draftData[objIndex].key_results[krIndex].responsible ? this.draftData[objIndex].key_results[krIndex].responsible : null
						})
					}
				} else {
					this.showModal('calendar_period', false, { 
						typeModal: 'objective',
						objective: this.draftData[objIndex], 
						intervalDates: JSON.stringify(this.draftData[objIndex].dateRange), 
						objIndex: objIndex,
						krIndex: krIndex
					} )
				}
			},
			showProgress(objIndex, krIndex) {
				var item = this.draftData[objIndex].key_results[krIndex];

				this.showModal('progress_simple', false, { 
					progressData: JSON.stringify({
						type: item.progress_type ? item.progress_type : '',
						unit: item.unity ? item.unity : '',
						from: item.start_value ? item.start_value : '',
						to: item.target ? item.target : ''
					}),
					objIndex: objIndex, 
					krIndex: krIndex
				})
			},
			deleteRow(objIndex, krIndex = false) {
				if(krIndex !== false) {
					this.draftData[objIndex].key_results.splice( krIndex , 1)
				} else {
					this.draftData.splice( objIndex , 1)
				}
			},
			populateMasterGoalId(value, index) {
				this.draftData[index].master_goal_id = value.id
			},
			populateUserId(value, objIndex, krIndex = false) {
				if(krIndex !== false) {
					this.draftData[objIndex].key_results[krIndex].responsible = value
					this.draftData[objIndex].key_results[krIndex].user_id = value.id
				} else {
					this.draftData[objIndex].responsible = value
					this.draftData[objIndex].user_id = value.id
				}
			},
			populatePrivacyId(value, index) {
				this.draftData[index].is_private = value.value
			},
			goNextKrOrAddNew(objIndex, krIndex = false) {
				if(this.draftData[objIndex].key_results.length) {
					if(krIndex !== false) {

						if(this.draftData[objIndex].key_results.length == (krIndex + 1)) {
							this.addNewKeyResult(objIndex)
							setTimeout(() => {
								this.$refs["kr_input_" + objIndex + "_" + (krIndex + 1)][0].focus();
							}, 200)
						} else {
							this.$refs["kr_input_" + objIndex + "_" + (krIndex + 1)][0].focus();
						}
					} else {
						this.$refs["kr_input_" + objIndex + "_0"][0].focus();
					}
				} else {
					this.addNewKeyResult(objIndex)
					setTimeout(() => {
						this.$refs["kr_input_" + objIndex + "_0"][0].focus();
					}, 200)
				}
			},
			saveAsDraft() {
				var buttonName = `saveAsDraft`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.draftMode = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.simple-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('smart_planner.save_as_draft')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				} else {
					axios.post(`${this.$auth.user().instance_id}/draft-planners/store-draft`, { structure: this.draftData })
					.then(() => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('smart_planner.save_as_draft')
								btnSubmit.disabled = false
							}, 500)
						}, 300)
					})
				}
			},
			makeLive(){
				var buttonName = `makeLive`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.draftMode = false;
				this.$v.$touch();

				if (this.$v.$invalid) {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.simple-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('smart_planner.make_live')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				} else {
					axios.post(`${this.$auth.user().instance_id}/draft-planners/store-live`, {structure: this.draftData})
					.then(() => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								this.draftData = [];
								axios.post(`${this.$auth.user().instance_id}/draft-planners/store-draft`, { structure: this.draftData })

								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('smart_planner.make_live')
								btnSubmit.disabled = false
							}, 500)
						}, 300)
					})
					.catch(error => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.simple-error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('smart_planner.make_live')
								btnSubmit.disabled = false

								if(error.response.data && Object.keys(error.response.data.errors).length) {
									this.errorsBe = error.response.data.errors
								}
							}, 1000)
						}, 300)
					});
				}
			}
		}
	};

	function getByKeywordUsers(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => (item.first_name + ' ' + item.last_name).toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	@import '../../../scss/templates/planner.scss'
</style>