<template>
	<div class="submodal-tbf submodal-calendar">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Calendar Period', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-content-modal">
			<div class="header-modal">
				<div class="title">{{ $t('calendar.period.title') }}</div>
				<div class="actions">
					<button class="btn-default-tbf reset-btn" @click="resetCalendar">{{ $t('calendar.period.reset') }}</button>
					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="form-section">
				<div class="sub-header">
					<div class="left-box">
						<div class="description" v-bind:class="{error: errorValidation}">{{ $t('calendar.period.description') }} <div class="error-min-end-date">{{ !validRange ? $t('calendar.period.min_end_date_error') : '' }}</div></div>
					</div>
				</div>

				<div class="input-group-tbf" v-if="loaded">
					<!-- :disabledDayNames="disabledDayNamesArray" -->
					<FunctionalCalendar
					ref="CalendarRange"
					v-model="datesSelected"
					class="calendar-tbf"
					:is-date-range="true"
					:is-multiple="true"
					:calendars-count="calendarCounts"
					:is-separately="false"
					:hidden-elements="['leftAndRightDays']"
					:limits="limitsRange"
					@selectedDaysCount="countDays"
					:changeMonthFunction="true"
					:changeYearFunction="true"
					:change-year-step="6"
					:disabledDates="disabledDatesUserFiltered"
					></FunctionalCalendar>
				</div>

				<div class="summary-and-submit">
					<div class="box-card-tbf">
						<div class="icon-data total-days">
							<img src="/build/icons/event-confirm.svg">
							<div class="value">{{ totalDays }}</div>
							<div class="label">{{ $tc('calendar.period.days', totalDays) }}<br/>{{ $tc('calendar.period.selected', totalDays) }}</div>
						</div>
						<div class="icon-data worked-days">
							<img src="/build/icons/event-confirm.svg">
							<div class="value">{{ workedDays }}</div>
							<div class="label">{{ $tc('calendar.period.days', workedDays) }}<br/>{{ $t('calendar.period.working') }}</div>
						</div>
						<button class="button-tbf-blue button-submit" @click="saveModifications">
							<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
							{{ $t('calendar.period.save-period') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				workedDays: 0,
				totalDays: 0,
				datesSelected: {
					dateRange:{
						start:'',
						end:''
					}
				},
				limitsRange: {
					min: '',
					max: ''
				},
				markedDateRange: {
					start: false,
					end: false
				},
				errorValidation: false,
				selectedRange: false,
				validRange: true,
				rangeDays: '',
				calendarCounts: 3,
				itsFirstTime: false,
				disabledDayNamesArray: [],
				arrayTranslations: [
					'calendar.period.title',
					'calendar.period.reset',
					'calendar.period.description',
					'calendar.period.days',
					'calendar.period.selected',
					'calendar.period.working',
					'calendar.period.save-period'
				]
			};
		},
		props:{
			typeModal: String,
			objective: Object,
			intervalDates: String,
			userSelected: Object,
			disabledDatesUser: Array
		},
		components: {
			IconPlus
		},
		validations: {
			datesSelected: {
				dateRange:{
					start: {required},
					end: {required}
				}
			}
		},
		computed: {
			disabledDatesUserFiltered(){
				return this.disabledDatesUser.map((el) => {
					return moment(el, 'DD/MM/YYYY').format('D/M/YYYY');
				})
			}
		},
		created(){
			if(JSON.parse(this.intervalDates).start_date != ''){
				this.datesSelected.dateRange.start = this.moment(JSON.parse(this.intervalDates).start_date).format('D/M/YYYY')
				this.datesSelected.dateRange.end = this.moment(JSON.parse(this.intervalDates).end_date).format('D/M/YYYY')
				// this.markedDateRange = { start: this.moment(JSON.parse(this.intervalDates).start_date).format('D/M/YYYY'), end: this.moment(JSON.parse(this.intervalDates).end_date).format('D/M/YYYY')}
				this.itsFirstTime = true
				this.selectedRange = true
				this.workedDays = this.addWeekdays(this.datesSelected.dateRange.start, this.datesSelected.dateRange.end);
			}


			if(this.typeModal == 'objective'){
				if(JSON.parse(this.intervalDates).start_date == ''){
					this.limitsRange.min = this.moment().format('D/M/YYYY')

					setTimeout(() => {
						this.$refs.CalendarRange.ChooseDate(this.moment().format('D/M/YYYY'))
					}, 0)
				}else{
					var start_date_moment = this.moment(JSON.parse(this.intervalDates).start_date)
					var end_date_moment = this.moment(JSON.parse(this.intervalDates).end_date)
					this.limitsRange.min = start_date_moment.format('D/M/YYYY')

					var monthsDiff = (end_date_moment.month() - start_date_moment.month()) + 1
					setTimeout(() => {
						this.$refs.CalendarRange.ChooseDate(start_date_moment.format('D/M/YYYY'))
					}, 0)
				}
			}else{
				var start_date_moment = this.moment(this.objective.start_date)
				var end_date_moment = this.moment(this.objective.end_date)
				this.limitsRange.min = start_date_moment.format('D/M/YYYY')
				this.limitsRange.max = end_date_moment.format('D/M/YYYY')

				var monthsDiff = (end_date_moment.month() - start_date_moment.month()) + 1
				setTimeout(() => {
					this.$refs.CalendarRange.ChooseDate(this.moment(this.objective.start_date).format('D/M/YYYY'))
				}, 0)
			}


			if(this.$mq.below(770)){
				this.calendarCounts = 2
			}
			if(this.$mq.below(600)){
				this.calendarCounts = 1
			}

			if(this.userSelected){
				var arrayDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
				let difference = arrayDays.filter(x => !this.userSelected.working_days.includes(x));

				difference.map(el => {
					this.disabledDayNamesArray.push(this.$t('calendar.small_days.' + el))
				})
			}
		},
		mounted() {
			this.loaded = true

		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			countDays(value){
				this.validRange = true
				this.selectedRange = true
				this.rangeDays = value + 1

				this.workedDays = this.addWeekdays(this.datesSelected.dateRange.start, this.datesSelected.dateRange.end);

				if(this.typeModal == 'objective'){
					if(this.objective.min_end_date && this.moment(this.datesSelected.dateRange.end, 'D/M/YYYY').format('YYYY-MM-DD') < this.moment(this.objective.min_end_date).format('YYYY-MM-DD')){
						this.validRange = false
					}

					if(this.objective.min_start_date && this.moment(this.datesSelected.dateRange.start, 'D/M/YYYY').format('YYYY-MM-DD') > this.moment(this.objective.min_start_date).format('YYYY-MM-DD')){
						this.validRange = false
					}
				}
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()

				if(!this.$v.datesSelected.$error && this.selectedRange && this.validRange){
					this.errorValidation = false
					$(e.target).attr('disabled', false)
					
					this.$emit("intervalData", JSON.stringify({
						start_date: this.moment(this.datesSelected.dateRange.start, 'D/M/YYYY').format('YYYY-MM-DD'),
						end_date: this.moment(this.datesSelected.dateRange.end, 'D/M/YYYY').format('YYYY-MM-DD'),
						intervalDays: this.rangeDays
					}));
				}else{
					this.errorValidation = true
					$(e.target).attr('disabled', false)
				}

			},
			resetCalendar(){
				this.datesSelected.dateRange.start = ''
				this.datesSelected.dateRange.end = ''
				this.workedDays = 0
				this.totalDays =  0
				this.selectedRange = false
				setTimeout(() => {
					$('.vfc-hovered').removeClass('vfc-hovered')
				}, 0)
			},
			addWeekdays(startDateIn, endDateIn) {
				var startDate = this.moment(startDateIn, 'D/M/YYYY')
				var endDate = this.moment(endDateIn, 'D/M/YYYY')

				var diff_days = endDate.diff(startDate, 'days')
				this.totalDays = diff_days + 1;

				var freeDays = 0;
				this.disabledDatesUserFiltered.map((el) => {
					if(moment(el, 'D/M/YYYY') >=  startDate && moment(el, 'D/M/YYYY') <= endDate) {
						freeDays++;
					}
				});

				var date = startDate;
			  	while (diff_days >= 0) {
			   	 	if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
			    		freeDays++;
			    	}
		  			date = date.add(1, 'days');
			    	diff_days -= 1;
				}
				
				return this.totalDays - freeDays;
			},
		}
	};
</script>