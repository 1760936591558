<template>
	<div v-if="loaded" class="opacity-page">
		<div class="item-page" v-if="notAllowed">
			<not-allowed></not-allowed>
		</div>
		<div class="reports-page" v-else>

			<section class="reports-tabs">
				<report-daily-promise v-if="activeTab == 'daily_promise_report'"/>
			</section>
		</div>
	</div>

</template>

<script type="text/javascript">
	import EmptyPage from '../General/EmptyPage'
	import NotAllowed  from '../General/NotAllowed'
	import NotPaid  from '../General/NotPaid'
	import IconReportsEmpty from '../Icons/ReportsEmpty'
	import IconPlus from '../Icons/Plus'
	import IconSimpleList from '../Icons/SimpleList'
	import ReportDailyPromise from '../Reports/DailyPromise'

	export default {
		data() {
			return {
				loaded: false,
				notAllowed: false,
				activeTab: 'daily_promise_report',
				arrayTranslations: [
					'navbar.reports',
				]
			};
		},
		components: {
			EmptyPage,
			NotAllowed,
			NotPaid,
			IconReportsEmpty,
			IconPlus,
			IconSimpleList,
			ReportDailyPromise
		},
		computed: {

		},
		async mounted() {
			var title = this.$t('navbar.reports');
			this.$root.$emit("navbar_title", title)

            if(this.$auth.user().rights.view_reports){
				this.loaded = true
            	setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}else{
				this.loaded = true
				this.notAllowed = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}
		},
		methods: {
		}
	};
</script>