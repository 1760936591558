<template>
	<div class="submodal-tbf" id="submodal_confirm_close">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Confirm Close', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/warning.svg"></div>
			<div class="title">{{$t('confirm-close.title')}}</div>
		</div>
		<div class="description-modal">{{$t('confirm-close.desc')}}</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelClose">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('confirm-close.cancel')}}
			</button>
			<button class="btn-blue-tbf button-action" @click="closeModal">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('confirm-close.leave')}}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				loaded: false,
				arrayTranslations: [
					'confirm-close.title',
					'confirm-close.desc',
					'confirm-close.cancel',
					'confirm-close.leave'
				]
			};
		},
		async mounted() {
			
		},
		methods: {
			cancelClose(){
				this.$emit("cancel_close");
			},
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>