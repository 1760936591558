<template>
	<div class="modal-tbf">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Change subscription', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-modal form-modal" v-if="loaded">
			<div class="header-modal">
				<div class="title">{{ instance_status == 'active' ? $t('subscription.title-upgrade') : $t('subscription.title-renew')}}</div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="alert-tbf red" v-if="stripeError != ''">
						<div class="data">
							<div class="title">
								{{ stripeError }}
							</div>
						</div>
					</div>
					
					<div class="form-section">
						<div class="packages-group">
							<div class="package-item" v-for="(pack, index) in packagesList.filter(el => oldPackage ? el.package_bundle == 'Old' : el.package_bundle == 'Single')" v-if="($auth.user().instance.status == 'grace_period' && pack.package_id == selectedPackage.package_id) || $auth.user().instance.status != 'grace_period'" :key="index">
								<div class="packages" v-bind:class="{selected: pack.package_id == selectedPackage.package_id, 'error-selected': $v.selectedPackage.$error}" @click="selectPlan(pack)">
									<div class="package-title">
										<h2>{{ pack.package_name }}</h2>
									</div>
									<div class="divider"></div>

									<div class="package-offers" v-for="textFe in pack.includes">
										<div class="package-icon">
											<icon-circle-check/>
										</div>
										{{ textFe }}
									</div>

									<div class="button-check">
										<button class="button-submit">
											<div class="price">
												<div class="old-price" v-if="newPrices.find((el) => el.package_id == pack.package_id)">{{ pack.price_in_euro }}&euro;</div>
												<div class="old-price" v-else-if="pack.old_price_in_euro != pack.price_in_euro">{{ pack.old_price_in_euro }}&euro;</div>

												<div class="current-price" v-if="newPrices.find((el) => el.package_id == pack.package_id)">{{ newPrices.find((el) => el.package_id == pack.package_id).price }}&euro;</div>
												<div class="current-price" v-else>{{ pack.price_in_euro }}&euro;</div>
											</div>
											<div class="period">{{ pack.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }}</div>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="input-group-tbf w-tbf-100">
							<div class="divider-form"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: stripeError != '' || cardErrors.cardNumber, complete: card.card_number != '', 'view-tooltip': activeTooltip == 'card_number' }" id="payment">
							<div class="label-input">
								<label style="min-width: 125px;">{{$t('register.card_number')}}</label>
								<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-card /></div>
								<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''">
									<icon-plus class="icon-close"/>
								</div>
								<input type="tel" :placeholder="$t('register.card_number_placeholder')" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
							</div>
							<div class="tooltip-input">
								<div class="header">
									<div class="title">{{ $t('register.step-3.card-number.header') }}</div>
									<div class="close-tooltip" @click="activeTooltip = ''">
										<icon-plus class="icon-close"/>
									</div>
								</div>
								<div class="description">{{ $t('register.step-3.card-number.description') }}</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: cardErrors.cardExpiry, complete: card.card_expiration != '', 'view-tooltip': activeTooltip == 'validity' }">
							<div class="label-input">
								<label>{{ $t('register.validity_date') }}</label>
								<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-date /></div>
								<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''">
									<icon-plus class="icon-close"/>
								</div>
								<input type="tel" :placeholder="$t('register.validity_date_placeholder')" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
							</div>
							<div class="tooltip-input">
								<div class="header">
									<div class="title">{{ $t('register.step-3.card-expiration.header') }}</div>
									<div class="close-tooltip" @click="activeTooltip = ''">
										<icon-plus class="icon-close"/>
									</div>
								</div>
								<div class="description">{{ $t('register.step-3.card-expiration.description') }}</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: cardErrors.cardCvc, complete: card.card_cvc != '', 'view-tooltip': activeTooltip == 'card_cvc' }">
							<div class="label-input">
								<label>{{ $t('register.cvc') }}</label>
								<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-plus class="icon-close"/></div>
								<input type="tel" :placeholder="$t('register.cvc_placeholder')" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
							</div>
							<div class="tooltip-input">
								<div class="header">
									<div class="title">{{ $t('register.step-3.card-cvc.header') }}</div>
									<div class="close-tooltip" @click="activeTooltip = ''">
										<icon-plus class="icon-close"/>
									</div>
								</div>
								<div class="description">{{ $t('register.step-3.card-cvc.description') }}</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: errorCoupon }">
							<div class="label-input">
								<label>{{ $t('register.step-3.coupon.label') }}</label>
								<div class="error-msg" v-if="errorCoupon">{{ cardErrors.couponInvalid }}</div>

							</div>
							<div class="input-with-btn">
								<div class="input-box">
									<div class="icon-left"><icon-password /></div>
									<input type="text" v-model="cuponCode" :placeholder="$t('register.step-3.coupon-placeholder')" class="input-text">
								</div>
								<div class="btn-right">
									<button class="btn-apply" @click="checkCode">
										<div class="loader"></div>
										<div class="text">{{ $t('register.step-3.coupon.apply') }}</div>
									</button>
								</div>
							</div>
						</div>
						<div class="submit-form">
							<button class="button-tbf-blue button-submit w-100" @click="saveModifications">
								<div class="loader"></div>
								<div class="text">{{ $t('subscription.btn-renew') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="input-group-tbf w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="input-group-tbf w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px;height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconCard from "../Icons/Card"
	import IconDate from "../Icons/Date"
	import IconPassword from '../Icons/Password';
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				selectedPackage: '',
				packagesList: {},
				stripeError: '',
				activeTooltip: '',
				cuponCode: '',
				newPrices: [],
				errorCoupon: false,
				instance_status: 'active',
				oldPackage: false,
				cui_valid: false,
				arrayTranslations: [
					'subscription.title-upgrade',
					'subscription.title-renew',
					'register.step-3.package-offer-1',
					'register.step-3.package-offer-2',
					'register.step-3.package-offer-3',
					'register.step-3.package-offer-4',
					'register.step-3.price',
					'register.card_number',
					'register.card_number_placeholder',
					'register.step-3.card-number.header',
					'register.step-3.card-number.description',
					'register.validity_date',
					'register.validity_date_placeholder',
					'register.step-3.card-expiration.header',
					'register.step-3.card-expiration.description',
					'register.cvc',
					'register.cvc_placeholder',
					'register.step-3.card-cvc.header',
					'register.step-3.card-cvc.description',
					'register.step-3.coupon.label',
					'register.step-3.coupon-placeholder',
					'register.step-3.coupon.apply',
					'subscription.btn-renew',
					'validator.invalid_credit_card',
					'validator.invalid_credit_card_date',
					'validator.invalid_credit_card_cvc',
					'subscription.btn-renew'
				]
			}
		},
		components: {
			IconPlus,
			IconInfo,
			IconCircleCheck,
			IconCard,
			IconDate,
			IconPassword
		},
		validations: {
			selectedPackage: {required}
		},
		async mounted() {
			if(this.$auth.user().package && this.$auth.user().package.group_name == 'Old'){
				this.oldPackage = true
			}

			await this.getPackages()
			this.instance_status = this.$auth.user().instance.status
		},
		methods: {
			checkUserVocuher(){
				if(this.$auth.user().voucher){
					this.cuponCode = this.$auth.user().voucher
					this.checkCode()
				}
			},
			async getPackages(){
				let paramsApiCall = {}
				if(this.oldPackage){
					paramsApiCall.package_token = this.$auth.user().package.token
				}

				await axios.get('packages', {params: paramsApiCall})
				.then(({data}) => {
					this.packagesList = data.data;
				})
				.finally(() => {
					if(this.$auth.user().instance){
						if(this.$auth.user().instance.package_id && this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)){
							let currentPackage = this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)
							this.selectedPackage = currentPackage
						}else{
							this.selectedPackage = {package_id: 0}
						}
					}

					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							this.checkUserVocuher()
						}, 0)
					}, 0)
				})
			},
			closeModal(){
				this.$emit("toggle_modal");
			},
			selectPlan(packageItem){
				this.selectedPackage = packageItem;
				setTimeout(function() {
                    $('#content').animate({
                        scrollTop: $("#payment").offset().top
                    }, 250);
                }, 250);
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				this.$v.$touch()
				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false

				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(cardError || this.$v.$invalid){
					btnSubmit.attr('disabled', false)
				}else{
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					if((this.$auth.user().instance.package_id && (this.$auth.user().instance.package_id == this.selectedPackage.check_package_id)) || this.$auth.user().free_account){
						this.storePayment(btnSubmit, btnSubmitLoader, btnSubmitText)
					}else{
						this.changeSubscription(btnSubmit, btnSubmitLoader, btnSubmitText)
					}
				}
			},
			checkCode(){
				var btnApply = $('.btn-apply')
				var btnApplyLoader = $('.btn-apply .loader')
				var btnApplyText = $('.btn-apply .text')

				btnApply.addClass('loading')
				btnApplyLoader.addClass('onProgress')
				this.errorCoupon = false
				
				axios.post('validate-coupon', {coupon : this.cuponCode}).then((data) => {
					if(data.data.is_valid){
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('btn-submit.success'))
							btnApply.addClass('completed')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('completed')
								btnApply.attr('disabled', false)
								btnApplyText.html(this.$t('register.step-3.coupon.apply-success'))

								this.newPrices = data.data.values

							}, 1000)
						}, 300)
					}else{
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('register.step-3.coupon.apply-error'))
							btnApply.addClass('error')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('error')
								btnApplyText.html(this.$t('register.step-3.coupon.apply'))
								btnApply.attr('disabled', false)
								this.errorCoupon = true
								this.newPrices = []
							}, 1000)
						}, 300)
					}
				})
			},
			refreshPage(){
				switch (this.$route.name) {
					case 'dashboard':
						this.$root.$emit('refreshPlanOfDay')
						break;
					case 'homepage':
						this.$root.$emit('refreshPlanOfDay')
						break;
					case 'objectives':
						this.$root.$emit('refreshObjectivesList')
						break;
					case 'show-objective':
						this.$root.$emit('refreshObjectivePage')
						break;
					case 'users':
						this.$root.$emit('refreshUsersList')
						break;
					case 'show-user':
						this.$root.$emit('refreshUserPage')
						break;
					case 'master-goals':
						this.$root.$emit('refreshMasterGoalsList')
						break;
					case 'show-master-goal':
						this.$root.$emit('refreshMasterGoalTree')
						break;
				}
			},
			changeSubscription(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post('/change-plan-account', {package_token: this.selectedPackage.token})
				.then(({data}) => {
					this.storePayment(btnSubmit, btnSubmitLoader, btnSubmitText)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.btn-pay')
						}, 1000)
					}, 300)
				})
			},
			storePayment(btnSubmit, btnSubmitLoader, btnSubmitText){
				let paymetFormData = {
					number: this.card.card_number,
					exp_month: this.card.card_expiration.substring(0, 2),
					exp_year: this.card.card_expiration.substring(5, 9),
					cvc: this.card.card_cvc,
					coupon: this.cuponCode
				}

				if(this.$auth.user().free_account) {
					paymetFormData.package_id = this.selectedPackage.package_id
				}

				axios.post('/payment', paymetFormData).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$auth.fetch();
							this.$root.$emit('change_modal', 'congratulations');
							this.refreshPage();
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')

					if(error.response.data.subscription && ['requires_source_action', 'requires_action'].includes(error.response.data.subscription.latest_invoice.payment_intent.status) ){
						var sub = error.response.data.subscription;

						let stripe = Stripe(process.env.VUE_APP_STRIPEPUBLISHABLE_KEY);

						stripe.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
						.then((result) => {
							if(result.error){
								btnSubmitText.html(this.$t('btn-submit.error'))
								btnSubmit.addClass('error')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('error')
									this.stripeError = result.error.message;
									btnSubmitText.html(this.$t('register.button-action-3'))
									btnSubmit.attr('disabled', false)
								}, 1000)
							}else{
								axios.post('/set-as-active')
								.then(({data}) => {
									btnSubmitLoader.addClass('finish')
									setTimeout(()=>{
										btnSubmitText.html(this.$t('btn-submit.success'))
										btnSubmit.addClass('completed')
										btnSubmitLoader.removeClass('onProgress finish')
										btnSubmit.removeClass('loading')
										setTimeout(()=>{
											btnSubmit.removeClass('completed')
											this.$auth.fetch();
											this.$root.$emit('change_modal', 'congratulations');
											this.refreshPage();
										}, 1000)
									}, 300)
								}).catch(error => {
									if(error.response) {
										if(error.response.status == 500) {
											alert(this.$t('error.500'))
										}
									}
								})
							}
						});
					}else{
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.error'))
							btnSubmit.addClass('error')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('error')
								btnSubmitText.html(this.$t('subscription.btn-renew'))
								this.stripeError = error.response.data.message;
								btnSubmit.attr('disabled', false)
							}, 1000)
						}, 300)							
					}
				});
			}
		}
	}
</script>