<template>
	<div class="modal_info modal_reset_password">
		<div class="header-submodal">
			<div class="title">{{ $t('reset_password_modal.title') }}</div>
		</div>
		<div class="description-modal">
			<div class="request-email" v-html="$t('reset_password_modal.request_email', {email: requiredData.email})"></div>
			
			<div class="reset-password-link">
				{{ $t('reset_password_modal.link_email') }}
				<input class="link" id="divToCopy" v-if="loaded" :value="linkReset" readonly/>
				<div class="placeholder-loader" v-else style="height: 40px; width: 100%; border-radius: 8px; margin-top: 10px;"></div>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelReset">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('delete.cancel') }}
			</button>
			<button id="submitResetBtn" class="btn-blue-tbf button-action" @click="loaded ? submitReset() : ''">
				<div class="loader"></div>
				<div class="text">{{ $t('reset_password_modal.copy_link') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				loaded: false,
				linkReset: ''
			};
		},
		props: {
			requiredData: Object
		},
		mounted() {
			this.getResetLink()
		},
		methods: {
			cancelReset(){
				this.$emit("close_modal");
			},
			getResetLink(){
				axios.post(`/auth/reset-password`, {email: this.requiredData.email, index_reset: true})
				.then(({data}) => {
					this.linkReset = data.data
				})
				.finally(() => {
					setTimeout(()=>{
						this.loaded = true
					}, 1000)
				})
			},
			submitReset(){
				var buttonName = `submitResetBtn`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				var copyText = document.getElementById("divToCopy");
				copyText.select();
				copyText.setSelectionRange(0, 99999);
				document.execCommand('copy');

				btnSubmit.classList.add('completed')
				btnSubmitText.innerHTML = this.$t('reset_password_modal.copied')

				setTimeout(()=>{
					btnSubmit.classList.remove('completed')
					btnSubmitText.innerHTML = this.$t('reset_password_modal.copy_link')
				}, 2000)
			}
		}
	};
</script>

<style lang="scss">
	.modal_reset_password{
		.description-modal{
			max-width: 100% !important;
			.request-email{
				a{
					color: $primary;
				}	
			}
			.reset-password-link{
				margin-top: 15px;
				.link{
					width: 100%;
					font-size: 14px;
					margin-top: 10px;
					border-radius: 8px;
					border: 1px solid $borderColor;
					height: 40px;
					padding: 3px 15px;
					color: $primary;
					&:focus{
						box-shadow: none;
						outline: none;
					}
				}
			}
		}
	}
</style>