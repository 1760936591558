<template>
	<div class="submodal-tbf submodal-progress">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Key Result Progress', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-content-modal">
			<div class="header-modal">
				<div class="title">{{ $t('key-result.progress.title') }}</div>
				<div class="actions">
					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="form-section" v-if="loaded">
				<div class="input-group-tbf" v-bind:class="{has_error: $v.progress_type.type.$error || validationLogic, complete: progress_type.type != ''}">
					<div class="label-input">
						<label>{{ $t('key-result.progress.type_progress_label') }}*</label>
						<div class="error-msg" v-if="validationLogic">{{ $t('validator.' + validationLogic) }}</div>
						<div class="info-input">
							<icon-circle-check class="icon-check" v-if="progress_type.type != '' && validationLogic == ''" />
						</div>
					</div>

					<div class="input-box bor-0">
						<div class="progress-types" v-bind:class="{has_error: $v.progress_type.type.$error}">
							<div class="item-progress" v-for="item in optionsProgress.slice(0,2)" @click="progress_type.type = item" v-bind:class="{active: progress_type.type == item}">
								<div class="icon">
									<img :src="'/build/icons/progress-type-' + item + '-active.svg'" class="active-icon">
									<img :src="'/build/icons/progress-type-' + item + '.svg'" class="simple-icon">
								</div>
								<div class="text">
									<div class="placeholder-text">{{ $t('progress_type.' + item) }}</div>
									<div class="placeholder-desc">{{ $t('progress_type_ex.' + item) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="input-group-tbf" v-bind:class="{has_error: $v.progress_type.unit.$error, complete: progress_type.unit != ''}">
					<div class="label-input">
						<label>{{ $t('key-result.progress.unity_label') }}*</label>
						<div class="info-input">
							<icon-circle-check class="icon-check" v-if="progress_type.unit != ''" />
						</div>
					</div>

					<div class="input-box">
						<div class="icon-right full-box" v-if="progress_type.unit != ''" @click.stop="progress_type.unit = ''"><icon-plus class="icon-clear"/></div>
						<div class="icon-left"><icon-unity /></div>
						<multiselect 
						v-model="progress_type.unit"
						class="select-tags-tbf"
						:options="optionsUnits"
						:allow-empty="false"
						:show-labels="false"
						label="label"
						track-by="value"
						:placeholder="$t('key-result.progress.grow-label')"
						:tag-placeholder="$t('key-result.progress.grow-label-tag')"
						@tag="addUnity"
						:taggable="true"
						:max-height="200"
						>
							<template slot="noResult">{{ $t('key-result.progress.no-elements') }}</template>
						</multiselect>
					</div>
				</div>

				<div class="input-group-tbf w-tbf-50" v-bind:class="{has_error: $v.progress_type.from.$error || validationLogic, complete: progress_type.from != ''}">
					<div class="label-input">
						<label>{{ $t('key-result.progress.from') }}*</label>
						<div class="info-input">
							<icon-circle-check class="icon-check" v-if="progress_type.from != '' && validationLogic == ''" />
						</div>
					</div>

					<div class="input-box">
						<div class="icon-left"><icon-start-value /></div>
						<div class="icon-right" v-if="progress_type.from != ''" @click.stop="progress_type.from = ''"><icon-plus class="icon-clear"/></div>
						<input type="text" v-model="progress_type.from" placeholder="0" class="input-text">
					</div>
				</div>

				<div class="input-group-tbf w-tbf-50" v-bind:class="{has_error: $v.progress_type.to.$error || validationLogic, complete: progress_type.to != ''}">
					<div class="label-input">
						<label>{{ $t('key-result.progress.to') }}*</label>
						<div class="info-input">
							<icon-circle-check class="icon-check" v-if="progress_type.to != '' && validationLogic == ''" />
						</div>
					</div>

					<div class="input-box">
						<div class="icon-left"><icon-end-value /></div>
						<div class="icon-right" v-if="progress_type.to != ''" @click.stop="progress_type.to = ''"><icon-plus class="icon-clear"/></div>
						<input type="text" v-model="progress_type.to" placeholder="100" class="input-text">
					</div>
				</div>

				<div class="submit-form">
					<button class="button-tbf-blue button-submit w-100" @click="saveModifications">
						<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
						{{ $t('key-result.progress.save-progress') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required, requiredIf, decimal } from 'vuelidate/lib/validators'
	import IconArrow from "../Icons/Arrow"
	import IconPlus from "../Icons/Plus"
	import IconUnity from "../Icons/Unity"
	import IconStartValue from "../Icons/StartValue"
	import IconEndValue from "../Icons/EndValue"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				showFormData: false,
			  	optionsProgress: [],
                progress_type: {
                	type: '',
                	unit: '',
                	from: '',
                	to: ''
                },
                optionsUnits: [],
                validationLogic: '',
                arrayTranslations: [
	                'key-result.progress.title',
	                'key-result.progress.type_progress_label',
	                'progress_type.above',
	                'progress_type.decrease',
	                'progress_type.grow',
	                'progress_type.percentage',
	                'progress_type.under',
	                'progress_type_ex.above',
	                'progress_type_ex.decrease',
	                'progress_type_ex.grow',
	                'progress_type_ex.percentage',
	                'progress_type_ex.under',
	                'key-result.progress.unity_label',
	                'key-result.progress.grow-label',
	                'key-result.progress.grow-label-tag',
	                'key-result.progress.no-elements',
	                'key-result.progress.from',
	                'key-result.progress.to',
	                'key-result.progress.save-progress'
                ]
			};
		},
		props:{
			progressData: String
		},
		components: {
			IconArrow,
			IconPlus,
			IconUnity,
			IconStartValue,
			IconEndValue,
			IconCircleCheck
		},
		validations: {
			progress_type: {
				type: {required},
				unit: {required: requiredIf(function (nestedModel) {
			        	return nestedModel.type != 'percentage'
			      	})},
				from: {required: requiredIf(function (nestedModel) {
			        	return nestedModel.type != 'percentage'
			      	}), decimal},
				to: {required: requiredIf(function (nestedModel) {
			        	return nestedModel.type != 'percentage'
			      	}), decimal}
			}
		},
		async mounted() {
			if(!(Object.keys(JSON.parse(this.progressData)).length === 0 && JSON.parse(this.progressData).constructor === Object)){
				this.progress_type = JSON.parse(this.progressData)

				if(this.progress_type.type != 'percentage'){
					var findUnit = this.optionsUnits.find(el => el.value == JSON.parse(this.progressData).unit);
					if(findUnit){
						this.progress_type.unit = findUnit
					}else{
						var newUnity = JSON.parse(this.progressData).unit;
						this.optionsUnits.push({label: newUnity, value: newUnity})
						this.progress_type.unit = {label: newUnity, value: newUnity}
					}
				}
			}

			await this.getProgressTypes()
		},
		methods: {
			async getProgressTypes(){
				await axios.get('/key-results/progress-type/list')
				.then(({data}) => {
					this.optionsProgress = data.data.progress_type
					this.optionsUnits = data.data.unities
				}).then(() => {
					this.loaded = true
				});
			},
			closeModal(){
				this.$emit("close_modal");
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					$(e.target).attr('disabled', false)

					if(this.progress_type.type == 'grow'){
						this.validationLogic = Number(this.progress_type.from) < Number(this.progress_type.to) ? '' : 'error_logic_grow'
					}else if(this.progress_type.type == 'decrease'){
						this.validationLogic = Number(this.progress_type.from) > Number(this.progress_type.to) ? '' : 'error_logic_decrease'
					}else if(this.progress_type.type == 'above'){
						this.validationLogic = Number(this.progress_type.from) < Number(this.progress_type.to) ? '' : 'error_logic_above'
					}else if(this.progress_type.type == 'under'){
						this.validationLogic = Number(this.progress_type.from) > Number(this.progress_type.to) ? '' : 'error_logic_under'
					}

					if(!this.validationLogic){
						this.progress_type.unit = this.progress_type.unit.value
						this.$emit("saveProgressData", JSON.stringify(this.progress_type));
					}
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			addUnity(newUnity){
				this.optionsUnits.push({label: newUnity, value: newUnity})
				this.progress_type.unit = {label: newUnity, value: newUnity}
			},
			changeType(value){
                if(this.progress_type.type != ''){
	                $('.box-card-tbf.active-bg').removeClass('active-bg');
	                $('.box-card-tbf.show').removeClass('show');
	                setTimeout(() => {
                		this.showFormData = false;
	                	$('.box-card-tbf.active').removeClass('active');

	                	this.progress_type = {
	                		type: value,
	                		unit: '',
	                		from: '',
	                		to: ''
	                	}

                		if(value == 'percentage'){
		                	$('#type_' + value).addClass('active-bg');
                		}else{
		                	$('#type_' + value).addClass('active');
                		}
	                	setTimeout( () => {
            				this.showFormData = true;
	                		$('#type_' + value).addClass('show');
	                	}, 200)
	                },200)
	            }else{
	            	this.progress_type = {
	            		type: value,
	            		unit: '',
	            		from: '',
	            		to: ''
	            	}

	            	if(value == 'percentage'){
	                	$('#type_' + value).addClass('active-bg');
            		}else{
	                	$('#type_' + value).addClass('active');
            		}
                	setTimeout( () => {
                		this.showFormData = true;
                		$('#type_' + value).addClass('show');
                	}, 200)
                }
			}
		}
	};
</script>