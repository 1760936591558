<template>
	<div class="modal-tbf">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Key Result', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="container-modal form-modal opacity-page" v-if="loaded">
			<div class="header-with-help">
				<div class="header-modal with-help">
					<div class="title">
						{{ editAction ? $t('key-result.modify') : $t('key-result.add') }}
					</div>
					<div class="actions" >
						<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
							<a class="btn-tbf blue only-icon help-btn" :href="helpData1.link" target="_blank" v-if="helpData1">
								<div class="icon"><icon-question /></div>
							</a>

							<template slot="popover">
								<div class="simple-text">{{ $t(`help_links.${helpData1.type}`) }}</div>
							</template>
						</v-popover>

						<div class="btn-default-tbf close-btn" @click="closeModal"> <icon-plus class="icon-close" /></div>
					</div>
				</div>
				<div class="help-text">
					<a :href="helpData2.link" target="_blank">{{ $t(`help_links.${helpData2.type}`) }}</a>
				</div>
			</div>

			<div class="info-content">
				<transition name="slide" mode="out-in">
					<div class="container-content-modal" v-if="activeSlide == 'main_form'">
						<div class="form-section">
							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.key_result.name.$error, complete: key_result.name != '' && !$v.key_result.name.$error, 'view-tooltip': activeTooltip == 'kr_name' }">
								<div class="label-input">
									<label>{{$t('key-result.form.name')}}*</label>
									<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-folder /></div>
									<div class="icon-right" v-if="key_result.name != ''" @click.stop="key_result.name = ''"><icon-plus class="icon-clear"/></div>
									<input type="text" :placeholder="$t('key-result.form.name-placeholder')" class="input-text" v-model="key_result.name" ref="first_input">
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-if="showObjectiveInput" v-bind:class="{has_error: $v.objective.$error, complete: objective != '' && !$v.objective.$error, 'view-tooltip': activeTooltip == 'kr_objective' }" id="objective_required">
								<div class="label-input">
									<label>{{$t('key-result.form.objective')}}*</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-folder /></div>
									<div class="icon-right" v-if="objective != ''" @click.stop="clearObjective"><icon-plus class="icon-clear"/></div>
									<multiselect 
									v-model="objective"
									class="select-tags-tbf"
									:options="objectiveOptions"
									:allow-empty="false"
									:show-labels="false"
									track-by="id" 
									label="name"
									@select="changeDisabledPeriod">
										<template slot="placeholder" slot-scope="props">
											<span class="text">
												{{$t('key-result.form.objective-choose')}}
											</span>
										</template>
										<template slot="singleLabel" slot-scope="props">
											<div class="option_ellipsis">{{ props.option.name }}</div>
										</template>
										<template slot="noResult">{{$t('key-result.form.objective-noresults')}}</template>
									</multiselect>	
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedUser.$error, complete: selectedUser != '' && !$v.selectedUser.$error, 'view-tooltip': activeTooltip == 'kr_user'}" v-if="viewSelectUser" id="user_required">
								<div class="label-input">
									<label>{{$t('key-result.form.person')}}*
										<span class="add-button-in" v-if="$auth.user().rights.can_create" @click="$auth.user().rights.can_create_user ? showModal('user') : openModalV2('cant_create_more', false, 'users')">{{ $t('key-result.form.add_user') }}
										</span>
									</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-people /></div>

									<multiselect 
										v-model="selectedUser"
										class="select-tags-tbf"
										:options="optionsUsers"
										:allow-empty="false"
										:show-labels="false"
										track-by="id" 
										label="first_name"
										:custom-label="fullName"
										@input="handleUserSelected">
											<template slot="placeholder" slot-scope="props">
												<span class="text">
													{{$t('key-result.form.person-choose')}}
												</span>
											</template>
											<template slot="singleLabel" slot-scope="props">
												<div class="option__image">
													<span>
														{{ props.option.first_name + ' ' + props.option.last_name }}
													</span>
												</div>
											</template>
											<template slot="option" slot-scope="props">
												<div class="option__image">
													<img :src="props.option.avatar" v-if="props.option.avatar">
													<img v-else src="/build/images/profile-default.svg">
													<span>
														{{ props.option.first_name + ' ' + props.option.last_name }}
													</span>
												</div>
											</template>
											<template slot="noResult">{{$t('key-result.form.person-noresults')}}</template>
										</multiselect>
								</div>								
							</div>

							<div class="input-group-tbf" v-bind:class="{complete: key_result.description != '', 'view-tooltip': activeTooltip == 'kr_description'}">
								<div class="label-input">
									<label>{{$t('key-result.form.description')}}</label>
									<div class="info-input" @click="activeTooltip == 'kr_description' ? activeTooltip = '' : activeTooltip = 'kr_description'">
										<icon-circle-check class="icon-check" v-if="key_result.description != '' " />
									</div>
								</div>
								<div class="input-box">
									<textarea class="input-textarea" :placeholder="$t('key-result.form.description-placeholder')" v-model="key_result.description" v-autosize rows="3"></textarea>
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.key_result.progressData.$error, complete: Object.keys(key_result.progressData).length && !$v.key_result.progressData.$error, 'view-tooltip': activeTooltip == 'kr_progress'}">
								<div class="label-input">
									<label>{{$t('key-result.form.progress')}}*</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-progress-kr /></div>
									<div class="input-text-link" @click="showSubModal('progress_type')">
										<div v-if="Object.keys(key_result.progressData).length">{{ $t('progress_type_text.' + key_result.progressData.type, { to: progressDataText.to.toString(), unit: progressDataText.unit, from: progressDataText.from.toString()}  ) }}</div>
										<div v-else class="placeholder">
											{{$t('key-result.form.progress-choose')}}
										</div>
									</div>
								</div>
							</div>

							<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.key_result.frequencyData.$error, complete: key_result.frequencyData != '' && !$v.key_result.frequencyData.$error, 'view-tooltip': activeTooltip == 'kr_frequency'}">
								<div class="label-input">
									<label>{{$t('key-result.form.frequency')}}*</label>
								</div>

								<div class="input-box bor-0">
									<div class="privacy-objectives">
										<div class="item-privacy" v-for="option in frequencyOptions" @click="changeActiveFrequency(option)" v-bind:class="{active: frequencyInterval == option}">
											<div class="name">{{$t('frequency-interval', {n: option})}}</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="input-group-tbf" v-bind:class="{has_error: $v.key_result.dateRange.start_date.$error, complete: key_result.dateRange.start_date != '' && !$v.key_result.dateRange.start_date.$error, 'view-tooltip': activeTooltip == 'kr_date-range', disabled: disabledPeriod}" id="period_required">
								<div class="label-input">
									<label>{{$t('key-result.form.period')}}*</label>
								</div>

								<div class="input-box">
									<div class="icon-left"><icon-date /></div>
									<div class="icon-right" v-if="key_result.dateRange.start_date != ''" @click.stop="clearRange"><icon-plus class="icon-clear"/></div>
									<div class="input-text-link" @click="disabledPeriod ? focusRequired('objective_required') : showSubModal('calendar_period')">
										<div class="placeholder" v-if="key_result.dateRange.start_date == ''">{{$t('key-result.form.select-days')}}</div>
										<div class="data-range" v-else>
											<div class="text">
												<span class="data">{{$t('key-result.form.start-date')}}: {{ key_result.dateRange.start_date | moment('DD MMM YYYY') }}</span> - {{$t('key-result.form.end-date')}}: <span class="data"> {{ key_result.dateRange.end_date | moment('DD MMM YYYY') }}</span>
											</div>
											<div class="total-days">{{ $tc('days', totalDaysRange) }}</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="submit-form">
								<button class="button-tbf-blue button-submit" @click="saveModifications">
									<div class="loader"></div>
									<div class="text">{{ editAction ? $t('key-result.form.modify') : $t('key-result.form.save') }}</div>
								</button>
							</div>
						</div>
					</div>
				</transition>
			</div>

			<div class="submodal-container" v-if="subModalShow">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose"></confirm-close>

				<key-result-period-modal v-if="show_subm_modal.calendar_period"
				id="submodal_calendar_period"
				typeModal="key_result"
				:objective="objective" 
				:intervalDates="JSON.stringify(key_result.dateRange)" 
				:userSelected="selectedUser ? selectedUser : null"
				:disabledDatesUser="disabledDatesUser"
				@intervalData="getIntervalData"
				@close_modal="closeSubModal('calendar_period')" />

				<type-progress-result-key-modal v-if="show_subm_modal.progress_type"
				id="submodal_progress_type"
				:progressData="JSON.stringify(key_result.progressData)"
				@saveProgressData="getProgressData"
				@close_modal="closeSubModal('progress_type')" />
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px;height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconDate from "../Icons/Date"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconProgressKr from "../Icons/ProgressKr"
	import IconRefresh from "../Icons/Refresh"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconQuestion from "../Icons/Question"

	import KeyResultPeriodModal from "../SubModals/CalendarPeriod"
	import TypeProgressResultKeyModal from "../SubModals/KeyResultProgress"
	import ConfirmClose from "../SubModals/ConfirmClose"

	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				activeSlide: 'main_form',
				activeTooltip: '',
				key_result: {
					name: '',
					description: '',
					dateRange: {
						start_date: '',
						end_date: '',
					},
					progressData: {},
					frequencyData: 7,
					intervalData: [],
				},
				frequencyOptions: [7,14,30,90],
				progressDataText: {},
			  	optionsUsers: [],
				selectedUser: '',
                intervalDays: {},
                subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                	calendar_period: false,
                	frequency_option: false,
                	progress_type: false
                },
                showObjectiveInput: false,
				objectiveOptions: [],
				objective: '',
				disabledPeriod: true,
				viewSelectUser: true,
                calendarCounts: 1,
                calendarCountsKeyResult: 1,
                totalDaysRange: 0,
                frequencyInterval: 7,
                errorsBe: {},
				disabledDatesUser: [],
				arrayTranslations: [
					'key-result.modify',
					'key-result.add',
					'key-result.form.name',
					'key-result.form.name-placeholder',
					'key-result.form.objective',
					'key-result.form.objective-choose',
					'key-result.form.objective-noresults',
					'key-result.form.person',
					'key-result.form.add_user',
					'key-result.form.person-choose',
					'key-result.form.person-noresults',
					'key-result.form.description',
					'key-result.form.description-placeholder',
					'key-result.form.progress',
					'progress_type_text.above',
					'progress_type_text.current_value',
					'progress_type_text.decrease',
					'progress_type_text.grow',
					'progress_type_text.under',
					'progress_type_text.value',
					'key-result.form.progress-choose',
					'key-result.form.frequency',
					'frequency-interval',
					'key-result.form.period',
					'key-result.form.select-days',
					'key-result.form.start-date',
					'key-result.form.end-date',
					'days',
					'key-result.form.modify',
					'key-result.form.save',
					'btn-submit.loading',
					'btn-submit.success',
					'btn-submit.error',
					'key-result.form.modify',
					'key-result.form.save'
				],
				helpData1: this.$store.getters['help_links/currentLink']('key_result_modal'),
				helpData2: this.$store.getters['help_links/currentLink']('key_result_modal_2'),
			};
		},
		props:{
			model: String,
			requiredData: Object,
			userData: Object,
			fromPage: String
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconDate,
			IconPeople,
			IconCircleCheck,
			ConfirmClose,
			KeyResultPeriodModal,
			TypeProgressResultKeyModal,
			IconProgressKr,
			IconRefresh,
			IconQuestion
		},
		validations: {
			key_result: {
				name: {required},
				dateRange:{
					start_date: {required},
					end_date: {required}
				},
				progressData: {required},
				frequencyData: {required}
			},
			selectedUser: {required},
			selectedProgress: {required},
			objective: {required}
		},
		async mounted() {
			if(Object.keys(this.requiredData).length === 0 && this.requiredData.constructor === Object){
				this.showObjectiveInput = true
				await this.getObjectives()
				this.objective = ''
			}else{
				this.objective = this.requiredData
				this.disabledPeriod = false
				var startDate = this.moment(this.objective.start_date)
				var endDate = this.moment(this.objective.end_date)

				var monthsDiff = (endDate.month() - startDate.month()) + 1

				this.calendarCounts =  monthsDiff >= 3 ? 3 : monthsDiff
			}

			if(this.model == ''){
				this.editAction = false
				this.getIntervalDataMounted()
			}else{
				await this.getKeyResultData()
			}

			if(!(Object.keys(this.userData).length === 0 && this.userData.constructor === Object)){
				this.viewSelectUser = false
				this.selectedUser = this.userData
			}

			await this.getUsers()

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	confirm_close: true
					}
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });

			this.$root.$on("visibilityHiddenKeyResult", (is_active) => {
				this.subModalShow = is_active;
				setTimeout(() => {
					if(is_active){
						$('.submodal-container').addClass('active');
					}else{
						$('.submodal-container').removeClass('active');					
					}
				}, 0)
			});

			this.$root.$on("refreshUsersListOnKeyResultModal", (user_detail) => {
				this.key_result.user_id = user_detail.id;
 				this.subModalShow = false;
 				$('.submodal-container').removeClass('active');
				this.getUsers(true);
			});
		},
		methods: {
			async getKeyResultData(){
				await axios.get('/key-results/'+ this.model +'/edit').then(({data}) => {
					this.key_result = data.data
				}).then(() => {
					this.key_result.dateRange = { start_date: this.key_result.start_date, end_date: this.key_result.end_date}
					this.checkKeyResult()
					this.editAction = true
				})
			},
			async getUsers(newWithUser = false){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true } })
				.then(({data}) => {
					this.optionsUsers = data.data.users

					if(this.editAction || newWithUser){
						this.selectedUser = this.optionsUsers.find(el => el.id == this.key_result.user_id);
					}
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			async getObjectives(){
				await axios.get('/objectives')
				.then(({data}) => {
					this.objectiveOptions = data.data
				});
			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true

					this.show_subm_modal = {
	                	confirm_close: true
					}
					setTimeout(() => {
	                	$('.submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				var btnSubmit = $('.button-submit')
				btnSubmit.attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					var btnSubmit = $('.button-submit')
					var btnSubmitLoader = $('.button-submit .loader')
					var btnSubmitText = $('.button-submit .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))
					if(this.editAction){
						this.updateKeyResult(e)
					}else{
						this.storeKeyResult(e)
					}
				}else{
					btnSubmit.attr('disabled', false)
				}
			},
			storeKeyResult(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				axios.post('/key-results/store', {
					name: this.key_result.name,
					description: this.key_result.description,
					user_id: this.selectedUser.id,
					objective_id: this.objective.id,
					start_date: this.moment(this.key_result.dateRange.start_date).format('YYYY-MM-DD'),
					end_date: this.moment(this.key_result.dateRange.end_date).format('YYYY-MM-DD'),
					measurement: this.key_result.frequencyData,
					measurement_frequency: 'weekly',
					progress_type: this.selectedProgress,
					unity: this.key_result.progressData.unit ? this.key_result.progressData.unit : '%',
					start_value: this.key_result.progressData.from ? this.key_result.progressData.from : 0,
					target: this.key_result.progressData.to ? this.key_result.progressData.to : 100
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					this.refreshPage()
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('key-result.form.modify') : this.$t('key-result.form.save'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			updateKeyResult(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				axios.patch('/key-results/' + this.key_result.id, {
					name: this.key_result.name,
					description: this.key_result.description,
					user_id: this.selectedUser.id,
					objective_id: this.objective.id,
					start_date: this.moment(this.key_result.dateRange.start_date).format('YYYY-MM-DD'),
					end_date: this.moment(this.key_result.dateRange.end_date).format('YYYY-MM-DD'),
					measurement: this.key_result.frequencyData,
					measurement_frequency: 'weekly',
					progress_type: this.selectedProgress,
					unity: this.key_result.progressData.unit ? this.key_result.progressData.unit : '%',
					start_value: this.key_result.progressData.from ? this.key_result.progressData.from : 0,
					target: this.key_result.progressData.to ? this.key_result.progressData.to : 100
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					this.refreshPage()
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$emit("toggle_modal");
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('key-result.form.modify') : this.$t('key-result.form.save'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			checkKeyResult(){
				this.frequencyInterval = parseInt(this.key_result.measurement)
				this.key_result.frequencyData = parseInt(this.key_result.measurement)
				
				this.key_result.progressData = {
					type: this.key_result.progress_type,
					from: this.key_result.start_value,
					to: this.key_result.target,
					unit: this.key_result.unity
				}
				this.progressDataText = this.key_result.progressData
				this.selectedProgress = this.key_result.progress_type

				var startDate = this.moment(this.key_result.dateRange.start_date)
				var endDate = this.moment(this.key_result.dateRange.end_date)

				var monthsDiffKeyResult = (endDate.month() - startDate.month()) + 1

				this.calendarCountsKeyResult =  monthsDiffKeyResult >= 3 ? 3 : monthsDiffKeyResult

				var diff_days = endDate.diff(startDate, 'days')

				var workDays = this.addWeekdays(startDate, diff_days);

				this.totalDaysRange = diff_days + 1
			},
			addWeekdays(startDate, days) {
			  var date = this.moment(startDate); // use a clone
			  var working_days = 0;
			  while (days >= 0) {
			    date = date.add(1, 'days');
			    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
			      working_days += 1;
			    }
			    days -= 1;
			  }
			  return working_days;
			},
			showSubModal(type){
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeSubModal(type){
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			getProgressData(value){
				this.closeSubModal('progress_type')

				this.key_result.progressData = JSON.parse(value)
				this.selectedProgress = this.key_result.progressData.type
				this.progressDataText = JSON.parse(value)
			},
			getIntervalData(value){
				this.closeSubModal('calendar_period')
				this.key_result.dateRange.start_date = JSON.parse(value).start_date
				this.key_result.dateRange.end_date = JSON.parse(value).end_date

				var start_date_moment = this.moment(this.key_result.dateRange.start_date)
				var end_date_moment = this.moment(this.key_result.dateRange.end_date)
				var monthsDiffKeyResult = (end_date_moment.month() - start_date_moment.month()) + 1

				this.calendarCountsKeyResult =  monthsDiffKeyResult >= 3 ? 3 : monthsDiffKeyResult

				this.totalDaysRange = end_date_moment.diff(start_date_moment, 'days') + 1
			},
			checkInputs(){
				var notEmpty = false

				if(this.key_result.name != '' 
					|| this.key_result.description != '' 
					|| this.key_result.dateRange.start_date != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			changeDisabledPeriod(selectedOption){
				if(selectedOption != ''){
					this.disabledPeriod = false
				}
			},
			clearRange(){
				this.key_result.dateRange = {start_date: '',end_date: '',}
			},
			clearObjective(){
				this.objective = ''
				this.key_result.dateRange = {start_date: '',end_date: '',}
				this.disabledPeriod = true
			},
			focusRequired(type){
				if(type == 'frequency_custom'){

					if(!this.selectedUser){
						$('#user_required').addClass('shake-anim')
						setTimeout(() => {
							$('#user_required').removeClass('shake-anim')
						}, 400)
					}

					if(this.key_result.dateRange.start_date == '' && this.key_result.dateRange.end_date == ''){
						$('#period_required').addClass('shake-anim')
						setTimeout(() => {
							$('#period_required').removeClass('shake-anim')
						}, 400)
					}

				}else{
					$('#' + type).addClass('shake-anim')
					setTimeout(() => {
						$('#' + type).removeClass('shake-anim')
					}, 400)
				}
			},
			refreshPage(){
				switch (this.fromPage) {
					case 'keyresult':
						this.$root.$emit('refreshObjectivePage')
						this.$root.$emit('refreshUserPage')
						break;
					case 'show_objective':
						this.$root.$emit('refreshObjectivePage')
						break;
					case 'show_user':
						this.$root.$emit('refreshUserPage')
						break;
					case 'tree':
						this.$root.$emit('refreshMasterGoalTree')
						break;
				}
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'keyresult');
			},
			changeActiveFrequency(option){
				this.frequencyInterval = option;
				this.key_result.frequencyData = option;
			},
			fullName({first_name, last_name, email}){
				return `${first_name} ${last_name} ${email}`
			},
			getIntervalDataMounted(){

				this.key_result.dateRange.start_date = this.objective.start_date
				this.key_result.dateRange.end_date = this.objective.end_date

				var start_date_moment = this.moment(this.key_result.dateRange.start_date)
				var end_date_moment = this.moment(this.key_result.dateRange.end_date)
				var monthsDiffKeyResult = (end_date_moment.month() - start_date_moment.month()) + 1

				this.calendarCountsKeyResult =  monthsDiffKeyResult >= 3 ? 3 : monthsDiffKeyResult

				this.totalDaysRange = end_date_moment.diff(start_date_moment, 'days') + 1
			},
			handleUserSelected(user){
				this.disabledPeriod = false;
				axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/${user.slug}/vacation-dates`, { params: { start_date: moment().format('YYYY-MM-DD'), end_date: moment().add(1, 'years').format('YYYY-MM-DD') } })
				.then(({data}) => {
					this.disabledDatesUser = data.data;
				})
			}
		}
	};
</script>