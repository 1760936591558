<template>
	<div class="submodal-tbf" id="submodal_error_max_leaders">
		<div class="header-submodal">
			<div class="image"><img src="/build/icons/warning.svg"></div>
			<div class="title">{{$t('subscription.leaders-max-reached-title')}}</div>
		</div>
		<div class="description-modal">{{$t('subscription.leaders-max-reached-description', {noLeaders: $auth.user().leader_limit })}}</div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="cancelClose">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('general.ok_thanks')}}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {

			};
		},
		async mounted() {
			
		},
		methods: {
			cancelClose(){
				this.$emit("cancel_close");
			},
		}
	};
</script>