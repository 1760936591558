<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Reset Pasword', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

                    <div class="content-icon reset">
                        <img src="/build/images/bird_key.png" class="reset"/>
                    </div>
                    <div class="title forgot">
                        <span>{{ $t('reset_password.title') }}</span>
                        <div class="close-btn" @click="redirect()">
                            <close-icon/>
                        </div>
                    </div>
                    <div class="container-content-modal">
                        <div class="form-section">
                            <div class="container-message forgot">
                                {{ $t('reset_password.description') }}
                            </div>

                            <div class="input-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error, 'view-tooltip': activeTooltip == 'email' }">
                                <div class="label-input">
                                    <label>{{$t('reset_password.email')}}</label>
                                    <div class="info-input" @click="activeTooltip == 'email' ? activeTooltip = '' : activeTooltip = 'email'">
                                    </div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-email /></div>
                                    <div class="icon-right" v-if="email != ''" @click.stop="email = ''"><close-icon /></div>
                                    <input type="email" :placeholder="$t('reset_password.email-placeholder')" class="input-text" v-model="email">
                                </div>
                                
                                <div class="tooltip-input">
                                    <div class="header">
                                        <div class="header-title">{{$t('reset_password.tooltip.email.header')}}</div>
                                        <div class="close-tooltip" @click="activeTooltip = ''">
                                            <close-icon/>
                                        </div>
                                    </div>
                                    <div class="description">{{$t('reset_password.tooltip.email.description')}}</div>
                                </div>
                            </div>

                            <div class="input-group-tbf w-tbf-100" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error, 'view-tooltip': activeTooltip == 'password' }">
                                <div class="label-input">
                                    <label>{{$t('reset_password.password')}}</label>
                                    <div class="error-msg" v-if="!$v.password.minLength">{{ $t('validator.password_min_length') }}</div>
                                    <div class="error-msg" v-else-if="!$v.confirm_password.sameAsPassword">{{ $t('validator.same_password') }}</div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-password /></div>
                                    <div class="icon-right" v-if="password != ''" @click.stop="password = ''"><close-icon /></div>
                                    <input type="password" :placeholder="$t('reset_password.password-placeholder')" class="input-text" v-model="password">
                                </div>
                                
                                <div class="tooltip-input">
                                    <div class="header">
                                        <div class="header-title">{{$t('reset_password.tooltip.password.header')}}</div>
                                        <div class="close-tooltip" @click="activeTooltip = ''">
                                            <close-icon/>
                                        </div>
                                    </div>
                                    <div class="description">{{$t('reset_password.tooltip.password.description')}}</div>
                                </div>
                            </div>

                            <div class="input-group-tbf w-tbf-100" v-bind:class="{has_error: $v.confirm_password.$error, complete: confirm_password == password && !$v.confirm_password.$error, 'view-tooltip': activeTooltip == 'confirm_password' }">
                                <div class="label-input">
                                    <label>{{$t('reset_password.password-confirm')}}</label>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-password /></div>
                                    <div class="icon-right" v-if="confirm_password != ''" @click.stop="confirm_password = ''"><close-icon /></div>
                                    <input type="password" :placeholder="$t('reset_password.password-confirm-placeholder')" class="input-text" v-model="confirm_password">
                                </div>
                                
                                <div class="tooltip-input">
                                    <div class="header">
                                        <div class="header-title">{{$t('reset_password.tooltip.confirm-password.header')}}</div>
                                        <div class="close-tooltip" @click="activeTooltip = ''">
                                            <close-icon/>
                                        </div>
                                    </div>
                                    <div class="description">{{$t('reset_password.tooltip.confirm-password.description')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action-form">
                        <button type="submit" class="button-tbf-blue button-submit" @click="resetPassword">
                            <div class="loader"></div>
                            <div class="text">{{ $t('reset_password.button-action') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CloseIcon from '../Icons/Close';
import IconInfo from "../Icons/Info"
import IconCircleCheck from "../Icons/CircleCheck"
import IconPassword from '../Icons/Password'
import IconEmail from '../Icons/Email'

import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: "",
            password: "",
            confirm_password: "",
            activeTooltip: "",
            arrayTranslations: [
                'reset_password.title',
                'reset_password.description',
                'reset_password.email',
                'reset_password.email-placeholder',
                'reset_password.tooltip.email.header',
                'reset_password.tooltip.email.description',
                'reset_password.password',
                'validator.password_min_length',
                'validator.same_password',
                'reset_password.password-placeholder',
                'reset_password.tooltip.password.header',
                'reset_password.tooltip.password.description',
                'reset_password.password-confirm',
                'reset_password.password-confirm-placeholder',
                'reset_password.tooltip.confirm-password.header',
                'reset_password.tooltip.confirm-password.description',
                'reset_password.button-action'
            ]
		}
    },
    components: {
        CloseIcon,
        IconPassword,
        IconInfo,
        IconEmail,
        IconCircleCheck
    },
    validations: {
        email: {required},
		password: {required,minLength: minLength(6)},
		confirm_password: {required, sameAsPassword: sameAs('password')}
	},
	methods: {
        resetPassword(e) {
            var btnSubmit = $('.button-submit')
            var btnSubmitLoader = $('.button-submit .loader')
            var btnSubmitText = $('.button-submit .text')

            this.$v.$touch()
            if(!this.$v.$invalid){
                btnSubmit.attr('disabled', 'disabled')

                btnSubmit.addClass('loading')
                btnSubmitLoader.addClass('onProgress')
                btnSubmitText.html(this.$t('btn-submit.loading'))

                axios.post("/auth/reset/password", {
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.confirm_password
                })
                .then(result => {
                    btnSubmitLoader.addClass('finish')
                    setTimeout(()=>{
                        btnSubmitText.html(this.$t('btn-submit.success'))
                        btnSubmit.addClass('completed')
                        btnSubmitLoader.removeClass('onProgress finish')
                        btnSubmit.removeClass('loading')
                        setTimeout(()=>{
                            btnSubmit.removeClass('completed')
                            btnSubmit.attr('disabled', false)
                            this.$router.push("/reset-success")
                        }, 1000)
                    }, 300)
                }).catch(error => {
                    btnSubmitLoader.addClass('finish')
                    setTimeout(()=>{
                        btnSubmitText.html(this.$t('btn-submit.error'))
                        btnSubmit.addClass('error')
                        btnSubmitLoader.removeClass('onProgress finish')
                        btnSubmit.removeClass('loading')
                        setTimeout(()=>{
                            btnSubmit.removeClass('error')
                            btnSubmitText.html(this.$t('reset_password.button-action'))
                            btnSubmit.attr('disabled', false)
                            this.success = false
                            this.has_error = true
                            this.errors = error.response.data.errors;
                        }, 1000)
                    }, 300)
                });
            }else{
                $(e.target).attr('disabled', false)
            }
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>