<template>
	<div class="container-modal">
		<div class="header-modal">
			<div class="tabs-title" v-if="$resize && $mq.above(660)">
				<div class="tab-item" @click="activateTab('updates')">
					<span>{{ $t('general.updates') }}</span>
				</div>
				<div class="tab-item" @click="activateTab('promises')">
					<span>{{ $t('general.promises') }}</span>
				</div>
				<div class="tab-item active" @click="activateTab('tasks')">
					<span>{{ $t('general.tasks') }}</span>
				</div>
				<div class="tab-item" @click="activateTab('comments')">
					<span>{{ $t('key-result-comments.title') }}</span>
				</div>
				<div class="tab-item">
					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">
						<icon-info />

						<template slot="popover">
							<div class="kr-title">{{ keyResult.name }}</div>
							<div class="kr-description">{{ keyResult.description }}</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.progress-type') }}</div>
								<div class="label-desc">{{ $t('progress_type_text_simple.' + keyResult.progress_type, { to: ($options.filters.numeral(parseFloat(keyResult.target), '0a')), unit: keyResult.unity, from: $options.filters.numeral(parseFloat(keyResult.start_value), '0a')}) }}</div>
							</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.frequency-interval') }}</div>
								<div class="label-desc">{{ $t('key-result-updates.interval-update-desc') }} {{ $tc('days', keyResult.measurement) }}</div>
							</div>

							<div class="kr-info">
								<div class="label">{{ $t('key-result-summary.period') }}</div>
								<div class="label-desc"><span class="date">{{ keyResult.start_date | moment('DD MMM') }}</span> {{ $t('key-result-summary.period-to') }} <span class="date">{{ keyResult.end_date | moment('DD MMM')}}</span></div>
							</div>
						</template>
					</v-popover>
				</div>
			</div>
			<div class="mobile-tabs" v-else>
				<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					{{ $t('general.tasks') }}
					<icon-arrow class="icon-arrow"/>
				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownFilter">
					<div class="dropdown-item" @click="activateTab('updates')">{{ $t('general.updates') }}</div>
					<div class="dropdown-item" @click="activateTab('promises')">{{ $t('general.promises') }}</div>
					<div class="dropdown-item" @click="activateTab('tasks')">{{ $t('general.tasks') }}</div>
					<div class="dropdown-item" @click="activateTab('comments')">{{ $t('key-result-comments.title') }}</div>
				</div>
			</div>

			<div class="actions">
				<div class="btn-blue-tbf add-btn btn-space-mr" @click="showAddTask" v-if="$route.name != 'public-master-goal' && keyResult.rights.can_create"><icon-plus class="icon-plus" /></div>

				<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="btn-space-mr" :delay="{show: 500, hide: 0}"  v-if="$route.name != 'public-master-goal' && keyResult.rights.can_create && parseInt(keyResult.percent) >= 100">
					<button class="btn-default-tbf finish-kr" v-bind:class="{finished: keyResult.status == 'finished'}" @click="completeKr"><icon-check /></button>

					<template slot="popover">
						<div class="simple-text">{{ keyResult.status == 'finished' ? $t('key-result.tooltip-not-finish-kr') : $t('key-result.tooltip-finish-kr') }}</div>
					</template>
				</v-popover>

				<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
			</div>
		</div>

		<div class="info-content">
			<div class="container-content-modal opacity-page" v-if="loaded">
				<div class="list-tasks">
					<div class="completed-tasks">
						{{$t('key-result-tasks.completed-tasks', { completed: completed_tasks, total: tasks.length})}} 
					</div>
					<div class="item-task new-task" v-bind:class="{active: showAddTaskInput}">
						<div class="checkbox-task">
							<div class="pending"></div>
						</div>
						<div class="name-input">
							<textarea 
							id="inputNewTask" 
							type="text" 
							v-model="newTaskName" 
							@keydown.enter.exact.prevent
							@keyup.enter.exact="createTask"
							:placeholder="$t('key-result-tasks.new-task')"></textarea>
						</div>
						<date-range-picker
							ref="picker"
							class="calendar-filter tasks"
							opens="left"
							:locale-data="calendarFilterSettings"
							:singleDatePicker="'single'"
							:showDropdowns="false"
							:ranges="false"
							:autoApply="true"
							:maxDate="moment(keyResult.end_date).format('YYYY-MM-DD')"
							:minDate="moment(keyResult.start_date).format('YYYY-MM-DD')"
							v-model="dateRange"
							>
							<template v-slot:input="picker">
								<div class="input-picker">
									<icon-calendar class="icon-calendar"/>
									<div class="date">{{ picker.endDate | moment('DD MMM YYYY') }}</div>
									<icon-arrow class="icon-arrow right"/>
								</div>
							</template>
						</date-range-picker>
						<div class="edit-task">
							<div class="actions-edit">
								<button id="buttonCreateTask" class="btn-default-tbf btn-save" @click="createTask">
									{{$t('key-result-tasks.save')}}
								</button>
								<button class="btn-delete" @click="showAddTaskInput = false"><icon-delete /></button>
							</div>
						</div>
					</div>
					<draggable v-model="tasks" @change="saveOrder()">
						<div class="item-task" v-bind:class="{complete: task.done, edit: editedTask == task.id}" v-for="task in tasks">
							<div class="checkbox-task" @click="$route.name != 'public-master-goal' ? updateTask(task, true) : ''">
								<div class="complete"><img src="/build/icons/check.svg"></div>
								<div class="pending"></div>
							</div>
							<div class="name-input">
								<input type="text" :id="'task_' + task.id" v-model="task.name" :placeholder="$t('key-result-tasks.new-task')" :disabled="editedTask == task.id ? false : true" @keyup.enter="editedTask == task.id ? updateTask(task, false) : ''">
							</div>
							<div v-if="editedTask == task.id">
								<date-range-picker
									ref="picker"
									class="calendar-filter tasks"
									opens="left"
									:locale-data="calendarFilterSettings"
									:singleDatePicker="'single'"
									:showDropdowns="fasle"
									:ranges="false"
									:autoApply="true"
									:maxDate="moment(keyResult.end_date).format('YYYY-MM-DD')"
									:minDate="moment(keyResult.start_date).format('YYYY-MM-DD')"
									v-model="editedDate"
									@update="updateTaskDeadline($event, task.id)"
									>
									<template v-slot:input="picker">
										<div class="input-picker">
											<icon-calendar class="icon-calendar"/>
											<div class="date">{{ picker.endDate | moment('DD MMM YYYY') }}</div>
											<icon-arrow class="icon-arrow right"/>
										</div>
									</template>
								</date-range-picker>
							</div>
							<div v-else class="deadline">
								{{ task.deadline | moment('DD MMM YYYY') }} 
							</div>
							<div class="edit-task" v-if="$route.name != 'public-master-goal' && ($resize && $mq.above(660))">
								<button class="edit-btn" @click="editItem(task)"><icon-edit/></button>
								<div class="actions-edit">
									<button class="btn-default-tbf btn-save" @click="updateTask(task, false)">
										{{$t('key-result-tasks.save')}}
									</button>
									<button class="btn-delete" @click="deleteItem('confirm_delete', task.id)"><icon-delete /></button>
								</div>
							</div>
						</div>
					</draggable>
				</div>
				<div class="empty-box" v-if="tasks.length == 0 && !showAddTaskInput">
					<div class="empty-row">
						<div class="title">
							{{ $t('key-result.no-tasks') }}
						</div>
						<div class="description" v-html="$t('key-result.no-tasks-description')">
						</div>
					</div>
				</div>
			</div>
			<div class="container-content-modal" v-else>
				<div class="list-tasks">
					<div class="item-task" v-for="n in 3">
						<div class="checkbox-task">
							<div class="pending"></div>
						</div>
						<div class="name-input">
							<div class="placeholder-loader" style="height: 21px; width: 135px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="submodal-container" v-if="subModalShow">
			<confirm-delete v-if="show_subm_modal.confirm_delete" nameItem="task" @cancel_delete="closeDelete('confirm_delete')" @delete_item="confirmDelete('confirm_delete')"></confirm-delete>
			<message-multiple-store-tasks v-if="show_subm_modal.multiple_store" :number="numberOfTasks" @singleStore="storeTask(true)" @multipleStore="storeMultipleTask" />
		</div>
	</div>
</template>

<script type="text/javascript">
	import ConfirmDelete from "../SubModals/ConfirmDelete"
	import MessageMultipleStoreTasks from "../SubModals/MessageMultipleStoreTasks"
	import IconPlus from "../Icons/Plus"
	import IconEdit from "../Icons/Edit"
	import IconArrow from "../Icons/Arrow"
	import IconInfo from "../Icons/Info"
	import IconDelete from "../Icons/Delete"
	import IconList from '../Icons/List'
	import IconCheck from "../Icons/Check"
	import IconCalendar from '../Icons/Date'
	import draggable from 'vuedraggable'

	export default {
		data() {
			return {
				loaded: false,
				tasks: {},
				subModalShow: false,
                show_subm_modal: {
                	confirm_delete: false,
                	multiple_store: false
                },
                deleteItemId: '',
                newTaskName: '',
                numberOfTasks: 0,
                hoverTask: '',
                editedTask: '',
                completed_tasks: 0,
                showAddTaskInput: false,
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
				dateRange: {
					startDate: this.keyResult.start_date,
					endDate: this.keyResult.start_date
				},
				editedDate: {
					startDate: this.keyResult.start_date,
					endDate: this.keyResult.start_date
				}
			};
		},
		props: {
			keyResult: Object,
			keyResultId: String,
			Uuid: String
		},
		components: {
			ConfirmDelete,
			MessageMultipleStoreTasks,
			IconPlus,
			IconEdit,
			IconCheck,
			IconArrow,
			IconDelete,
			IconList,
			IconInfo,
			IconCalendar,
			draggable
		},
		async mounted() {
			await this.getTasks()

            this.$root.$on("reload_modal_key_result", () => {
				this.getTasks()
            });
		},
		methods: {
			async getTasks(){
				await axios.get('/key-results/public/' + this.Uuid + '/tasks')
				.then(({data}) => {
					this.tasks = data.data

					this.completed_tasks = this.tasks.reduce(function(n, val) {
						return n + (val.done === 1);
					}, 0);
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			deleteItem(type, itemId){
				this.deleteItemId = itemId
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeDelete(type){
				this.deleteItemId = ''
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			confirmDelete(type){
				axios.delete('/tasks/' + this.deleteItemId)
				.then(() => {
					this.deleteItemId = ''
					$("#submodal_" + type).removeClass("active")
					this.getTasks()
					this.$emit('refreshModalData')
					setTimeout(() => {
						$('.submodal-container').removeClass('active')
						setTimeout(() => {
							this.subModalShow = false;
							this.show_subm_modal[type] = false
						}, 200);
					}, 200);
				})
			},
			createTask(e){
				this.numberOfTasks = this.newTaskName.split(/\r\n|\r|\n/).filter(el =>  el != "" ).length; 
				if(this.numberOfTasks > 1){
					this.subModalShow = true
					this.show_subm_modal.multiple_store = true
					setTimeout(() => {
		            	$('.submodal-container').addClass('active');
		            	setTimeout(() => {
		            		$('#submodal_multiple_store').addClass('active');
						}, 200);
		            }, 0);
				} else {
					this.storeTask();
				}
			},
			storeTask(fromMultiple = false){
				var btnSubmit = $('#buttonCreateTask')

				this.newTaskName.replace(/(\r\n|\n|\r)/gm, " ")

				axios.post('/' + this.keyResultId + '/tasks/store', {
					name: this.newTaskName,
					key_result_id: this.keyResultId,
					deadline: moment(this.dateRange.endDate).format('YYYY-MM-DD')
				}).then(({data}) => {
					this.getTasks()
					this.newTaskName = ''
					this.$emit('refresh')
					this.$emit('refreshModalData')
				})
				.finally(() => {
					btnSubmit.attr('disabled', false)

					if(fromMultiple){
						$("#submodal_multiple_store").removeClass("active")
						setTimeout(() => {
							$('.submodal-container').removeClass('active')
							setTimeout(() => {
								this.subModalShow = false;
								this.show_subm_modal.multiple_store = false
							}, 200);
						}, 200);
					}
				})
			},
			storeMultipleTask(){
				var btnSubmit = $('#buttonCreateTask')

				this.newTaskName.split(/\r\n|\r|\n/).filter(el =>  el != "" ).map(el => {
					axios.post('/' + this.keyResultId + '/tasks/store', {
						name: el,
						key_result_id: this.keyResultId,
						deadline: moment(this.dateRange.endDate).format('YYYY-MM-DD')
					})
				})

				$("#submodal_multiple_store").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.multiple_store = false
					}, 200);
				}, 200);

				setTimeout(() => {
					this.getTasks()
					this.newTaskName = ''
					this.$emit('refresh')
					this.$emit('refreshModalData')
					btnSubmit.attr('disabled', false)
				}, 200);
			},
			updateTask(task, checkTask){
				if(checkTask){
					var checkTaskValue = 0;
					if(task.done == 1){
						this.completed_tasks -= 1
						checkTaskValue = 0
						this.tasks.find(el => el.id == task.id).done = checkTaskValue
					}else{
						this.completed_tasks += 1
						checkTaskValue = 1
						this.tasks.find(el => el.id == task.id).done = checkTaskValue
					}
				}else{
					var checkTaskValue = task.done
				}

				axios.patch('/tasks/' + task.id, {
					name: task.name,
					key_result_id: task.key_result_id,
					deadline: moment(this.editedDate.endDate).format('YYYY-MM-DD'),
					done: checkTaskValue
				}).then(() => {
					this.$emit('refresh')
					this.$emit('refreshModalData')
				})

				this.editedTask = ''
			},
			closeModal(){
				this.$emit("toggle_modal_tab");
			},
			showAddTask(){
				this.showAddTaskInput = true
				setTimeout(() => {
					$('#inputNewTask').focus()
				}, 100)
			},
			editItem(task){
				this.editedTask = task.id
				this.editedDate.startDate = task.deadline
				this.editedDate.endDate = task.deadline
				setTimeout(() => {
					$('#task_'+ task.id).focus()
				}, 100)
			},
			activateTab(type){
				this.$emit("changeTab", type);
			},
			completeKr(e){
				$(e.target).attr('disabled', 'disabled')

				var statusKr = this.keyResult.status == 'finished' ? 'active' : 'finished'

				axios.post(`/key-results/${this.keyResult.id}/update-status`, { status: statusKr })
				.then(({data}) => {
					$(e.target).addClass('completed')
					setTimeout(() => {
						$(e.target).removeClass('completed')
						this.$emit('refreshModalData')
						this.$emit('refresh')
					}, 1500)
				})
				.finally(() => {
					setTimeout(() => {
						$(e.target).attr('disabled', false)
					}, 2000)
				})
			},
			updateTaskDeadline(e, taskId){
				this.tasks.find(el => el.id == taskId).deadline = moment(e.endDate).format('YYYY-MM-DD')
			},
			saveOrder(){
				var reorder = this.tasks.map((el, index) => { return {id: el.id, order_no: index} })

				axios.post(`/${this.keyResult.id}/tasks/reorder`, { reorder: reorder})
			}
		}
	};
</script>