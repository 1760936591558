<template>
	<div class="template-public">
  		<router-view/>

		<modal-translate id="modal_translate" v-if="show_modal.translate" :component="componentTranslation" :translationData="arrayTranslationKeys" @close_modal="closeModal('translate')"/>
    </div>
</template>

<script type="text/javascript">
	import ModalTranslate from "../General/ModalTranslate"

	export default {
		data() {
			return {
				show_modal: {
					translate: false
				},
				arrayTranslationKeys: [],
				componentTranslation: ''
			};
		},
		components: {
			ModalTranslate
		},
		mounted() {
			this.$root.$on('open_modal_translation', (component, arrayData) => {
				this.show_modal.translate = true
				this.arrayTranslationKeys = arrayData
				this.componentTranslation = component
				setTimeout(() => {
					$("#modal_translate").addClass("active");
				}, 0);
			})

			this.checkLanguage();
		},
		methods: {
			closeModal(type = false){
				$("#modal_" + type).removeClass("active")
				setTimeout(() => {
					this.show_modal[type] = false
				}, 200);
			},
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
		}
	};
</script>