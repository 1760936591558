<template>
	<div class="container-modal form-modal" id="step1">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register 1', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

        <div class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="container-holder">
			<div class="header-modal">
				<div class="title">{{ $t('register.title_step_1') }}</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.first_name.$error, complete: user.first_name != '' && !$v.user.first_name.$error}">
							<div class="label-input">
								<label>{{ $t('register.first_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.first_name')" class="input-text" v-model="user.first_name" ref="first_input">
							</div>
						</div>
						
						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.last_name.$error, complete: user.last_name != '' && !$v.user.last_name.$error}">
							<div class="label-input">
								<label>{{ $t('register.last_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.last_name')" class="input-text" v-model="user.last_name" ref="second_input">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.user.email.$error || mail_exists, complete: user.email != '' && !$v.user.email.$error && !mail_exists}">
							<div class="label-input">
								<label>{{$t('register.email')}}</label>
								<div class="error-msg" v-if="mail_exists">{{ $t('validator.email_already_exists') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-email /></div>
								<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><close-icon /></div>
								<input type="email" :placeholder="$t('register.email-placeholder')" class="input-text" v-model="user.email" ref="third_input">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.phone.$error, complete: user.phone != '' && !$v.user.phone.$error}">
							<div class="label-input">
								<label>{{$t('register.phone')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-phone /></div>
								<div class="icon-right" v-if="user.phone != ''" @click.stop="user.phone = ''"><close-icon /></div>
								<input type="number" :placeholder="$t('register.phone-placeholder')" class="input-text phone" v-model="user.phone" ref="fourth_input">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label>{{$t('register.country')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-globe /></div>
								<multiselect 
									class="select-tags-tbf hide-tags"
									v-model="user.country"
									:options="optionsCountries"
									:allow-empty="true"
									:show-labels="false"
									:multiple="false" 
									:close-on-select="true"
									:placeholder="$t('register.choose_country')"
									track-by="iso" 
									label="name"
									:maxHeight="250"
									openDirection="top"
									>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.user.password.$error, complete: user.password != '' && !$v.user.password.$error }">
							<div class="label-input">
								<label>{{$t('register.password')}}</label>
	                            <div class="error-msg pwd-error-msg" v-if="!$v.user.password.minLength">{{ $t('validator.password_min_length') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="user.password != ''" @click.stop="user.password = ''"><close-icon /></div>
								<input type="password" :placeholder="$t('register.password-placeholder')" class="input-text" v-model="user.password" ref="fourth_input">
							</div>
						</div>
					</div>
					<div class="submit-form">
						<button class="button-tbf-blue button-submit" @click="goNextStep">
							<div class="loader"></div>
							<div class="text">{{$t('register.button-action')}}</div>
						</button>
					</div>

					<!-- <div class="existing-account">
						<div>
							{{$t('register.account_ok')}} <router-link :to="{path: '/login' }" class="to-login">{{$t('register.login')}}</router-link>
						</div>
					</div> -->
				</div>
			</div>
			
		</div>

	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../Animations/tbfdigital_logo.json';
	import CloseIcon from '../Icons/Close'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconGlobe from "../Icons/Globe"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import InformationIcon from '../Icons/Information'
	import IconEmail from '../Icons/Email'
	import IconPassword from '../Icons/Password'
	import IconPhone from '../Icons/Phone'

	import { required, requiredIf, minLength, email, sameAs } from 'vuelidate/lib/validators'
	const isPhone = (value) => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				value_language: '',
				error: false,
				optionsLanguage: [],
				errorData: [],
				mail_exists: false,
				optionsCountries: [],
				arrayTranslations: [
					'register.title_step_1',
					'register.last_name',
					'register.first_name',
					'register.email',
					'register.email-placeholder',
					'register.password',
					'validator.password_min_length',
					'register.button-action'
				]
			}
		},
		props: {
			user: Object
		},
		components: {
			CloseIcon,
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			InformationIcon,
			IconEmail,
			IconPassword,
			IconPhone,
			IconGlobe,
			Lottie
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				email: {required, email},
				phone: {
					phoneValid:isPhone
				},
				password: {required,minLength: minLength(6)},
				// confirm_password: {required, sameAsPassword: sameAs('password')}
			}
		},
		created() {
		},
		async mounted() {
			await this.getLanguages();
			await this.getCountries()
			this.user.has_company = 1;
			this.user.referral_uuid = this.$route.query.referral ? this.$route.query.referral : false
			this.logoAnim();
		},
		methods: {
			async getLanguages(){
				await axios.get('/languages').then(({data}) => {
					this.optionsLanguage = data.data
					this.value_language = this.optionsLanguage.find(el => el.name == (this.$route.query.lang ? this.$route.query.lang : 'ro'))
					this.user.language = this.value_language.name
				})
			},
			async getCountries(){
				await axios.get('/countries')
				.then(({data}) => {
					for (var key of Object.keys(data.data)){
						this.optionsCountries.push({iso: key, name: data.data[key]})
					}
				})
			},
			goNextStep(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				
				this.$v.$touch()
				if(this.$v.$invalid){
					$(e.target).attr('disabled', false)
				}else{
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					var checkEmail = false
					axios.get('/email-validation', { params: {email: this.user.email}}).then(({data}) => {
						if(!data.data.is_valid){
							btnSubmitLoader.addClass('finish')
							setTimeout(()=>{
								btnSubmitText.html(this.$t('btn-submit.error'))
								btnSubmit.addClass('error')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('error')
									this.errorData['email'] = true
									this.mail_exists = true
									btnSubmitText.html(this.$t('register.button-action'))
									btnSubmit.attr('disabled', false)
								}, 1000)
							}, 300)
							
						}else{
							btnSubmitLoader.addClass('finish')
							setTimeout(()=>{
								btnSubmitText.html(this.$t('btn-submit.success'))
								btnSubmit.addClass('completed')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('completed')
									btnSubmit.attr('disabled', false)
									this.$emit("next_step", {user: this.user, next_step: 12});
								}, 1000)
							}, 300)
						}
					})
				}

			},
			selectLanguage(value, id){
				this.user.language = value.name
			},
			closeModal(){
				this.$router.push("/login");
			},
			checkInputs(){
				var notEmpty = false;

				if(this.user.first_name != '' 
					|| this.user.last_name != '' 
				){
					notEmpty = true
				}

				return notEmpty
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			toLogin() {
				this.$router.push("/login")
			}
		}
	}
</script>