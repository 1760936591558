<template>
	<div class="modal_info modal_delete">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Delete', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="header-submodal">
			<div class="image"><img src="/build/icons/trash-icon.svg"></div>
			<div class="title">{{ $t('delete.title') }}</div>
		</div>
		<div class="description-modal">
			{{ type == 'master_goal' ? $t('delete.description_master_goal')  : $t('delete.description',{text: $t('delete.' + type)}) }}
			<!-- <br>{{ nameItem }} -->
		</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelDelete">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('delete.cancel') }}
			</button>
			<button id="buttonSubmitDelete" class="btn-red-tbf button-action" @click="submitDelete">
				<div class="loader"></div>
				<div class="text">{{ $t('delete.delete') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				nameItem: '',
				arrayTranslations: [
					'delete.cancel',
					'delete.delete',
					'delete.description',
					'delete.description_master_goal',
					'delete.kr',
					'delete.master_goal',
					'delete.objective',
					'delete.title',
					'delete.user'
				]
			};
		},
		props: {
			model: Object,
			type: String,
			from: String
		},
		mounted() {
			switch (this.type) {
				case 'kr':
					this.nameItem = this.model.name
					break;
				case 'objective':
					this.nameItem = this.model.name
					break;
				case 'user':
					this.nameItem = this.model.first_name + ' ' + this.model.last_name
					break;
				case 'master_goal':
					this.nameItem = this.model.name
					break;
				case 'tag':
					this.nameItem = this.model.name
					break;
				case 'role':
					this.nameItem = this.model.name
					break;
			}
		},
		methods: {
			cancelDelete(){
				this.$emit("close_modal");
			},
			submitDelete(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.type) {
					case 'kr':
						this.deleteKr()
						break;
					case 'objective':
						this.deleteObjective()
						break;
					case 'user':
						this.deleteUser()
						break;
					case 'master_goal':
						this.deleteMasterGoal()
						break;
					case 'role':
						this.deleteRole()
						break;
				}
			},
			deleteKr(){
				axios.delete('/key-results/' + this.model.id)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
			},
			deleteObjective(){
				axios.delete('/objectives/' + this.model.slug)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
			},
			deleteMasterGoal(){
				axios.delete('/master-goals/' + this.model.slug)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
			},
			deleteUser(){
				axios.delete('/people/' + this.model.slug)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
			},
			deleteRole(){
				axios.delete('/tags/' + this.model.id)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				})
			},
			refreshPage(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				switch (this.from) {
					case 'daily_plan':
						this.$root.$emit('refreshPlanOfDay')
						break;
					case 'objectives':
						this.$root.$emit('refreshObjectivesList')
						break;
					case 'show_objective':
						this.$root.$emit('refreshObjectivePage')
						break;
					case 'users':
						this.$root.$emit('refreshUsersList')
						this.$auth.fetch()
						break;
					case 'show_user':
						this.$root.$emit('refreshUserPage')
						break;
					case 'master_goals':
						this.$root.$emit('refreshMasterGoalsList')
						break;
					case 'index_roles':
						this.$root.$emit('refreshRolesIndex')
						break;
					case 'tree':
						this.$root.$emit('refreshMasterGoalTree')
						break;
				}
				btnSubmitLoader.classList.add('finish')
				setTimeout(()=>{
					btnSubmitText.innerHTML = this.$t('btn-submit.success')
					btnSubmit.classList.add('completed')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('completed')
						btnSubmitText.innerHTML = this.$t('delete.delete')
						this.$emit("close_modal");
					}, 1000)
				}, 300)
			}
		}
	};
</script>