<template>
	<div>
		<!-- objectives list -->
		<section class="list-items-section tab-container active" v-if="data.list.length">
			<div class="count-list">
				<div class="space-left"></div>
				<div class="content-row">
					<div class="total-items">{{ $t('general.total_items') }} <span class="number">{{ data.totalItems }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>
			
			<div class="group_items" v-for="objective in data.list">
				<div class="space-left" @click="showObjective(objective.slug)">
					<icon-arrow class="icon-arrow right"/>
				</div>
				<div class="content-row" @click="showObjective(objective.slug)">
					<div class="row-tbf action-row">
						<div class="column-tbf icon-title obj obj-icon-width" v-if="$resize && $mq.above(770)">
							<icon-objectives-list />
						</div>

						<router-link :to="{ name: 'show-objective', params: { slug: objective.slug }, query: { form_user: data.userSlug}}" class="column-tbf row-title obj-user-name-width">
							<div class="text"><img class="attention-update" src="/build/icons/attention-square.svg" v-if="$resize && $mq.below(769) && (objective.needs_an_update == 'today' || objective.needs_an_update == 'past')">{{ objective.name }}</div>
						</router-link>

						<div class="column-tbf column-value obj-user-kr-width" v-if="$resize && $mq.above(1030)">
							<div class="label">{{ $t('objectives.key-results')}}</div>
							<div class="value"><img class="circle-info" src="/build/icons/circle-info-orange.svg" v-if="objective.needs_an_update == 'today' || objective.needs_an_update == 'past' || objective.need_evaluate_promise"> {{  $t('objectives.key-results-progress', {n: objective.key_results_in_progress, total: objective.key_results_count }) }}</div>
						</div>

						<div class="column-tbf column-value obj-user-deadline-width" v-if="$resize && $mq.above(770)">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
								<div class="label">{{ moment(objective.start_date) >= moment() ? $t('objectives.start_on') : $t('objectives.deadline')}}</div>

								<div class="value" v-if="objective.percent >= 100"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $t('status.completed') }}</div>
								<div class="value" v-else-if="diffDays(objective.end_date) < 0"><img class="circle-info" src="/build/icons/circle-info-red.svg"> {{ $t('show-user.outdated') }}</div>
								<div class="value" v-else-if="moment(objective.start_date) >= moment()">{{ objective.start_date | moment('DD/MM/YYYY') }}</div>
								<div class="value" v-else>{{ diffWeeksAndDays(objective.end_date) }}</div>

								<template slot="popover">
									<div class="simple-text">
										{{ objective.end_date | moment('D MMMM YYYY') }}
									</div>
								</template>
							</v-popover>
						</div>

						<div class="column-tbf item-value obj-user-progress-width" v-if="$resize && $mq.above(600)">
							<div class="top-data">
								<div class="current-value">
									<div class="value" v-if="moment(objective.start_date) >= moment()">0</div>
									<div class="value" v-else>{{ parseInt(objective.percent).toString() + '%' }}</div>
								</div>
								<div class="info"><icon-info class="icon-info"/></div>
							</div>
							<div class="progress-bar-tbf" v-bind:class="{disabled: moment(objective.start_date) > moment()}">
								<div class="complete-progress-tbf" v-bind:class="{pink: (diffDays(objective.end_date) < 1 && objective.percent != 100), green: objective.percent >= 100}" :style="'width: calc(2px + ' + (parseInt(objective.percent) < 1 ? '2px' : objective.percent + '%') + ');'"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right">
					<div class="dots-edit" :id="'edit-objective-' + objective.id" v-click-outside="hideDropdownObj" v-if="objective.rights.edit || objective.rights.delete">
						<div class="dropdown edit-item-dropdown">
							<div class="overlay-button" @click.stop="showDropdownObj(objective.id)"></div>
							<div class="edit-item-button" :id="'dropdownEditObjective'+objective.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<icon-edit-dots />
							</div>
							<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenuObjective'+ objective.id " :aria-labelledby="'dropdownEditObjective'+objective.id">
								<div class="dropdown-item" @click.stop="showModal('objective', objective.slug)" v-if="objective.rights.edit">{{$t('show-user.edit')}}</div>
								<div class="dropdown-item" @click.stop="openModalV2('delete', objective, 'objective', 'show_user')" v-if="objective.rights.delete">{{$t('show-user.delete')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- end objectives list -->

		<!-- no objectives message -->
		<section class="list-items-section tab-container active" v-else>
			<div class="group_items">
				<div class="space-left"></div>
				<div class="empty-row mrgn">
					<div class="title single" v-html="$t('show-user.no-objective', { name: data.userName })">
					</div>
					<div class="description wide" v-html="$t('show-user.desc-no-objective')">
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</section>
		<!-- END no objectives message -->
	</div>
</template>

<script type="text/javascript">
	import IconObjectivesList from '../../Icons/ObjectivesList'
	import IconInfo from '../../Icons/Info'
	import IconArrow from '../../Icons/Arrow'
	import IconEditDots from '../../Icons/EditDots'

	export default {
		props: {
			data: Object
		},
		data() {
			return {
			};
		},
		components: {
			IconObjectivesList,
			IconInfo,
			IconArrow,
			IconEditDots
		},
		mounted() {

		},
		methods: {
			showObjective(objectiveSlug){
				this.$router.push({ name: 'show-objective', params: { slug: objectiveSlug }, query: { form_user: this.data.userSlug}})
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			diffWeeksAndDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');

				var diff = moment.duration(b.diff(a));
				
				return diff.asDays() > 7 ? `${this.$tc('weeks', Math.floor(diff.asWeeks()))} ${diff.days()%7 != 0 ? this.$tc('days', diff.days()%7) : ''}` : this.$tc('days', diff.days()%7) ;  
			},
			showDropdownObj(itemId){
				if(itemId == this.hoverObjective){
					$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}else{
					this.hoverObjective = itemId
					setTimeout(() => {
						$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					}, 0)
				}
			},
			hideDropdownObj(){
				if(this.hoverObjective != ''){
					$('#dropdownEditObjective' + this.hoverObjective).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}
			},
			showModal(type, model = false, requiredData = false, userData = false){
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'show_user');
				this.hideDropdownObj()
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdownObj()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
		}
	};
</script>