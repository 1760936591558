import Vue from 'vue'

import auth                  from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer      from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google    from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook  from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';

driverOAuth2Google.params.client_id = '547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com';
driverOAuth2Facebook.params.client_id = '196729390739201';

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }
    },
    options: {
        rolesKey: 'rights',
        notFoundRedirect: {name: 'not-exist'},
        rememberKey: 'auth_remember_digital',
        staySignedInKey: 'auth_stay_signed_in_digital',
        tokenDefaultKey: 'auth_token_digital',
        tokenImpersonateKey: 'auth_token_impersonate_digital',
        authRedirect: { path: '/login'},
        stores: ['cookie'],
        cookie: {
            Path: '/',
            Domain: process.env.VUE_APP_MAIN_DOMAIN,
            Expires: 12096,
            Secure: false,
            SameSite: 'Lax',
            'Max-Age': 2592000
        },
        logoutData: {
            redirect: '/login',
            makeRequest: true
        },
        loginData: {redirect: ''}
    }
});


import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'en' });

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import autosize from 'v-autosize/dist/plugin'
Vue.use(autosize);

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.directive('click-outside',
{
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

import VueMoment from 'vue-moment'
import momentTz from 'moment-timezone'
window.moment = require('moment');
window.moment.locale('ro');
window.moment.locale('ro', {
  relativeTime: {
    future: '+ %s',
  }
});

Vue.use(VueMoment, {
    moment,
    momentTz
});
Vue.prototype.moment = moment;

import FunctionalCalendar from 'vue-functional-calendar'
Vue.use(FunctionalCalendar, {
    dayNames: moment.weekdaysMin(true),
    monthNames: moment.months(),
    shortMonthNames: moment.monthsShort()
});

import VueSvgGauge from 'vue-svg-gauge'
Vue.use(VueSvgGauge)

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
Vue.component('date-range-picker', DateRangePicker)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import { MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns';
Vue.use(MultiSelectPlugin);

import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
MultiSelect.Inject(CheckBoxSelection);

import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);