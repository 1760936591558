<template>
	<div class="modal_info">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Payment Error', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/payment-problem-icn.svg"></div>
			<div class="title">{{ $t('subscription.error_payment_large') }}</div>
		</div>
		<div class="description-modal">{{ $auth.user().is_entrepreneur ? $t('subscription.grace_period_desc') : $t('subscription.grace_period_desc_user') }}</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="closeModal" v-if="!$auth.user().is_entrepreneur">
				{{ $t('general.ok_thanks') }}
			</button>
			<button class="btn-blue-tbf button-action" @click="changeModal" v-if="$auth.user().is_entrepreneur">
				{{ $t('subscription.change_method') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				nameItem: '',
				arrayTranslations: [
					'subscription.error_payment_large',
					'subscription.grace_period_desc',
					'subscription.change_method'
				]
			};
		},
		props: {
			type: String,
		},
		mounted() {
			
		},
		methods: {
			changeModal(){
				window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;
				
				this.$root.$emit('change_modal', 'change_subscription');
			},
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>