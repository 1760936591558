<template>
    <div class="system-message">
        <div class="content">
            <div class="icon not-paid">
                <img src="/build/icons/not-allowed.svg">
            </div>
            <div class="title">
                {{ $t('not-allowed.title') }}
            </div>
            <div class="description">
                {{ $t('not-allowed.description') }}
            </div>
            <div class="action-button">
                <a v-if="$auth.check() && $auth.user().instance_id" class="button-tbf-blue" :href="`${organigram_fe_link}/dashboard`">
                    <span class="text">{{$t('not-allowed.button')}}</span>
                </a>
                <router-link v-else :to="{name: 'login' }">
                    <span class="text">{{$t('not-found.button')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data(){
            return{
                organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK
            }
        },
        components: {
        },
        methods: {
        }
    }
</script>