<template>
	<div class="submodal-tbf submodal-create-update">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Create Update', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-content-modal">
			<div class="header-modal">
				<div class="title">{{ $t('create-update.title') }} {{ moment().format('DD MMM') }}</div>
				<div class="actions">
					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="form-section box-update-key-result">
				<div class="input-group-tbf update-kr">
					<div class="top-description" v-html="$t('key-result.create-updates-description-detailed', {in_the_past: diffDaysFromCreate(keyResult.start_date), last_update_value: (keyResult.last_update_value % 1 != 0 ? $options.filters.numeral(keyResult.last_update_value, '0,0.00') : $options.filters.numeral(keyResult.last_update_value, '0,0')) + keyResult.unity, text: $t('progress_type_text.' + keyResult.progress_type, { to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target, '0,0.00') : $options.filters.numeral(keyResult.target, '0,0'), unit: keyResult.unity, from: keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value, '0,0.00') : $options.filters.numeral(keyResult.start_value, '0,0')}).toLowerCase()})"></div>
				</div>
				<div class="input-group-tbf update-kr" v-bind:class="{has_error: $v.update_key_result.value.$error, complete: update_key_result.value != '' && !$v.update_key_result.value.$error}">
					<div class="label-input">
						<label>{{ $t('update-key-results.new-value') }} ({{ keyResult.unity }})</label>

					</div>
					<div class="input-box">
						<input type="number" :placeholder="$t('update-key-results.new-value') + ' ('+ keyResult.unity +')'" class="input-update-kr" min="0" v-model="update_key_result.value" ref="first_input">
						<div class="calculate-new-percent">
							<img src="/build/icons/new-percent.svg">
							<span class="value">{{ diffPercent(update_key_result.value) }}</span>
						</div>
					</div>
				</div>

				<div class="input-group-tbf update-kr" v-bind:class="{has_error: $v.update_key_result.description.$error, complete: update_key_result.description != '' && !$v.update_key_result.description.$error}">
					<div class="label-input">
						<label>{{ $t('update-key-results.description') }}</label>

					</div>
					<div class="input-box">
						<textarea class="input-textarea" :placeholder="$t('update-key-results.description-placeholder')" v-model="update_key_result.description" v-autosize  rows="3"></textarea>
					</div>
				</div>

				<div class="input-group-tbf" v-bind:class="{has_error: $v.update_key_result.degree_of_confidence.$error, complete: update_key_result.degree_of_confidence != '' && !$v.update_key_result.degree_of_confidence.$error}">
					<div class="label-input">
						<label>{{ $t('update-key-results.trust-grade') }}</label>

					</div>
					<div class="options-degree">
						<div class="option" v-for="optionDegree in optionsDegreeConfidence" @click="update_key_result.degree_of_confidence = optionDegree" v-bind:class="{selected: optionDegree == update_key_result.degree_of_confidence}">
							<img :src="'/build/icons/mood-trs-' + optionDegree + '.svg'" class="active-icon">
							<img :src="'/build/icons/mood-' + optionDegree + '-bw.svg'" class="simple-icon">
							<div class="name">{{ $t('mood_type.' + optionDegree) }}</div>
						</div>
					</div>
				</div>

				<div class="submit-update">
					<button class="btn-default-tbf cancel" @click="closeModal">{{ $t('update-key-results.cancel') }}</button>
					<button class="btn-blue-tbf submit" @click="submitUpdate">{{ $t('update-key-results.update') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconArrow from "../Icons/Arrow"
	import IconClose from "../Icons/Close"
	import IconCheck from "../Icons/Check"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"
	import { required, decimal } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				update_key_result: {
					value: '',
					description: '',
					degree_of_confidence: ''
				},
                optionsDegreeConfidence: ["uncertain","not_very_sure","sure"],
                arrayTranslations: [
	                'create-update.title',
	                'key-result.create-updates-description-detailed',
	                'update-key-results.new-value',
	                'update-key-results.description',
	                'update-key-results.description-placeholder',
	                'update-key-results.trust-grade',
	                'mood_type.not_very_sure',
	                'mood_type.sure',
	                'mood_type.uncertain',
	                'update-key-results.cancel',
	                'update-key-results.update'
                ]
			};
		},
		components: {
			IconPlus,
			IconArrow,
			IconClose,
			IconCheck,
			IconInfo,
			IconCircleCheck,
		},
		validations: {
			update_key_result: {
				value: {required, decimal},
				description: {required},
				degree_of_confidence: {required}
			}
		},
		props:{
			keyResult: Object
		},
		async mounted() {

		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			diffPercent(value){
				if(value){
					var percent = 0;
					var diffPercentVal = 0;
					switch (this.keyResult.progress_type) {
						case 'grow':
							percent = 100 - ((this.keyResult.target - value) / ((this.keyResult.target - this.keyResult.start_value) / 100));
							diffPercentVal = percent.toFixed(2) - this.keyResult.percent;
							diffPercentVal = diffPercentVal.toFixed(2);
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						case 'decrease':
							percent = 100 - ((value - this.keyResult.target) / ((this.keyResult.start_value - this.keyResult.target) / 100));
							diffPercentVal = percent.toFixed(2) - this.keyResult.percent;
							diffPercentVal = diffPercentVal.toFixed(2);
							return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';

						// case 'above':
						// 	percent = value > parseInt(this.keyResult.target) ? 100 : 0;
						// 	diffPercentVal = percent - parseInt(this.keyResult.percent);
						// 	diffPercentVal = diffPercentVal.toFixed(2);
						// 	return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
						// case 'under':
						// 	percent = value < parseInt(this.keyResult.target) ? 100 : 0;
						// 	diffPercentVal = percent - parseInt(this.keyResult.percent);
						// 	diffPercentVal = diffPercentVal.toFixed(2);
						// 	return diffPercentVal < 0 ? diffPercentVal + '%' : '+' + diffPercentVal + '%';
					}
				}else{
					return '0%'
				}
			},
			diffDaysFromCreate(start_date) {
				var a = moment(start_date).startOf('day');
				var b = moment().startOf('day');
				return b.diff(a, 'days');  
			},
			submitUpdate(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					axios.post('/' + this.keyResult.id + '/key-result-logs/store',{
						key_result_id: this.keyResult.id,
						description: this.update_key_result.description,
						value: Number(this.update_key_result.value),
						status: this.update_key_result.degree_of_confidence
					}).then(() => {
						this.$root.$emit('refreshKrLogs')
						this.$emit("close_modal");
						this.$emit('refreshBack')
					}).finally(() => {
						setTimeout(() => {$(e.target).attr('disabled', false)}, 2000)
					})
				}else{
					$(e.target).attr('disabled', false)
				}
			}
		}
	};
</script>