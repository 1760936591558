<template>
	<div v-if="loaded">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'ShowMasterGoal', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div v-if="notAllowed">
			<not-allowed />
		</div>
		<div v-if="notFound">
			<not-found />
		</div>
		<div v-else>
			<div class="tree-page">
				<div class="tree-actions">
					<div class="left-part">
						<button class="btn-default-tbf tree-action-btn sdb-details" @click="openSidebar"><img src="/build/icons/master-goals.svg"></button>
						<div class="dropdown settings-org">
							<button class="btn-default-tbf tree-action-btn" id="dropdownSettingsOrg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="/build/icons/filters.svg"></button>

							<div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownSettingsOrgg">
								<div class="dropdown-item with-check" @click.stop="hideFinished('objectives')" v-bind:class="{'active-check': hideItemsFinished.objectives}">
									<div class="checkmark-org"><icon-check /></div>	
									<div class="text">{{$t('master_goal.view_objectives_finished')}}</div>
								</div>
								<div class="dropdown-item with-check" @click.stop="hideFinished('key_results')" v-bind:class="{'active-check': hideItemsFinished.key_results}">
									<div class="checkmark-org"><icon-check /></div>	
									<div class="text">{{$t('master_goal.view_key_results_finished')}}</div>
								</div>

								<div class="dropdown-item" @click.stop="centerTree">{{$t('master_goal.centertree')}}</div>
								<div class="dropdown-item" @click.stop="extendTree">{{$t('master_goal.extendtree')}}</div>
								<div class="dropdown-item" id="btnExport" @click.stop="exportImg">
									<div class="loader"></div>
									<div class="text">{{$t('master_goal.exporttree')}}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="center-part">
						<div class="text" v-if="$resize && $mq.above(600)">{{ master_goal.name ? master_goal.name : $t('master_goal.private') }}</div>
					</div>

					<div class="right-part">
						<div class="btns-zoom">
							<div class="zoom-minus" @click="parseFloat(zoomPage).toFixed(1) > 0.50 ? zoomPage += -0.1 : zoomPage" v-bind:class="{disabled: zoomPage == 0.50}"><img src="/build/icons/minus.svg"></div>
							<div class="current-zoom" @click="zoomPage = 1">{{ parseFloat(zoomPage * 100).toFixed(0) }}%</div>
							<div class="zoom-plus" @click="parseFloat(zoomPage).toFixed(1) == 1.50 ? zoomPage : zoomPage += 0.1" v-bind:class="{disabled: zoomPage == 1.50}"><img src="/build/icons/plus.svg"></div>
						</div>
						<div class="back-button">
							<div class="btn-default-tbf close-btn" @click="backToList"><icon-plus class="icon-close" /></div>
						</div>
					</div>
				</div>

				<div class="scrollable-chart" v-dragscroll>
					<div class="center-box" id="divToImage">
						<TreeChart class="organigram-tbf" :json="Object.keys(master_goal).length ? master_goal : {}" :maxNumber="master_goal.goal_count" :zoomPage="zoomPage" :class="{landscape: landscape.length}" :key="treeChartKey" :hideItemsFinished="hideItemsFinished" @click-node="clickNode" :style="'transform: scale(' + zoomPage + ');' " />
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>


<script type="text/javascript">
	import NotAllowed from '../General/NotAllowed'
	import NotFound from '../Pages/NotFound'
	import TreeChart from '../General/TreeChart.vue'
	import IconPlus from '../Icons/Plus.vue'
	import IconCheck from '../Icons/Check.vue'
	import * as htmlToImage from 'html-to-image'
	import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
	import download from 'downloadjs'

	import domtoimage from 'dom-to-image';
	
	export default {
		data() {
			return {
				loaded: true,
				limitedDescription: true,
				master_goal: {},
				notAllowed: false,
				notFound: false,
				viewMasterGoalAllDescription: false,
				landscape: [],
				treeChartKey: 1,
				zoomPage: 1,
				extendItems: true,
				exportImgOnProgress: false,
				master_goal_details: {},
				hideItemsFinished: {
					objectives: false,
					key_results: false
				},
				arrayTranslations: [
					'master_goal.centertree',
					'master_goal.extendtree',
					'master_goal.exporttree',
					'master_goal.private',
					'btn-submit.success',
					'tree-chart.title-master-goal',
					'tree-chart.add_mastergoal',
					'tree-chart.add_objective',
					'tree-chart.edit',
					'tree-chart.delete',
					'master_goal.private',
					'tree-chart.days',
					'tree-chart.title-goal',
					'tree-chart.addkeyresult',
					'tree-chart.view',
					'objective.private',
					'tree-chart.title-results',
					'users.role-name-admin',
					'users.role-name-employee',
					'users.role-name-manager',
					'objective.kr_private',
					'tree-chart.updates',
					'key-result.tooltip-update-today',
					'key-result.tooltip-update-past',
					'key-result.tooltip-update-future',
					'key-result.tooltip-update-cant',
					'key-result.tooltip-updates-view',
					'progress_type_text.above',
					'progress_type_text.current_value',
					'progress_type_text.decrease',
					'progress_type_text.grow',
					'progress_type_text.under',
					'progress_type_text.value',
					'tree-chart.no-key'
				]
			};
		},
		components: {
			NotAllowed,
			NotFound,
			TreeChart,
			IconPlus,
			IconCheck
		},
		watch:{
			$route (to, from){
				var jsonExtend = []
				localStorage.setItem('numberCount', 0)
				localStorage.setItem('numberCountExtend', 0)
				localStorage.setItem('jsonExtend', jsonExtend)

				if(this.$route.params.slug){
					this.getMasterGoal()
				}else{
					this.$router.push({ name: 'page-404'})
				}
            }
        },
		async mounted() {
			var jsonExtend = []
			localStorage.setItem('numberCount', 0)
			localStorage.setItem('numberCountExtend', 0)
			localStorage.setItem('jsonExtend', jsonExtend)

			if(this.$route.params.slug){
				await this.getMasterGoal()
			}else{
				this.$router.push({ name: 'page-404'})
			}

			this.$root.$on("refreshMasterGoalTree", (oldMG = false) => {
				if(oldMG && this.master_goal.id == oldMG.id && this.$route.params.slug != oldMG.slug){
					this.$router.push({ name: 'show-master-goal', params: { slug: oldMG.slug }})
				}else{
					this.getMasterGoal()
				}
            });
		},
		beforeDestroy () {
			this.$root.$off('refreshMasterGoalTree')
		},
		methods: {
			async getMasterGoal(){
				await axios.get('/master-goals/'+ this.$route.params.slug)
				.then(({data}) => {
					this.master_goal = data.data
					this.master_goal_details = { main: data.data, visions: data.all_visions, objectives: data.all_objectives, key_results: data.all_key_results }

					localStorage.setItem('numberCount', 0)
					localStorage.setItem('maxNumber', this.master_goal.goal_count)
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'
					this.notFound = status == 'not found'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()

						setTimeout(() => {
							if(this.$auth.check()){
								location.reload()
							}
						}, 300)
					}
				}).then(() => {
					setTimeout(()=>{
						this.loaded = true
					}, 300)
				})
			},
			displayAllText() {
				this.limitedDescription = false;
			},
			clickNode: function(node){
				// console.log(node)
			},
			backToList(){
				this.$router.push({ name: 'master-goals'})
			},
			updateTree(){
				this.treeChartKey += 1
			},
			openSidebar(){
				this.$root.$emit('open_modal', 'sidebar_org', false, this.master_goal_details)
			},
			extendTree(){
				localStorage.setItem('numberCountExtend', 0)

				setTimeout(() => {
					this.extendItems = !this.extendItems
					this.$root.$emit('toggleExtendAll', this.extendItems)
				}, 0)
			},
			hideFinished(type){
				this.hideItemsFinished[type] = this.hideItemsFinished[type] ? false : true
			},
			centerTree(){
				var calcWidth = $('.scrollable-chart .center-box .parentLevel .node').position().left - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel .node').width() / 2))

				$('.scrollable-chart').animate({
					scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
					scrollLeft: calcWidth
				}, 300);

			},
			exportImg(e){
				this.exportImgOnProgress = true
				var btnExport = $('#btnExport')
				var btnExportLoader = $('#btnExport .loader')
				var btnExportText = $('#btnExport .text')

				btnExport.addClass('loading')
				btnExportLoader.addClass('onProgress')
				btnExportText.html('...')

				btnExport.attr('disabled', 'disabled')

				var node = document.getElementById('divToImage');

				htmlToImage.toPng(node)
				.then((dataUrl) => {
					download(dataUrl, (this.master_goal.name ? this.master_goal.name : $t('master_goal.private') ) + '.png');
				})
				.catch(function (error) {
					console.error('oops, something went wrong!', error);
				})
				.finally(() => {
					btnExportLoader.addClass('finish')
					setTimeout(()=>{
						btnExportText.html(this.$t('btn-submit.success'))
						btnExport.addClass('completed')
						btnExportLoader.removeClass('onProgress finish')
						btnExport.removeClass('loading')
						setTimeout(()=>{
							btnExport.removeClass('completed')
							btnExportText.html(this.$t('master_goal.exporttree'))
							setTimeout(() => {
								this.exportImgOnProgress = false
							}, 1000)
							btnExport.attr('disabled', false)
						}, 1000)
					}, 300)
				})
			}
		}
	};
</script>

<style type="text/css">
	.dropdown-item.with-check{
		display: flex;
		align-items: center;
	}
	.dropdown-item.with-check .checkmark-org{
		border-radius: 3px;
	    width: 15px;
	    margin-right: 5px;
	    height: 15px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	   
	    border: solid 1px #0060c3;
		background-color: rgba(8, 129, 255, 0.75);
	}
	.dropdown-item.with-check .checkmark-org svg{
		height: 7px;
	}
	.dropdown-item.with-check .checkmark-org svg .stroke-color{
		stroke: #FFF;
	}

	.dropdown-item.with-check.active-check .checkmark-org{
		border: 1px solid #dcdee1;
	    background-color: #fcfcfc;
	}
</style>