<template>
    <div class="not-paid-page system-message">
        <div class="content">
            <div class="icon not-paid">
                <img src="/build/icons/warning-info.png">
            </div>
            <div class="title">
                {{ $auth.user().status == 'unpaid' ? $t('not-paid.title') : $t('not-paid-first.title')}}
            </div>
            <div class="description">
                {{ $auth.user().status == 'unpaid' ? $t('not-paid.description') : $t('not-paid-first.description')}}
            </div>
            <div class="action-button">
                <button @click="openModalSubscription">
                    <span class="text">{{ $auth.user().status == 'unpaid' ? $t('not-paid.button') : $t('not-paid-first.button')}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
            }
        },
        components: {
        },
        methods: {
            openModalSubscription(){
                window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;
                
                if(this.$auth.user().rights.instance == 'not_instance'){
                    this.$root.$emit('open_modal', 'required_instance');
                }else{
                    this.$root.$emit('open_modal', 'change_subscription');
                }
            }
        }
    }
</script>