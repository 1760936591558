<template>
	<div class="modal-tbf modal_share">
		<div class="container-modal form-modal opacity-page" v-if="loaded">
			<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Share Master Goal', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
			<div class="header-modal">
				<div class="title">{{ $t('share_master_goal.title') }}<a href="https://help.tbf.ro/ro/articles/4557654-obiective" target="_blank"><icon-info class="icon-info"/></a></div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="input-group-tbf">
				<div class="label-input">{{ $t('share_master_goal.label-status') }}</div>
				<div class="btns-status">
					<button class="button-action btn-default-tbf" @click="changeStatus('on')" v-bind:class="{active: master_goal.share_status == 'on'}">
						{{ $t('share_master_goal.start-share') }}
					</button>
					<button class="button-action btn-default-tbf" @click="changeStatus('off')" v-bind:class="{inactive: master_goal.share_status == 'off'}">
						{{ $t('share_master_goal.stop-share') }}
					</button>
				</div>
			</div>

			<div class="input-group-tbf mb-0">
				<div class="label-input">{{ $t('share_master_goal.label-link') }}</div>
				<div class="input-box referral-copy">
					<div class="icon-left"><icon-referral-code /></div>
					<div class="icon-right" @click="copyToClipboard">{{ $t('share_master_goal.btn-copy') }}</div>
					<input type="text" id="referralCode" class="input-text" :value="master_goal.uuid" readonly>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconReferralCode from "../Icons/ReferralCode"

	export default {
		data() {
			return {
				loaded: false,
				master_goal: {},
				arrayTranslations: [
					'share_master_goal.title',
					'share_master_goal.label-status',
					'share_master_goal.start-share',
					'share_master_goal.stop-share',
					'share_master_goal.label-link',
					'share_master_goal.btn-copy',
					'share_master_goal.btn-copied'
				]
			};
		},
		props: {
			model: String,
		},
		components: {
			IconPlus,
			IconReferralCode,
			IconInfo
		},
		async mounted() {
			await this.getMasterGoalData()
		},
		methods: {
			async getMasterGoalData(){
				await axios.get('/master-goals/'+ this.model +'/edit').then(({data}) => {
					this.master_goal = data.data
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			closeModal(){
				this.$emit("close_modal");
			},
			changeStatus(status){
				$('.btns-status .button-action').attr('disabled', 'disabled')

				this.master_goal.share_status = status
				axios.post('/master-goals/'+ this.master_goal.slug + '/change-share-status', {share_status: status})
				.then((data) => {
					$('.btns-status .button-action').attr('disabled', false)
					this.getMasterGoalData()
				})
			},
			copyToClipboard(){
				var copyText = document.getElementById("referralCode");
				copyText.select();
				copyText.setSelectionRange(0, 99999)
				document.execCommand("copy");

				$('.referral-copy .icon-right').addClass('success')
				$('.referral-copy .icon-right').html(this.$t('share_master_goal.btn-copied'))
				setTimeout(() => {
					$('.referral-copy .icon-right').removeClass('success')
					$('.referral-copy .icon-right').html(this.$t('share_master_goal.btn-copy'))
				}, 3000)
			}
		}
	};
</script>