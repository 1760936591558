<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
    import MainTemplate from '@/components/Layouts/MainTemplate'
    import LoginTemplate from '@/components/Layouts/LoginTemplate'
    import FullPage from '@/components/Layouts/FullPage'
    import SettingsTemplate from '@/components/Layouts/SettingsTemplate.vue'
    import SystemTemplate from '@/components/Layouts/SystemTemplate.vue'

    export default {
        data() {
            return {
                layout: 'login-template',
                finishApplications: false
            }
        },
        components: {
            MainTemplate,
            LoginTemplate,
            FullPage,
            SettingsTemplate,
            SystemTemplate
        },
        watch:{
            $route (to, from){
                this.layout = this.$route.meta.layout
            }
        },
        mounted(){
            this.layout = this.$route.meta.layout

            var refreshIntervalId = setInterval(() => {
                if(this.finishApplications){
                    clearInterval(refreshIntervalId);
                }else{
                    this.getApplications()
                    this.checkStatus()
                }
            }, 50);
        },
        methods:{
            getApplications(){
                if(this.$auth.check()){
                    this.finishApplications = true
                    this.$store.dispatch('applications/getApplications');
                }
            },
            checkStatus(){
                if(this.$auth.check() && !this.$auth.user().is_active){
                    this.$auth.logout();
                }
            }
        }
    }
</script>