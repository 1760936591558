<template>
	<div class="report-page" v-if="loaded">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Reports', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<section class="principal-info-section">
			<div class="space-left"></div>
			<div class="content-section" >
				<div class="header-tabs">
					<div v-if="$resize && $mq.above(1201)" class="tabs">
						<a class="title-tab" :href="`${procedures_fe_link}/reports?type=users`">{{ $t('reports.users') }}</a>
						<a class="title-tab" :href="`${organigram_fe_link}/reports?type=users`">{{ $t('reports.responsibilities') }}</a>
						<a class="title-tab" :href="`${organigram_fe_link}/reports?type=activities`">{{ $t('reports.activities') }}</a>
						<a class="title-tab" :href="`${procedures_fe_link}/reports?type=processes`">{{ $t('reports.processes') }}</a>
						<a class="title-tab" :href="`${procedures_fe_link}/reports?type=procedures`">{{ $t('reports.procedures') }}</a>
						<a class="title-tab" :href="`${procedures_fe_link}/reports?type=improvements`">{{ $t('reports.improvement_requests') }}</a>
						<router-link class="title-tab active" :to="{ name: 'reports'}">{{ $t('reports.promises') }}</router-link>
					</div>
					<div v-if="$resize && $mq.below(1200)" class="actions-dropdown">
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text">{{$t('reports.promises') }}</span>
								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=users`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.users') }}</span>
									</div>
								</a>
								<a class="dropdown-item" :href="`${organigram_fe_link}/reports?type=users`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.responsibilities') }}</span>
									</div>
								</a>
								<a class="dropdown-item" :href="`${organigram_fe_link}/reports?type=activities`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.activities') }}</span>
									</div>
								</a>
								<a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=processes`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.processes') }}</span>
									</div>
								</a>
								<a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=procedures`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.procedures') }}</span>
									</div>
								</a>
								<a class="dropdown-item" :href="`${procedures_fe_link}/reports?type=improvements`">
									<div class="checkbox">
										<span class="text">{{ $t('reports.improvement_requests') }}</span>
									</div>
								</a>
								<router-link class="dropdown-item" :to="{ name: 'reports'}">
									<div class="checkbox">
										<span class="text">{{ $t('reports.promises') }}</span>
									</div>
								</router-link>
							</div>
						</div>
					</div>
	
					<div class="graph-btn" v-show="reportData.promises.length" v-if="soft_objectives.is_active">
						<button class="btn-tbf white only-icon" @click="showChart = !showChart; getDailyPromises()">
							<icon-chart-mixed/>
						</button>
					</div>
				</div>

				<template v-if="soft_objectives.is_active">
					<div v-show="showChart">
						<div class="chart-daily">
							<div class="chart-box">
								<apexchart ref="chartPromises" type="line" height="300" :options="optionsChart" :series="seriesChart"></apexchart>
							</div>
						</div>
					</div>
					<div v-show="!showChart">
						<div class="cards-info carousel" v-if="$resize && $mq.below(1030)">
							<carousel-3d :autoplay="false" :loop="true" :display="3" :perspective="0" :border="0" :height="130" :space="$mq.below(780) ? 'auto' : 380">
								<slide :index="0">
									<div class="info-card responsabled-card">
										<div class="data-image">
											<div class="user-circle">
												<icon-user-settings/>
											</div>
										</div>
										<div class="data-card">
											<div class="value">{{ $tc('reports.people_work_today', people_working) }}</div>
											<div class="description">
												<span class="label"><button class="button-tbf-link" @click="showModal('report_people')">{{ $t('reports.people_report') }}</button></span>
											</div>
										</div>
									</div>
								</slide>
								<slide :index="0">
									<div class="info-card progress-card">
										<div class="data-icon">
											<img src="/build/icons/promises.svg">
										</div>
										<div class="data-card">
											<div class="value">{{ $tc('reports.promises_established', reportData.set_promises) }}</div>
											<div class="description">
												<span class="label">{{ $t('reports.promises_established_from_total', {established: reportData.set_promises, total: reportData.set_promises + reportData.remained_promises}) }}</span>
											</div>
										</div>
									</div>
								</slide>
								<slide :index="2">
									<div class="info-card progress-card">
										<div class="data-icon">
											<img src="/build/icons/progress.svg">
										</div>
										<div class="data-card">
											<div class="value">{{ $tc("reports.completed_promises", reportData.completed_promises) }}</div>
											<div class="description">
												<span class="label">{{ $tc("reports.completed_promises", reportData.completed_promises) }} {{ $tc("reports.remained_completed_promises", reportData.completed_promises + reportData.uncompleted_promises) }}</span>
											</div>
										</div>
									</div>
								</slide>
							</carousel-3d>
						</div>
						<div class="cards-info" v-else>
							<div class="info-card responsabled-card">
								<div class="data-image">
									<div class="user-circle">
										<icon-user-settings/>
									</div>
								</div>
								<div class="data-card">
									<div class="value">{{ $tc('reports.people_work_today', reportData.people_working) }}</div>
									<div class="description">
										<span class="label"><button class="button-tbf-link" @click="showModal('report_people')">{{ $t('reports.people_report') }}</button></span>
									</div>
								</div>
							</div>
							<div class="info-card progress-card">
								<div class="data-icon">
									<img src="/build/icons/promises.svg">
								</div>
								<div class="data-card">
									<div class="value">{{ $tc('reports.promises_established', reportData.set_promises) }}</div>
									<div class="description">
										<span class="label">{{ $t('reports.promises_established_from_total', {established: reportData.set_promises, total: reportData.set_promises + reportData.remained_promises}) }}</span>
									</div>
								</div>
							</div>
							<div class="info-card progress-card">
								<div class="data-icon">
									<img src="/build/icons/progress.svg">
								</div>
								<div class="data-card">
									<div class="value">{{ $tc("reports.completed_promises", reportData.completed_promises) }}</div>
									<div class="description">
										<span class="label">{{ $tc("reports.completed_promises", reportData.completed_promises) }} {{ $tc("reports.remained_completed_promises", reportData.completed_promises + reportData.uncompleted_promises) }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="space-right"></div>
		</section>

		<template v-if="soft_objectives.is_active">
			<section class="header-list-section">
				<div class="space-left"><icon-list class="list-icon" /></div>
				<div class="content-section">
					<div class="header-table">
						<div class="title">{{$t('general.promises')}}</div>
						<div class="filter-btns">
							<date-range-picker
								ref="picker"
								class="calendar-filter"
								opens="left"
								:locale-data="calendarFilterSettings"
								:singleDatePicker="$mq.below(650) ? 'range' : false"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								:maxDate="maxDateCalendar"
								v-model="dateRange"
								@update="updateRangeFilter"
								>
								<template v-slot:input="picker">
									<div class="input-picker">
										<icon-calendar class="icon-calendar"/>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YYYY') }}</div>
										<div class="date" v-else>{{ picker.startDate | moment('DD MMM YYYY') }} - {{ picker.endDate | moment('DD MMM YYYY') }}</div>
										<icon-arrow class="icon-arrow right"/>
									</div>
								</template>
							</date-range-picker>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>

			<section class="list-items-section list-promises-rows" v-if="reportData.promises.length > 0">
				<div class="group_items" v-for="promise in reportData.promises">
					<div class="space-left"></div>
					<div class="content-row">
						<div class="row-tbf">
							<div class="column-tbf user-img obj-user-width" v-if="$resize && $mq.above(780)">
								<v-popover offset="0" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 0, hide: 0}" >
									<img :src="promise.user.avatar" v-if="promise.user.avatar">
									<div class="user-circle" v-else>
										<icon-user-settings/>
									</div>

									<template slot="popover">
										<div class="simple-text">{{ promise.user.first_name+" "+promise.user.last_name }}</div>
									</template>
								</v-popover>
							</div>
							<!-- Promise name box -->
							<div class="column-tbf row-title with-icon kr-name-width">
								<v-popover offset="10" trigger="hover click" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 0, hide: 0}" v-if="promise.status != 'not_set'">
									<div class="text">{{ promise.name }}</div>
									<div class="subtitle" v-if="promise.key_results_names != null">{{ $t('key-result-promises.contribute-to') }}: {{ promise.key_results_names }}</div>
									<template slot="popover">
										<div class="simple-text">{{ promise.name }}</div>
										<div class="reason" v-if="promise.why_not"><span>{{ $t('show-user.timeline.reason') }}:</span> {{ promise.why_not }}</div>
									</template>
								</v-popover>
								<div class="text not-set-promise" v-else>{{ $t('reports.user_promise_not_set', {user_name: promise.user.first_name}) }}</div>
							</div>			
							<div class="column-tbf column-value">
								<div class="column-status-promise">
									<img src="/build/icons/updated.svg" v-if="promise.status == 'completed'">
									<img src="/build/icons/upcoming.svg" class="upcoming" v-if="promise.status == 'pending'">
									<img src="/build/icons/attention-square.svg" v-if="promise.status == 'not_set'">
									<img src="/build/icons/failed.svg" v-if="promise.status == 'not_completed'">
								</div>
							</div>	
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</section>
			<section class="list-items-section" v-else>
				<div class="group_items">
					<div class="space-left"></div>
					<div class="empty-row full">
						<div class="icon">
							<icon-reports-empty />
						</div>
						<div class="title">
							{{$t('reports.no-user-is-working-today')}}
						</div>
						<div class="description narrow">
							{{$t('reports.no-user-is-working-today-desc')}}
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</section>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script type="text/javascript">
	import NotModule from '@/components/General/NotModule'
	import EmptyPage from '../General/EmptyPage'
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconFilter from '../Icons/Filter'
	import IconCalendar from '../Icons/Date'
	import IconList from '../Icons/List'
	import IconKeyResults from "../Icons/KeyResults"
	import IconObjectivesList from '../Icons/ObjectivesList'
	import IconUserSettings from '../Icons/UserSettings'
	import IconCheck from "../Icons/Check"
	import IconClose from "../Icons/Close"
	import IconNotice from "../Icons/Notice"
	import IconReportsEmpty from "../Icons/ReportsEmpty"
	import IconChartMixed from '../Icons/ChartMixed'
	import { Carousel3d, Slide } from 'vue-carousel-3d';
	import VueApexCharts from 'vue-apexcharts'

	export default {
		data() {
			return {
				loaded: false,
				procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
                organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
				reportData: {},
				maxDateCalendar: moment().format('YYYY-MM-DD'),
				dateRange: {
					startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
					endDate: new Date
				},
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
				activeStatus: 'active',
				selectedTags: [],
				arrayTranslations: [
					'reports.people_work_today',
					'reports.people_established_today',
					'reports.promises_established',
					'reports.promises_established_from_total',
					'reports.completed_promises',
					'reports.remained_completed_promises',
					'reports.people_work_today',
					'reports.people_established_today',
					'general.promises',
					'key-result-promises.contribute-to',
					'show-user.timeline.reason',
					'reports.user_promise_not_set',
					'reports.no-user-is-working-today',
					'reports.no-user-is-working-today-desc'
				],
				showChart: false,
				optionsChart: {
					chart: {
						height: 350,
						type: 'line',
					},
					stroke: {
						width: [2, 2, 2,]
					},
					title: {
						text: this.$t('reports.chart.title')
					},
					dataLabels: {
						enabled: false,
						enabledOnSeries: [0, 1, 2 ]
					},
					xaxis: {
						type: 'datetime'
					},
					yaxis: [{
						title: {
						text: this.$t('reports.chart.value'),
						},
					
					}]
				},
				seriesChart: [],
			};
		},
		components: {
			NotModule,
			Carousel3d,
			Slide,
			EmptyPage,
			IconPlus,
			IconArrow,
			IconFilter,
			IconList,
			IconKeyResults,
			IconCalendar,
			IconObjectivesList,
			IconUserSettings,
			IconCheck,
			IconClose,
			IconNotice,
			IconReportsEmpty,
			IconChartMixed,
			apexchart: VueApexCharts,
		},
		computed: {
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective');
			}
        },
		async mounted() {
			await this.getDailyReport()
		},
		methods: {
			async getDailyReport(){
				await axios.get('/reports/' + this.$auth.user().instance_id + '/daily-promises',{
					params: {
						start_date: this.dateRange.startDate, 
						end_date: this.dateRange.endDate 
				    }  
				})
				.then(({data}) => {
					this.reportData = data.data
				})
				.then(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
						var title = this.$t('navbar.reports');
						this.$root.$emit("navbar_title", title);
					}, 0)
				})
			},
			getDailyPromises(){
				if(this.showChart){
					axios.get('/reports/' + this.$auth.user().instance_id + '/daily-promises-chart',{
						params: {
							start_date: this.dateRange.startDate, 
							end_date: this.dateRange.endDate 
						}  
					})
					.then(({data}) => {
						var arrayTotal = data.data.total
						var arraySet = data.data.set
						var arrayCompleted = data.data.completed

						this.seriesChart = [
							{
								name: this.$t('reports.chart.total_promises'),
								type: 'line',
								data: arrayTotal ? arrayTotal : []
							}, {
								name: this.$t('reports.chart.set_promises'),
								type: 'line',
								data: arraySet ? arraySet : []
							}, {
								name: this.$t('reports.chart.completed_promises'),
								type: 'line',
								data: arrayCompleted ? arrayCompleted : []
							}
						]
					})
				}
			}, 
			updateRangeFilter(obiec){
				this.getDailyReport()
				if ( this.showChart ){
					this.getDailyPromises()
				}
			},
			selectTag(tagName){
				if(this.selectedTags.indexOf(tagName) >= 0){
					this.selectedTags.splice(this.selectedTags.indexOf(tagName), 1)
				}else{
					this.selectedTags.push(tagName)
				}
			},
			showModal(type, model = false, requiredData = false, userData = false){
				var dataSend = {
					start_date: this.dateRange.startDate,
					end_date: this.dateRange.endDate 
				}

				this.$root.$emit('open_modal', type, model, dataSend, userData, 'reports');
			},
		}
	};
</script>