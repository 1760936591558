<template>
	<div class="vh-100 flappy-deleg">
		<div class="button-back">
			<div class="btn-default-tbf close-btn" @click.stop="backToList"><icon-plus class="icon-close" /></div>
		</div>
		<div id="gamecontainer" v-if="gameLoaded">
			<div id="gamescreen">
				<div id="sky" class="animated">
					<div id="flyarea">
						<div id="ceiling" class="animated"></div>
						<!-- This is the flying and pipe area container -->
						<div id="player" class="bird animated"></div>

						<div id="bigscore"></div>

						<div id="splash"></div>

						<div id="scoreboard">
							<div id="medal"></div>
							<div id="currentscore"></div>
							<div id="highscore"></div>
							<div id="replay"><img src="build/flappy/play.svg" alt="replay"></div>
						</div>

						<!-- Pipes go here! -->
					</div>
				</div>
				<div id="land" class="animated"><div id="debug"></div></div>
			</div>
		</div>
		<div class="boundingbox" id="playerbox"></div>
		<div class="boundingbox" id="pipebox"></div>
	</div>
</template>

<script>
	import IconPlus from '../Icons/Plus.vue'
	export default {
		data(){
			return {
				gameLoaded: false
			}
		},
		components: {
			IconPlus
		},
		watch: {
		},
		created() {

		},
		mounted() {
			this.gameLoaded = true;
			game_start();
			stopGame();
		},
		methods: {
			backToList(){
				this.$router.push({ name: 'dashboard'})
			}
		}
	}
</script>