<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Forgot Password', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
                    <div class="content-icon forgot">
                        <img src="/build/images/bird_forgot.png" class="forgot"/>
                    </div>
                    <div class="title forgot">
                        <span>{{ $t('forgot_password.title') }}</span>
                        <div class="close-btn" @click="redirect()">
                            <close-icon/>
                        </div>
                    </div>
                    <div class="container-content-modal">
                        <div class="form-section">
                            <div class="container-message forgot">
                                {{ $t('forgot_password.description') }}
                            </div>
                            <div class="input-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error, 'view-tooltip': activeTooltip == 'email' }">
                                <div class="label-input">
                                    <label>{{$t('forgot_password.email')}}</label>
                                    <div class="info-input" @click="activeTooltip == 'email' ? activeTooltip = '' : activeTooltip = 'email'">
                                    </div>
                                </div>
                                <div class="input-box">
                                    <div class="icon-left"><icon-email /></div>
                                    <div class="icon-right" v-if="email != ''" @click.stop="email = ''"><close-icon /></div>
                                    <input type="email" :placeholder="$t('forgot_password.email-placeholder')" class="input-text" v-model="email">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action-form">
                        <button type="submit" class="button-tbf-blue button-submit" @click="resetPassword">
                            <div class="loader"></div>
                            <div class="text">{{ $t('forgot_password.button-action') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CloseIcon from '../Icons/Close';
import EmailIcon from '../Icons/Email';
import IconInfo from "../Icons/Info"
import IconCircleCheck from "../Icons/CircleCheck"

import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: "",
            activeTooltip: "",
            arrayTranslations: [
                'forgot_password.title',
                'forgot_password.description',
                'forgot_password.email',
                'forgot_password.email-placeholder',
                'forgot_password.button-action'
            ]
		}
    },
    components: {
        CloseIcon,
        "icon-email" : EmailIcon,
        IconInfo,
        IconCircleCheck
    },
    validations: {
		email: {required, email},
	},
	methods: {
        resetPassword(e) {
            var btnSubmit = $('.button-submit')
            var btnSubmitLoader = $('.button-submit .loader')
            var btnSubmitText = $('.button-submit .text')

            $(e.target).attr('disabled', 'disabled')

            btnSubmit.addClass('loading')
            btnSubmitLoader.addClass('onProgress')
            btnSubmitText.html(this.$t('btn-submit.loading'))

            axios.post("/auth/reset-password", {email: this.email})
            .then(result => {
                btnSubmitLoader.addClass('finish')
                setTimeout(()=>{
                    btnSubmitText.html(this.$t('btn-submit.success'))
                    btnSubmit.addClass('completed')
                    btnSubmitLoader.removeClass('onProgress finish')
                    btnSubmit.removeClass('loading')
                    setTimeout(()=>{
                        btnSubmit.removeClass('completed')
                        btnSubmit.attr('disabled', false)
                        this.$router.push({ name: 'password-forgot-success', query: { email: this.email }});
                    }, 1000)
                }, 300)
            }).catch(error => {
                btnSubmitLoader.addClass('finish')
                setTimeout(()=>{
                    btnSubmitText.html(this.$t('btn-submit.error'))
                    btnSubmit.addClass('error')
                    btnSubmitLoader.removeClass('onProgress finish')
                    btnSubmit.removeClass('loading')
                    setTimeout(()=>{
                        btnSubmit.removeClass('error')
                        btnSubmitText.html(this.$t('forgot_password.button-action'))
                        btnSubmit.attr('disabled', false)
                    }, 1000)
                }, 300)
            });
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>