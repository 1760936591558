<template>
	<div class="login-page">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Login', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="form-content">
			<div class="login-box">
				<form @submit.prevent="login" method="post">
					<div class="form-group">
						<h2>
							{{ $t('login.title') }}
						</h2>
					</div>

					<div class="input-group-tbf" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error}">
						<div class="label-input">
							<label>{{ $t('login.email') }}</label>
							<div class="error-msg" v-if="$v.email.$error || error">{{ $t('login.login-error') }}</div>
						</div>

						<div class="input-box">
							<div class="icon-left"><email-icon /></div>
							<input type="email" :placeholder="$t('login.email-placeholder')" class="input-text" v-model="email">
						</div>
					</div>
					<div class="input-group-tbf" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error}">
						<div class="label-input">
							<label>{{ $t('login.password') }}</label>
						</div>

						<div class="input-box">
							<div class="icon-left"><password-icon /></div>
							<input type="password" :placeholder="$t('login.password-placeholder')" class="input-text" v-model="password">
						</div>
					</div>

					<div class="form-group">
						<div class="forgot">
							<!-- <router-link :to="{path: '/register?token=33e71c8436036b299a0dabfb534a28058'}" class="go-register">{{ $t('login.no-account') }}</router-link> -->
							<a href="https://central.tbf.ro/price" class="go-register">{{ $t('login.no-account') }}</a>
							<router-link :to="{path: '/password/forgot' }" class="forgot-password">{{ $t('login.forgot-password') }}</router-link>
						</div>
					</div>
					<div class="action-form">
						<button type="submit" class="button-tbf-blue button-submit">
							<div class="loader"></div>
							<div class="text">{{ $t('login.login-button') }}</div>
						</button>
					</div>
				</form>
			</div>
		</div>
		<div class="flappy-deleg" v-if="$resize && $mq.above(770)">
			<div class="preview_game" @click="viewGame" v-if="!view_game">
				<div id="sky" class="animated d-flex align-items-center">
					<lottie class="bird-login active" :options="defaultOptionsLogin" :height="220" :width="220" v-on:animCreated="handleAnimationLogin"/>
				</div>
				<div id="land" class="animated"><div id="debug"></div></div>
			</div>
			<div id="gamecontainer" v-else>
				<div id="gamescreen">
					<div id="sky" class="animated">
						<div id="flyarea">
							<div id="ceiling" class="animated"></div>
							<!-- This is the flying and pipe area container -->
							<div id="player" class="bird animated"></div>

							<div id="bigscore"></div>

							<div id="splash"></div>

							<div id="scoreboard">
								<div id="medal"></div>
								<div id="currentscore"></div>
								<div id="highscore"></div>
								<div id="replay"><img src="/build/flappy/play.svg" alt="replay"></div>
							</div>

							<!-- Pipes go here! -->
						</div>
					</div>
					<div id="land" class="animated"><div id="debug"></div></div>
				</div>
			</div>
			<div class="boundingbox" id="playerbox"></div>
			<div class="boundingbox" id="pipebox"></div>
		</div>
	</div>
</template>

<script>
import Lottie from 'vue-lottie';
import * as animationDataLogin from '../Animations/tbfdigital_login.json';

import CloseIcon from '../Icons/Close';
import EmailIcon from '../Icons/Email';
import PasswordIcon from '../Icons/Password';
import IconInfo from "../Icons/Info"
import IconCircleCheck from "../Icons/CircleCheck"

import { required, email } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
			error: false,
			password: '',
			email: '',
			defaultOptionsLogin: {
				animationData: animationDataLogin.default,
				autoplay: true,
				loop: true,
			},
			view_game: false,
			options: [
				{ title: 'Romana', img: '/build/images/ro.png' },
				{ title: 'Español', img: '/build/images/es.png' },
			],
			value_language: '',
			activeTooltip: '',
			arrayTranslations: [
				'login.title',
				'login.email',
				'login.email-placeholder',
				'login.password',
				'login.password-placeholder',
				'login.no-account',
				'login.forgot-password',
				'login.login-button',
				'btn-submit.loading',
				'btn-submit.success',
				'btn-submit.error'
			]
		}
	},
	components: {
		Lottie,
		CloseIcon,
		EmailIcon,
		PasswordIcon,
		IconInfo,
		IconCircleCheck,
	},
	validations: {
		email: {required, email},
		password: {required}
	},
	watch: {},
	created() {

	},
	mounted() {

	},
	methods: {
		login(){
			var btnSubmit = $('.button-submit')
			var btnSubmitLoader = $('.button-submit .loader')
			var btnSubmitText = $('.button-submit .text')
			
			btnSubmit.addClass('loading')
			btnSubmitLoader.addClass('onProgress')
			btnSubmitText.html(this.$t('btn-submit.loading'))

			var app = this
			this.$auth.login({
				data: {
					email: app.email, password: app.password
				},
				fetchUser: true,
				staySignedIn: true
			})
			.then(() => {
				window.location.replace(`${process.env.VUE_APP_ORGANIGRAM_FE_LINK}/dashboard`);
                return true;
			}, (res) => {
				let response = res.response.data
				if(response.status == 'error'){
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							this.error = true
							btnSubmitText.html(this.$t('login.login-button'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				}
			})
			.finally(() => {
				setTimeout(()=>{
					btnSubmit.disabled = false
				}, 1500)
			})
		},
		handleAnimationLogin(anim){
			this.anim_login = anim;
			this.anim_login.setSpeed(1);
		},
		viewGame(){
			$('.bird-login').removeClass('active');
			setTimeout(() => {
				this.view_game = true;
				setTimeout(() => {
					game_start();
				},0)
			},300)
		}
	}
}
</script>