<template>
	<div class="modal-tbf modals-tabs-kr">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Key Result Modals', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="content-tabs" v-if="loaded">
			<modal-key-result-updates v-if="activeTabPr == 'updates'" :keyResult="keyResultObj" :Uuid="uuid" @changeTab="activateTab" @toggle_modal_tab="closeModal" @refresh="refreshModal" @refreshModalData="refreshData"/>
			<modal-key-result-promises v-if="activeTabPr == 'promises'" :keyResult="keyResultObj" :Uuid="uuid" @changeTab="activateTab" @toggle_modal_tab="closeModal" @refresh="refreshModal" @refreshModalData="refreshData"/>
			<modal-key-result-tasks v-if="activeTabPr == 'tasks'" :keyResult="keyResultObj" :keyResultId="keyResult" :Uuid="uuid" @changeTab="activateTab" @toggle_modal_tab="closeModal" @refresh="refreshModal" @refreshModalData="refreshData"/>
			<modal-key-result-comments v-if="activeTabPr == 'comments'" :keyResult="keyResultObj" :keyResultId="keyResult" :Uuid="uuid" @changeTab="activateTab" @toggle_modal_tab="closeModal"/>
		</div>
	</div>
</template>

<script>
	import ModalKeyResultTasks from "../Modals/KeyResultTasks"
	import ModalKeyResultPromises from "../Modals/KeyResultPromises"
	import ModalKeyResultUpdates from "../Modals/KeyResultUpdates"
	import ModalKeyResultComments from "../Modals/KeyResultComments"

	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	export default {
		data() {
			return {
				loaded: false,
				activeTabPr: '',
				keyResultObj: {},
				arrayTranslations: [
					'general.updates',
					'general.promises',
					'general.tasks',
					'key-result-comments.title',
					'key-result-summary.progress-type',
					'progress_type_text_simple.above',
					'progress_type_text_simple.current_value',
					'progress_type_text_simple.decrease',
					'progress_type_text_simple.grow',
					'progress_type_text_simple.under',
					'key-result-summary.frequency-interval',
					'key-result-updates.interval-update-desc',
					'days',
					'key-result-summary.period',
					'key-result-summary.period-to',
					'key-result-comments.title',
					'key-result-comments.label-description',
					'key-result-comments.cancel',
					'key-result-comments.save',
					'key-result-comments.input-placeholder',
					'key-result-comments.label-status',
					'key-result-comments.status-good_job',
					'key-result-comments.status-info',
					'key-result-comments.status-not_satisfied',
					'key-result-comments.reply',
					'key-result-comments.edit-btn',
					'key-result-comments.cancel-btn',
					'key-result-comments.save',
					'key-result-comments.reply-placeholder',
					'key-result-comments.save',
					'key-result.no-comments',
					'key-result.no-comments-description',
					'key-result.promises.today_promise',
					'daily_promise.title-input-placeholder',
					'key-result.promises.set_promise',
					'key-result.promises.top-promises-description-first',
					'key-result.promises.top-promises-description-second',
					'timeline_status.completed',
					'timeline_status.not_completed',
					'timeline_status.not_set',
					'timeline_status.pending',
					'timeline_status.state_title',
					'set-promise.question_ev',
					'key-result.tooltip-promises-cant',
					'key-result.no-promises',
					'key-result.no-promises-description',
					'key-result-tasks.new-task',
					'key-result-tasks.save',
					'key-result-tasks.new-task',
					'key-result-tasks.save',
					'key-result.no-tasks',
					'key-result.no-tasks-description',
					'key-result.tooltip-update-cant',
					'key-result.top-updates-description',
					'key-result-updates.days_from_createing_key_result',
					'key-result-updates.days_from_last_update',
					'progress_type_text.value',
					'key-result.tooltip-update-cant',
					'key-result.no-updates',
					'key-result.no-updates-description'
				]
			};
		},
		props:{
			keyResult: String,
			activeTab: String,
			fromPage: String,
			uuid: String
		},
		components: {
			ModalKeyResultTasks,
			ModalKeyResultPromises,
			ModalKeyResultUpdates,
			ModalKeyResultComments,
			IconPlus,
			IconInfo
		},
		async mounted() {
            this.$root.$on("confirm_close", () => {
				this.$emit("toggle_modal");
            });

            this.activeTabPr = this.activeTab

            if(this.$route.name != 'public-master-goal'){
            	// await this.getKeyResultData()
            	await this.getKeyResultDataPublic()
            }else{
            	await this.getKeyResultDataPublic()
            }
		},
		methods: {
			async getKeyResultDataPublic(){
				await axios.get('/key-results/public/'+ this.uuid +'/show').then(({data}) => {
					this.keyResultObj = data.data
				}).then(() => {
            		this.loaded = true
				})
			},
			async getKeyResultData(){
				await axios.get('/key-results/'+ this.keyResult +'/edit').then(({data}) => {
					this.keyResultObj = data.data
				}).then(() => {
            		this.loaded = true
				})
			},
			closeModal(){
				this.$emit("toggle_modal");
			},
			activateTab(tab_name){
				this.activeTabPr = tab_name;
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			refreshModal(){
				switch (this.fromPage) {
					case 'show_objective':
						this.$root.$emit('refreshObjectivePage')
						break;
					case 'show_user':
						this.$root.$emit('refreshUserPage')
						break;
					case 'tree':
						this.$root.$emit('refreshMasterGoalTree')
						break;
				}
			},
			refreshData(){
				if(this.$route.name != 'public-master-goal'){
					this.getKeyResultData()
				}else{
					this.getKeyResultDataPublic()
				}
			}
		}
	};
</script>