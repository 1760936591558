<template>
	<div class="container-modal form-modal">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register 2b', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

        <div class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="header-modal">
			<div class="title">{{ $t('register.title_step_invoice_data') }}</div>
		</div>
		<div class="container-content-modal">
			<div class="form-section">
						<!-- Date facturare -->
						
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'company'}" @click="changeTypePerson('company')">
								<div class="type">
									<span class="title">{{ $t('register.company_title') }}</span>
									<span>{{ $t('register.team') }}</span>
								</div>
								<div class="checkboxes-group">
									<label class="checkbox-tbf">
										<input type="radio" value="company" v-model="selectedTypePerson">
										<span class="checkmark"></span>
									</label>					
								</div>
							</div>
						</div>
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'individual'}" @click="changeTypePerson('individual')">
								<div class="type">
									<span class="title">{{ $t('register.individual') }}</span>
									<span>{{ $t('register.personal') }}</span>
								</div>
								<div class="checkboxes-group">
									<label class="checkbox-tbf">
										<input type="radio" value="individual" v-model="selectedTypePerson">
										<span class="checkmark"></span>
									</label>					
								</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.complet_name.$error, complete: user.complet_name != '' && !$v.user.complet_name.$error}" v-if="selectedTypePerson == 'individual'">
							<div class="label-input">
								<label>{{ $t('register.complet_name') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.complet_name != ''" @click.stop="user.complet_name = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.complet_name')" class="input-text" v-model="user.complet_name" ref="first_input">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.cnp.$error, complete: user.cnp != '' && !$v.user.cnp.$error }" v-if="selectedTypePerson == 'individual'">
							<div class="label-input">
								<label>{{$t('register.cnp')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-cnp /></div>
								<div class="icon-right" v-if="user.cnp != ''" @click.stop="user.cnp = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.cnp')" class="input-text" v-model="user.cnp">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.company_name.$error, complete: user.company_name != '' && !$v.user.company_name.$error}" v-if="selectedTypePerson == 'company'">
							<div class="label-input">
								<label>{{$t('register.company')}}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-building /></div>
								<div class="icon-right" v-if="user.company_name != ''" @click.stop="user.company_name = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.company')" class="input-text" v-model="user.company_name">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.company_cui.$error, complete: user.company_cui != '' && !$v.user.company_cui.$error}" v-if="selectedTypePerson == 'company'">
							<div class="label-input">
								<label>{{ $t('register.cui') }}</label>
								<div class="error-msg" v-if="invalidCui">{{ $t('validator.cui_invalid') }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-cui /></div>
								<div class="icon-right" v-if="user.company_cui != ''" @click.stop="user.company_cui = ''"><close-icon /></div>
								<input type="text" :placeholder="$t('register.cui')" class="input-text" v-model="user.company_cui">
							</div>
						</div>
						<!-- end date facturare -->
						<div class="input-group-tbf checkboxes-group">
							<label class="checkbox-tbf no-margin" v-bind:class="{'error-msg': $v.user.terms_and_conditions.$error}">
								<span class="text-checkbox error-msg-terms" href="https://tbf.ro/termeni-si-conditii-tbf-digital/" v-html="$t('register.terms', {terms_link: 'https://tbf.ro/termeni-si-conditii-tbf-digital/'})">
								</span>
								<input type="checkbox" value="1" v-model="user.terms_and_conditions">
								<span class="checkmark"></span>
							</label>						
						</div>

			</div>
			<div class="submit-form">
				<button class="button-tbf-blue button-submit" @click="goNextStep">
					<div class="loader"></div>
					<div class="text">{{$t('register.button-action-2')}}</div>
				</button>
			</div>
		</div>
	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../Animations/tbfdigital_logo.json';
	import CloseIcon from '../Icons/Close'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import InformationIcon from '../Icons/Information'
	import IconIndividual from '../Icons/Individual'
	import IconOffice from '../Icons/Office'
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import UserSettingsIcon from '../Icons/UserSettings';
	import SettingsGoalIcon from '../Icons/SettingsGoal';
	import IconObjectivesList from '../Icons/ObjectivesList'
	import PasswordIcon from '../Icons/Password';
	import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				selectedTypePerson: 'company',
				invalidCui: false,
				subModalShow: false,
				arrayTranslations: [
					'register.title_step_invoice_data',
					'register.company_title',
					'register.team',
					'register.individual',
					'register.personal',
					'register.complet_name',
					'register.cnp',
					'register.company',
					'register.cui',
					'validator.cui_invalid',
					'register.terms',
					'register.button-action-2'
				]
			}
		},
		props:{
			user: Object
		},
		components: {
			CloseIcon,
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			InformationIcon,
			IconIndividual,
			IconOffice,
			IconBuilding,
			IconCnp,
			IconCui,
			UserSettingsIcon,
			SettingsGoalIcon,
			IconObjectivesList,
			PasswordIcon,
			Lottie
		},
		validations: {
			selectedTypePerson: {required},
			user: {
				terms_and_conditions: { sameAs: val => val === true },
				company_name: {
					required: requiredIf(function() {
						return this.user.has_company === 1
					})
				},
				company_cui: {
					required: requiredIf(function()  {
						return this.user.has_company === 1
					})
				},
				complet_name: {
					required: requiredIf(function()  {
						return this.user.has_company === 0
					})
				},
				cnp: {
					required: requiredIf(function()  {
						return this.user.has_company === 0 
					})
				},
			}
		},
		watch: {},
		async mounted() {
			this.user.complet_name = this.user.last_name + ' ' + this.user.first_name
			this.logoAnim();
		},
		methods: {
			goNextStep(e){
				this.invalidCui = false;

				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				btnSubmit.addClass('loading')
				btnSubmitLoader.addClass('onProgress')
				btnSubmitText.html(this.$t('btn-submit.loading'))

				this.$v.$touch()

				if(!this.$v.$invalid){
					if(this.user.has_company){
						this.checkVatNumber(e)
					}else{
						this.submitRegister(e)
					}
				}else{
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.$t('register.button-action-2'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				}
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			checkVatNumber(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				
				axios.post('/check-vat', {cui: `${this.user.country ? this.user.country.iso : 'RO'}${this.user.company_cui}`})
				.then(({data}) => {
					if(data.is_valid){
						this.submitRegister(e)
					}else{
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.error'))
							btnSubmit.addClass('error')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('error')
								btnSubmitText.html(this.$t('register.button-action-2'))
								btnSubmit.attr('disabled', false)
								this.invalidCui = true
							}, 1000)
						}, 300)
					}
				})
			},
			submitRegister(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				this.invalidCui = false;

				var app = this

				var formData = {
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					password: this.user.password,
					email: this.user.email,
					language: this.user.language,
					country: this.user.country ? this.user.country.iso : 'RO',
					has_company: this.user.has_company,
					agree_with_terms: this.user.terms_and_conditions,
					package_token: this.user.packageActive.token,
					referral_uuid: this.user.referral_uuid,
					coupon: this.user.coupon
				}
				if(this.user.has_company){
					formData.company_name = this.user.company_name
					formData.cui = `${this.user.country ? this.user.country.iso : 'RO'}${this.user.company_cui}`
				}else{
					formData.name = this.user.complet_name
					formData.cnp = this.user.cnp
				}

				this.$auth.register({
					data: formData,
					staySignedIn: true,
					autoLogin: true,
					fetchUser: true,
					redirect: {name: 'payment', query: { token: this.user.packageActive.token}},
				})
				.then(() => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$emit("next_step", {user: this.user, next_step: 3});
						}, 1000)
					}, 300)
				}, (res) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.$t('register.button-action-2'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			changeTypePerson(type) {
				this.selectedTypePerson = type;
				if(this.selectedTypePerson == 'company'){
					this.user.has_company = 1;
				}else{
					this.user.has_company = 0;
				}
			}
		}
	}
</script>