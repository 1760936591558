<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg"></div>
			<div class="title">{{ $t('subscription.cancel_title') }}</div>
		</div>
		<div class="description-modal" v-html="$t('subscription.cancel_contact_us')"></div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="cancelAction">
				<div class="loader"></div>
				<div class="text">{{ $t('general.ok_thanks_long') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {

			};
		},
		components: {

		},
		mounted() {
			this.sendOnlyEmail()
		},
		methods: {
			cancelAction(){
				this.$emit("close_modal");
			},
			sendOnlyEmail(){
				axios.post('/cancel-subscription-mail')
				.then(() => {
					this.$auth.fetch()
				})
			}
		}
	};
</script>