<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register Success', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

                    <div class="content-icon success-register">
                        <img src="/build/images/bird_confetti.png" />
                    </div>
                    <div class="title">{{ $t('register_success.title') }}</div>
                    <div class="container-content-modal">
                        <div class="container-message">
                            {{ $t('register_success.description') }}
                        </div>
                    </div>
                    <div class="action-form">
                        <button type="submit" class="button-tbf-blue" @click="redirect()">
                            <div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
                                {{ $t('register_success.button-action') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
	data(){
		return {
            arrayTranslations: [
                'register_success.title',
                'register_success.description',
                'register_success.button-action'
            ]
		}
	},
	methods: {
        redirect() {
            this.$auth.fetch();
            this.$router.push("/obiective");
        }
	}
}
</script>