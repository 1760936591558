<template>
	<div class="submodal-tbf" id="submodal_confirm_delete">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Confirm Delete', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/trash-icon.svg"></div>
			<div class="title">{{$t('confirm-delete.title')}}</div>
		</div>
		<div class="description-modal">{{$t('confirm-delete.desc')}} {{ nameItem }}</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelDelete">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('confirm-delete.cancel')}}
			</button>
			<button class="btn-red-tbf button-action" @click="submitDelete">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{$t('confirm-delete.delete')}}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				loaded: false,
				arrayTranslations: [
					'confirm-delete.title',
					'confirm-delete.desc',
					'confirm-delete.cancel',
					'confirm-delete.delete'
				]
			};
		},
		props: {
			nameItem: String
		},
		async mounted() {
			
		},
		methods: {
			cancelDelete(){
				this.$emit("cancel_delete");
			},
			submitDelete(){
				this.$emit("delete_item");
			},
		}
	};
</script>