<template>
	<div class="modal-tbf">
		<div class="container-modal form-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					<div class="responsable-image">
						<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
							<img :src="objective.user.avatar" v-if="objective.user.avatar">
							<div class="user-circle" v-else>
								<icon-user/>
							</div>

							<template slot="popover">
								<div class="simple-text">{{ objective.user.first_name + ' ' + objective.user.last_name }}</div>
							</template>
						</v-popover>	
					</div>
					{{ objective.name }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="cards-info">
					<div class="info-card progress-card">
						<div class="data-icon">
							<img src="/build/icons/progress.svg">
						</div>
						<div class="data-card">
							<div class="value">{{ parseInt(objective.percent).toString() }} %</div>
							<div class="description">
								<span class="label">{{$t('objectives.total-progress')}}</span>
								<span class="count-data">{{ objective.key_results_finished }} {{$t('objectives.completed')}}</span>
							</div>
							<div class="progress-bar-tbf">
								<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (objective.percent < 1 ? '2px' : objective.percent + '%') + ');'"></div>
							</div>
						</div>
					</div>
					<div class="info-card deadline-card">
						<div class="data-icon">
							<img src="/build/icons/end-date.svg">
						</div>
						<div class="data-card">
							<div class="value">{{ objective.end_date | moment('DD MMMM')}}</div>
							<div class="description">
								<span class="label">{{$t('objectives.deadline')}}</span>
								<span class="count-data">{{ diffDays(objective.end_date) }} {{ $t('calendar.days_left') }}</span>
							</div>
							<div class="progress-bar-tbf">
								<div class="complete-progress-tbf blue" :style="'width: calc(2px + ' + (percentIntervalDays() < 1 ? '2px' : percentIntervalDays() + '%') + ');'"></div>
							</div>
						</div>
					</div>
				</div>

				<div class="description-objective">{{ objective.description }}</div>

			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconUser from '../Icons/UserSettings'

	export default {
		data() {
			return {
				loaded: false,
				objective: {}
			};
		},
		props:{
			model: String
		},
		components: {
			IconPlus,
			IconInfo,
			IconUser
		},
		async mounted() {
			await this.getObjectiveData()
		},
		methods: {
			async getObjectiveData(){
				await axios.get('/objectives/'+this.model)
				.then(({data}) => {
					this.objective = data.data
				}).catch(error => {
					var status = error.response.data.status
					this.notAllowed = status == 'not allowed'
					this.notFound = status == 'not found'

					if(status == 'error' && error.response.data.message == 'Unauthorized'){
						this.$auth.refresh()

						setTimeout(() => {
							if(this.$auth.check()){
								location.reload()
							}
						}, 300)
					}
				}).finally(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			closeModal(){
				this.$emit("toggle_modal");
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			percentIntervalDays(){
				var a1 = moment(this.objective.start_date);
				var b1 = moment(this.objective.end_date);
				var intervalDays = b1.diff(a1, 'days')

				var a2 = moment(this.objective.start_date);
				var b2 = moment();
				var daysCompleted = b2.diff(a2, 'days')

				var percent = parseInt((daysCompleted * 100)/intervalDays)
				return percent > 100 ? 100 : percent;
			}
		}
	};
</script>