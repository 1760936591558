<template>
	<div class="profile-settings">
		<div class="top">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('my_profile.title') }}</h1>
			<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
		</div>
		<div class="main-screen-settings">
			<div class="input-box-avatar" v-bind:class="{has_error: errorData['avatar']}">
				<div class="upload_image">
					<img v-if="url" :src="url" class="img-responsabile"/>
					<img v-else-if="$auth.user().avatar != null" :src="$auth.user().avatar" class="img-responsabile"
					>
				</div>
				<label class="label">
					{{ $t('my_profile.avatar') }}
					<span id="img-name">{{ $t('my_profile.add-new-image') }}</span>
				</label>
				<button id="file_upload_profile" class="action-data" onclick="$('#file').click();"><pencil-avatar-icon /></button>
				<input style="display:none;" id="file" type="file" name="" value="" v-on:change="onFileChange" placeholder="">
			</div>
			<div class="form-section">
				<div class="input-group-tbf" v-bind:class="{has_error: $v.user.last_name.$error, complete: user.last_name != '' && !$v.user.last_name.$error}">
					<div class="label-input">
						<label>{{ $t('my_profile.last_name') }}</label>
					</div>
					<div class="input-box">
						<div class="icon-left"><user-settings-icon /></div>
						<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-plus class="icon-clear"/></div>
						<input type="text" :placeholder="$t('my_profile.last_name')" class="input-text" v-model="user.last_name">
					</div>
				</div>
				<div class="input-group-tbf" v-bind:class="{has_error: $v.user.first_name.$error, complete: user.first_name != '' && !$v.user.first_name.$error }">
					<div class="label-input">
						<label>{{ $t('my_profile.first_name') }}</label>
					</div>
					<div class="input-box">
						<div class="icon-left"><user-settings-icon /></div>
						<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-plus class="icon-clear"/></div>
						<input type="text" :placeholder="$t('my_profile.first_name')" class="input-text" v-model="user.first_name">
					</div>
				</div>

				<div class="input-group-tbf" v-bind:class="{has_error: $v.user.language.$error, complete: user.language != '' && !$v.user.language.$error}">
					<div class="label-input">
						<label>{{ $t('my_profile.language') }}</label>
					</div>
					<div class="input-box">
						<div class="icon-left"><icon-language /></div>
						<multiselect
						class="select-tags-tbf language-profile"
						v-model="value_language"
						label="name" 
						track-by="name" 
						:options="optionsLanguage" 
						:option-height="104" 
						:show-labels="false"
						:allow-empty="false"
						:searchable="false"
						@input="selectLanguage">
							<template slot="singleLabel" slot-scope="props">
								<div class="optionLanguage">
									<span class="option__title">{{ $t('languages.' + props.option.name) }}</span>
									<icon-arrow class="icon-arrow" />
								</div>
							</template>
							<template slot="option" slot-scope="props">
								<div class="optionLanguage">
									<span class="option__title">{{ $t('languages.' + props.option.name) }}</span>
								</div>
							</template>
						</multiselect>
					</div>
				</div>

				<div class="input-group-tbf" v-bind:class="{has_error: $v.user.email.$error || errorData['email'], complete: user.email != '' && !$v.user.email.$error }">
					<div class="label-input">
						<label>{{ $t('my_profile.email') }}</label>
						<div class="error-msg" v-if="errorData['email']">{{ errorData['email'][0] }}</div>
						<div class="error-msg" v-if="!$v.user.email.email">{{ $t('validator.email_format_validation') }}</div>
					</div>
					<div class="input-box">
						<div class="icon-left"><email-icon /></div>
						<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-plus class="icon-clear"/></div>
						<input type="email" :placeholder="$t('my_profile.email')" class="input-text" v-model="user.email">
					</div>
				</div>

				<div class="input-group-tbf" v-bind:class="{has_error: $v.user.password.$error || $v.user.password_confirmation.$error, complete: user.password != '' && !$v.user.password.$error && !$v.user.password_confirmation.$error }">
					<div class="label-input">
						<label>{{ $t('my_profile.password') }}</label>
						<div class="error-msg" v-if="!$v.user.password.minLength">{{ $t('validator.password_min_length') }}</div>
						<div class="error-msg" v-else-if="!$v.user.password_confirmation.sameAsPassword">{{ $t('validator.same_password') }}</div>
					</div>
				</div>

				<div class="input-group-tbf w-tbf-50 no-top-margin" v-bind:class="{has_error: $v.user.password.$error || $v.user.password_confirmation.$error, complete: user.password != '' && !$v.user.password.$error, 'view-tooltip': activeTooltip == 'password' }">
					<div class="input-box" v-if="view_fields_pass" v-bind:class="{has_error: errorData['password']}">
						<div class="icon-left"><password-icon /></div>
						<div class="icon-right" v-if="user.password != ''" @click.stop="user.password = ''"><icon-plus class="icon-clear"/></div>
						<input type="password" :placeholder="$t('my_profile.password')" class="input-text" v-model="user.password">
					</div>
				</div>

				<div class="input-group-tbf w-tbf-50 no-top-margin" v-bind:class="{has_error: $v.user.password_confirmation.$error, complete: user.password_confirmation != '' && !$v.user.password_confirmation.$error, 'view-tooltip': activeTooltip == 'password_confirmation' }">
					<div class="input-box" v-if="view_fields_pass" v-bind:class="{has_error: errorData['password_confirmation']}">
						<div class="icon-left"><password-icon /></div>
						<div class="icon-right" v-if="user.password_confirmation != ''" @click.stop="user.password_confirmation = ''"><icon-plus class="icon-clear"/></div>
						<input type="password" :placeholder="$t('my_profile.pwd_confirmation')" class="input-text" v-model="user.password_confirmation">
					</div>
				</div>

				<div class="input-group-tbf checkboxes-group">
					<label class="checkbox-tbf no-margin">
						<span class="text-checkbox error-msg-terms" v-html="$t('my_profile.report_mail')" />
						<input type="checkbox" value="1" v-model="user.report_mail">
						<span class="checkmark"></span>
					</label>						
				</div>

				<div class="input-group-tbf checkboxes-group checkbox-mails">
					<label class="checkbox-tbf no-margin">
						<span class="text-checkbox error-msg-terms" v-html="$t('my_profile.net_set_promise_mail')" />
						<input type="checkbox" value="1" v-model="user.net_set_promise_mail">
						<span class="checkmark"></span>
					</label>						
				</div>

				<div class="input-group-tbf checkboxes-group checkbox-mails">
					<label class="checkbox-tbf no-margin">
						<span class="text-checkbox error-msg-terms" v-html="$t('my_profile.next_day_assigment_mail')" />
						<input type="checkbox" value="1" v-model="user.next_day_assigment_mail">
						<span class="checkmark"></span>
					</label>						
				</div>

			</div>
			<div class="submit-form">
				<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
					<div class="loader"></div>
					<div class="text">{{ $t('my_profile.save') }}</div>
				</button>
			</div>
		</div>
	</div>

</template>

<script>
	import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
	import ConfirmClose from "../SubModals/ConfirmClose"
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import EmailIcon from '../Icons/Email';
	import PasswordIcon from '../Icons/Password';
	import UserSettingsIcon from '../Icons/UserSettings';
	import PencilAvatarIcon from '../Icons/PencilAvatar';
	import IconLanguage from '../Icons/Language';

	export default {
		data() {
			return {
				view_fields_pass : true,
				user: {
					first_name: this.$auth.user().first_name,
					last_name: this.$auth.user().last_name,
					email: this.$auth.user().email,
					language: this.$auth.user().language,
					report_mail: this.$auth.user().report_mail,
					net_set_promise_mail: this.$auth.user().net_set_promise_mail,
					next_day_assigment_mail: this.$auth.user().next_day_assigment_mail,
					avatar: '',
					password: '',
					password_confirmation: '',
				},
				activeTooltip: '',
				errorData: [],
				url: null,
				update_sent: true,
                subModalShow: false,
                show_subm_modal: {
                	confirm_close: false
                },
				optionsLanguage: [],
				value_language: '',
			}
		},
		components: {
			ConfirmClose,
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			EmailIcon,
			PasswordIcon,
			UserSettingsIcon,
			PencilAvatarIcon,
			IconLanguage
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				language: {required},
				email: {required, email},
				password: {minLength: minLength(6)},
				password_confirmation: {sameAsPassword: sameAs('password')},
			}
		},
		watch: {},
		async mounted() {
			await this.getLanguages();
		},
		methods: {
			async getLanguages(){
				await axios.get('/languages').then(({data}) => {
					this.optionsLanguage = data.data
					this.value_language = this.optionsLanguage.find(el => el.name == this.user.language)
				})
			},
			selectLanguage(value, id){
				this.user.language = value.name
			},
			closeModal(){
				this.$emit("close_modal");
			},
			viewFieldsPassword(){
				if(this.view_fields_pass){
					this.view_fields_pass = false;
					$('.change_pass').removeClass('text-red');
					$('.change_pass').html('Schimba Parola');
				}else{
					this.view_fields_pass = true;
					$('.change_pass').addClass('text-red');
					$('.change_pass').html('Anuleaza');
				}
			},
			onFileChange(e) {
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				this.user.avatar = files[0];
				this.url = URL.createObjectURL(files[0]);
				/* Push file name to the fake image upload button (placeholder) */
				$( '#img-name' ).html(files[0].name );
				$( '#file_upload_profile' ).addClass( 'has-file' );
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				this.errorData = []

				this.$v.$touch()
				if(!this.$v.$invalid){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					var formData = new FormData();
					formData.append("first_name", this.user.first_name)
					formData.append("last_name", this.user.last_name)
					formData.append("language", this.user.language)
					formData.append("report_mail", this.user.report_mail ? 1 : 0)
					formData.append("next_day_assigment_mail", this.user.next_day_assigment_mail ? 1 : 0)
					formData.append("net_set_promise_mail", this.user.net_set_promise_mail ? 1 : 0)

					if(this.user.avatar != ''){
						formData.append("avatar", this.user.avatar);
					}
					formData.append("email", this.user.email)
					if(this.user.password){
						formData.append("password", this.user.password)
						formData.append("password_confirmation", this.user.password_confirmation)
					}
					axios.post('/edit-account',formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(({data})=>{

						this.$i18n.locale = this.user.language
						this.$cookies.set('language', this.user.language, '1m')

						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.success'))
							btnSubmit.addClass('completed')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('completed')
								btnSubmit.attr('disabled', false)
								this.$auth.fetch()
								this.$emit("toggle_modal");
								this.$emit('change_slide', 'main-settings-page');
							}, 1000)
						}, 300)
					}).catch(error => {
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.error'))
							btnSubmit.addClass('error')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('error')
								btnSubmitText.html(this.$t('my_profile.save'))
								btnSubmit.attr('disabled', false)
								this.errorData = error.response.data.errors
							}, 1000)
						}, 300)
					});
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			checkInputs(){
				var notEmpty = false

				if(this.user.first_name != '' 
					|| this.user.last_name != '' 
					|| this.user.email != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			selectFocus(name){
				setTimeout(()=>{
					this.focusedInput = name
				}, 100)
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			}
		}
	}
</script>

<style lang="scss">
	.checkbox-mails{
		margin-top: 15px !important;
	}
</style>