<template>
	<div class="container-modal form-modal" id="step1">
        <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Register 1b', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

        <div class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="container-holder">
			<div class="header-modal">
				<div class="title">{{ $t('register.step-3.apply_coupon')}}</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf" v-bind:class="{has_error: errorCoupon}">
							<div class="label-input">
								<label>{{ $t('register.step-3.coupon.label') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="cuponCode != ''" @click.stop="cuponCode = ''"><close-icon /></div>
								<input type="text" v-model="cuponCode" :placeholder="$t('register.step-3.coupon-placeholder')" class="input-text">
							</div>
						</div>
					</div>
					<div class="submit-form multiple-btns">
						<button class="btn-default-tbf button-submit" @click="goNextWithout">
							<div class="loader"></div>
							<div class="text">{{$t('register.step-3.coupon.not-have')}}</div>
						</button>
						<button class="button-tbf-blue button-submit btn-apply" @click="checkCode">
							<div class="loader"></div>
							<div class="text">{{$t('register.step-3.coupon.apply')}}</div>
						</button>
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../Animations/tbfdigital_logo.json';
	import CloseIcon from '../Icons/Close'
	import IconPassword from '../Icons/Password'
	
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				bird_noti: false,
				errorCoupon: false,
				cuponCode: '',
				arrayTranslations: [
					'register.step-3.apply_coupon',
					'register.step-3.coupon.label',
					'register.step-3.coupon-placeholder',
					'register.step-3.coupon.not-have',
					'register.step-3.coupon.apply',
					'register.step-3.coupon.apply-success',
					'register.step-3.coupon.apply-error'
				]
			}
		},
		props: {
			user: Object
		},
		components: {
			CloseIcon,
			IconPassword,
			Lottie
		},
		async mounted() {
			this.logoAnim();
		},
		methods: {
			getUserNewPrices(){
				axios.post('get-referral-coupon', {referral_id : this.user.referral_uuid}).then((data) => {
					if(data.data.is_valid){
						this.user.newPrices = data.data.values
						this.user.coupon = data.data.couponCode;
					}
				})				
			},
			goNextWithout(){
				this.user.newPrices = []
				this.user.coupon = null
				if(this.user.referral_uuid){
					this.getUserNewPrices();
				}
				this.$emit("next_step", {user: this.user, next_step: 2});
			},
			checkCode(e){
				var btnApply = $('.btn-apply')
				var btnApplyLoader = $('.btn-apply .loader')
				var btnApplyText = $('.btn-apply .text')

				btnApply.addClass('loading')
				btnApplyLoader.addClass('onProgress')
				this.errorCoupon = false
				
				axios.post('validate-coupon', {coupon : this.cuponCode}).then((data) => {
					if(data.data.is_valid){
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('btn-submit.success'))
							btnApply.addClass('completed')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('completed')
								btnApply.attr('disabled', false)
								btnApplyText.html(this.$t('register.step-3.coupon.apply-success'))

								this.user.newPrices = data.data.values
								this.user.coupon = this.cuponCode;
								this.$emit("next_step", {user: this.user, next_step: 2});
							}, 1000)
						}, 300)
					}else{
						btnApplyLoader.addClass('finish')
						setTimeout(()=>{
							btnApplyText.html(this.$t('register.step-3.coupon.apply-error'))
							btnApply.addClass('error')
							btnApplyLoader.removeClass('onProgress finish')
							btnApply.removeClass('loading')
							setTimeout(()=>{
								btnApply.removeClass('error')
								btnApplyText.html(this.$t('register.step-3.coupon.apply'))
								btnApply.attr('disabled', false)
								this.errorCoupon = true
								this.user.newPrices = []
								this.user.coupon = null;
							}, 1000)
						}, 300)
					}
				})
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	}
</script>