<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('list-apps.title') }}</h1>
		</div>
		<section class="list-items-section">
			<div>
				<div class="group_items apps_items">
					<div class="space-left"></div>
					<div class="content-row">
						<div class="row-tbf action-row">
							<div class="column-tbf row-title invoice-name-width">
								<div class="icon-left active"><icon-windows-active /></div>
								<div class="icon-left inactive"><icon-windows /></div>
								<div class="name">{{ $t('settings.download-windows') }}</div>
							</div>

							<div class="column-tbf row-details invoice-download-width">
								<a href="" download>
									<icon-download class="download-invoice" />
								</a>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
				<div class="group_items apps_items">
					<div class="space-left"></div>
					<div class="content-row">
						<div class="row-tbf action-row">
							<div class="column-tbf row-title invoice-name-width">
								<div class="icon-left inactive"><icon-apple /></div>
								<div class="icon-left active"><icon-apple-active /></div>
								<div class="name">{{ $t('settings.download-mac') }}</div>
							</div>

							<div class="column-tbf row-details invoice-download-width">
								<a href="" download>
									<icon-download class="download-invoice" />
								</a>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
				<div class="group_items apps_items">
					<div class="space-left"></div>
					<div class="content-row">
						<div class="row-tbf action-row">
							<div class="column-tbf row-title invoice-name-width">
								<div class="icon-left inactive"><icon-linux /></div>
								<div class="icon-left active"><icon-linux-active /></div>
								<div class="name">{{ $t('settings.download-linux') }}</div>
							</div>

							<div class="column-tbf row-details invoice-download-width">
								<a href="" download>
									<icon-download class="download-invoice" />
								</a>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
		</section>
	</div>
</template>

<script type="text/javascript">
	import ConfirmDelete from "../SubModals/ConfirmDelete"
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'
	import IconPencil from '../Icons/PencilAvatar'
	import IconEmail from '../Icons/Email'
	import IconBillingDetails from "../Icons/BillingDetails"
	import IconDownload from "../Icons/Download"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconInfo from "../Icons/Info"
	import IconDelete from "../Icons/Delete"
	import IconInvoice from "../Icons/Invoice"
	import IconWindows from "../Icons/WindowsIcon"
	import IconWindowsActive from "../Icons/WindowsActiveIcon"
	import IconLinux from "../Icons/LinuxIcon"
	import IconLinuxActive from "../Icons/LinuxActiveIcon"
	import IconApple from "../Icons/AppleIcon"
	import IconAppleActive from "../Icons/AppleActiveIcon"

	export default {
		data() {
			return {
				loaded: false,
				invoices: [],
				invoiceEmails: '',
				editEmails: true,
				inputEmailsError: false,
				subModalShow: false,
                show_subm_modal: {
                	confirm_delete: false
                },
                deleteItemId: '',
                hoverTag: '',
                receiverEmails: ''
			};
		},
		components: {
			ConfirmDelete,
			IconPlus,
			IconArrow,
			IconEditDots,
			IconPencil,
			IconEmail,
			IconBillingDetails,
			IconDownload,
			IconCircleCheck,
			IconInfo,
			IconDelete,
			IconInvoice,
			IconWindows,
			IconWindowsActive,
			IconLinux,
			IconLinuxActive,
			IconApple,
			IconAppleActive

		},
		async mounted() {

		},
		methods: {
			viewSlide(slide, modelRole){
				this.$emit('change_slide', slide, modelRole)
			}
		}
	};
</script>