<template>
	<div class="modal_info modal_prize">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Prize', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header">
			<img :src="prize.image_prize">
			<div class="title">{{ $t('prize.congratulations-title') }} {{ prize.name }}</div>
			<div class="description">{{ prize.description }}</div>
		</div>

		<joining-prize class="joining-img" />

		<div class="coupon-code">
			<input class="code" readonly id="prizeCode" :value="prize.prefix">
			<div class="copy-clipboard" @click="copyToClipboard">{{ $t('prize.copy-clipboard') }}</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JoiningPrize from '../Icons/JoiningPrize'

	export default {
		data() {
			return {
				arrayTranslations: [
					'prize.congratulations-title',
					'prize.copy-clipboard'
				]
			};
		},
		props: {
			prize: Object
		},
		components: {
			JoiningPrize
		},
		mounted() {
			
		},
		methods: {
			copyToClipboard(){
				var copyText = document.getElementById("prizeCode");
				copyText.select();
				copyText.setSelectionRange(0, 99999)
				document.execCommand("copy");

				$('.copy-clipboard').html(this.$t('prize.copied'))
				setTimeout(() => {
					$('.copy-clipboard').html(this.$t('prize.copy-clipboard'))
				}, 3000)
			}
		}
	};
</script>