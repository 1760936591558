<template>
	<div class="modal_info modal_cant_create_more">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Congratulations', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image congrat-img"><img src="/build/icons/bird-congratulations.svg"></div>
			<div class="title">{{ $t('congratulations-modal.title') }}</div>
		</div>
		<div class="description-modal">{{ $t('congratulations-modal.text') }}</div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="redirect()">
				{{ $t('congratulations-modal.btn') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				nameItem: '',
				arrayTranslations: [
					'congratulations-modal.title',
					'congratulations-modal.text',
					'congratulations-modal.btn'
				]
			};
		},
		props: {
			type: String,
		},
		mounted() {
			
		},
		methods: {
			redirect() {
	            location.reload('/dashboard');
	        }
		}
	};
</script>