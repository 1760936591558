<template>
	<div class="opacity-page show">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Objectives', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="list-page-tbf goals-page">
			<section class="header-list-page-section" v-if="loaded">
				<div class="space-left">
					<img src="/build/icons/search-disabled.svg" />
				</div>
				<div class="content-section">
					<div class="header-table">
						<div class="left-part">
							<input type="text" name="search" :placeholder="$t('objectives.search_by_name')" id="inputSearch" v-model="search_text" autocomplete="off" class="search-box" v-debounce:500="searchFilterFunct">
						</div>
						<div class="right-part">
							<template v-if="$resize && $mq.above(600)">
								<div class="filter-dropdown-header dropdown filter-responsibles">
									<button class="btn-tbf white" id="dropdownFilterUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
										<span class="text" v-else-if="selectedUsers.length == 1">{{ filteredUsers.find(el => el.id == selectedUsers[0]).name }}</span>
										<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.users_selected') }}</span>

										<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow class="icon-arrow"/></div>
										<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
									</button>
									<div class="dropdown-menu" aria-labelledby="dropdownFilterUsers">
										<div class="search-filter-dropdown">
											<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_by_name')">
										</div>

										<template v-for="user in filteredUsers">
											<div class="dropdown-item" @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-users-' + user.id">
												<div class="checkbox">
													<div class="checkmark"></div><span class="text">{{ user.name }}</span>
												</div>
											</div>
										</template>
									</div>
								</div>

								<div class="action-header filter-date dropdown-tbf">
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
										{{ $t('filter_objectives_date.' + activeDate) }}<icon-arrow class="icon-arrow"/>
									</button>

									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>

									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div class="dropdown-item" @click="activeDate = 'asc'" v-bind:class="{active: activeDate == 'asc'}">
											{{ $t('filter_objectives_date.asc')}}
										</div>
										<div class="dropdown-item" @click="activeDate = 'desc'" v-bind:class="{active: activeDate == 'desc'}">
											{{ $t('filter_objectives_date.desc')}}
										</div>
									</div>
								</div>

								<div class="action-header filter-date dropdown-tbf">
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
										{{ $t('filter_objectives.' + activeStatusObj) }} <icon-arrow class="icon-arrow"/>
									</button>

									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>
									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div v-for="objStatus in objStatusList" 
										class="dropdown-item" 
										@click="changeActiveStatus(objStatus)" 
										v-bind:class="{active: activeStatusObj == objStatus}">
										{{ $t('filter_objectives.'+objStatus) }}
										</div>
									</div>
								</div>

								<div class="action-header filter-quarter dropdown-tbf">
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="$resize && $mq.above(600)">
										<div class="quarter-no" v-if="activeQuarter">{{ $t('objectives.quarter') }} {{ activeQuarter.quarter }}, {{activeQuarter.year }}</div> 
										<div class="quarter-no" v-else>{{ $t('general.all') }}</div>
										<icon-arrow class="icon-arrow"/>
									</button>
									<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else><icon-filter /></button>

									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div class="dropdown-item bor-bottom" @click="changeActiveQuarter('all')">{{ $t('general.all') }}</div>
										<div class="dropdown-item" v-for="quarterItem in arrayQuarters" @click="changeActiveQuarter(quarterItem)" v-bind:class="{active: activeQuarter == quarterItem, 'bor-top': quarterItem.quarter == 4 }">
											<div class="quarter-no">{{ $t('objectives.quarter_short') }}{{ quarterItem.quarter }} {{ quarterItem.year }}</div>  <div class="quarter-period">{{ quarterItem.start_quarter | moment('MMMM') }} - {{ quarterItem.end_quarter | moment('MMMM') }}</div>
										</div>
									</div>
								</div>
							</template>

							<div class="action-header add-button" v-if="$auth.user().rights.can_create">
								<button class="button-tbf-blue" @click="$auth.user().rights.can_create_objective ? showModal('objective') : openModalV2('cant_create_more_objective', false, 'objectives')"><icon-plus class="white" /> {{ $resize && $mq.above(600) ? $t('objectives.add') : '' }}</button>
							</div>
						</div>
						
						<div class="filter-section-mobile" v-if="$resize && $mq.below(599)">
							<div class="filter-dropdown-header dropdown filter-responsibles">
								<button class="btn-tbf white" id="dropdownFilterUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
									<span class="text" v-else-if="selectedUsers.length == 1">{{ filteredUsers.find(el => el.id == selectedUsers[0]).name }}</span>
									<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.users_selected') }}</span>
	
									<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow class="icon-arrow"/></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterUsers">
									<div class="search-filter-dropdown">
										<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_by_name')">
									</div>
	
									<template v-for="user in filteredUsers">
										<div class="dropdown-item" @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-users-' + user.id">
											<div class="checkbox">
												<div class="checkmark"></div><span class="text">{{ user.name }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
	
							<div class="action-header filter-date dropdown-tbf">
								<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{{ $t('filter_objectives_date.' + activeDate) }}<icon-arrow class="icon-arrow"/>
								</button>
	
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" @click="activeDate = 'asc'" v-bind:class="{active: activeDate == 'asc'}">
										{{ $t('filter_objectives_date.asc')}}
									</div>
									<div class="dropdown-item" @click="activeDate = 'desc'" v-bind:class="{active: activeDate == 'desc'}">
										{{ $t('filter_objectives_date.desc')}}
									</div>
								</div>
							</div>
	
							<div class="action-header filter-date dropdown-tbf">
								<button class="btn-default-tbf" id="dropdownFilter-status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{{ $t('filter_objectives.' + activeStatusObj) }} <icon-arrow class="icon-arrow"/>
								</button>
	
								<div class="dropdown-menu" aria-labelledby="dropdownFilter-status">
									<div v-for="objStatus in objStatusList" 
									class="dropdown-item" 
									@click="changeActiveStatus(objStatus)" 
									v-bind:class="{active: activeStatusObj == objStatus}">
									{{ $t('filter_objectives.'+objStatus) }}
									</div>
								</div>
							</div>
	
							<div class="action-header filter-quarter dropdown-tbf">
								<button class="btn-default-tbf" id="dropdownFilter-quarter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="quarter-no" v-if="activeQuarter">{{ $t('objectives.quarter') }} {{ activeQuarter.quarter }}, {{activeQuarter.year }}</div> 
									<div class="quarter-no" v-else>{{ $t('general.all') }}</div>
									<icon-arrow class="icon-arrow"/>
								</button>
	
								<div class="dropdown-menu" aria-labelledby="dropdownFilter-quarter">
									<div class="dropdown-item bor-bottom" @click="changeActiveQuarter('all')">{{ $t('general.all') }}</div>
									<div class="dropdown-item" v-for="quarterItem in arrayQuarters" @click="changeActiveQuarter(quarterItem)" v-bind:class="{active: activeQuarter == quarterItem, 'bor-top': quarterItem.quarter == 4 }">
										<div class="quarter-no">{{ $t('objectives.quarter_short') }}{{ quarterItem.quarter }} {{ quarterItem.year }}</div>  <div class="quarter-period">{{ quarterItem.start_quarter | moment('MMMM') }} - {{ quarterItem.end_quarter | moment('MMMM') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</section>
			<div v-else>
				<section class="header-list-page-section">
					<div class="space-left">
						<img src="/build/icons/search-disabled.svg" />
					</div>
					<div class="content-section">
						<div class="header-table">
							<div class="left-part">
								<div class="placeholder-loader" style="width: 150px; height: 24px"></div>
							</div>
							<div class="right-part">
								<div class="placeholder-loader" style="width: 130px; height: 35px; margin-right: 20px;"></div>
								<div class="placeholder-loader" style="width: 35px; height: 35px"></div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</section>
			</div>

			<template v-if="loadedList">
				<section class="list-items-section" v-if="filteredObj.length">
					<div class="group_items" v-for="objective in filteredObj">
						<div class="space-left">
							<icon-arrow :id="'arrow-key-result-' + objective.id" class="icon-arrow right"/>
						</div>
						<div class="content-row">
							<div class="row-tbf action-row" @click="showObjective(objective)">
								<div class="column-tbf obj-icon" v-if="$resize && $mq.above(600)">
									<div class="pin" @click.stop="pinObjective(objective)" v-bind:class="{active: objective.is_pinned}">
										<!-- aici o stea ar merge mai bine -->
										<icon-pin/>
									</div>
									<icon-objectives-list />
								</div>

								<router-link class="column-tbf row-title obj-name-width" :to="{ name: 'show-objective', params: { slug: objective.slug }}">
									<div class="name" v-html="highlight((objective.name.charAt(0).toUpperCase() + objective.name.slice(1)), search_text)"></div>
									<div class="description">{{ objective.description ? objective.description.charAt(0).toUpperCase() + objective.description.slice(1) : '' }}</div>
								</router-link>
								
								<div class="column-tbf column-value obj-reward-width" v-if="$resize && $mq.above(1030)">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf reward-popover" :delay="{show: 200, hide: 0}">										
										<div class="icon" v-if="objective.reward.filename || objective.reward.name ">
											<icon-reward />
										</div>

										<template slot="popover">
											<img :src="objective.reward.file_path" v-bind:class="{mrg_bot: objective.reward.name != ''}" v-if="objective.reward.file_path">
											<div class="simple-text" v-if="objective.reward.name">{{ objective.reward.name }}</div>
										</template>
									</v-popover>
								</div>

								<div class="column-tbf column-value obj-key-results-width" v-if="$resize && $mq.above(1030)">
									<div class="label">{{ $t('objectives.key-results')}}</div>
									<div class="value"><img class="circle-info" src="/build/icons/circle-info-orange.svg" v-if="objective.needs_an_update == 'today' || objective.needs_an_update == 'past' || objective.need_evaluate_promise"> {{  $t('objectives.key-results-progress', {n: objective.key_results_in_progress, total: objective.key_results_count }) }}</div>
								</div>

								<div class="column-tbf column-value obj-deadline-width" v-if="$resize && $mq.above(780)">
									<div class="label">{{ moment(objective.start_date) >= moment() ? $t('objectives.start_on') : $t('objectives.deadline')}}</div>
									<div class="value" v-if="objective.percent == 100"><img class="circle-info" src="/build/icons/circle-check-green.svg"> {{ $t('status.completed') }}</div>
									<div class="value" v-else-if="moment(objective.start_date) >= moment()">{{ objective.start_date | moment('DD/MM/YYYY') }}</div>
									<div class="value" v-else-if="diffDays(objective.end_date) < 0"><img class="circle-info" src="/build/icons/circle-info-red.svg"> {{ $t('show-user.outdated') }}</div>

									<div class="value" v-else>{{ diffWeeksAndDays(objective.end_date) }}</div>
								</div>

								<div class="column-tbf item-value obj-column-right" v-if="$resize && $mq.above(600)">
									<div class="top-data">
										<div class="current-value">
											<div class="value" v-if="moment(objective.start_date) >= moment()">0</div>
											<div class="value" v-else>{{ parseInt(objective.percent).toString() + '%' }}</div>
										</div>
										<div class="info"><icon-info class="icon-info"/></div>
									</div>
									<div class="progress-bar-tbf" v-bind:class="{disabled: moment(objective.start_date) > moment()}">
										<div class="complete-progress-tbf" v-bind:class="{pink: (diffDays(objective.end_date) < 1 && objective.percent != 100), green: objective.percent == 100}" :style="'width: calc(2px + ' + (parseInt(objective.percent) < 1 ? '2px' : objective.percent + '%') + ');'"></div>
									</div>
								</div>

								<div class="column-tbf user-img obj-user-width" v-if="$resize && $mq.above(780)">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<img :src="objective.user.avatar" v-if="objective.user.avatar">
										<div class="user-circle" v-else>
											<icon-user-settings/>
										</div>

										<template slot="popover">
											<div class="simple-text">{{ objective.user.first_name + ' ' + objective.user.last_name }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div class="dots-edit" :id="'edit-key-result-' + objective.id" v-click-outside="hideDropdown" v-if="objective.rights.edit || objective.rights.delete">
								<div class="dropdown edit-item-dropdown">
									<div class="overlay-button" @click.stop="showDropdown(objective.id)"></div>
									<div class="edit-item-button" :id="'dropdownEdit'+objective.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<icon-edit-dots />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ objective.id " :aria-labelledby="'dropdownEdit'+objective.id">
										<div class="dropdown-item" @click.stop="showModal('objective', objective.slug)" v-if="objective.rights.edit">{{ $t('objectives.edit')}}</div>
										<div class="dropdown-item" @click.stop="openModalV2('delete', objective, 'objective', 'objectives')" v-if="objective.rights.delete">{{ $t('objectives.delete')}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="list-items-section empty" v-else>
					<div class="group_items">
						<div class="space-left"></div>
						<div class="empty-row full">
							<div class="icon">
								<img src="/build/icons/no-results-found.svg">
							</div>
							<div class="title">
								{{ $t('objectives.empty.you')}}
							</div>
							<div class="description narrow" v-html="$t('objectives.empty.desc')">
								{{ }}
							</div>
							<div class="add-button" v-if="$auth.user().rights.can_create">
								<button class="button-tbf-blue" @click="$auth.user().rights.can_create_objective ? showModal('objective') : openModalV2('cant_create_more_objective', false, 'objectives')"><icon-plus class="white" />{{ $t('objectives.add')}}</button>
							</div>
						</div>
						<div class="space-right"></div>
					</div>
				</section>
			</template>
			<infinite-loading :identifier="infiniteId" @infinite="getObjectivesInfinite" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
			<div v-if="!loadedList">
				<section class="list-items-section">
					<div class="group_items" v-for="n in 5">
						<div class="space-left">
							<icon-arrow class="icon-arrow right"/>
						</div>
						<div class="content-row">
							<div class="row-tbf">
								<div class="column-tbf row-title obj-name-width">
									<div class="name placeholder-loader" style="height: 19px; width: 100px;"></div>
									<div class="description placeholder-loader" style="height: 16px; width: 150px;"></div>
								</div>

								<div class="column-tbf item-value obj-column-right" v-if="$resize && $mq.above(600)">
									<div class="top-data">
										<div class="current-value">
											<div class="value placeholder-loader" style="height: 16px; width: 15px;"></div>
										</div>
										<div class="deadline placeholder-loader" style="height: 14px; width: 15px;"></div>
									</div>
									<div class="progress-bar-tbf placeholder-loader"></div>
								</div>

								<div class="column-tbf user-img obj-user-width" v-if="$resize && $mq.above(600)">
									<div class="placeholder-loader user" style="height: 38px; width: 38px;"></div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div class="dots-edit">
								<div class="dropdown edit-item-dropdown">
									<div class="overlay-button"></div>
									<div class="edit-item-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<icon-edit-dots />
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import EmptyPage from '../General/EmptyPage'
	import NotAllowed  from '../General/NotAllowed'
	import NotPaid  from '../General/NotPaid'
	import IconObjectiveEmpty from '../Icons/ObjectivesEmpty'
	import IconPlus from '../Icons/Plus'
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'
	import IconFilter from '../Icons/Filter'
	import IconInfo from '../Icons/Info'
	import IconClose from '../Icons/Close'
	import IconUserSettings from '../Icons/UserSettings'
	import IconObjectivesList from '../Icons/ObjectivesList'
	import IconPin from '../Icons/Pin'
	import IconReward from '../Icons/Award'
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
		components: {
			EmptyPage,
			NotAllowed,
			NotPaid,
			IconObjectiveEmpty,
			IconPlus,
			IconArrow,
			IconEditDots,
			IconFilter,
			IconUserSettings,
			IconObjectivesList,
			IconInfo,
			IconClose,
			IconPin,
			IconReward,
			InfiniteLoading
		},
		data() {
			return {
				loaded: false,
				loadedList: false,
				page: 0,
				infiniteId: 1,
				search_text: '',
				objectives: [],
				notAllowed: '',
				hoverObjective: '',
				activeDate: 'asc',
				activeQuarter: { 
					quarter: moment().quarter(),
					year: moment().format('YYYY'),
					start_quarter: moment().startOf('quarter').format('YYYY-MM-DD'), 
					end_quarter: moment().endOf('quarter').format('YYYY-MM-DD') 
				},
				acceptedStatuses: ['all','active','overdue','finished','upcoming'],
				lengthMaxValue: 0,
				arrayQuarters: [],
				objStatusList: [],
                activeStatusObj: 'active',
				arrayTranslations: [
					'objectives.search_by_name',
					'filter_objectives.active',
					'filter_objectives.all',
					'filter_objectives.finished',
					'filter_objectives.overdue',
					'filter_objectives.upcoming',
					'objectives.quarter',
					'objectives.quarter_short',
					'objectives.add',
					'objectives.key-results',
					'objectives.key-results-progress',
					'objectives.start_on',
					'objectives.deadline',
					'status.completed',
					'days',
					'objectives.edit',
					'objectives.delete',
					'objectives.empty.you',
					'objectives.empty.desc',
					'objectives.empty.title',
					'navbar.objectives',
				],
				optionsUsers: [],
				selectedUsers: [],
				searchUsers: '',
				defaultFilters: {}
			};
		},
		watch: {
			search_text: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_text;

				if(this.search_text == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
		},
		computed: {
			filteredObj(){
				return SortByDeadline(getByStatus(this.objectives, this.activeStatusObj), this.activeDate)
			},
			filteredUsers() {
				return getByKeywordFilter(this.optionsUsers, this.searchUsers)
			}
		},
		beforeDestroy () {
			this.$root.$off('refreshObjectivesList')
		},
		async mounted() {
			//Create array of quarters
			var awaitArray = true
			var currentQuarter = moment(this.$auth.user().instance_created_at)

			while(awaitArray){
				this.arrayQuarters.push({ 
					quarter: currentQuarter.quarter(), 
					year: currentQuarter.format('YYYY'), 
					start_quarter: currentQuarter.startOf('quarter').format('YYYY-MM-DD'), 
					end_quarter: currentQuarter.endOf('quarter').format('YYYY-MM-DD') 
				})

				if(currentQuarter.endOf('quarter') < moment().add(1, 'year')){
					currentQuarter = currentQuarter.endOf('quarter').add(1, 'day')
				}else{
					awaitArray = false
				}
			}
			this.arrayQuarters.reverse()
			//END Create array of quarters

			await this.getFilters();
			await this.checkQueryFilters();
			
			// End filters
			await this.getObjStatusList()
			
			this.$root.$on("refreshObjectivesList", () => {
				this.searchFilterFunct()
			});
		},
		methods: {
			getObjectivesInfinite($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

				axios.get(`/${this.$auth.user().instance_id}/objectives`, {params: paramsCall})
				.then(({data}) => {
					if(data.data.data.length){
						this.page++
						this.objectives.push(...data.data.data)
						setTimeout(() => { $state.loaded() }, 200)
						if(data.data.data.length < 20){
							$state.complete()
						}
					}else{
						$state.complete()
					}
				})
				.finally(() => {
        			this.loadedList = true
				})
				.catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
			},
			async getObjStatusList(){
				await axios.get('/objectives/status/list').then(({data}) => {
					this.objStatusList = data.data
					this.objStatusList.unshift("all");
				})
				.finally(() => {
					var title = this.$t('navbar.objectives');
					this.$root.$emit("navbar_title", title);
					this.loaded = true
				})
			},
			getFilters() {
				axios.get(`instances/${this.$auth.user().instance_id}/filter`, { params: { users: true, responsible_objectives: true } })
				.then(({data}) => {
					this.optionsUsers = data.data.users; 
				})
			},
			checkQueryFilters() {
				// filters
				if(this.$route.query.q && this.$route.query.year && this.$route.query.q != '' && this.$route.query.year != ''){
					this.activeQuarter = this.arrayQuarters.find(el => el.year == this.$route.query.year && el.quarter == this.$route.query.q)
				}
				
				if(this.$route.query.search && this.$route.query.search != ""){
					this.search_text = this.$route.query.search;
				}
				if(this.$route.query.status && this.$route.query.status != ""){
					this.activeStatusObj = this.$route.query.status;
				}

				if(this.$route.query.users && this.$route.query.users != "") {
					this.selectedUsers = this.$route.query.users.split(',').map(Number)
				}
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'objectives');
			},
			showObjective(objective){
				this.$router.push({ name: 'show-objective', params: { slug: objective.slug }})

				var title = objective.name;
				var backRoute = 'objectives' 
				this.$root.$emit("navbar_title", title, {route: backRoute}, objective.description)
			},
			diffDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');
				return b.diff(a, 'days');  
			},
			diffWeeksAndDays(end_date) {
				var a = moment().startOf('day');
				var b = moment(end_date).startOf('day');

				var diff = moment.duration(b.diff(a));
				
				return diff.asDays() > 7 ? `${this.$tc('weeks', Math.floor(diff.asWeeks()))} ${diff.days()%7 != 0 ? this.$tc('days', diff.days()%7) : ''}` : this.$tc('days', diff.days()%7) ;  
			},
			showDropdown(itemId){
				if(itemId == this.hoverObjective){
					$('#dropdownEdit' + this.hoverObjective).dropdown('toggle');
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}else{
					this.hoverObjective = itemId
					setTimeout(() => {
						$('#dropdownEdit' + this.hoverObjective).dropdown('toggle');
					}, 0)
				}

			},
			hideDropdown(){
				if(this.hoverObjective != ''){
					$('#dropdownEdit' + this.hoverObjective).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverObjective = ''
					}, 0)
				}
			},
			openModalV2(type, model = false, typeDelete = false, fromDelete = false){
				this.hideDropdown()
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
			highlight(text, query){
				String.prototype.replaceBetween = function(start, end, what) {
					return this.substring(0, start) + what + this.substring(end);
				};

				if(query != ''){
					var regex = new RegExp( query ,'ig');
					var new_text = text
					var match = ''

					var match_obj = []

					while ((match = regex.exec(text)) != null) {
						match_obj.push({
							text_replace: text.substring(match.index, match.index+query.length),
							start_position: match.index,
							end_position: match.index+query.length
						})
					}
					match_obj.reverse()

					if(match_obj.length){
						match_obj.forEach(element => {
							new_text = new_text.replaceBetween(element.start_position, element.end_position, '<mark class="highlight">' + element.text_replace + '</mark>')
						})
					}

					return new_text
				}else{
					return text
				}
			},
			viewKeyResult(keyResult){
				this.viewAllDescription = false
				if(this.keyResultSelected == keyResult){
					this.keyResultSelected = ''
				}else{
					this.keyResultSelected = keyResult

					if(this.keyResultSelected.key_result_logs.length){
						let data = []

						this.keyResultSelected.key_result_logs.map( el => {
							// data.push([new Date(this.moment(el.related_to_date)).getTime(), el.key_result_percent ])
							data.push(el.key_result_percent)
						})

						if(this.keyResultSelected.key_result_logs[this.keyResultSelected.key_result_logs.length -1].related_to_date != this.keyResultSelected.start_date){
							// data.push([new Date(this.keyResultSelected.start_date).getTime(), 0])
							data.push(0)
						}

						this.series = [
							{data: data.reverse()},
						]
					}
				}
				// axios.get('/key-results/'+keyResult.id)
				// .then(({data}) => {
				// 	this.keyResultSelected = data.data
				// })
			},
			checkDiffUnity(unity){
				var inlineUnits = ['€', '$', 'RON']
				return inlineUnits.includes(unity)
			},
			changeActiveQuarter(quarter){
				this.activeQuarter = quarter == 'all' ? '' : quarter;

				var dataQuery = Object.assign({}, this.$route.query);

				if(quarter != 'all') {
					dataQuery['q'] = quarter.quarter;
					dataQuery['year'] = quarter.year;
				} else {
					delete dataQuery.q;
					delete dataQuery.year;
				}

				if(this.$route.query.q != quarter.quarter || this.$route.query.year != quarter.year){
					this.$router.push({name: 'objectives', query : dataQuery }).catch(err => {})
				}
				this.searchFilterFunct()
			},
			changeActiveStatus(status){
				this.activeStatusObj = status;

				var dataQuery = Object.assign({}, this.$route.query);
				dataQuery['status'] = status;

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.objectives = []
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
			queryObject(paramsCall){
				// var dataQuery = Object.assign({}, this.$route.query);

				paramsCall.search = this.search_text;
				paramsCall.status = this.activeStatusObj == 'all' ? '' : this.activeStatusObj;
				if(this.activeQuarter) {
					paramsCall.start_quarter = this.activeQuarter.start_quarter;
					paramsCall.end_quarter = this.activeQuarter.end_quarter;
				}

				if(this.selectedUsers.length) {
					paramsCall.users_ids =  this.selectedUsers;
				}

				if(Object.keys(this.defaultFilters).length === 0){
					this.defaultFilters = Object.freeze(JSON.parse(JSON.stringify(paramsCall)));
				}

				if(this.areObjectsEqual(this.defaultFilters, paramsCall) == false){
					paramsCall.custom_filter = true;
				} 

				// Object.keys(dataQuery).forEach(key => {
				// 	if(key == 'search'){ paramsCall.search = dataQuery[key] }
				// 	if(key == 'status' && dataQuery[key] != 'all'){ paramsCall.status = dataQuery[key]}
				// 	if(key == 'q') {
				// 		var qaurter = this.arrayQuarters.find(el => el.year == this.$route.query.year && el.quarter == this.$route.query.q)
				// 		paramsCall.start_quarter = qaurter.start_quarter
				// 		paramsCall.end_quarter = qaurter.end_quarter
				// 	}
				// });
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
					
				} else {
					if(this[filter].includes(parseInt(value))){
					
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
					
				}

				this.addFilterToRouteQuery(filter)
        	},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedUsers'){ dataQuery['users'] = this.selectedUsers.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				this[filter] = []

				this.addFilterToRouteQuery(filter)
			},
			pinObjective(objective){
				axios.post(`/objectives/${objective.slug}/pin`)
				.then(() => {
					this.searchFilterFunct();
				})
			},
			areObjectsEqual(obj1, obj2) {
				if (Object.keys(obj1).length !== Object.keys(obj2).length) {
					return false;
				}

				for (let prop in obj1) {
					if (!obj2.hasOwnProperty(prop) || obj1[prop] !== obj2[prop]) {
					return false;
					}
				}
				return true;
			}
		}
	};

	function getByStatus(list, status) {
		if(status == 'all'){ return list }
			
		return list.filter(item => item.status == status)
	}
	function SortByDeadline(list, type) {
			if (type == 'asc'){
				return list.sort(function(a,b){
					return moment(a.end_date) - moment(b.end_date)
					}
				)
			} else if( type == 'desc') {
				return list.sort(function(a,b){
					return moment(b.end_date) - moment(a.end_date)
					}
				)
			}
	}

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>