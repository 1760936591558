<template>
	<div class="modal_info modal_cant_create_more_objective">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Cant Add More Updates', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="header-submodal">
			<div class="image"><img src="/build/icons/warning-info.png"></div>
			<div class="title">
				{{ $t('subscription.is-not-within-parameters') }}
			</div>
		</div>
		<div class="description-modal">
			{{ $t('subscription.is-not-within-parameters-description') }}
		</div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="closeModal">{{ $t('subscription.is-not-within-parameters-btn') }}</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				arrayTranslations: [
					'subscription.is-not-within-parameters',
					'subscription.is-not-within-parameters-description',
					'subscription.is-not-within-parameters-btn'
				]
			};
		},
		mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>