<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="title">{{ $t('user_archivate.title') }}</div>
		</div>
		<div class="description-modal">
			{{ data.status == 'active' ? $t('user_archivate.question_archivate') : $t('user_archivate.question_activate') }}
		</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="cancelArchivate">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonArchivate" class="button-tbf-blue button-action" @click="submitArchivate( data.status == 'active' ? 'archived' : 'active' )">
				<div class="loader"></div>
				<div class="text">{{ data.status == 'active' ? $t('general.archived') : $t('general.activate') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {

			};
		},
		props: {
			data: Object
		},
		components: {

		},
		mounted() {

		},
		methods: {
			cancelArchivate() {
				this.$emit("close_modal");
			},
			submitArchivate(status) {
				var buttonName = `buttonArchivate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post(`/users/${this.data.userSlug}/change-status`, { status: status })
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					this.$root.$emit('refreshUsersList')
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							this.$emit("close_modal");
						}, 1000)
					}, 300)
				})

			}
		}
	};
</script>