<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('list_tags')" class="back-arrow">
			<h1>
				{{ editAction ? $t('create-tags.title_edit') : $t('create-tags.title_new') }}
				<a href="https://help.tbf.ro/ro/articles/4557638-oameni" target="_blank">
					<icon-info class="icon-info"/>
				</a>
			</h1>
		</div>
		<div class="form-section">
			<div class="input-group-tbf" v-bind:class="{has_error: $v.tag.name.$error, complete: tag.name != '' && !$v.tag.name.$error}">
				<div class="label-input">
					<label>{{ $t('create-tags.name') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-tags /></div>
					<div class="icon-right" v-if="tag.name != ''" @click.stop="tag.name = ''"><icon-plus class="icon-clear"/></div>
					<input type="text" :placeholder="$t('create-tags.name-placeholder')" class="input-text" v-model="tag.name" ref="first_input">
				</div>
			</div>

			<div class="input-group-tbf" v-bind:class="{has_error: $v.selectedColor.$error, complete: selectedColor != '' && !$v.selectedColor.$error }">
				<div class="label-input">
					<label>{{ $t('create-tags.color') }}</label>
				</div>
				<div class="radio-colors">
					<div class="tag-color can-select" v-for="color in colors" v-bind:class="[selectedColor == color.id ? 'selected color-' + color.name : 'color-' + color.name]" @click="selectColor(color.id)">
						<img src="/build/icons/settings-tags-color.svg">
						<span></span>
					</div>
				</div>
			</div>



			<div class="input-group-tbf" v-bind:class="{complete: selectUsersId != ''}">
				<div class="label-input">
					<label>{{ $t('create-tags.users') }}</label>
				</div>

				<div class="input-box">
					<div class="icon-left"><icon-people /></div>
					<!-- <div class="icon-right" v-if="selectUsersId != ''" @click.stop="selectUsersId = ''"><icon-plus class="icon-clear"/></div> -->
					<multiselect 
						:preserve-search="true"
						v-model="usersSelected"
						class="select-tags-tbf hide-tags"
						:options="optionsUsers" 
						:multiple="true" 
						group-values="groupItems"
						group-label="groupName" 
						:group-select="true"
						:close-on-select="false"
						:placeholder="$t('create-tags.search-users')" 
						track-by="id" 
						label="first_name"
						:custom-label="customLabel"
						:selectLabel="$t('create-tags.select')"
						:selectGroupLabel="$t('create-tags.select-all')"
						:selectedLabel="$t('create-tags.selected')"
						:deselectLabel="$t('create-tags.deselect')"
						:deselectGroupLabel="$t('create-tags.deselect-all')"
						@input="selectUserId">
							<template slot="placeholder" slot-scope="props">
								<span class="text">
									{{ $t('create-tags.search-users') }}
								</span>
							</template>
							<template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('create-tags.selected-users') }}</span></template>
							<template slot="singleLabel" slot-scope="props">
								<div class="option__image">
									<span>
										{{ props.option.first_name + ' ' + props.option.last_name }}
									</span>
								</div>
							</template>
							<template slot="option" slot-scope="props">
								<div class="option__image">
									<img :src="props.option.avatar" v-if="props.option.avatar">
									<img v-else src="/build/images/profile-default.svg">
									<span>
										{{ props.option.first_name + ' ' + props.option.last_name }}
									</span>
								</div>
							</template>
							<template slot="noResult">{{ $t('create-tags.no-results') }}</template>
						</multiselect>
				</div>
				<div class="selectedValues" v-if="usersSelected.length > 0">
					<span v-for="usr, index in usersSelected" class="name">
						{{ usr.first_name + ' ' + usr.last_name }}
						<img src="/build/icons/settings-icon-delete-tag.svg" class="icon-remove-tag" @click="removeTaguser(usr, index)">
					</span>
					
				</div>
			</div>

		</div>

		<div class="submit-form">
			<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
				<div class="loader"></div>
				<div class="text">{{ editAction ? $t('create-tags.edit_button') : $t('create-tags.save_button') }}</div>
			</button>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"
	import IconRemoveTag from "../Icons/IconRemoveTag"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconTags from "../Icons/Tags"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				selectedColor: '',
				colors: {},
				users: {},
				activeTooltip: '',
				tag: {
					name: ''
				},
				errorData: [],
				optionsUsers: [
					{
						groupName: {
							first_name: this.$t('create-tags.select-all'),
							last_name: ''
						},
						groupItems: []
					}
				],
				usersSelected: [],
				selectUsersId: [],
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                },
			};
		},
		props:{
			model: Object
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			IconRemoveTag,
			IconTags
		},
		validations: {
			selectedColor: {required},
			tag: {
				name: {required},
			}
		},
		async mounted() {
			if(Object.keys(this.model).length === 0 && this.model.constructor === Object){
				this.tag =  {
					name: ''
				}
				this.editAction = false
			}else{
				this.tag = this.model
				this.editAction = true
			}

			await this.getColors()
			await this.getUsers()
		},
		methods: {
			closeModal(){
				this.subModalShow = true
				this.show_subm_modal.confirm_close = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_confirm_close').addClass('active');
					}, 200);
                }, 0);
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			customLabel({ first_name, last_name }){
				return `${first_name} ${last_name}`
			},
			async getColors(){
				await axios.get('/colors').then(({data}) => {
					this.colors = data.data
					
					if(this.editAction){
						this.selectedColor = this.colors.find(el => el.id == this.tag.color.id).id
					}
				})
			},
			async getUsers(){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true } })
				.then(({data}) => {
					this.optionsUsers[0].groupItems = data.data.users

					if(this.editAction){
						this.tag.users.map( (el) => {
							this.usersSelected.push(this.optionsUsers[0].groupItems.find(element => element.id == el))
							this.selectUsersId.push(el)
						})
					}
					
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
						}, 0)
					}, 300)
				});
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				this.$v.$touch()
				if(!this.$v.$invalid){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					if(this.editAction){
						this.updateTag(e)
					}else{
						this.storeTag(e)
					}
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			storeTag(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				axios.post('/tags/store', {
					name: this.tag.name,
					color_id: this.selectedColor,
					users: this.selectUsersId
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$emit('change_slide', 'list_tags')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('create-tagsedit_button') : this.$t('create-tagssave_button'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				});
			},
			updateTag(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				axios.patch('/tags/' + this.tag.id, {
					name: this.tag.name,
					color_id: this.selectedColor,
					users: this.selectUsersId
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$emit('change_slide', 'list_tags')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('create-tags.edit_button') : this.$t('create-tags.save_button'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				});
			},
			selectUserId(value, id){
				this.selectUsersId = []
				value.map(el => {
					this.selectUsersId.push(el.id)
				})
			},
			removeTaguser(usr, index){
				this.usersSelected.splice(index,1);
				this.selectUsersId.splice(this.selectUsersId.findIndex(el => el == usr.id), 1)
			},
			checkInputs(){
				var notEmpty = false

				if(this.tag.name != ''){
					notEmpty = true
				}

				return notEmpty
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			}
		}
	};
</script>