import Vue       from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

Vue.use(VueRouter)

import Login from '@/components/Auth/Login'
import Register from '@/components/Auth/Register'
import RegisterSuccess from '@/components/Auth/RegisterSuccess'
import ForgotPassword from '@/components/Auth/ForgotPassword'
import ForgotPasswordSuccess from '@/components/Auth/ForgotPasswordSuccess'
import ResetPassword from '@/components/Auth/ResetPassword'
import ResetPasswordSuccess from '@/components/Auth/ResetPasswordSuccess'

import Objectives from '@/components/Pages/Objectives'
import MasterGoals from '@/components/Pages/MasterGoals'
import ShowObjective from '@/components/Pages/ShowObjective'
import ShowMasterGoal from '@/components/Pages/ShowMasterGoal'

import Users from '@/components/Pages/Users'
import ShowUser from '@/components/Pages/ShowUser'
import Reports from '@/components/Pages/Reports'

import Game from '@/components/Pages/Game'

import PublicMasterGoal from '@/components/Pages/PublicMasterGoal'
import SmartPlanner from '@/components/Pages/Settings/SmartPlanner'

import NotFound from '@/components/Pages/NotFound'
import Forbbiden from '@/components/Pages/Forbbiden'

const routes = [
	// Main Pages
	{ path: '/login', name: 'login', component: Login, meta: { auth: false, layout: 'login-template' }},
	// { path: '/register', name: 'register', component: Register, meta: { auth: false, layout: 'login-template' }},
	{ path: '/register-success', name: 'register-success', component: RegisterSuccess, meta: { auth: true, layout: 'login-template' }},
	{ path: '/password/forgot', name: 'password-forgot', component: ForgotPassword, meta: { auth: false, layout: 'login-template' }},
	{ path: '/password/forgot-success', name: 'password-forgot-success', component: ForgotPasswordSuccess, meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password/:token', name: 'password-reset', component: ResetPassword, meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-success', name: 'password-reset-success', component: ResetPasswordSuccess, meta: { auth: false, layout: 'login-template' }},
	{ path: '/payment', name: 'payment', component: Register, meta: { auth: true, layout: 'login-template' }},
	
	// { path: '/dashboard', name: 'dashboard', component: ShowUser, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/', name: 'homepage', component: Objectives, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},

	{ path: '/obiective', name: 'objectives', component: Objectives, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/obiectiv/:slug', name: 'show-objective', component: ShowObjective, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},

	//Modals Page
	{ path: '/obiectiv/:slug/:krId/:modalType', name: 'show-objective-modal', component: ShowObjective, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},

	{ path: '/master-goals', name: 'master-goals', component: MasterGoals, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/master-goal/:slug', name: 'show-master-goal', component: ShowMasterGoal, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-page' }},

	// { path: '/users', name: 'users', component: Users, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/utilizator/:slug', name: 'show-user', component: ShowUser, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' }},
	{ path: '/reports', name: 'reports', component: Reports, meta: { auth: true, layout: 'main-template' }},
	
	{ path: '/public/:uuid', name: 'public-master-goal', component: PublicMasterGoal, meta: { auth: undefined, layout: 'full-page' }},
	
	// { path: '/settings/roles', name: 'settings-roles', component: loadView('Pages/Settings/Roles'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	{ path: '/settings/profile', name: 'settings-profile', component: loadView('Pages/Settings/Profile'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	// { path: '/settings/subscription', name: 'settings-subscriptions', component: loadView('Pages/Settings/Subscriptions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},

	// { path: '/software-updates', name: 'software-updates', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/software-updates/:id', name: 'software-updates-show', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/work-in-progress', name: 'work-in-progress', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},
	// { path: '/work-in-progress/:id', name: 'work-in-progress-show', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},

	{ path: '/smart-planner', name: 'smart-planner', component: SmartPlanner, meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	
	{ path: '/game', name: 'game', component: Game, meta: { auth: true, layout: 'full-page' }},
	{ path: '/403', name: 'forbbiden', component: Forbbiden, meta: { auth: true, layout: 'main-template' } },
	{ path: '/404', name: 'page-404', component: NotFound, meta: { auth: true, layout: 'main-template' }},
	{ path: '*', name: 'not-exist', component: NotFound, meta: { auth: true, layout: 'main-template' }},
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active'
})

export default Vue.router;