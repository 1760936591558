<template>
	<div class="modal_info modal_contact">
		<div class="header-submodal">
			<div class="image"><icon-chat /></div>
			<div class="title">{{ $t('contact_modal.title') }}</div>
		</div>

		<div class="description-modal" v-html="$t('contact_modal.description')">
		</div>

		<div class="button-actions">
			<button class="btn-blue-tbf button-action" @click="closeModal">
				<div class="text">{{ $t('general.ok_thanks') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconChat from '../Icons/Chat'

	export default {
		data() {
			return {

			};
		},
		components: {
			IconChat
		},
		mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>

<style lang="scss">
	.modal_info.modal_contact{
		max-width: 500px;
		.header-submodal{
			.image{
				color: $blue;
				svg{
					height: 100px;
					.fa-secondary{
						fill: #e5f2ff;
					}
				}
			}
		}
		.description-modal{
			max-width: 100%;
		}
	}
</style>