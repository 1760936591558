<template>
	<div class="modal-tbf">
		<div class="container-modal form-modal opacity-page" v-if="loaded">
			<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'User', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
			<div class="header-modal">
				<div class="title">
					{{ editAction ? $t('users.title_edit') : $t('users.title_new') }} 
				</div>
				<div class="actions">
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon help-btn" :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.first_name.$error, complete: user.first_name != '' && !$v.user.first_name.$error}">
							<div class="label-input">
								<label>{{ $t('users.first_name') }}*</label>
								<div v-if="errorsBe.first_name" class="error-msg">{{ errorsBe.first_name.join(" | ") }}</div>
								<div class="info-input">
									<icon-circle-check class="icon-check" v-if="user.first_name != '' && !$v.user.first_name.$error" />
									<!-- <icon-info class="icon-info" v-else/> -->
								</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-plus class="icon-clear"/></div>
								<input type="text" :placeholder="$t('users.placeholder_first_name')" class="input-text" v-model="user.first_name" ref="first_input">
							</div>
						</div>
						
						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.last_name.$error, complete: user.last_name != '' && !$v.user.last_name.$error}">
							<div class="label-input">
								<label>{{ $t('users.last_name') }}*</label>
								<div v-if="errorsBe.last_name" class="error-msg">{{ errorsBe.last_name.join(" | ") }}</div>
								<div class="info-input">
									<icon-circle-check class="icon-check" v-if="user.last_name != '' && !$v.user.last_name.$error" />
									<!-- <icon-info class="icon-info" v-else/> -->
								</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-people /></div>
								<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-plus class="icon-clear"/></div>
								<input type="text" :placeholder="$t('users.placeholder_last_name')" class="input-text" v-model="user.last_name">
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-if="canChangeDummy">
							<div class="label-input">
								<label>{{ $t('users.dummy_question') }}</label>
							</div>

							<div class="input-box bor-0">
								<div class="select-workdays">
									<label class="checkbox-workdays">
										<input type="radio" value="0" v-model="user.dummy_account">
										<span class="checkmark">{{ $t('general.yes') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="radio" value="1" v-model="user.dummy_account">
										<span class="checkmark">{{ $t('general.no') }}</span>
									</label>
								</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.email.$error || errorData.email || errorsBe.email, complete: user.email != '' && !$v.user.email.$error, disabled: user.dummy_account == 1}">
							<div class="label-input">
								<label>{{ $t('users.email') }}*</label>
								<div v-if="errorsBe.email" class="error-msg">{{ errorsBe.email.join(" | ") }}</div>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-email /></div>
								<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-plus class="icon-clear"/></div>
								<input type="text" :placeholder="$t('users.placeholder_email')" class="input-text" v-model="user.email" :disabled="user.dummy_account == 1">
							</div>
						</div>


						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.tagsSelected.$error, complete: tagsSelected.length > 0  && !$v.tagsSelected.$error}">
							<div class="label-input">
								<label>
									{{ $t('users.tags') }}*
									<span class="add-button-in" v-if="$auth.user().rights.can_create" @click="showModal('tag')">{{ $t('users.add_role') }}</span>
								</label>
							</div>

							<div class="input-box bor-0">
								<div class="icon-left"><icon-tags /></div>
								<!-- <div class="icon-right" v-if="tagsSelected.length > 0" @click.stop="clearSelectedTags"><icon-plus class="icon-clear"/></div> -->

								<div class="filter-tags dropdown-tbf">
									<div class="input-form-tags input-box" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span class="value" v-if="tagsSelected.length">{{ tagsSelected.length == 1 ? (optionsTags[0].groupItems.find(el => el.id == tagsSelected[0]) ? optionsTags[0].groupItems.find(el => el.id == tagsSelected[0]).name : $t('filter_users.search_tags')) : $t('filter_users.selected', {n: tagsSelected.length}) }}</span>
										<span class="placeholder" v-else>{{ $t('filter_users.search_tags') }}</span>
									</div>
									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div class="filter-dropdown">
											<input type="text" v-model="searchTags" :placeholder="$t('filter_users.search_tag_by_name')">
										</div>
										<div class="dropdown-list-checkboxes">
											<div v-for="tag in filteredTags" 
											class="dropdown-item" 
											@click.stop="selectTag(tag)" 
											v-bind:class="{active: tagsSelected.includes(tag.id)}">
												<div class="checkmark"></div>
												<span class="text">{{ tag.name }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.user.promise_time.$error, complete: user.promise_time.length > 0 && !$v.user.promise_time.$error}">
							<div class="label-input">
								<label>{{ $t('users.promise_day') }}</label>
							</div>

							<div class="input-box">
								<div class="icon-left"><icon-timer /></div>
								<div class="icon-right" v-if="user.promise_time != ''" @click.stop="user.promise_time = ''"><icon-plus class="icon-clear"/></div>
								<multiselect 
								v-model="user.promise_time"
								class="select-tags-tbf"
								:options="optionsHours"
								:allow-empty="false"
								:show-labels="false"
								:maxHeight="200">
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{$t('users.choose-hour')}}
										</span>
									</template>
									<template slot="option" slot-scope="props">
										<div class="option_ellipsis">{{ props.option }}</div>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option }}</div>
									</template>
									<template slot="noResult">{{ $t('users.no-results-search') }}</template>
								</multiselect>	
							</div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label>{{ $t('users.working_days') }}</label>
								<div class="info-input">
									<!-- <icon-circle-check class="icon-check" v-if="user.working_days.length > 0 && !$v.user.working_days.$error" />
									<icon-info class="icon-info" v-else/> -->
								</div>
							</div>

							<div class="input-box bor-0">
								<div class="select-workdays">
									<label class="checkbox-workdays">
										<input type="checkbox" value="monday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.monday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="tuesday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.tuesday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="wednesday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.wednesday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="thursday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.thursday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="friday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.friday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="saturday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.saturday') }}</span>
									</label>
									<label class="checkbox-workdays">
										<input type="checkbox" value="sunday" v-model="user.working_days">
										<span class="checkmark">{{ $t('calendar.small_days.sunday') }}</span>
									</label>
								</div>
							</div>
						</div>

						<div class="input-group-tbf checkboxes-group mar-form-user" v-if="!user.is_entrepreneur">
							<div class="label-input">
								<label>{{ $t('users.checkbox_is_admin_or_manager') }}</label>
								<div v-if="errorsBe.admin_user" class="error-msg">{{ errorsBe.admin_user.join(" | ") }}</div>
							</div>
							<div class="error-msg under-label" v-if="errorData.required_admin">{{ requiredErrorMsg }}</div>

							<div class="inline-checkboxes">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('users.checkbox_admin') }}</span>
									<input type="checkbox" v-model="isAdmin" @change="checkOtherInput('admin')">
									<span class="checkmark"></span>
								</label>
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('users.checkbox_manager') }}</span>
									<input type="checkbox" v-model="isManager" @change="checkOtherInput('manager')">
									<span class="checkmark"></span>
								</label>					
							</div>
						</div>

						<div class="submit-form">
							<button class="button-tbf-blue button-submit" @click="saveModifications" id="button-submit-user">
								<div class="loader"></div>
								<div class="text">{{ editAction ? $t('users.submit_change') : $t('users.submit_create') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="submodal-container" v-if="subModalShow" id="user-submodal-container">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose"></confirm-close>
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf w-mob-100 w-tbf-50">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="input-group-tbf">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>

						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px;height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconEmail from "../Icons/Email"
	import IconTimer from "../Icons/Timer"
	import IconTags from "../Icons/Tags"
	import IconQuestion from "../Icons/Question"

	import ConfirmClose from "../SubModals/ConfirmClose"
	import { required, email, requiredIf } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				custom_modal_class: '',
				activeTooltip: '',
				user: {
					first_name: '',
					last_name: '',
					email: '',
					working_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
					promise_time: '10:00',
					dummy_account: 0
				},
				selectTagsId: [],
				tagsSelected: [],
				optionsTags: [
					{
						groupName: 'Selecteaza toate tag-urile',
						groupItems: []
					}
				],
				selectedRolId: '',
				rolSelected: [],
				optionsRoles: [],
				optionsHours: [],
				errorData: {email: false, required_admin: false},
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                },
                fields: { text: 'name', value: 'id' },
                isAdmin: 0,
                isManager: 0,
                searchTags: '',
                requiredErrorMsg: '',
				errorsBe: {},
				arrayTranslations: [
					'users.title_edit',
					'users.title_new',
					'users.last_name',
					'users.placeholder_last_name',
					'users.first_name',
					'users.placeholder_first_name',
					'users.email',
					'validator.email_already_exists',
					'users.placeholder_email',
					'users.tags',
					'users.add_role',
					'filter_users.selected',
					'filter_users.search_tags',
					'filter_users.search_tag_by_name',
					'users.working_days',
					'calendar.small_days.monday',
					'calendar.small_days.tuesday',
					'calendar.small_days.wednesday',
					'calendar.small_days.thrusday',
					'calendar.small_days.friday',
					'calendar.small_days.saturday',
					'calendar.small_days.sunday',
					'users.promise_day',
					'users.choose-hour',
					'users.no-results-search',
					'users.checkbox_is_admin_or_manager',
					'users.checkbox_admin',
					'users.checkbox_manager',
					'users.submit_change',
					'users.submit_create',
					'btn-submit.loading',
					'btn-submit.error',
					'btn-submit.success',
				],
				helpData: this.$store.getters['help_links/currentLink']('users_modal'),
			};
		},
		props:{
			model: String,
			fromPage: String
		},
		computed: {
			filteredTags(){
				return getByKeywordTags(this.optionsTags[0].groupItems, this.searchTags)
			}
		},
		components: {
			IconPlus,
			IconInfo,
			IconPeople,
			IconCircleCheck,
			IconEmail,
			IconArrow,
			IconTimer,
			IconTags,
			ConfirmClose,
			IconQuestion
		},
		validations: {
			canChangeDummy: false,
			user: {
				first_name: {required},
				last_name: {required},
				email: { required: requiredIf( function (model) {
					return this.user.dummy_account == 0
				})},
				promise_time: {required}
			},
			selectedRolId: {required},
			tagsSelected: {required}
		},
		async mounted() {
			if(this.model == ''){
				this.editAction = false
			}else{
				await this.getUserData()
				this.editAction = true
			}

			await this.getRoles()
			await this.getTags()

			this.generateHours();

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal.confirm_close = true
					setTimeout(() => {
						$('#user-submodal-container').addClass('active');
						setTimeout(() => {
							$('#submodal_confirm_close').addClass('active');
						}, 200);
					}, 0);
				}else{
					this.$emit("toggle_modal");
				}
			});

            // this is a workaround to hide objective modal until you finish to add a user
			if(this.fromPage == 'objective'){
            	this.$root.$emit('visibilityHiddenObjective', true);
			}
			if(this.fromPage == 'keyresult'){
            	this.$root.$emit('visibilityHiddenKeyResult', false);
			}
			
			this.$root.$on("visibilityHiddenUser", (is_active) => {
				this.subModalShow = is_active;
				setTimeout(() => {
					if(is_active){
						$('#user-submodal-container').addClass('active');
					}else{
						$('#user-submodal-container').removeClass('active');					
					}
				}, 0)
			}); 

			// hide modal
 			this.$root.$on("refreshTagsListModal", (tag_detail) => {
 				this.subModalShow = false;
 				$('#user-submodal-container').removeClass('active');	
				this.getTags(tag_detail);
			}); 

		},
		methods: {
			async getUserData(){
				await axios.get('/people/' + this.model + '/edit').then(({data}) => {
					this.user = data.data
					this.user.email = data.data.dummy_account ? "" : data.data.email
					this.canChangeDummy = data.data.dummy_account
				})
			},
			async getRoles(){
				await axios.get('/roles').then(({data}) => {
					this.optionsRoles = data.data

					if(this.editAction){
						var role = this.optionsRoles.find(el => el.name == this.user.role_name);
						this.rolSelected.push(role)
						this.selectedRolId = role.id
						this.isAdmin = role.id == 1 ? 1 : 0
						this.isManager = role.id == 3 ? 1 : 0
					}else{
						var role = this.optionsRoles.find(el => el.name == 'employee');
						this.rolSelected.push(role)
						this.selectedRolId = role.id
					}
				})
			},
			async getTags(newTagAdded = false){
				await axios.get('/' + this.$auth.user().master_instance_id + '/tags')
				.then(({data}) => {
					this.optionsTags[0].groupItems = data.data

					if(newTagAdded){
						this.tagsSelected.push(newTagAdded.id);
						this.selectTagsId.push(newTagAdded.id)
					}else if(this.editAction) {
						this.user.tags.map( (el) => {
							this.tagsSelected.push(el.id);
							this.selectTagsId.push(el.id)
						})
					}
				})
				.then(() => {
					setTimeout(()=>{
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
							$('.opacity-page').addClass('show')
						}, 0)
					}, 300)
				})
			},
			generateHours(){
				var MinuteInterval = 60;
			    var o, n, i, g, r, S, l;

			    o = ['00:00'], n = 0, i = 0, r = 0, S = 0, g = 0, l = "AM";
			    for (var e = 1; e < 60 / MinuteInterval * 24; e++) 60 == (i += MinuteInterval) && (g = n += 1, n >= 12 && (l = "PM"), i = 0), r = n > 12 ? n - 12 : n, 0 == n && (r = 12), 1 == n.toString().length && (g = "0" + n.toString(), g = "0" + n.toString()), 1 == r.toString().length && (r = "0" + r.toString()), S = 1 == i.toString().length ? "0" + i.toString() : i, o.push((g.toString() + ":" + S.toString()));

			    this.optionsHours = o

			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal.confirm_close = true
					setTimeout(() => {
	                	$('#user-submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					if(this.fromPage == 'objective'){
		            	this.$root.$emit('visibilityHiddenObjective', false);
					}
					if(this.fromPage == 'keyresult'){
		            	this.$root.$emit('visibilityHiddenKeyResult', false);
					}
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('#user-submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				var btnSubmit = $('#button-submit-user')
				btnSubmit.attr('disabled', 'disabled')
				this.errorsBe = {}

				this.$v.$touch()
				if(!this.$v.$invalid){
					var btnSubmitLoader = $('#button-submit-user .loader')
					var btnSubmitText = $('#button-submit-user .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					var paramsVerification = { email: this.user.email }
					if(this.editAction){
						paramsVerification.user_id = this.user.id
					}

					if(this.editAction){
						this.updateUser(e)
					}else{
						this.storeUser(e)
					}

				}else{
					btnSubmit.attr('disabled', false)
				}
			},
			storeUser(e){
				var btnSubmit = $('#button-submit-user')
				var btnSubmitLoader = $('#button-submit-user .loader')
				var btnSubmitText = $('#button-submit-user .text')
				axios.post('/people/store', {
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					email: this.user.email,
					role_id: this.isAdmin ? 1 : (this.isManager ? 3 : 2),
					working_days: this.user.working_days,
					promise_time: this.user.promise_time,
					dummy_account: this.user.dummy_account,
					tags: this.tagsSelected
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							this.$auth.fetch().then(() => {
								this.refreshPage(data);
								this.$emit("toggle_modal");
							})
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('users.submit_change') : this.$t('users.submit_create'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			updateUser(e){
				var btnSubmit = $('#button-submit-user')
				var btnSubmitLoader = $('#button-submit-user .loader')
				var btnSubmitText = $('#button-submit-user .text')

				axios.patch('/people/' + this.user.slug, {
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					email: this.user.email,
					role_id: this.isAdmin ? 1 : (this.isManager ? 3 : 2),
					working_days: this.user.working_days,
					promise_time: this.user.promise_time,
					dummy_account: this.user.dummy_account,
					tags: this.tagsSelected
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							if(this.user.slug == this.$auth.user().slug){
								this.$auth.fetch()
							}
							this.$emit("toggle_modal");
							this.refreshPage(data);
						}, 1000)
					}, 300)
				}).catch(error => {
					this.errorsBe = error.response.data.errors

					if(error.response.data.errors.admin_user){
						this.requiredErrorMsg = error.response.data.errors.admin_user[0]
						this.errorData.required_admin = true
					}
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.editAction ? this.$t('users.submit_change') : this.$t('users.submit_create'))
						}, 1000)
					}, 300)
				}).finally(() => {
					setTimeout(()=>{
						btnSubmit.attr('disabled', false)
					}, 1500)
				})
			},
			selectTag(item){
				if(this.tagsSelected.includes(parseInt(item.id))){
					this.tagsSelected.splice(this.tagsSelected.indexOf(parseInt(item.id)), 1)
				}else{
					this.tagsSelected.push(parseInt(item.id))
				}
				this.searchTags = ''
			},
			clearSelectedTags(){
				this.tagsSelected = []
			},
			selectRolId(value, id){
				this.selectedRolId = value.id
			},
			checkInputs(){
				var notEmpty = false

				if(this.user.first_name != '' || this.user.last_name != '' || this.user.email != ''
				){
					notEmpty = true
				}

				return notEmpty
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'user');
			},
			displayAllTags(){
				$(".checkbox-tags").removeClass('hide');
				$(".all-tags").addClass('hide');
			},
			refreshPage(user_detail){
				switch (this.fromPage) {
					case 'users':
						this.$root.$emit('refreshUsersList')
						break;
					case 'objective':
						this.$root.$emit('refreshUsersListOnObjectiveModal', user_detail.data)
						break;
					case 'keyresult':
						this.$root.$emit('refreshUsersListOnKeyResultModal', user_detail.data)
						break;
				}
			},
			checkOtherInput(type){
				if(type == 'admin'){
					if(this.isAdmin){
						this.isManager = false
					}	
				}else if(type == 'manager'){
					if(this.isManager){
						this.isAdmin = false
					}
				}
			}
		}
	};

	function getByKeywordTags(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>