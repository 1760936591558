<template>
	<div class="modal-tbf smaller">
		<div class="container-modal" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $t('reports.people_report_title') }}
					<p class="related_date">{{ requiredData.start_date | moment('DD MMM YYYY') }} - {{ requiredData.end_date | moment('DD MMM YYYY') }}</p>
				</div>
				<div class="actions">
					<div class="action-header-filter dropdown-tbf" v-if="$resize && $mq.above(600)">
						<button class="btn-default-tbf" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!activeUserStatus">{{ $t('filter_users.search_status') }}</span>
							<span class="text" v-else>{{ $t(`user_archivate.status.${activeUserStatus}`) }}</span>
							<icon-arrow class="icon-arrow" v-if="activeUserStatus == ''"/>
							<img class="clearFilter" src="/build/icons/close.svg" @click.stop="changeUsersStatus('')" v-else>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div v-for="objStatus in acceptedUserStatuses" 
							class="dropdown-item" 
							@click="changeUsersStatus(objStatus)" 
							v-bind:class="{active: activeUserStatus == objStatus}">
							{{ $t('user_archivate.status.'+objStatus) }}
							</div>
						</div>
					</div>

					<button class="btn-tbf blue center only-icon" @click="donwloadXLS">
						<icon-download class="icon"/>
					</button>

					<button class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></button>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="report-people">
						<div class="report-item" v-for="user in reportData">
							<div class="column-tbf user-img" v-if="$resize && $mq.above(780)">
								<v-popover offset="0" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" popoverClass="description-popover" :delay="{show: 0, hide: 0}" >
									<img :src="user.avatar" v-if="user.avatar">
									<div class="user-circle" v-else>
										<icon-user-settings/>
									</div>

									<template slot="popover">
										<div class="simple-text">{{ user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + ' ' + user.last_name }}</div>
									</template>
								</v-popover>
							</div>

							<div class="column-tbf row-title">
								<div class="text-medium" v-html="user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1) + ' ' + user.last_name"></div>
							</div>

							<div class="column-tbf column-value promises-count-width" v-if="$resize && $mq.above(780)">
								<div class="label">{{ $t('reports.promise_set')}}</div>
								<div class="value">{{  $tc('reports.promises_count', user.total_promises) }}</div>
							</div>

							<div class="column-tbf column-value promises-count-width" v-if="$resize && $mq.above(780)">
								<div class="label">{{ $t('reports.promise_completed')}}</div>
								<div class="value">{{  $tc('reports.promises_count', user.completed_promises) }}</div>
							</div>

							<div class="column-tbf column-value percent-width">
								<div class="label">{{ $t('reports.percent_ratio')}}</div>
								<div class="value">{{ parseFloat(parseFloat(user.completed_promises_percent).toFixed(2)) }}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconUserSettings from '../Icons/UserSettings'
	import IconArrow from '../Icons/Arrow'
	import IconDownload from '../Icons/Download'

	export default {
		data() {
			return {
				loaded: false,
				reportData: [],
				activeUserStatus: '',
				acceptedUserStatuses: ['active', 'archived']
			};
		},
		props: {
			requiredData: Object
		},
		components: {
			IconPlus,
			IconInfo,
			IconArrow,
			IconDownload,
			IconUserSettings
		},
		mounted() {
			this.getReportPeoplePercentRatio()
		},
		methods: {
			closeModal(){
				this.$emit("toggle_modal");
			},
			getReportPeoplePercentRatio(){
				var paramsObj = {
					start_date: moment(this.requiredData.start_date).format('YYYY-MM-DD'),
					end_date: moment(this.requiredData.end_date).format('YYYY-MM-DD')
				}
				if(this.activeUserStatus) {
					paramsObj.status = this.activeUserStatus
				}

				axios.get(`/reports/${this.$auth.user().instance_id}/company/people-promises-completed`, {
					params: paramsObj 
				})
				.then(({data}) => {
					this.reportData = data.data
				})
				.finally(() => {
					this.loaded = true
				})
			},
			donwloadXLS() {
				var showColumnsObj = ['name', 'total_promises', 'completed_promises', 'completed_promises_percent']

				var paramsCall = {}
				paramsCall.type = 'xls'
				paramsCall.show_columns = showColumnsObj

				paramsCall.start_date = moment(this.requiredData.start_date).format('YYYY-MM-DD')
				paramsCall.end_date = moment(this.requiredData.end_date).format('YYYY-MM-DD')

				if(this.activeUserStatus) {
					paramsObj.status = this.activeUserStatus
				}

				axios.get(`/reports/${this.$auth.user().instance_id}/company/people-promises-completed`, 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = `${this.$t('reports.people_report_title')} ${moment(this.requiredData.start_date).format('DD MMM YYYY')} - ${moment(this.requiredData.end_date).format('DD MMM YYYY')}.xlsx`;

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
			changeUsersStatus(status) {
				if(this.activeUserStatus == status) { 
					this.activeUserStatus = ''
				} else {
					this.activeUserStatus = status
				}
				this.getReportPeoplePercentRatio()
			}
		}
	};
</script>