<template>
	<div class="modal-tbf modal-tag-custom" @keydown.esc="closeModal">
		<div class="container-modal form-modal">
			<div class="header-modal">
				<div class="title">{{ $t('create-tags.title_new') }}</div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf" v-bind:class="{has_error: $v.tag.name.$error, complete: tag.name != '' && !$v.tag.name.$error}">
							<div class="label-input">
								<label>{{ $t('create-tags.name') }}</label>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" class="margin-info-input">
									<div class="info-input">
										<icon-circle-check class="icon-check" v-if="tag.name != '' && !$v.tag.name.$error" />
										<icon-info class="icon-info" v-else/>
									</div>

									<template slot="popover">
										<div class="simple-text">{{ $t('create-tags.name-tooltip-desc') }}</div>
									</template>
								</v-popover>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-tags /></div>
								<div class="icon-right" v-if="tag.name != ''" @click.stop="tag.name = ''"><icon-plus class="icon-clear"/></div>
								<input type="text" :placeholder="$t('create-tags.name-placeholder')" class="input-text" v-model="tag.name" ref="first_input">
							</div>
						</div>

						<div class="input-group-tbf" v-bind:class="{has_error: $v.selectedColor.$error, complete: selectedColor != '' && !$v.selectedColor.$error }">
							<div class="label-input">
								<label>{{ $t('create-tags.color') }}</label>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" class="margin-info-input">
									<div class="info-input">
										<icon-info class="icon-info"/>
									</div>

									<template slot="popover">
										<div class="simple-text">{{ $t('create-tags.color-tooltip-desc') }}</div>
									</template>
								</v-popover>
							</div>
							<div class="radio-colors">
								<div class="tag-color can-select" v-for="color in colors" v-bind:class="[selectedColor == color.id ? 'selected color-' + color.name : 'color-' + color.name]" @click="selectColor(color.id)">
									<img src="/build/icons/settings-tags-color.svg">
									<span></span>
								</div>
							</div>
						</div>
						<div class="submit-form">
							<button class="button-tbf-blue button-submit" @click="saveModifications" id="button-submit-tag">
								<div class="loader"></div>
								<div class="text">{{ $t('create-tags.submit_create') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"
	import IconRemoveTag from "../Icons/IconRemoveTag"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconTags from "../Icons/Tags"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: false,
				selectedColor: '',
				colors: {},
				activeTooltip: '',
				tag: {
					name: ''
				},
				errorData: [],
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                },
			};
		},
		props:{
			model: String
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			IconRemoveTag,
			IconTags
		},
		validations: {
			selectedColor: {required},
			tag: {
				name: {required},
			}
		},
		async mounted() {
			this.tag =  {
				name: ''
			}

			this.$root.$emit('visibilityHiddenUser', true);

			await this.getColors()
		},
		methods: {
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal = {
	                	confirm_close: true
		            }
				}else{
					this.$root.$emit('visibilityHiddenUser', false);
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			customLabel({ first_name, last_name }){
				return `${first_name} ${last_name}`
			},
			async getColors(){
				await axios.get('/colors').then(({data}) => {
					this.colors = data.data
				})
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('#button-submit-tag')
				var btnSubmitLoader = $('#button-submit-tag .loader')
				var btnSubmitText = $('#button-submit-tag .text')

				this.$v.$touch()
				if(!this.$v.$invalid){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))
					this.storeTag(e)
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			storeTag(e){
				var btnSubmit = $('#button-submit-tag')
				var btnSubmitLoader = $('#button-submit-tag .loader')
				var btnSubmitText = $('#button-submit-tag .text')

				axios.post('/tags/store', {
					name: this.tag.name,
					color_id: this.selectedColor,
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$emit("toggle_modal");
							this.$root.$emit('refreshTagsListModal', data.data)
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.$t('create-tagssave_button'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)
				});
			},
			checkInputs(){
				var notEmpty = false

				if(this.tag.name != ''){
					notEmpty = true
				}

				return notEmpty
			}
		}
	};
</script>