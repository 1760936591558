<template>
	<div class="submodal-tbf" id="submodal_multiple_store">
		<div class="header-submodal">
			<div class="title">{{ $t('key-result-tasks.multiple_store_title') }}</div>
		</div>
		<div class="description-modal">{{ $t('key-result-tasks.multiple_store_desc', {n: number}) }}</div>

		<div class="button-actions">
			<button class="btn-default-tbf button-action" @click="singleStore">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('key-result-tasks.multiple_store_btn_single') }}
			</button>
			<button class="btn-blue-tbf button-action" @click="multipleStore">
				<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
				{{ $t('key-result-tasks.multiple_store_btn_multiple', {n: number}) }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {
				loaded: false,
			};
		},
		props: {
			number: Number
		},
		async mounted() {

		},
		methods: {
			singleStore(){
				this.$emit("singleStore");
			},
			multipleStore(){
				this.$emit("multipleStore");
			},
		}
	};
</script>