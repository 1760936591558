<template>
    <div class="system-message">
        <div class="content">
            <div class="icon not-found">
                <img src="/build/icons/not-found.svg">
            </div>
            <div class="title">
                {{ $t('not-found.title') }}
            </div>
            <div class="description">
                {{ $t('not-found.description') }}
            </div>
            <div class="action-button">
                <a class="button-tbf-blue" :href="`${organigram_fe_link}/dashboard`">
                    <span class="text">{{$t('not-found.button')}}</span>
                </a>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        data(){
            return{
                organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK
            }
        },
        mounted(){
            var title = '404';
            var backRoute = 'objectives';
            this.$root.$emit("navbar_title", title, {route: backRoute});
        },
        methods: {
        }
    }
</script>
