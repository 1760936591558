<template>
	<div class="modal-sidebar-translate">
		<div class="top">
			<h1>Translations</h1>

			<button class="btn-action save" @click="saveToFile">
				<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="save" class="svg-inline--fa fa-save fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z"></path></svg>
			</button>
			<button class="btn-action" @click="closeModal">
				<svg class="icon-close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
					<g fill="none" fill-rule="evenodd" class="stroke-color" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
						<path d="M6 0L6 12M0 6L12 6" transform="translate(2 2)"/>
					</g>
				</svg>
			</button>
		</div>

		<div class="textarea-language">
			<label>roRo</label>
			<textarea rows="3">{{ roRo }}</textarea>
		</div>
		<div class="textarea-language last">
			<label>enUs</label>
			<textarea rows="3">{{ enUs }}</textarea>
		</div>

		<div class="short-description">
			Translations for the "{{ component }}" component
		</div>

		<div class="translations-list" v-if="loaded">
			<div class="translation-item" v-for="item in translationData">
				<label>{{ item }}</label>
				<div class="input-lang">
					<span>ro-Ro</span>
					<textarea v-autosize v-model="valuesRo[item]" rows="1"></textarea>
				</div>
				<div class="input-lang">
					<span>en-Us</span>
					<textarea v-autosize v-model="valuesEn[item]" rows="1"></textarea>
				</div>
			</div>
		</div>
		<button class="save-translations" @click="saveToFile">Save</button>


		<div class="submodal-container" v-bind:class="{active: subModalShow}" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<div class="submodal-tbf-translate" id="submodal_confirm_close_translate">
				<div class="header-submodal">
					<div class="title">Atentie!</div>
				</div>
				<div class="description-modal">Datele introduse vor fi sterse la parasirea acestui formular</div>

				<div class="button-actions">
					<button class="btn-default-tbf button-action" @click="cancelClose">
						<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
						Anuleaza
					</button>
					<button class="btn-blue-tbf button-action" @click="confirmClose">
						<div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
						Paraseste formularul
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import roFile from '../../locales/ro.json';
	import enFile from '../../locales/en.json';

	export default {
		data() {
			return {
				loaded: false,
				roRo: roFile,
				enUs: enFile,
				valuesRo: [],
				valuesEn: [],
				subModalShow: false
			};
		},
		props:{
			component: String,
			translationData: Array
		},
		async mounted() {
			if(window.localStorage.getItem('translation_ro')){
				this.roRo = JSON.parse(window.localStorage.getItem('translation_ro'))
			}

			if(window.localStorage.getItem('translation_en')){
				this.enUs = JSON.parse(window.localStorage.getItem('translation_en'))
			}

			if(this.translationData.length){
				this.translationData.map(el => {
					this.valuesRo[el] = el.split('.').reduce((o,i)=>o[i], this.roRo)
					this.valuesEn[el] = el.split('.').reduce((o,i)=>o[i], this.enUs)
				})
			}else{
				
			}

			setTimeout(() => {
				this.loaded = true
			}, 300)
		},
		methods: {
			closeModal(){
				this.subModalShow = true
				setTimeout(() => {
					$('#submodal_confirm_close_translate').addClass('active')
				},200)
			},
			confirmClose(){
				this.$emit("close_modal");
			},
			cancelClose(){
				$('#submodal_confirm_close_translate').removeClass('active')
				setTimeout(() => {
					this.subModalShow = false
				}, 200)
			},
			saveToFile(e){
				Object.keys(this.valuesEn).map((index) => {
					putToObjByPath(this.enUs, index, this.valuesEn[index])
				})

				Object.keys(this.valuesRo).map((index) => {
					putToObjByPath(this.roRo, index, this.valuesRo[index])
				})

				const transRo = JSON.stringify(this.roRo)
				window.localStorage.setItem('translation_ro', transRo);
				const transEn = JSON.stringify(this.enUs)
				window.localStorage.setItem('translation_en', transEn);

				$(e.target).addClass('completed')
				setTimeout(() => {
					$(e.target).removeClass('completed')
				}, 2000)
			}
		}
	};

	function putToObjByPath(obj, path, val) {
		var stringToPath = function (path) {

			if (typeof path !== 'string') return path;

			var output = [];

			path.split('.').forEach(function (item, index) {

				item.split(/\[([^}]+)\]/g).forEach(function (key) {

					if (key.length > 0) {
						output.push(key);
					}

				});

			});

			return output;

		};
		path = stringToPath(path);

		var length = path.length;
		var current = obj;

		path.forEach(function (key, index) {
			if (index === length -1) {
				current[key] = val;
			}
			else {
				if (!current[key]) {
					current[key] = {};
				}
				current = current[key];
			}
		});
	};
	
</script>