<template>
	<div class="modal-promise">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Tutorial Promise', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<section class="header-section">
			<div class="title-desc">
				<div class="title">{{ $t('tutorial-promise.title-modal') }}</div>
				<div class="description">{{ $t('tutorial-promise.description-modal') }}</div>
			</div>
			<div class="actions">
				<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
			</div>
		</section>

		<section class="submit-section">
			<div class="submit-form">
				<button class="button-tbf-blue button-action" @click="closeModal">
					{{ $t('tutorial-promise.ok-btn') }}
				</button>
			</div>
		</section>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"

	export default {
		data() {
			return {
				loaded: false,
				arrayTranslations: [
					'tutorial-promise.title-modal',
					'tutorial-promise.description-modal',
					'tutorial-promise.ok-btn'
				]
			};
		},
		components: {
			IconPlus
		},
		async mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>