<template>
	<div class="modal-tbf">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Add Promise', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>

		<div class="container-modal form-modal" v-if="loaded">
			<div class="header-modal">
				<div class="title">{{ $t('daily_promise.title') }}</div>
				<div class="actions" @click="closeModal">
					<div class="btn-default-tbf close-btn"><icon-plus class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.promise_day.name.$error, complete: promise_day.name != '' && !$v.promise_day.name.$error, 'view-tooltip': activeTooltip == 'promise_description'}">
								<div class="label-input">
									<label>{{ $t('daily_promise.title-label') }}*</label>
								</div>
								<div class="input-box">
									<textarea class="input-textarea" :placeholder="$t('daily_promise.title-input-placeholder')" v-model="promise_day.name" v-autosize  rows="3"></textarea>
								</div>
						</div>

						<div class="input-group-tbf w-mob-100" v-bind:class="{has_error: $v.keyresultsSelected.$error, complete: keyresultsSelected.length > 0  && !$v.keyresultsSelected.$error}">
							<div class="label-input">
								<label>{{ $t('daily_promise.key_results') }}*</label>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" class="margin-info-input">
									<div class="info-input">
										<icon-circle-check class="icon-check" v-if="keyresultsSelected.length > 0 && !$v.keyresultsSelected.$error" />
									</div>
								</v-popover>
							</div>

							<div class="input-box bor-0">
								<div class="icon-left"><icon-tags /></div>
								<div class="icon-right" v-if="keyresultsSelected.length > 0" @click.stop="clearSelectedKeyResults"><icon-plus class="icon-clear"/></div>

								<div class="filter-tags dropdown-tbf">
									<div class="input-form-tags input-box" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span class="value" v-if="keyresultsSelected.length">{{ keyresultsSelected.length == 1 ? optionsKeyResults[0].groupItems.find(el => el.id == keyresultsSelected[0]).name : $t('filter_users.selected', {n: keyresultsSelected.length}) }}</span>
										<span class="placeholder" v-else>{{ $t('daily_promise.search_keyresults') }}</span>
									</div>
									<div class="dropdown-menu" aria-labelledby="dropdownFilter">
										<div class="filter-dropdown">
											<input type="text" v-model="searchKeyResults" :placeholder="$t('filter_users.search_tag_by_krs')">
										</div>
										<div class="dropdown-list-checkboxes">
											<div v-for="keyresult in filteredKeyResults" 
											class="dropdown-item" 
											@click.stop="selectKeyResult(keyresult)" 
											v-bind:class="{active: keyresultsSelected.includes(keyresult.id)}">
												<div class="checkmark"></div>
												<span class="text">{{ keyresult.name }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="submit-form">
							<button class="button-tbf-blue button-submit" @click="saveModifications" id="button-submit-user">
								<div class="loader"></div>
								<div class="text">{{ $t('daily_promise.save_promise') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="submodal-container" v-if="subModalShow" id="promise-submodal-container">
				<div class="overlay-submodal"></div>
				<confirm-close v-if="show_subm_modal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose"></confirm-close>
			</div>
		</div>
		<div class="container-modal form-modal" v-else>
			<div class="header-modal">
				<div class="title">
					<div class="placeholder-loader" style="height: 27px; width: 100px;"></div>
				</div>
				<div class="actions">
					<div class="placeholder-loader" style="height: 35px; width: 35px;"></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group-tbf w-mob-100 w-tbf-100">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 84px;"></div>
						</div>
						<div class="input-group-tbf w-mob-100">
							<div class="label-input">
								<label><div class="placeholder-loader" style="height: 21px; width: 130px;"></div></label>
							</div>
							<div class="input-box placeholder-loader" style="height: 40px;"></div>
						</div>
						<div class="submit-form">
							<div class="placeholder-loader" style="width: 250px;height: 40px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconTags from "../Icons/Tags"

	import ConfirmClose from "../SubModals/ConfirmClose"
	import { required, email } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				editAction: false,
				activeTooltip: '',
				promise_day: {
					name: '',
				},
				selectKeyResultsId: [],
				keyresultsSelected: [],
				optionsKeyResults: [
					{
						groupName: 'Selecteaza toate rezultatele cheie',
						groupItems: []
					}
				],
				subModalShow: false,
                show_subm_modal: {
                	confirm_close: false,
                },
                fields: { text: 'name', value: 'id' },
                isAdmin: 0,
                searchKeyResults: '',
                arrayTranslations: [
                	'daily_promise.title',
                	'daily_promise.title-label',
                	'daily_promise.title-input-placeholder',
                	'daily_promise.key_results',
                	'filter_users.selected',
                	'daily_promise.search_keyresults',
                	'filter_users.search_tag_by_krs',
                	'daily_promise.save_promise'
                ]
			};
		},
		props:{
			model: String,
			fromPage: String
		},
		computed: {
			filteredKeyResults(){
				return getByKeywordKeyResults(this.optionsKeyResults[0].groupItems, this.searchKeyResults)
			}
		},
		components: {
			IconPlus,
			IconInfo,
			IconPeople,
			IconCircleCheck,
			IconArrow,
			IconTags,
			ConfirmClose
		},
		validations: {
			promise_day: {
				name: {required}
			},
			keyresultsSelected: {required}
		},
		async mounted() {
			await this.getUserKeyResults()

			this.$root.$on("confirm_close", () => {
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal.confirm_close = true
					setTimeout(() => {
	                	$('#promise-submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
            });
		},
		methods: {
			async getUserKeyResults(){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { key_results: true } })
				.then(({data}) => {
					this.optionsKeyResults[0].groupItems = data.data.key_results
					this.loaded = true
				})
			},
			closeModal(){
				if(this.checkInputs()){
					this.subModalShow = true
					this.show_subm_modal.confirm_close = true
					setTimeout(() => {
	                	$('#promise-submodal-container').addClass('active');
	                	setTimeout(() => {
	                		$('#submodal_confirm_close').addClass('active');
						}, 200);
	                }, 0);
				}else{
					this.$emit("toggle_modal");
				}
			},
			closeModalConfirmed(){
				this.$emit("toggle_modal");
			},
			cancelClose(){
				$("#submodal_confirm_close").removeClass("active")
				setTimeout(() => {
					$('#promise-submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal.confirm_close = false
					}, 200);
				}, 200);
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					var btnSubmit = $('#button-submit-user')
					var btnSubmitLoader = $('#button-submit-user .loader')
					var btnSubmitText = $('#button-submit-user .text')

					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))
					this.storePromise(e)
				}else{
					$(e.target).attr('disabled', false)
				}
			},
			storePromise(e){
				var btnSubmit = $('#button-submit-user')
				var btnSubmitLoader = $('#button-submit-user .loader')
				var btnSubmitText = $('#button-submit-user .text')
				axios.post('/promises/store',{
					key_result_ids: this.keyresultsSelected,
					name: this.promise_day.name
				}).then(({data}) => {
					$(e.target).attr('disabled', false);
					this.$root.$emit("refreshUserPage");
					this.$emit("toggle_modal");
				})
			},
			selectKeyResult(item){
				if(this.keyresultsSelected.includes(parseInt(item.id))){
					this.keyresultsSelected.splice(this.keyresultsSelected.indexOf(parseInt(item.id)), 1)
				}else{
					this.keyresultsSelected.push(parseInt(item.id))
				}
				this.searchKeyResults = ''
			},
			clearSelectedKeyResults(){
				this.keyresultsSelected = []
			},
			checkInputs(){
				var notEmpty = false

				if(this.promise_day.name != ''){
					notEmpty = true
				}

				return notEmpty
			},
			showModal(type, model = false, requiredData = false, userData = false){
				if(model){
					this.hideDropdown()
				}
				this.$root.$emit('open_modal', type, model, requiredData, userData, 'user');
			}
		}
	};

	function getByKeywordKeyResults(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>