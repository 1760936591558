<template>
	<div class="modal-sidebar left details-org">
		<div class="fe-btn-translation" @click="$root.$emit('open_modal_translation', 'Sidebar Org', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
		<div class="top">
			<h1>{{ mainData.name }}</h1>
			<div class="btn-default-tbf close-btn" @click="closeModal"><icon-plus class="icon-close" /></div>
		</div>

		<div class="short-description">
			{{ mainData.description }}
		</div>

		<div class="list-collapse first-list" v-if="loaded">
			<button class="title-list-coll collapsed" type="button" data-toggle="collapse" data-target="#collapseVisions" aria-expanded="false" aria-controls="collapseVisions">
				{{ $t('tree-chart.sidebar-master-goals') }} ({{ visions.length }})
				<icon-arrow class="icon-arrow"/>
			</button>
			<div class="collapse" id="collapseVisions">
				<div class="item-list" v-for="vision in visions">
					<div class="icon"><img src="/build/icons/drag-icn.svg"></div>
					<div class="name">{{ vision.name ? vision.name : $t('master_goal.private') }}</div>
					<div class="percentage">
						<div class="progress-bar-tbf">
							<div class="complete-progress-tbf" :style="'width: calc(2px + ' + (parseInt(vision.percentage_finished) < 7 ? '2px' : (parseInt(vision.percentage_finished) < 100 ? parseInt(vision.percentage_finished) + '%' : '100%')) + ');'"></div>
						</div>
						<div class="percetange-value">{{ parseInt(vision.percentage_finished) }}%</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-collapse" v-if="loaded">
			<button class="title-list-coll collapsed" type="button" data-toggle="collapse" data-target="#collapseObjectives" aria-expanded="false" aria-controls="collapseObjectives">
				{{ $t('tree-chart.sidebar-objectives') }} ({{ objectives.length }})
				<icon-arrow class="icon-arrow"/>
			</button>
			<div class="collapse" id="collapseObjectives">
				<div class="item-list" v-for="objective in objectives">
					<div class="icon"><img src="/build/icons/drag-icn.svg"></div>
					<div class="name">{{ objective.name ? objective.name : $t('objective.private') }}</div>
					<div class="percentage">
						<div class="progress-bar-tbf">
							<div class="complete-progress-tbf" :style="'width: calc(2px + ' + (parseInt(objective.percent) < 7 ? '2px' : (parseInt(objective.percent) < 100 ? parseInt(objective.percent) + '%' : '100%')) + ');'"></div>
						</div>
						<div class="percetange-value">{{ parseInt(objective.percent) }}%</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-collapse" v-if="loaded">
			<button class="title-list-coll collapsed" type="button" data-toggle="collapse" data-target="#collapseKResults" aria-expanded="false" aria-controls="collapseKResults">
				{{ $t('tree-chart.sidebar-key-results') }} ({{ keyResults.length }})
				<icon-arrow class="icon-arrow"/>
			</button>
			<div class="collapse" id="collapseKResults">
				<div class="item-list" v-for="key_result in keyResults">
					<div class="icon"><img src="/build/icons/drag-icn.svg"></div>
					<div class="name">{{ key_result.name ? key_result.name : $t('objective.kr_private') }}</div>
					<div class="percentage">
						<div class="progress-bar-tbf">
							<div class="complete-progress-tbf" :style="'width: calc(2px + ' + (parseInt(key_result.percent) < 7 ? '2px' : (parseInt(key_result.percent) < 100 ? parseInt(key_result.percent) + '%' : '100%')) + ');'"></div>
						</div>
						<div class="percetange-value">{{ parseInt(key_result.percent) }}%</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPlus from "../Icons/Plus"
	import IconArrow from "../Icons/Arrow"
	import IconMasterGoals from "../Icons/MasterGoals"

	export default {
		data() {
			return {
				loaded: false,
				mainData: {},
				visions: [],
				objectives: [],
				keyResults: [],
				arrayTranslations: [
					'tree-chart.sidebar-master-goals',
					'master_goal.private',
					'tree-chart.sidebar-objectives',
					'objective.private',
					'tree-chart.sidebar-key-results',
					'objective.kr_private'
				]
			};
		},
		props:{
			requiredData: Object
		},
		components: {
			IconPlus,
			IconArrow,
			IconMasterGoals
		},
		async mounted() {
			this.mainData = this.requiredData.main
			this.visions = this.requiredData.visions ? this.requiredData.visions : []
			this.objectives = this.requiredData.objectives ? this.requiredData.objectives : []
			this.keyResults = this.requiredData.key_results ? this.requiredData.key_results : []

			setTimeout(() => {
				this.loaded = true
			}, 300)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>