<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('subscription_general')" class="back-arrow">
			<h1>{{ $t('subscription.upgrade.title') }}</h1>
		</div>
		<div v-if="loaded">
			<div class="subscription-box custom" v-for="subPackage in optionsPackages.filter(el => el.package_bundle == 'Single')" v-bind:class="{active: subPackage.package_id == selectedPackage.package_id}" @click="ChangeActivePlan(subPackage)">
				<div class="top-section">
					<p class="first">
						{{ subPackage.package_name }}
					</p>
					<div class="second">
						<div class="price">
							<span class="old-price" v-if="subPackage.old_price_in_euro != subPackage.price_in_euro">{{ subPackage.old_price_in_euro }}</span>
							{{ newPrices.find((el) => el.package_id == subPackage.package_id) ? newPrices.find((el) => el.package_id == subPackage.package_id).price + ' ' + $auth.user().package.euro_prefix : subPackage.price_in_euro + ' EUR' }}
						</div>
						<span>{{ $t('subscription.monthly') }}</span>
					</div>
				</div>
				<div class="third-section">
					<div class="box">
						<div class="icon"><user-settings-icon /></div>
						<!-- <span v-if="subPackage.user_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.user_limit }} <br>{{ $t('subscription.upgrade.employees') }}</span>
						<span v-else>{{ $t('subscription.upgrade.employees-b') }} <br>{{ $t('subscription.upgrade.unlimited') }}</span> -->
						<span>{{ subPackage.includes[0] }}</span>
					</div>
					<div class="box">
						<div class="icon icon-obj"><icon-objectives-list /></div>
						<!-- <span v-if="subPackage.objective_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.objective_limit }} <br>{{ $t('subscription.upgrade.objectives') }}</span>
						<span v-else>{{ $t('subscription.upgrade.objectives-b') }} <br>{{ $t('subscription.upgrade.unlimiteds') }}</span> -->
						<span>{{ subPackage.includes[1] }}</span>
					</div>
					<div class="box">
						<div class="icon"><settings-goal-icon /></div>
						<!-- <span v-if="subPackage.results_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.results_limit }} <br>{{ $t('subscription.upgrade.key-results') }}</span>
						<span v-else>{{ $t('subscription.upgrade.key-results-b') }} <br> {{ $t('subscription.upgrade.unlimiteds') }}</span> -->
						<span>{{ subPackage.includes[2] }}</span>
					</div>
				</div>
			</div>
			<div class="submit-form">
				<div class="error-msg upgrade-plan-msg" v-if="$v.selectedPackage.$error">{{ $t('validator.error_upgrade') }}</div>
				<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
					<div class="loader"></div>
					<div class="text">{{ $t('subscription.upgrade.modify-plan') }}</div>
				</button>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import UserSettingsIcon from '../Icons/UserSettings';
	import SettingsGoalIcon from '../Icons/SettingsGoal';
	import IconObjectivesList from '../Icons/ObjectivesList'

	export default {
		data() {
			return {
				loaded: false,
				selectedPackage: {package_id: 0},
				optionsPackages: [],
				newPrices: []
			};
		},
		components: {
			UserSettingsIcon,
			SettingsGoalIcon,
			IconObjectivesList
		},
		validations: {
			selectedPackage: {required}
		},
		async mounted() {
			await this.getPackages()
			this.getUserNewPrices();
		},
		methods: {
			async getPackages(){
				await axios.get('packages').then(({data}) => {
					this.optionsPackages = data.data
				})
				.finally(() => {
					if(this.$auth.user().instance){
						if(this.$auth.user().instance.package_id && this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)){
							let currentPackage = this.packagesList.find(el => el.check_package_id == this.$auth.user().instance.package_id)
							this.selectedPackage = currentPackage
						}else{
							this.selectedPackage = {package_id: 0}
						}
					}

					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			getUserNewPrices(){
				axios.post('get-referral-coupon', {instance_id : this.$auth.user().instance_id}).then((data) => {
					if(data.data.is_valid){
						this.newPrices = data.data.values;
					}
				})				
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			},
			saveModifications(e){
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				
				btnSubmit.attr('disabled', 'disabled')

				this.$v.$touch()
				if(!this.$v.$invalid){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					axios.post('/change-plan-account', {
						package_token: this.selectedPackage.token
					}).then(({data}) => {
						btnSubmitLoader.addClass('finish')
						setTimeout(()=>{
							btnSubmitText.html(this.$t('btn-submit.success'))
							btnSubmit.addClass('completed')
							btnSubmitLoader.removeClass('onProgress finish')
							btnSubmit.removeClass('loading')
							setTimeout(()=>{
								btnSubmit.removeClass('completed')
								btnSubmit.attr('disabled', false)
								this.$auth.fetch()
								this.$emit('change_slide', 'subscription_general')
							}, 1000)
						}, 300)
					})
				}else{
					btnSubmit.attr('disabled', false)
				}
			},
			ChangeActivePlan(plan) {
				if(this.$auth.user().instance.package_id != plan.id){
					this.selectedPackage = plan;
				}
			}
		}
	};
</script>