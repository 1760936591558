<template>
	<div class="full-page">
		<lottie :options="defaultOptions" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
		<div class="text-page-access-error">
			<p>{{ textEmpty }}</p>
		</div>
	</div>
</template>


<script>
    import Lottie from 'vue-lottie';
    import * as animationData from '../Animations/tbfdigital_sleep.json';

    export default {
        data(){
            return{
                defaultOptions: {
                    animationData: animationData.default
                }
            }
        },
        props: {
        	textEmpty: String
        },
        components: {
            'lottie': Lottie
        },
        methods: {
            handleAnimation(anim){
                this.anim = anim;
                this.anim.setSpeed(1);
            },
        }
    }
</script>