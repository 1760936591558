<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('list_invoices')" class="back-arrow">
			<h1>{{ $t('invoice-details.title') }}
			</h1>
		</div>
		<div class="form-section">
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.instance.has_company.$error, complete: instance.has_company != '' && !$v.instance.has_company.$error, 'view-tooltip': activeTooltip == 'instance_has_company' }">
				<div class="label-input">
					<label>{{ $t('invoice-details.account-type') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-people /></div>
					<multiselect 
						:allow-empty="false"
						:preserve-search="true"
						v-model="instance.has_company"
						class="select-tags-tbf"
						:options="optionsCompanies" 
						:multiple="false" 
						:close-on-select="true"
						:placeholder="$t('invoice-details.account-type')" 
						track-by="value" 
						label="name"
						:show-labels="false">
						<template slot="noResult">{{ $t('invoice-details.no-results') }}</template>
					</multiselect>
				</div>
			</div>
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.selectedCountry.$error, complete: selectedCountry != '' && !$v.selectedCountry.$error, 'view-tooltip': activeTooltip == 'instance_country' }">
				<div class="label-input">
					<label>{{ $t('invoice-details.country') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-globe /></div>
					<multiselect 
						v-model="selectedCountry"
						class="select-tags-tbf"
						:options="optionsCountries"
						:allow-empty="false"
						:show-labels="false"
						:placeholder="$t('invoice-details.choose-country')" 
						track-by="id" 
						label="name">
							<template slot="singleLabel" slot-scope="props">
								<div class="option_ellipsis">
									{{ props.option.name }}
								</div>
							</template>
							<template slot="option" slot-scope="props">
								<div class="option_ellipsis">
									{{ props.option.name }}
								</div>
							</template>
							<template slot="noResult">{{ $t('invoice-details.no-results') }}</template>
						</multiselect>
				</div>
			</div>
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.instance.name.$error, complete: instance.name != '' && !$v.instance.name.$error}"  v-if="instance.has_company.value == 'individual'">
				<div class="label-input">
					<label>{{ $t('invoice-details.name') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-people /></div>
					<input type="text" :placeholder="$t('invoice-details.palceholder-name')" class="input-text" v-model="instance.name">
				</div>
			</div>
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.instance.cnp.$error, complete: instance.cnp != '' && !$v.instance.cnp.$error, 'view-tooltip': activeTooltip == 'instance_cnp' }"  v-if="instance.has_company.value == 'individual'">
				<div class="label-input">
					<label>{{ $t('invoice-details.cnp') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cnp /></div>
					<input type="text" :placeholder="$t('invoice-details.palceholder-cnp')" class="input-text" v-model="instance.cnp">
				</div>
			</div>
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.instance.company_name.$error, complete: instance.company_name != '' && !$v.instance.company_name.$error, 'view-tooltip': activeTooltip == 'instance_company_name' }"  v-if="instance.has_company.value == 'company'">
				<div class="label-input">
					<label>{{ $t('invoice-details.company') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-building /></div>
					<input type="text" :placeholder="$t('invoice-details.palceholder-company_name')" class="input-text" v-model="instance.company_name">
				</div>
			</div>
			<div class="input-group-tbf w-mob-100 w-tbf-50" v-bind:class="{has_error: $v.instance.cui.$error, complete: instance.cui != '' && !$v.instance.cui.$error, 'view-tooltip': activeTooltip == 'instance_cui' }"  v-if="instance.has_company.value == 'company'">
				<div class="label-input">
					<label>{{ $t('invoice-details.cui') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cui /></div>
					<input type="text" :placeholder="$t('invoice-details.palceholder-cui')" class="input-text" v-model="instance.cui">
				</div>
			</div>
		</div>
		<div class="submit-form">
			<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
				<div class="loader"></div>
				<div class="text">{{ $t('invoice-details.save') }}</div>
			</button>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { required } from 'vuelidate/lib/validators'
	import IconPlus from "../Icons/Plus"
	import IconInfo from "../Icons/Info"
	import IconFolder from "../Icons/Folder"
	import IconArrow from "../Icons/Arrow"
	import IconPeople from "../Icons/People"
	import IconCircleCheck from "../Icons/CircleCheck"
	import IconGlobe from "../Icons/Globe"
	import IconBuilding from "../Icons/Building"
	import IconCircles from "../Icons/Circles"
	import IconCnp from "../Icons/Cnp"
	import IconCui from "../Icons/Cui"

	export default {
		data() {
			return {
				loaded: false,
				instance:{
					name: this.$auth.user().instance.name ? this.$auth.user().instance.name : '',
					cnp: this.$auth.user().instance.cnp ? this.$auth.user().instance.cnp : '',
					company_name: this.$auth.user().instance.company_name ? this.$auth.user().instance.company_name : '',
					cui: this.$auth.user().instance.cui ? this.$auth.user().instance.cui : '',
					has_company: this.$auth.user().instance.has_company ? {value: "company", name: this.$t('invoice-details.company_title')} : {value: "individual", name: this.$t('invoice-details.individual')},
				},
				activeTooltip: '',
				selectedCountry: {},
				optionsCountries: [],
				optionsCompanies: [{value: "individual", name: this.$t('invoice-details.individual')}, {value: "company", name: this.$t('invoice-details.company_title')}]
			};
		},
		components: {
			IconPlus,
			IconInfo,
			IconFolder,
			IconArrow,
			IconPeople,
			IconCircleCheck,
			IconGlobe,
			IconBuilding,
			IconCircles,
			IconCnp,
			IconCui
		},
		validations: {
			selectedCountry: {required},
			instance: {
				name: {required},
				cnp: {required},
				company_name: {required},
				cui: {required},
				has_company: {required}
			}
		},
		async mounted() {
			await this.getCountries()
		},
		methods: {
			async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name:el[1]}
					})
					this.selectedCountry = this.optionsCountries.find(el => el.id == this.$auth.user().instance.country)
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			// async getCardDetails(){
			// 	await axios.get('card-details')
			// 	.then(({data}) => {
			// 		this.cardDetails = data
			// 		this.loaded = true
			// 	})
			// },
			viewSlide(slide){
				this.$emit('change_slide', slide)
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')
				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')
				this.$v.$touch()
				if(this.instance.has_company.value == 'company'){
					if(!this.$v.instance.company_name.$error && !this.$v.instance.cui.$error){
						btnSubmit.addClass('loading')
						btnSubmitLoader.addClass('onProgress')
						btnSubmitText.html(this.$t('btn-submit.loading'))

						axios.post('/edit-instance', {
							country: this.selectedCountry.id,
							has_company: 1,
							company_name: this.instance.company_name,
							cui: this.instance.cui
						}).then(({data}) => {
							btnSubmitLoader.addClass('finish')
							setTimeout(()=>{
								btnSubmitText.html(this.$t('btn-submit.success'))
								btnSubmit.addClass('completed')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('completed')
									btnSubmit.attr('disabled', false)
									this.$auth.fetch()
									this.$emit('change_slide', 'list_invoices')
								}, 1000)
							}, 300)
						})
					}else{
						$(e.target).attr('disabled', false)
					}
				}else if(this.instance.has_company.value == 'individual'){
					btnSubmit.addClass('loading')
					btnSubmitLoader.addClass('onProgress')
					btnSubmitText.html(this.$t('btn-submit.loading'))

					if(!this.$v.instance.name.$error && !this.$v.instance.cnp.$error){
						axios.post('/edit-instance', {
							country_id: this.selectedCountry.id,
							has_company: 0,
							name: this.instance.name,
							cnp: this.instance.cnp
						}).then(({data}) => {
							btnSubmitLoader.addClass('finish')
							setTimeout(()=>{
								btnSubmitText.html(this.$t('btn-submit.success'))
								btnSubmit.addClass('completed')
								btnSubmitLoader.removeClass('onProgress finish')
								btnSubmit.removeClass('loading')
								setTimeout(()=>{
									btnSubmit.removeClass('completed')
									btnSubmit.attr('disabled', false)
									this.$auth.fetch()
									this.$emit('change_slide', 'list_invoices')
								}, 1000)
							}, 300)
						})
					}else{
						$(e.target).attr('disabled', false)
					}
				}
			},
			checkValidation(firstPar, secondPar){
				if(!secondPar){
					this.$v[firstPar].$touch()
				}else{
					this.$v[firstPar][secondPar].$touch()
				}
			}
		}
	};
</script>