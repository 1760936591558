<template>
    <transition name="slide" mode="out-in">
        <div class="register-success-tbf">
            <div class="success-modal">
                <div class="content-modal">
                    <div class="fe-btn-translation p-absolute" @click="$root.$emit('open_modal_translation', 'Forgot Password Success', arrayTranslations)" v-if="globalTranslate">OPEN TRANSLATION</div>
                    <div class="content-icon forgot">
                        <img src="/build/images/bird_forgot.png" class="forgot"/>
                    </div>
                    <div class="title">{{ $t('forgot_password_success.title') }}</div>
                    <div class="container-content-modal">
                        <div class="container-message">
                            {{ $t('forgot_password_success.description_1') }} <strong>{{ emailSend }}</strong> {{ $t('forgot_password_success.description_2') }}
                        </div>
                    </div>
                    <div class="action-form">
                        <button type="submit" class="button-tbf-blue" @click="redirect()">
                            <div class="lds-ring white-spin"><div></div><div></div><div></div><div></div></div>
                                {{ $t('forgot_password_success.button-action') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
	data(){
		return {
            emailSend: '',
            arrayTranslations: [
                'forgot_password_success.title',
                'forgot_password_success.description_1',
                'forgot_password_success.description_2',
                'forgot_password_success.button-action'
            ]
		}
	},
    mounted(){
        if(this.$route.query.email){
            this.emailSend = this.$route.query.email
        }else{
            this.emailSend = ''
        }
    },
	methods: {
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>