<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('list_invoices')" class="back-arrow">
			<h1>{{$t('list-invoices.receiver-mail')}}</h1>
		</div>
		<div class="form-section" v-if="loaded">
			<div class="input-group-tbf">
				<div class="label-input">
					<label>{{$t('list-invoices.receiver-mail-label')}}</label>
				</div>
				<div class="input-box">
					<textarea class="input-textarea" :placeholder="$t('list-invoices.receiver-mail-placeholder')" v-model="receiverMails" v-autosize rows="2"></textarea>
				</div>
			</div>
		</div>
		<div class="form-section" v-else>
			<div class="input-group-tbf">
				<div class="input-box placeholder-loader" style="height: 66px;"></div>
			</div>
		</div>
		<div class="submit-form">
			<button class="button-tbf-blue button-submit" id="updateSendBtn" @click="saveModifications">
				<div class="loader"></div>
				<div class="text">{{$t('list-invoices.btn-set')}}</div>
			</button>
		</div>
	</div>
</template>


<script type="text/javascript">
	import CreditCardIcon from "../Icons/CreditCard"
	import IconPlus from "../Icons/Plus"
	import IconDate from "../Icons/Date"
	import IconPassword from "../Icons/Password"
	import IconInfo from "../Icons/Info"
	import IconCircleCheck from "../Icons/CircleCheck"

	export default {
		data() {
			return {
				loaded: false,
				receiverMails: ''
			};
		},
		components: {
		},
		async mounted() {
			await this.getEmails()
		},
		methods: {
			async getEmails(){
				await axios.get('/instance-notifications')
				.then(({data}) => {
					this.receiverMails = data.data.toString()
				})
				.then(() => {
					this.loaded = true
				})
			},
			viewSlide(slide){
				this.$emit('change_slide', slide)
			},
			saveModifications(e){
				$(e.target).attr('disabled', 'disabled')

				var btnSubmit = $('.button-submit')
				var btnSubmitLoader = $('.button-submit .loader')
				var btnSubmitText = $('.button-submit .text')

				btnSubmit.addClass('loading')
				btnSubmitLoader.addClass('onProgress')
				btnSubmitText.html(this.$t('btn-submit.loading'))

				axios.patch('/instance-notifications', {
					email: this.receiverMails,
				}).then(({data}) => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.success'))
						btnSubmit.addClass('completed')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('completed')
							btnSubmit.attr('disabled', false)
							this.$emit('change_slide', 'list_invoices')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.addClass('finish')
					setTimeout(()=>{
						btnSubmitText.html(this.$t('btn-submit.error'))
						btnSubmit.addClass('error')
						btnSubmitLoader.removeClass('onProgress finish')
						btnSubmit.removeClass('loading')
						setTimeout(()=>{
							btnSubmit.removeClass('error')
							btnSubmitText.html(this.$t('list-invoices.btn-set'))
							btnSubmit.attr('disabled', false)
						}, 1000)
					}, 300)

				});
			}
		}
	};
</script>